
const authConstants = {
    SET_USER: 'SET_USER',

    CHECKING_SESSION: 'USER_CHECKING_SESSION',

    LOGGING_IN: 'USER_LOGGING_IN',
    SET_LOGIN_ERROR: 'SET_LOGIN_ERROR',

    LOGGING_OUT: 'USER_LOGGING_OUT',
    PHONE_LENGTH: 'PHONE_LENGTH'
};

export default authConstants;