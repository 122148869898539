import { UploadRequestOption } from "rc-upload/lib/interface";

class FileUtils {
  static bytesToMb(bytes: number): string {
    return (bytes / 1024 / 1024).toFixed(2);
  }

	//only use on upload component
  static generalDummyRequestLocal = (
    options: UploadRequestOption<any>
  ): void => {
    const { file, onSuccess }: UploadRequestOption<any> = options;
    setTimeout(() => {
      onSuccess && onSuccess("ok");
    }, 0);
  };
}

export default FileUtils;
