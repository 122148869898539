import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import statisticsService from "../../pages/services/statistics";

const initialState = {
  statistics: [],
  //get statistics
  loadingGetStatistics: false,
  errorGetStatistics: null,
  successGetStatistics: false,
};

const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    resetGetStatistics: (state) => {
      state.loadingGetStatistics = false;
      state.errorGetStatistics = null;
      state.successGetStatistics = false;
    },
  },
  extraReducers: (builder) => {
    //get statistics
    builder.addCase(getStatistics.pending, (state) => {
      state.loadingGetStatistics = true;
      state.errorGetStatistics = null;
      state.successGetStatistics = false;
    });
    builder.addCase(getStatistics.fulfilled, (state, action: any) => {
      state.loadingGetStatistics = false;
      state.errorGetStatistics = null;
      state.successGetStatistics = true;
      state.statistics = action.payload;
    });
    builder.addCase(getStatistics.rejected, (state, action: any) => {
      state.loadingGetStatistics = false;
      state.errorGetStatistics = action.payload.error;
      state.successGetStatistics = false;
    });
  },
});

const getStatistics = createAsyncThunk(
  "statistics/getStatistics",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await statisticsService.getStatistics(params);
      return response.data.data;
    } catch (error) {
      return rejectWithValue({
        error,
      });
    }
  }
);

export const { resetGetStatistics } = statisticsSlice.actions;

export { getStatistics };

export default statisticsSlice;
