import React, { useState, useEffect } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import {
  Layout,
  Calendar,
  Table,
  Alert,
  Modal,
  Menu,
  Dropdown,
  Space,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Breadcrumb,
  Button,
  Upload,
  message,
  InputNumber,
  Empty,
} from "antd";
import {
  CheckCircleOutlined,
  CloudUploadOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import moment from "moment";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector, useDispatch } from "react-redux";
import { setPhoneLengthValidation } from "../../../redux/auth/actions";

import styles from "../styles.module.scss";
import { BASE_URL } from "../../../helpers/api";
// import Leftmenu from '../leftmenu';
import Topmenu from "../topmenu";
import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import { reportServices } from "../../../_services/reportServices";
import { facturesServices } from "../../../_services/facturesServices";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { VerticalAlignBottomOutlined } from "@mui/icons-material";
import {
  TemplateBillingSecretary,
} from "../../../helpers/billing";
import { resetReportState } from "../../../redux/report";
import { getAllUsers } from "../../../redux/user";
import { getAllBillsSecretary } from "../../../redux/billing";
import { RoutesDashboard } from "../../../helpers/routes";
import ModalDocument from "../../../components/ModalDocument/ModalDocument";
import { saveAs } from "file-saver";

var mime = require("mime-types");
const { Dragger } = Upload;
const { Header, Footer, Sider, Content } = Layout;
const { Option } = Select;

function BillsMonthSecretary() {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.auth);
  const { billsSecretary, billsDocumentsSecretary } = useSelector(
    (state: any) => state.billing
  );
  const { users } = useSelector((state: any) => state.user);
  const [billsSecretariesFiltered, setBillsSecretariesFiltered]: any = useState(
    []
  );
  const [filters, setFilters] = useState({
    month: moment().format("MMMM"),
    year: moment().format("YYYY"),
    pageSize: 10,
  });
  const [modalViewDocument, setModalViewDocument] = useState({
    visible: false,
    data: {},
  });

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(
      getAllBillsSecretary({
        secretary_id: user.id,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (billsSecretary?.length > 0) {
      let billsFiltered = billsSecretary.filter((bill) => {
        const filteredBillsDocumentsSecretary = billsDocumentsSecretary.filter(
          (billDocument) => {
            const dateBill = moment(bill.last_status_change);
            const dateBillDocument = moment(billDocument.created_at);

            return dateBill.format("YYYY") === dateBillDocument.format("YYYY");
          }
        );

        return filteredBillsDocumentsSecretary.length > 0;
      });

      billsFiltered = billsFiltered.map((bill) => {
        const findedBill = billsDocumentsSecretary.find((billDocument) => {
          const dateBill = moment(bill.last_status_change);
          const dateBillDocument = moment(billDocument.created_at);

          return dateBill.format("YYYY") === dateBillDocument.format("YYYY");
        });

        return {
          ...bill,
          bill: findedBill,
        };
      });

      billsFiltered = billsFiltered.map((bill) => {
        const total_count_pages = bill.reports.reduce(
          (acc: any, report: any) => acc + report.count_pages_retrocession,
          0
        );
        const total_count_photos = bill.reports.reduce(
          (acc: any, report: any) => acc + report.count_photos_retrocession,
          0
        );

        const reports = bill.reports.map((report: any) => {
          return {
            ...report,
            amount_without_taxes_pages:
              Math.round(
                report.count_pages_retrocession -
                report.count_photos_retrocession / 2
              ) *
              bill.secretary_price_page *
              (report.discount > 0 ? report.discount / 100 : 1),
            amount_without_taxes_photos:
              report.count_photos_retrocession *
              bill.secretary_price_photo *
              (report.discount > 0 ? report.discount / 100 : 1),
            bailiff: users.find((user: any) => user.id === report.bailiff_id),
            secretary: users.find(
              (user: any) => user.id === report.secretary_id
            ),
          };
        });

        return {
          ...bill,
          total_count_pages: total_count_pages,
          total_count_photos: total_count_photos,
          secretary: users.find((user: any) => user.id === bill.secretary_id),
          amount_without_taxes:
            (bill.discount > 0
              ? total_count_pages *
              bill.secretary_price_page *
              (bill.discount / 100)
              : total_count_pages * bill.secretary_price_page) +
            (bill.discount > 0
              ? total_count_photos *
              bill.secretary_price_photo *
              (bill.discount / 100)
              : total_count_photos * bill.secretary_price_photo),
          reports: reports,
        };
      });

      setBillsSecretariesFiltered(billsFiltered);
    }
  }, [billsSecretary, filters, users]);

  const columns = [
    {
      title: "Mois",
      key: "month",
      render: (bill) => {
        const date = moment(bill.createdAt);
        return (
          date.format("MMMM").charAt(0).toUpperCase() +
          date.format("MMMM").slice(1)
        );
      },
    },
    {
      title: "Montant HT",
      key: "amount_without_taxes",
      render: (bill) => bill.amount_without_taxes?.toFixed(2) + " €",
    },
    {
      title: "Voir",
      key: "view",
      render: (bill) => {
        const enabled = bill?.bill ? true : false;

        return (
          <Space size="middle">
            <EyeOutlined
              style={enabled ? { opacity: 1 } : { opacity: 0.3 }}
              onClick={(event) => {
                event.stopPropagation();
                if (!enabled) return;

                setModalViewDocument({
                  ...modalViewDocument,
                  visible: true,
                  data: {
                    link: bill.bill.files[0].linkUrl,
                  },
                });
              }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </Space>
        );
      },
    },
    {
      title: "Télécharger",
      key: "download",
      render: (bill) => {
        const enabled = bill?.bill ? true : false;

        return (
          <Space size="middle">
            <VerticalAlignBottomOutlined
              className="clickable"
              style={enabled ? { opacity: 1 } : { opacity: 0.3 }}
              onClick={(event) => {
                event.stopPropagation();
                if (!enabled) return;

                saveAs(
                  bill.bill.files[0].linkUrl,
                  bill.bill.files[0].originalname
                );
              }}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <Layout className="creer-constat-wrapper noleftmenu_section">
      <ModalDocument
        visible={modalViewDocument.visible}
        data={modalViewDocument.data}
        onClose={() => setModalViewDocument({ visible: false, data: {} })}
      />
      <Topmenu />
      <Row>
        <Col xs={24} md={24} lg={24}>
          <div className="dashboard-right-section secretarymybills_wrapper">
            <div className="form_outer_wrapper">
              <div className="onlyscreen_template_inner">
                <div className="onlyscreen_template_wrapper">
                  <div className="form_outer_onlyscreenwrapper">
                    <div className="form_onlyscreenwrapper">
                      <div className="userprofile-layout-wrapper">
                        <Layout>
                          <Sider>
                            <Menu
                              defaultSelectedKeys={["2"]}
                              defaultOpenKeys={["sub2"]}
                              mode="inline"
                              theme="light"
                            >
                              <Menu.Item>
                                <Link to="/dashboard/">Accueil</Link>
                              </Menu.Item>
                              <Menu.Item>
                                <Link to="/dashboard/secretaryprofile">
                                  Mon Profil
                                </Link>
                              </Menu.Item>
                              <Menu.Item key="2">
                                <Link to={RoutesDashboard.billsMonthSecretary}>
                                  Mes Factures
                                </Link>
                              </Menu.Item>
                              <Menu.Item>
                                <Link to="/dashboard/secretarycalendar">
                                  Calendrier
                                </Link>
                              </Menu.Item>
                            </Menu>
                          </Sider>
                          <Layout>
                            <Header>
                              <h2>MES FACTURES</h2>
                            </Header>
                            <Content>
                              <div className="tabletop-filter-section">
                                <Row>
                                  <Col
                                    id="mybillselect"
                                    className="gutter-row"
                                    xs={8}
                                    md={10}
                                    lg={2}
                                  >
                                    <Select
                                      defaultValue="10"
                                      className="table-rows-select"
                                      onChange={(value: any) =>
                                        setFilters({
                                          ...filters,
                                          pageSize: Number(value),
                                        })
                                      }
                                    >
                                      <Option value="10">10</Option>
                                      <Option value="20">20</Option>
                                      <Option value="30">30</Option>
                                      <Option value="40">40</Option>
                                      <Option value="50">50</Option>
                                    </Select>
                                  </Col>
                                  <Col
                                    className="gutter-row"
                                    xs={8}
                                    md={10}
                                    lg={3}
                                  >
                                    <Select
                                      defaultValue={filters.year}
                                      className="table-rows-select-two"
                                      onChange={(value: any) =>
                                        setFilters({
                                          ...filters,
                                          year: value,
                                        })
                                      }
                                    >
                                      {Array.from(
                                        Array(moment().year() - 2019).keys()
                                      ).map((year) => (
                                        <Option value={`${2020 + year}`}>
                                          {2020 + year}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Col>
                                </Row>
                              </div>
                              <Table
                                className="components-table-demo-nested billssecretaries-table"
                                columns={columns}
                                pagination={{ pageSize: filters.pageSize }}
                                dataSource={billsSecretariesFiltered || []}
                                locale={{
                                  emptyText: (
                                    <Empty
                                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                                      description="Aucune facture."
                                    />
                                  ),
                                }}
                              />
                            </Content>
                          </Layout>
                        </Layout>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default BillsMonthSecretary;
