
const servicesConstants = {
  SET_LOADING_SERVICES: "SET_LOADING_SERVICES",
  SET_SERVICES: "SET_SERVICES",
  SET_CREATING_SERVICE: "SET_CREATING_SERVICE",
  SET_CREATED_SERVICE: "SET_CREATED_SERVICE",
  SET_DELETING_SERVICES: "SET_DELETING_SERVICES",
  REMOVE_SERVICE: "REMOVE_SERVICE",
  SET_EDITING_SERVICES: "SET_EDITING_SERVICES",
  SET_EDITED_SERVICES: "SET_EDITED_SERVICES",
};

export default servicesConstants;