import React, { useState, useEffect } from "react"
import { Layout, Table, Menu, Select, Breadcrumb, Input, Empty, Button } from "antd"
import { EditOutlined, SearchOutlined } from "@ant-design/icons"
import { IUsersAPI } from "../../../interfaces/users.interface"
import { usersService } from "../../../_services/usersSerives"
import Createmyaccount from "../../../pages/dashboard/createmyaccount"
import Bailiffaccount from "../../../pages/dashboard/bailiffaccount"
import Admins from "../../../pages/dashboard/adminaccount"
import { authenticationService } from "../../../_services/authentication"
import { useDispatch } from "react-redux"
import { setUser, setLogging } from "../../../redux/auth/actions"

const { Content } = Layout

function SeeTheAccount(props) {
  const dispatch = useDispatch()
  const [allUsers, setAllUsers] = useState(null)
  const [editSections, setEditSections] = useState({
    bailiff: false,
    admin: false,
    secretary: false,
  })

  const [userRoleData, setUserRoleData] = useState({
    bailiff: "",
    admin: "",
    secretary: "",
  })

  useEffect(() => {
    _getUsersList()
  }, [])

  const _editUser = (user: IUsersAPI) => {
    let temp: any = editSections
    let temp2: any = userRoleData
    for (var i in temp) temp[i] = false
    switch (user.role_name) {
      case "Bailiff":
        editSections.bailiff = true
        temp2.bailiff = user
        break
      case "Admin":
        editSections.admin = true
        temp2.admin = user
        break
      case "Secretary":
        editSections.secretary = true
        temp2.secretary = user
        break
      default:
        for (var i in temp) temp[i] = false
        break
    }
    setUserRoleData({ ...userRoleData, ...temp2 })
    setEditSections({ ...editSections, ...temp })
  }

  const columns = [
    {
      title: "",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Nom/ Prénom",
      dataIndex: "nom",
      key: "nom",
    },
    {
      title: "Type d'utilisateur",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Accéder en tant que...",
      dataIndex: "acceder",
      key: "acceder",
    },
    {
      title: "Éditer",
      dataIndex: "edit",
      key: "edit",
      render: (user: IUsersAPI) => (
        <>
          <a onClick={() => _editUser(user)}>
            <EditOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </a>
        </>
      ),
    },
  ]

  const loginToUser = async (user) => {
    dispatch(setLogging(true))
    await authenticationService
      .loginAsUserAPI({ email: user.email })
      .then((response) => response.data)
      .then((response) => {
        // debugger
        dispatch(setLogging(false))
        localStorage.setItem("token", response.tokens.access.token)
        dispatch(setUser(response.user))
        props.history.push("/dashboard")
      })
      .then((err) => {
        dispatch(setLogging(false))
        console.error(err)
      })
  }

  const onBlurHandler = async (event) => {
    if (event.target.value != '') {
      await _getUsersList({ keyword: event.target.value })
    }
    else {
      await _getUsersList()
    }
  }
  const keywordHanderEnter = async (event) => {
    if (event.key == 'Enter') {
      if (event.target.value != '') {
        await _getUsersList({ keyword: event.target.value })
      }
      else {
        await _getUsersList()
      }
    }
  }

  const _getUsersList = async (params = {}) => {
    await usersService
      .getAllUsersAPI(params)
      .then((response) => response.data)
      .then((response: [IUsersAPI]) => {
        let temp: any = []
        response.map((user, index) => {

          let rep: any = []
          if (user.role_name === "Bailiff") {
            rep = "Huissier"

          }
          if (user.role_name === "Admin") {
            rep = "Admin"
          }
          if (user.role_name === "Secretary") {
            rep = "Secr\xE9taire"

          }
          temp.push({
            key: "",
            nom: user.first_name + " " + user.last_name,
            type: rep || "",
            acceder: <a onClick={() => loginToUser(user)}>Se connecter</a>,
            edit: user,
          })

        })
        setAllUsers(temp)
      })
      .catch((err) => console.error(err))
  }

  const isDoneEdit = () => {
    let temp = editSections
    for (var i in temp) temp[i] = false
    setEditSections({ ...editSections, ...temp })
    _getUsersList()
  }


  return (
    //   <Table
    //   columns={columns}
    //   expandable={{
    //     expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
    //     rowExpandable: record => record.date !== 'Not Expandable',
    //   }}
    //   dataSource={data}
    //   className="constats-table"
    // />

    <div className="dashboard-right-section seetheaccount_wrapper_mask">
      <Content style={{ marginLeft: "0px" }}>
        <Breadcrumb>
          <Breadcrumb.Item>Comptes</Breadcrumb.Item>
          <Breadcrumb.Item>Voir les comptes</Breadcrumb.Item>
        </Breadcrumb>
      </Content>
      <h2 className="commonpagetitle_leftmenu">VOIR LES COMPTES</h2>
      <div className="tabletop_individual">
        <Button style={{ marginRight: '1em', height: 'auto' }}
          onClick={() => props.history.push("/dashboard/bailiffaccount")}
        >Créer un compte
        </Button>
        <Input
          size="large"
          className="table-search-input"
          placeholder="Rechercher..."
          prefix={<SearchOutlined className="searchiconalign" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
          onBlur={onBlurHandler}
          onKeyPress={keywordHanderEnter}
        />
      </div>
      {!editSections.secretary && !editSections.admin && !editSections.bailiff && (
        <div className="table_outer_wrapper seetheaccount-table">
          <Table dataSource={allUsers || []}
            columns={columns}
            scroll={{ "x": 400 }}
            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Aucun constat.' /> }}
          />
        </div>
      )}

      {editSections.secretary && (
        <Createmyaccount
          secretaryForm={userRoleData.secretary}
          isDoneEdit={isDoneEdit}
        />
      )}

      {editSections.bailiff && (
        <Bailiffaccount bailifForm={userRoleData.bailiff} isDoneEdit={isDoneEdit} />
      )}

      {editSections.admin && (
        <Admins injectedForm={userRoleData.admin} isDoneEdit={isDoneEdit} />
      )}
    </div>
  )
}

export default SeeTheAccount
