import React, { useEffect, useState } from "react"
import { Redirect, Route } from "react-router-dom"
import Loader from "../components/AkimadLoader"
import { authenticationService } from "../_services/authentication"
import { useDispatch } from "react-redux"
import { setUser } from "../redux/auth/actions"

const ConfigurePrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch()
  const [isLoggedIn, setIsLoggedIn] = useState({
    isLogged: false,
    gotAnswerfromServer: false,
  })

  useEffect(() => {
    const _checkForExistence = async () => {
      const refreshToken = localStorage.getItem("token")
      if (refreshToken) {
        await authenticationService
          .guardAPI(refreshToken)
          .then((response) => {
            if (response.status === 200) {
              dispatch(setUser(response.data))
              setIsLoggedIn({ isLogged: true, gotAnswerfromServer: true })
            }
          })
          .catch((error) => {
            localStorage.clear()
            setIsLoggedIn({ isLogged: false, gotAnswerfromServer: true })
          })
      } else {
        localStorage.clear()
        setIsLoggedIn({ isLogged: false, gotAnswerfromServer: true })
      }
    }
    _checkForExistence()
  }, [])

  return isLoggedIn.gotAnswerfromServer ? (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn.isLogged ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  ) : (
    <Loader />
  )
}
export default ConfigurePrivateRoute
