import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

//admin
import ReportsInProgressAdmin from "../pages/dashboard/reports-in-progress-admin";
import ReportsTerminatedAdmin from "../pages/dashboard/reports-terminated-admin";
import ReportsArchivedAdmin from "../pages/dashboard/reports-archived-admin";
import CreateReportAdmin from "../pages/dashboard/create-report-admin";

import ReportsInProgressMonthBailiff from "../pages/dashboard/reports-in-progress-month-bailiff";
import Mesconstats from "../pages/dashboard/mesconstats";
import Statistiques from "../pages/dashboard/statistiques";
import Retrocessionconstats from "../pages/dashboard/retrocessions-reports-secretary";
import Leftmenu from "../pages/dashboard/leftmenu";
import Facturationtable from "../pages/dashboard/facturationtable";
import Bailiffaccount from "../pages/dashboard/bailiffaccount";
import Secretary from "../pages/dashboard/secretary";
import Creerungroupe from "../pages/dashboard/creerungroupe";
import CreateReportBailiff from "../pages/dashboard/create-report-bailiff";
import Voirlesgroupes from "../pages/dashboard/voirlesgroupes";
import Createmyaccount from "../pages/dashboard/createmyaccount";
import Seethegroup from "../pages/dashboard/seethegroup";
import Admins from "../pages/dashboard/adminaccount";
import Secretaryaccount from "../pages/dashboard/secretaryaccount";
import RetrocessionsReportsAdmin from "../pages/dashboard/retrocessions-reports-admin";
import RetrocessionsBillingsAdmin from "../pages/dashboard/retrocessions-billings-admin";
import Seetheaccount from "../pages/dashboard/seetheaccount";
import allCalendars from "../pages/dashboard/adminCalendar";

import ReportsInProgressSecretary from "../pages/dashboard/reports-in-progress-secretary";
import ReportsMonthSecretary from "../pages/dashboard/reports-month-secretary";
import BillsMonthBailiff from "../pages/dashboard/bills-month-bailiff";
import Bailiffprofile from "../pages/dashboard/bailiffprofile";
import Bailiffcalendar from "../pages/dashboard/bailiffcalendar";

import Secretarycalendar from "../pages/dashboard/secretarycalendar";
import Adminprofile from "../pages/dashboard/adminprofile";
import Secretaryprofile from "../pages/dashboard/secretaryprofile";
import Viewnews from "../pages/dashboard/viewnews";
import Viewnewsecretarie from "../pages/dashboard/viewnewsecretarie";
import Createanalert from "../pages/dashboard/createanalert";
import EditBaliffAccount from "../pages/dashboard/editBailiffAccount";
import Createanews from "../pages/dashboard/createanews";
import ReportsRetrocessionsSecretary from "../pages/dashboard/retrocession-reports-secretary";
import EditNews from "../pages/dashboard/editeanews";
import BillsMonthSecretary from "../pages/dashboard/bills-month-secretary";
import BillingAdmin from "../pages/dashboard/billing-admin";
import Emailtemplate from "../pages/dashboard/emailtemplate";
import EditCreerungroupe from "../pages/dashboard/editCreerungroupe";
import ReportsArchivedMonthBailiff from "../pages/dashboard/reports-archived-month-bailiff";
import EditVoirLesGroupes from "../pages/dashboard/editVoirLesGroupes";
import voirTousNotifications from "../pages/dashboard/voirTousNotifications";

enum RoutesDashboard {
  //Admin----------------------------------------------------------
  //reports admin
  reportsInProgessAdmin = "/dashboard/constats-en-cours-admin",
  reportsTerminatedAdmin = "/dashboard/constats-termines-admin",
  reportsArchivedAdmin = "/dashboard/constats-archives-admin",
  createReportAdmin = "/dashboard/creer-un-constat-admin",
  //retrocessions admin
  retrocessionsReportsAdmin = "/dashboard/retrocessions-constats-admin",
  retrocessionsBillsAdmin = "/dashboard/retrocessions-factures-admin",
  //billing admin
  billingAdmin = "/dashboard/facturation-admin",

  //Bailiff----------------------------------------------------------
  //reports bailiff
  reportsInProgessMonthBailiff = "/dashboard/constats-en-cours-moi-huissier",
  reportsArchivedMonthBailiff = "/dashboard/constats-archives-moi-huissier",
  createReportBailiff = "/dashboard/creer-un-constat-huissier",
  //billing bailiff
  billsMonthBailiff = "/dashboard/mes-factures-huissier",

  //Secretary----------------------------------------------------------
  //reports secretary
  reportsRetrocessionsSecretary = "/dashboard/retrocessions-constats-secretaire",
  reportsInProgressSecretary = "/dashboard/constats-en-cours-secretaire",
  reportsMonthSecretary = "/dashboard/constats-mois-secretaire",
  billsMonthSecretary = "/dashboard/factures-mois-secretaire",
}

const _dashboardAdmin = (
  <Switch>
    {/* Constats */}
    <Route
      exact
      path={RoutesDashboard.reportsInProgessAdmin}
      component={ReportsInProgressAdmin}
    />
    <Route
      exact
      path={RoutesDashboard.reportsArchivedAdmin}
      component={ReportsArchivedAdmin}
    />
    <Route
      exact
      path={RoutesDashboard.reportsTerminatedAdmin}
      component={ReportsTerminatedAdmin}
    />
    <Route
      exact
      path={RoutesDashboard.createReportAdmin}
      component={CreateReportAdmin}
    />
    {/* Retrocessions */}
    <Route
      exact
      path={RoutesDashboard.retrocessionsReportsAdmin}
      component={RetrocessionsReportsAdmin}
    />
    <Route
      exact
      path={RoutesDashboard.retrocessionsBillsAdmin}
      component={RetrocessionsBillingsAdmin}
    />
    {/* Facturation */}
    <Route exact path="/dashboard/bailiffaccount" component={Bailiffaccount} />
    <Route exact path="/dashboard/creerungroupe" component={Creerungroupe} />
    <Route
      exact
      path="/dashboard/creerungroupe/:id"
      component={EditCreerungroupe}
    />
    <Route
      exact
      path="/dashboard/editvoirlesgroupes/:id"
      component={EditVoirLesGroupes}
    />
    <Route exact path="/dashboard/voirlesgroupes" component={Voirlesgroupes} />
    <Route
      exact
      path="/dashboard/createmyaccount"
      component={Createmyaccount}
    />
    <Route exact path="/dashboard/seetheaccount" component={Seetheaccount} />
    <Route
      exact
      path="/dashboard/seetheaccount/:id"
      component={EditBaliffAccount}
    />
    <Route exact path="/dashboard/statistiques" component={Statistiques} />
    <Route exact path="/dashboard/createanews" component={Createanews} />
    <Route
      exact
      path="/dashboard/secretarydashboard"
      component={ReportsInProgressSecretary}
    />
    <Route
      exact
      path="/dashboard/mybillsbailiff"
      component={BillsMonthBailiff}
    />
    <Route exact path="/dashboard/bailiffprofile" component={Bailiffprofile} />
    <Route exact path="/dashboard/adminprofile" component={Adminprofile} />
    <Route
      exact
      path="/dashboard/secretaryprofile"
      component={Secretaryprofile}
    />
    <Route path="/dashboard/seethegroup" component={Seethegroup} />
    <Route exact path="/dashboard/leftmenu" component={Leftmenu} />
    <Route exact path="/dashboard/adminaccount" component={Admins} />
    <Route
      exact
      path="/dashboard/secretaryaccount"
      component={Secretaryaccount}
    />
    <Route exact path="/dashboard/viewnews" component={Viewnews} />
    <Route
      exact
      path="/dashboard/secretarydashboard"
      component={ReportsInProgressSecretary}
    />
    <Route exact path="/dashboard/secretary" component={Secretary} />
    <Route
      exact
      path="/dashboard/bailiffcalendar"
      component={Bailiffcalendar}
    />
    <Route exact path="/dashboard/createanews/:id" component={EditNews} />
    <Route exact path="/dashboard/facturation" component={BillingAdmin} />
    <Route exact path="/dashboard/emailtemplate" component={Emailtemplate} />
    <Route exact path="/dashboard/Facturationtable" component={Emailtemplate} />
    <Route
      exact
      path="/dashboard/mesconstats"
      component={ReportsArchivedAdmin}
    />
    <Route
      exact
      path="/dashboard/seeallnotifications"
      component={voirTousNotifications}
    />
    <Route exact path="/dashboard/allAbsences" component={allCalendars} />
    <Route exact path={["/dashboard"]} component={ReportsInProgressAdmin} />

    <Redirect from="*" to="/dashboard" />
  </Switch>
);

const _dashboardBailiff = (
  <Switch>
    <Route
      exact
      path={[RoutesDashboard.reportsInProgessMonthBailiff]}
      component={ReportsInProgressMonthBailiff}
    />
    <Route
      exact
      path={RoutesDashboard.reportsArchivedMonthBailiff}
      component={ReportsArchivedMonthBailiff}
    />
    <Route
      exact
      path={RoutesDashboard.createReportBailiff}
      component={CreateReportBailiff}
    />
    <Route
      exact
      path={RoutesDashboard.billsMonthBailiff}
      component={BillsMonthBailiff}
    />

    <Route
      exact
      path="/dashboard/bailiffcalendar"
      component={Bailiffcalendar}
    />
    <Route exact path="/dashboard/bailiffprofile" component={Bailiffprofile} />
    <Route exact path="/dashboard/bailiffaccount" component={Bailiffaccount} />
    <Route exact path="/dashboard/mesconstats" component={Mesconstats} />
    <Route exact path="/dashboard/createanalert" component={Createanalert} />
    <Route
      exact
      path="/dashboard/seeallnotifications"
      component={voirTousNotifications}
    />
    <Redirect from="*" to={RoutesDashboard.reportsInProgessMonthBailiff} />
  </Switch>
);

const _dashboardSecretary = (
  <Switch>
    <Route
      exact
      path={RoutesDashboard.reportsRetrocessionsSecretary}
      component={ReportsRetrocessionsSecretary}
    />
    <Route
      exact
      path={RoutesDashboard.reportsInProgressSecretary}
      component={ReportsInProgressSecretary}
    />
    <Route
      exact
      path={RoutesDashboard.reportsMonthSecretary}
      component={ReportsMonthSecretary}
    />
    <Route
      exact
      path={RoutesDashboard.billsMonthSecretary}
      component={BillsMonthSecretary}
    />
    <Route
      exact
      path="/dashboard/retrocessionconstats"
      component={Retrocessionconstats}
    />
    <Route exact path="/dashboard/secretary" component={Secretary} />
    <Route
      exact
      path="/dashboard/secretarycalendar"
      component={Secretarycalendar}
    />
    <Route
      exact
      path="/dashboard/viewnewsecretarie"
      component={Viewnewsecretarie}
    />
    <Route
      exact
      path="/dashboard/secretaryprofile"
      component={Secretaryprofile}
    />
    <Route exact path="/dashboard/createanalert" component={Createanalert} />
    <Route
      exact
      path="/dashboard/seeallnotifications"
      component={voirTousNotifications}
    />
    <Redirect from="*" to={RoutesDashboard.reportsInProgressSecretary} />
  </Switch>
);

const routers = {
  _dashboardAdmin,
  _dashboardBailiff,
  _dashboardSecretary,
};

export { RoutesDashboard, routers };
