import React, { useState } from "react"
import { Link, Route, Switch, useHistory } from "react-router-dom"
import {
  Layout,
  Table,
  Input,
  Badge,
  Menu,
  Dropdown,
  Space,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Breadcrumb,
  Button,
} from "antd"
import {
  UserOutlined,
  VerticalAlignBottomOutlined,
  MailOutlined,
  FolderViewOutlined,
  DownOutlined,
  PlusOutlined,
  CaretRightOutlined,
  SearchOutlined,
} from "@ant-design/icons"

import styles from "../styles.module.scss"

import Leftmenu from "../leftmenu"
import Topmenu from "../topmenu"

const { Content } = Layout
const { Option } = Select

const handleChange = () => {
  console.log(`selected`)
}

const menu = (
  <Menu>
    <Menu.Item>Action 1</Menu.Item>
    <Menu.Item>Action 2</Menu.Item>
  </Menu>
)

function MesConstats() {
  const [paginateNo, setPaginateNo] = useState(10)

  const expandedRowRender = () => {
    const columns = [
      {
        dataIndex: "date",
        key: "date",
        render: () => (
          <div className="tabletoggle-section">
            <Space size="middle" className="tabletoggle-section-title">
              <h2>ÉLÉMENTS DÉPOSÉS</h2>
              <a>
                <PlusOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /> Ajouter un fichier
              </a>
            </Space>
            <div className="tabletoggle-section-list-mask">
              <div className="tabletoggle-section-list">
                <div className="tabletoggle-section-info">
                  <h2>UC_013.mp3</h2>
                  <div className="table-audio-icons">
                    <ul>
                      <li>
                        <CaretRightOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </li>
                      <li>
                        <a><VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-autio-date">
                  <ul>
                    <li>03/10/2017</li>
                    <li>802ko</li>
                    <li>2.03 min</li>
                  </ul>
                </div>
              </div>
              <div className="tabletoggle-section-list">
                <div className="tabletoggle-section-info">
                  <h2>UC_013.mp3</h2>
                  <div className="table-audio-icons">
                    <ul>
                      <li>
                        <CaretRightOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </li>
                      <li>
                        <a><VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-autio-date">
                  <ul>
                    <li>03/10/2017</li>
                    <li>802ko</li>
                    <li>2.03 min</li>
                  </ul>
                </div>
              </div>
              <div className="tabletoggle-section-list">
                <div className="tabletoggle-section-info">
                  <h2>UC_013.mp3</h2>
                  <div className="table-audio-icons">
                    <ul>
                      <li>
                        <CaretRightOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </li>
                      <li>
                        <a> <VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-autio-date">
                  <ul>
                    <li>03/10/2017</li>
                    <li>802ko</li>
                    <li>2.03 min</li>
                  </ul>
                </div>
              </div>
              <div className="tabletoggle-section-list">
                <div className="tabletoggle-section-info">
                  <h2>UC_013.mp3</h2>
                  <div className="table-audio-icons">
                    <ul>
                      <li>
                        <CaretRightOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </li>
                      <li>
                        <a><VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-autio-date">
                  <ul>
                    <li>03/10/2017</li>
                    <li>802ko</li>
                    <li>2.03 min</li>
                  </ul>
                </div>
              </div>
              <div className="tabletoggle-section-list">
                <div className="tabletoggle-section-info">
                  <h2>UC_013.mp3</h2>
                  <div className="table-audio-icons">
                    <ul>
                      <li>
                        <CaretRightOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </li>
                      <li>
                        <a><VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-autio-date">
                  <ul>
                    <li>03/10/2017</li>
                    <li>802ko</li>
                    <li>2.03 min</li>
                  </ul>
                </div>
              </div>
              <div className="tabletoggle-section-list">
                <div className="tabletoggle-section-info">
                  <h2>UC_013.mp3</h2>
                  <div className="table-audio-icons">
                    <ul>
                      <li>
                        <CaretRightOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </li>
                      <li>
                        <a><VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-autio-date">
                  <ul>
                    <li>03/10/2017</li>
                    <li>802ko</li>
                    <li>2.03 min</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        dataIndex: "name",
        key: "name",
        render: () => (
          <div className="tabletoggle-section">
            <Space className="tabletoggle-section-title">
              <h2>ÉLÉMENTS RETOURNÉS</h2>
            </Space>
            <div className="tabletoggle-section-list-mask">
              <div className="tabletoggle-section-list">
                <div className="tabletoggle-section-info">
                  <h2>UC_013.mp3</h2>
                  <div className="table-audio-icons">
                    <ul>
                      {/* <li><CaretRightOutlined /></li> */}
                      <li>
                        <a><VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-autio-date">
                  <ul>
                    <li>03/10/2017</li>
                    <li>802ko</li>
                    <li>2.03 min</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ),
      },
    ]

    const data = [] as any
    for (let i = 0; i < 1; ++i) {
      data.push({
        key: i,
        date: "24/12/2014",
        name: "This is production name",
        upgradeNum: "Upgraded: 56",
      })
    }
    return <Table columns={columns} dataSource={data} pagination={false} />
  }

  const columns = [
    // {title: '', dataIndex:'bullet', key: 'bullet',
    // render: () => (
    //   <span>
    //     <Badge status="success" />
    //   </span>
    //   ),
    // },
    { title: "Date", dataIndex: "date", key: "date" },
    // { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: "Nom du constat", dataIndex: "nomduconstat", key: "nomduconstat" },
    {
      title: "Éléments déposés",
      dataIndex: "elementsdeposes",
      key: "elementsdeposes",
      render: () => (
        <Space size="middle">
          <a>2</a>
          <a>11.61 Kb</a>
        </Space>
      ),
    },
    {
      title: "Éléments retournés",
      dataIndex: "elementsretournes",
      key: "elementsretournes",
      render: () => (
        <Space size="middle">
          <a>2</a>
          <a className="telecharger-link">
            <VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /> Télécharger
          </a>
        </Space>
      ),
    },
    // { title: 'Secrétaire', dataIndex: 'secretaire', key: 'secretaire',
    // render: () => (
    //   <Space size="middle">
    //     <Dropdown overlay={menu} className="table-dropdown">
    //       <a>
    //       Sélectionnez <DownOutlined />
    //       </a>
    //     </Dropdown>
    //     <a className="okbtn">ok</a>
    //   </Space>
    //   ),
    //  },
    { title: "Page", dataIndex: "page", key: "page" },
    { title: "Photos", dataIndex: "photos", key: "photos" },
    { title: "Statut", dataIndex: "statut", key: "statut" },
    {
      title: "Commentaire",
      key: "operation",
      render: () => (
        <Space size="middle">
          <a>
            <MailOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </a>
        </Space>
      ),
    },
    {
      title: "Archiver",
      key: "archiver",
      render: () => (
        <Space size="middle">
          <a>
            <FolderViewOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </a>
        </Space>
      ),
    },
  ]

  const data = [] as any
  for (let i = 0; i < 3; ++i) {
    data.push({
      key: i,
      date: "24/12/2014",
      name: "Diebold",
      nomduconstat:
        "edg-201-rue-st.kd-hilton-constat-1pl-jkl-www-hilton-hilton-madrid-302-kls-pql-90-carac-max",
      page: 2,
      photos: 2,
      statut: "TE",
      createdAt: "24/12/2014",
    })
  }

  return (
    <div className="dashboard-right-section creer-constat-wrapper noleftmenu_section mesconstats_wrapper">
      {/* <Content>
              <Breadcrumb>
                <Breadcrumb.Item>Comptes</Breadcrumb.Item>
                <Breadcrumb.Item>Constats en cours</Breadcrumb.Item>
              </Breadcrumb>
            </Content> */}
      <div className="form_outer_wrapper">
        <div className="topfour-coloum">
          <Row gutter={[24, 24]}>
            <Col className="gutter-row" xs={24} md={6} lg={8}>
              <div className="dashboard-top-one">
                <Row gutter={[2, 24]}>
                  <Col className="gutter-row" xs={4} md={8} lg={4}>
                    <div className="dashboard-icon-center">
                      <img
                        src={"/images/dashboard-top-icon-one.svg"}
                        alt="icon-one"
                        className={styles.responsive_img}
                      />
                    </div>
                  </Col>
                  <Col className="gutter-row" xs={20} md={16} lg={20}>
                    <h2>MES CONSTATS</h2>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className="gutter-row" xs={24} md={6} lg={8}>
              <div className="dashboard-top-two">
                <Row gutter={[2, 24]}>
                  <Col className="gutter-row" xs={4} md={8} lg={4}>
                    <div className="dashboard-icon-center">
                      <img
                        src={"/images/dashboard-top-icon-two.svg"}
                        alt="icon-two"
                        className={styles.responsive_img}
                      />
                    </div>
                  </Col>
                  <Col className="gutter-row" xs={20} md={16} lg={20}>
                    <h2>CRÉER CONSTAT</h2>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className="gutter-row" xs={24} md={6} lg={8}>
              <div className="dashboard-top-three">
                <Row gutter={[2, 24]}>
                  <Col className="gutter-row" xs={4} md={8} lg={4}>
                    <div className="dashboard-icon-center">
                      <img
                        src={"/images/dashboard-top-icon-three.svg"}
                        alt="icon-three"
                        className={styles.responsive_img}
                      />
                    </div>
                  </Col>
                  <Col className="gutter-row" xs={20} md={16} lg={20}>
                    <h2>MES FACTURES</h2>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <div className="form_tableouter_wrapper">
          <h2>MES CONSTATS</h2>
          <Row gutter={[3, 24]} align={'middle'}>
            <Col className="gutter-row" xs={24} md={4} lg={8}>
              <Select
                defaultValue="10"
                className="table-rows-select"
                onChange={(value: any) => setPaginateNo(Number(value))}
              >
                <Option value="10">10</Option>
                <Option value="20">20</Option>
                <Option value="30">30</Option>
                <Option value="40">40</Option>
                <Option value="50">50</Option>
                <Option value="60">60</Option>
              </Select>
            </Col>
            <Col className="gutter-row" xs={24} md={20} lg={16}>
              <Row>
                <Col className="gutter-row" xs={24} md={9} lg={9}>
                  <Button type="primary" htmlType="submit" className="nouvelle-btn">
                    <PlusOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /> Nouvelle indisponibilité
                  </Button>
                </Col>
                <Col className="gutter-row" xs={24} md={9} lg={9}>
                  <Input
                    size="large"
                    className="table-search-input"
                    placeholder="Rechercher..."
                    prefix={<SearchOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                  />
                </Col>
                <Col className="gutter-row" xs={24} md={6} lg={6}>
                  <Link to="" className="tabletop-filter-options">
                    Recherche avancée
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
          <Table
            className="components-table-demo-nested mesconstats-table"
            columns={columns}
            expandable={{ expandedRowRender }}
            dataSource={data}
            pagination={{ pageSize: paginateNo }}
          />
        </div>
      </div>
    </div>
  )
}

export default MesConstats
