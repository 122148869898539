import React from "react";
import { Card, Col, Modal, Row } from "antd";
import { UserOutlined } from "@ant-design/icons";

const ModalInstructions = ({ visible, onClose, data, onOpenFile }) => {
  return (
    <Modal centered footer={null} visible={visible} onCancel={onClose} destroyOnClose={true}>
      <Row>
        <Col xs={24} md={24} lg={24}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <UserOutlined style={{ marginRight: "10px" }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            <h3 style={{ marginTop: "10px" }}>{data.last_name}</h3>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={24} lg={24}>
          <h3>Consignes aux secrétaires : </h3>
          {data.Instructions_to_secretaries == null ||
          data.Instructions_to_secretaries == "" ||
          data.Instructions_to_secretaries == undefined ? (
            <div
              style={{
                borderBlock: "1px solid #979797",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                borderLeft: "1px solid #979797",
                borderRight: "1px solid #979797",
              }}
            >
              <img
                src={"/images/warning-icon.svg"}
                style={{ marginRight: "10px" }}
              />
              <p
                style={{
                  color: "#F8AC3B",
                  textAlign: "center",
                  marginTop: "15px",
                }}
              >
                Il n'y a pas de consignes assignées aux secrétaires
              </p>
            </div>
          ) : (
            <div
              style={{
                borderBlock: "1px solid #979797",
                borderLeft: "1px solid #979797",
                fontWeight: "bold",
                borderRight: "1px solid #979797",
              }}
            >
              <p>{data.Instructions_to_secretaries}</p>
            </div>
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} md={24} lg={24}>
          <h3>Matrices associées a l'utilisateur :</h3>
          {data.matrices_associated && data.matrices_associated.length > 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                borderBlock: "1px solid #979797",
                borderLeft: "1px solid #979797",
                borderRight: "1px solid #979797",
              }}
            >
              {data.matrices_associated.map((e) => {
                return (
                  <div
                    className="site-card-wrapper"
                    style={{ marginTop: "10px" }}
                  >
                    <Row>
                      <Col span={8}>
                        <Card
                          hoverable
                          style={{ width: 120 }}
                          cover={
                            <img
                              style={{ width: 113, height: 113 }}
                              alt="example"
                              src={e}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = "/images/document.svg";
                              }}
                            />
                          }
                          bordered={false}
                          onClick={() => {
                            onOpenFile({ link: e });
                          }}
                        >
                          <p>{e.slice(79)}</p>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                borderBlock: "1px solid #979797",
                alignItems: "center",
                borderLeft: "1px solid #979797",
                borderRight: "1px solid #979797",
              }}
            >
              <img
                src={"/images/warning-icon.svg"}
                style={{ marginRight: "5px" }}
              />
              <p style={{ color: "#F8AC3B", marginTop: "15px" }}>
                Il n'y a pas de matrices assignées aux secrétaires
              </p>
            </div>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalInstructions;
