import api from "../configuration/api";
import globals from "../helpers/globals";
const axios = require("axios").default;
const URL = globals.domain;

const getAllUsersAPI = (params) => {
  const token = localStorage.getItem("token");
  return axios
    .create({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .get(`${URL}/api/v1/users`, { params });
};

const getUsersByIDAPI = (id: string) => {
  const token = localStorage.getItem("token");
  return axios
    .create({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .get(`${URL}/api/v1/users/${id}`);
};

const updateUsersAPI = (id, data) => {
  const token = localStorage.getItem("token");
  if (data?.group_id !== 0) {
    return axios
      .create({
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .put(`${URL}/api/v1/users/${id}`, data);
  }
  return Promise.reject({
    message: "group_id",
    description: "Sélectionnez un groupe",
    status: 400,
  });
};

const createUsersAPI = (data) => {
  const token = localStorage.getItem("token");
  if (data?.group_id !== 0) {
    return axios
      .create({
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .post(`${URL}/api/v1/users`, data);
  }
  return Promise.reject({
    message: "group_id",
    description: "Sélectionnez un groupe",
    status: 400,
  });
};

export const usersService = {
  getAllUsersAPI,
  getUsersByIDAPI,
  updateUsersAPI,
  createUsersAPI,
};

export const userService = {
  getAll: () =>
    api.get("users", {
      headers: {
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
};
