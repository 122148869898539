import React, { useState, useEffect, useRef } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import {
  Layout,
  Table,
  Button,
  Input,
  Badge,
  Menu,
  Modal,
  Dropdown,
  Space,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Breadcrumb,
  message,
  Empty,
  Spin,
} from "antd";
import { AlignType } from 'rc-table/lib/interface'
import {
  VerticalAlignBottomOutlined,
  MailOutlined,
  EyeOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import { Base64 } from 'js-base64';

import styles from "../styles.module.scss";
import download from "downloadjs";
import domtoimage from "dom-to-image";
import Leftmenu from "../leftmenu";
import Topmenu from "../topmenu";
import Facturationtable from "../facturationtable";
import { facturesServices } from "../../../_services/facturesServices";
import { reportServices } from "../../../_services/reportServices";
import { groupServices } from "../../../_services/groupsServices";
import { constants } from "buffer";
import BailifHeader from "../commonbailiffheader";
import moment from "moment";
import { isNumber } from "util";
import { report } from "process";
import { getBillId, sleep } from "../../../utils/functions";
import { saveAs } from "file-saver";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
} from "../../../firebase/firebase";
import AdvanceFilterOptions from "../../../components/AdvanceFilterOptions";
import { useDispatch, useSelector } from "react-redux";
import {
  getAvailablesGroupsBillings,
  createBill as createBillBilling,
  resetBillingState,
  resetCreateBillState,
  sendBillToEmail,
  resetSendBillToEmailState,
  setBillPaid,
  resetSetBillPaidState,
  generateBillPdf,
  resetGenerateBillPdfState,
  getNextBillNumber,
} from "../../../redux/billing";
import ModalSetupBilling from "../../../components/ModalSetupBilling/ModalSetupBilling";
import {
  TemplateBillingBailiff,
} from "../../../helpers/billing";
import { getAllUsers } from "../../../redux/user";
import ReactDOMServer from "react-dom/server";
import ModalDocument from "../../../components/ModalDocument/ModalDocument";

moment.locale("fr");
const { Content } = Layout;
const { Option } = Select;

function BillingAdmin() {
  const dispatch = useDispatch();
  const {
    availablesGroupsBillings,
    successCreateBill,
    errorCreateBill,
    loadingGenerateBillPdf,
    successGenerateBillPdf,
    errorGenerateBillPdf,
    successSendBillToEmail,
    errorSendBillToEmail,
    successSetBillPaid,
    errorSetBillPaid,
    nextBillNumber,
  } = useSelector((state: any) => state.billing);
  const { users } = useSelector((state: any) => state.user);
  const [availablesGroupsBillingsFiltered, setBillingsFiltered]: any = useState(
    []
  );
  const [generatedBillNumber, setGeneratedBillNumber] = useState()
  const [filters, setFilters] = useState({
    month: moment().format("MMMM"),
    year: moment().format("YYYY"),
  });
  const [modalViewDocument, setModalViewDocument] = useState({
    visible: false,
    data: {},
  });
  const [modalSetupBilling, setModalSetupBilling]: any = useState({
    visible: false,
    group: {},
    data: [],
  });
  const [dataBilling, setDataBilling]: any = useState([]);

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getAvailablesGroupsBillings());
    dispatch(getNextBillNumber());
  }, [
    dispatch,
    successSetBillPaid,
    errorSetBillPaid,
    successCreateBill,
    successSendBillToEmail,
  ]);

  useEffect(() => {
    if (nextBillNumber) {
      setGeneratedBillNumber(nextBillNumber)
    }
  }, [nextBillNumber])


  useEffect(() => {
    if (availablesGroupsBillings?.length > 0) {
      let billingsFiltered = availablesGroupsBillings.map((billing) => {
        const reports = billing.reports.map((report: any) => {
          return {
            ...report,
            amount_without_taxes_pages:
              (
                report.count_pages_billing - Math.round(report.count_photos_billing / 2)
              ) * billing.group_price_page,
            amount_without_taxes_photos:
              report.count_photos_billing * billing.group_price_photo,
            bailiff: users.find((user: any) => user.id === report.bailiff_id),
            secretary: users.find(
              (user: any) => user.id === report.secretary_id
            ),
          };
        });

        const actualBill = billing?.bills?.find(
          (bill: any) =>
            moment(bill.createdAt).format("MMMM") === filters.month &&
            moment(bill.createdAt).format("YYYY") === filters.year
        );

        let reportsBilled = reports.filter((report: any) =>
          actualBill?.reports?.find(
            (reportBill: any) => reportBill.id === report.id
          )
        );

        reportsBilled = reportsBilled.map((report: any) => {
          const reportFinded = actualBill?.reports?.find(
            (reportBill: any) => reportBill.id === report.id
          );

          const amount_without_taxes_pages_with_discount =
            report.count_pages_billing *
            billing.group_price_page *
            (1 - (reportFinded?.discount || 0) / 100);

          const amount_without_taxes_photos_with_discount =
            report.count_photos_billing *
            billing.group_price_photo *
            (1 - (reportFinded?.discount || 0) / 100);

          return {
            ...report,
            amount_without_taxes_pages_with_discount:
              amount_without_taxes_pages_with_discount,
            amount_without_taxes_photos_with_discount:
              amount_without_taxes_photos_with_discount,
          };
        });

        const total_count_pages = reportsBilled.reduce(
          (acc: any, report: any) => {
            return acc + report.count_pages_billing;
          },
          0
        );

        const total_count_photos = reportsBilled.reduce(
          (acc: any, report: any) => {
            return acc + report.count_photos_billing;
          },
          0
        );

        return {
          ...billing,
          ...(actualBill ? { bill: actualBill } : {}),
          total_count_pages: total_count_pages,
          total_count_photos: total_count_photos,
          amount_without_taxes:
            reportsBilled.reduce((acc: any, report: any) => {
              return (
                acc +
                report.amount_without_taxes_pages_with_discount +
                report.amount_without_taxes_photos_with_discount
              );
            }, 0) *
            (1 - (actualBill?.discount || 0) / 100),
          reports: reports.filter((report: any) => {
            const date = moment(report.last_status_change);
            return (
              !billing?.bills?.find((bill: any) =>
                bill.reports.find(
                  (reportBill: any) => reportBill.id === report.id
                )
              ) ||
              actualBill?.reports?.find(
                (reportBill: any) => reportBill.id === report.id
              )
            );
          }),
        };
      });

      setBillingsFiltered(billingsFiltered);
    }
  }, [availablesGroupsBillings, filters, users]);

  useEffect(() => {

    if (successCreateBill) {
      message.success("facture créée avec succès");
      setBillingsFiltered(
        availablesGroupsBillingsFiltered.map((billing: any) => {
          if (billing.group_id === dataBilling.group_id) {
            return {
              ...billing,
              loading_create_bill: false,
              bill: successCreateBill,
            };
          }
          return billing;
        })
      );
    }

    if (errorCreateBill) {
      message.error("erreur lors de la création de la facture");
      setBillingsFiltered(
        availablesGroupsBillingsFiltered.map((billing: any) => {
          if (billing.group_id === dataBilling.group_id) {
            return {
              ...billing,
              loading_create_bill: false,
            };
          }
          return billing;
        })
      );
    }

    if (successSendBillToEmail) {
      message.success("Facture envoyée avec succès");
      setBillingsFiltered(
        availablesGroupsBillingsFiltered.map((billing: any) => {
          return {
            ...billing,
            loading_send_bill_to_email: false,
          };
        })
      );
    }

    if (errorSendBillToEmail) {
      message.error("erreur lors de l'envoi de la facture");
      setBillingsFiltered(
        availablesGroupsBillingsFiltered.map((billing: any) => {
          return {
            ...billing,
            loading_send_bill_to_email: false,
          };
        })
      );
    }

    dispatch(resetCreateBillState());
    dispatch(resetSendBillToEmailState());
    dispatch(resetSetBillPaidState());
  }, [
    successCreateBill,
    errorCreateBill,
    successSendBillToEmail,
    errorSendBillToEmail,
    successSetBillPaid,
    errorSetBillPaid,
  ]);

  useEffect(() => {
    if (successGenerateBillPdf) {
      // Récupérer le blob PDF et continuer le traitement
      const blobPdf = successGenerateBillPdf;
      const filtersDate = moment(`${filters.month} ${filters.year}`, "MMMM YYYY");
      const date = moment(dataBilling.reports[0].last_status_change);
      const fileName = `facture-${dataBilling.billing_number}.pdf`;

      const formData = new FormData();
      dataBilling.reports.forEach((report: any) => {
        formData.append("reports", report.id);
        formData.append("discounts", report.discount);
      });
      formData.append("group_id", dataBilling.group_id);
      formData.append("discount", dataBilling.discount);
      formData.append("bill_number", dataBilling.billing_number);
      formData.append("bill", blobPdf, fileName.toLowerCase().replace(/\s/g, ""));
      // window.open(URL.createObjectURL(blobPdf)) // Use to debug pdf template

      dispatch(createBillBilling(formData));
      dispatch(resetGenerateBillPdfState());
    }

    if (errorGenerateBillPdf) {
      message.error("Error generating PDF");
      dispatch(resetGenerateBillPdfState());
    }
  }, [successGenerateBillPdf, errorGenerateBillPdf, dispatch, dataBilling, filters]);

  const createBill = async (group: any) => {

    if (!generatedBillNumber) {
      message.error('Error getting next bill number')
      return
    }
    const { billing_number } = generatedBillNumber

    setBillingsFiltered(
      availablesGroupsBillingsFiltered.map((billing: any) => {
        if (billing.group_id === group.group_id) {
          return {
            ...billing,
            loading_create_bill: true,
          };
        }
        return billing;
      })
    );

    let billingData = modalSetupBilling.data.find(
      (item: any) => item.group_id === group.group_id
    );
    billingData = { billing_number, ...billingData }

    setDataBilling(billingData);
    await new Promise((resolve) => setTimeout(resolve, 1000));

    const footer = <TemplateBillingBailiff data={billingData} footerOnly />
    const content = <TemplateBillingBailiff data={billingData} contentOnly />
    const footerRootElement = document.createElement('div');
    footerRootElement.innerHTML = ReactDOMServer.renderToString(footer);
    const contentRootElement = document.createElement('div');
    contentRootElement.innerHTML = ReactDOMServer.renderToString(content);

    if (footerRootElement && contentRootElement) {
      dispatch(generateBillPdf({
        footer: Base64.encode(footerRootElement.innerHTML),
        content: Base64.encode(contentRootElement.innerHTML),
      }));
    }
  };

  const columns = [
    {
      title: "Clients à facturer",
      key: "group_name",
      align: "left" as AlignType,
      render: (group) => {
        return (
          <Link to={`/dashboard/creerungroupe/${group.group_id}/`}>
            <span>{group.group_name}</span>
          </Link>
        );
      },
    },
    {
      title: "Nombre pages confirmées",
      key: "total_billing_page",
      render: (group) => {
        return <span>{group.total_count_pages || "-"}</span>;
      },
    },
    {
      title: "Nombre photos confirmées",
      key: "total_billing_photo",
      render: (group) => {
        return <span>{group.total_count_photos || "-"}</span>;
      },
    },
    {
      title: "Montant HT",
      key: "total_billing_amount",
      render: (group) => {
        const value = group.amount_without_taxes
          ? group.amount_without_taxes?.toFixed(2) + "€"
          : "-";
        return <span>{value}</span>;
      },
    },
    {
      title: "Sélection constats",
      key: "selectreports",
      render: (group) => {
        const canViewOrSelect =
          (moment(filters.month, "MMMM").month() >= moment().month() &&
            moment(filters.year, "YYYY").year() >= moment().year()) ||
            group?.bill
            ? true
            : false;
        return (
          <span
            className={canViewOrSelect ? "clickable" : ""}
            onClick={() => {
              if (canViewOrSelect) {
                setModalSetupBilling({
                  ...modalSetupBilling,
                  visible: true,
                  group: group,
                  data: [],
                });
              }
            }}
          >
            Détails
          </span>
        );
      },
    },
    {
      title: "Générer facture",
      key: "generateinvoice",
      render: (group) => {
        const isActualDate = moment().format("MMMM") === filters.month && moment().format("YYYY") === filters.year;
        const enabled =
          modalSetupBilling.data.length > 0 &&
            modalSetupBilling.data.find(
              (item: any) => item.group_id === group.group_id
            ) && isActualDate
            ? true
            : false;
        const withBill = group?.bill ? true : false;

        return (
          <Space size="middle">
            <LoginOutlined
              style={enabled && !withBill ? { opacity: 1 } : { opacity: 0.3 }}
              onClick={(event) => {
                event.stopPropagation();
                if (!enabled || withBill) return;

                createBill(group);
              }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            {group?.loading_create_bill && <Spin />}
          </Space>
        );
      },
    },
    {
      title: "Envoyer facture",
      key: "sendinvoice",
      render: (group) => {
        const enabled = group?.bill ? true : false;

        return (
          <Space size="middle">
            <div className={group?.bill?.is_sent ? "red-dot-container" : ""}>
              {group?.bill?.is_sent ? (
                <div className="green-dot-alert" />
              ) : null}
              <MailOutlined
                style={enabled ? { opacity: 1 } : { opacity: 0.3 }}
                onClick={(event) => {
                  event.stopPropagation();
                  if (!enabled) return;

                  setBillingsFiltered(
                    availablesGroupsBillingsFiltered.map((billing: any) => {
                      if (billing.group_id === group.group_id) {
                        return {
                          ...billing,
                          loading_send_bill_to_email: true,
                        };
                      }
                      return billing;
                    })
                  );

                  dispatch(
                    sendBillToEmail({
                      bill_id: group.bill.id,
                      group_id: `${group.group_id}`,
                    })
                  );
                }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              {group?.loading_send_bill_to_email && <Spin />}
            </div>
          </Space>
        );
      },
    },
    {
      title: "Voir",
      key: "view",
      render: (group) => {
        const enabled = group?.bill ? true : false;
        return (
          <Space size="middle">
            <EyeOutlined
              style={enabled ? { opacity: 1 } : { opacity: 0.3 }}
              onClick={(event) => {
                event.stopPropagation();
                if (!enabled) return;

                setModalViewDocument({
                  ...modalViewDocument,
                  visible: true,
                  data: {
                    link: group.bill.files[0].linkUrl,
                  },
                });
              }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </Space>
        );
      },
    },
    {
      title: "Télécharger",
      key: "download",
      render: (group) => {
        const enabled = group?.bill ? true : false;
        return (
          <Space size="middle">
            <VerticalAlignBottomOutlined
              style={enabled ? { opacity: 1 } : { opacity: 0.3 }}
              onClick={(event) => {
                event.stopPropagation();
                if (!enabled) return;

                saveAs(
                  group.bill.files[0].linkUrl,
                  group.bill.files[0].originalname
                );
              }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </Space>
        );
      },
    },
    {
      title: "Payé",
      key: "paid",
      render: (group) => {
        return (
          <Checkbox
            checked={group?.bill?.is_paid ? true : false}
            onChange={(event) => {
              event.stopPropagation();
              if (!group?.bill) return;

              dispatch(
                setBillPaid({
                  bill_id: group.bill.id,
                  is_paid: event.target.checked,
                })
              );
            }}
          ></Checkbox>
        );
      },
    },
  ];

  return (
    <div className="dashboard-right-section retrocessionadminreports_wrapper">
      <Content style={{ marginLeft: "0px" }}>
        <Breadcrumb>
          <Breadcrumb.Item>Facturation</Breadcrumb.Item>
        </Breadcrumb>
      </Content>
      <ModalDocument
        visible={modalViewDocument.visible}
        data={modalViewDocument.data}
        onClose={() => setModalViewDocument({ visible: false, data: {} })}
      />
      <ModalSetupBilling
        visible={modalSetupBilling.visible}
        group={modalSetupBilling.group}
        onClose={() => {
          setModalSetupBilling({
            ...modalSetupBilling,
            visible: false,
          });
        }}
        onDone={(data) => {
          const groupAlreadyExist = modalSetupBilling.data.find(
            (group: any) => group.group_id === data.group_id
          );

          if (groupAlreadyExist) {
            const newData = modalSetupBilling.data.map((group) => {
              if (group.group_id === data.group_id) {
                return data;
              } else {
                return group;
              }
            });

            return setModalSetupBilling({
              ...modalSetupBilling,
              visible: false,
              data: newData,
            });
          }

          setModalSetupBilling({
            ...modalSetupBilling,
            visible: false,
            data: [...modalSetupBilling.data, data],
          });
        }}
      />
      <div className="form_tableouter_wrapper">
        <h2>FACTURATION</h2>

        <div className="tabletop-filter-section">
          <Row style={{ justifyContent: "space-between" }}>
            <Col className="gutter-row" lg={2}>
              <Select
                value={filters.year}
                onChange={(value) => {
                  setModalSetupBilling({
                    visible: false,
                    group: {},
                    data: [],
                  });
                  setFilters({
                    ...filters,
                    year: value,
                  });
                }}
              >
                {Array.from(Array(moment().year() - 2019).keys()).map(
                  (year, index) => (
                    <Option key={index} value={`${2020 + year}`}>{2020 + year}</Option>
                  )
                )}
              </Select>
            </Col>
            <Col className="gutter-row" lg={2}>
              <Select
                value={filters.month}
                onChange={(value) => {
                  setModalSetupBilling({
                    visible: false,
                    group: {},
                    data: [],
                  });
                  setFilters({
                    ...filters,
                    month: value,
                  });
                }}
              >
                {moment.months().map((month, index) => (
                  <Option key={index} value={month}>
                    {month.charAt(0).toUpperCase() + month.slice(1)}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col className="gutter-row" xs={24} md={7} lg={17}>
              <div className="table-toptable-layout">
                <div className="left-section">
                  <div className="left-section-top-title">
                    <div className="top-title-left-section">CUMUL PÉRIODE</div>
                    <div className="top-title-right-section">Total</div>
                  </div>
                  <div className="left-section-content">
                    <div className="left-section-content-left">
                      Nb de constats
                    </div>
                    <div className="left-section-content-right">
                      {availablesGroupsBillingsFiltered.reduce(
                        (acc, billing) => {
                          return acc + billing.reports.length;
                        },
                        0
                      )}
                    </div>
                  </div>
                  <div className="left-section-content">
                    <div className="left-section-content-left">
                      Nb de pages texte
                    </div>
                    <div className="left-section-content-right">
                      {availablesGroupsBillingsFiltered.reduce(
                        (acc, billing) => {
                          return acc + billing.total_count_pages;
                        },
                        0
                      )}
                    </div>
                  </div>
                  <div className="left-section-content">
                    <div className="left-section-content-left">
                      Nb de photos
                    </div>
                    <div className="left-section-content-right">
                      {availablesGroupsBillingsFiltered.reduce(
                        (acc, billing) => {
                          return acc + billing.total_count_photos;
                        },
                        0
                      )}
                    </div>
                  </div>
                </div>
                <div className="right-section">
                  <div className="left-section-top-title">
                    <div className="top-title-right-section">Total HT</div>
                  </div>
                  <div className="left-section-content">
                    <div className="left-section-content-total">
                      {availablesGroupsBillingsFiltered
                        .reduce((acc, billing) => {
                          return acc + billing.amount_without_taxes;
                        }, 0)
                        .toFixed(2) + " €"}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div style={{ width: "0px", height: "0px", overflow: "hidden" }}>
          {dataBilling && Object.keys(dataBilling).length > 0 && (
            <TemplateBillingBailiff data={dataBilling} contentOnly />
          )}
        </div>

        <Table
          className="facturation-table"
          columns={columns}
          dataSource={availablesGroupsBillingsFiltered.map((billing) => ({
            ...billing,
            key: billing.group_id
          })) || []}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Aucun constat."
              />
            ),
          }}
        />
      </div>
    </div>
  );
}

export default BillingAdmin;
