import React, { useState, useEffect } from "react"
import { Layout, Table, Breadcrumb, Input, Button, Empty } from "antd"
import { EditOutlined, SearchOutlined } from "@ant-design/icons"
import { groupServices } from "../../../_services/groupsServices"
import moment from "moment"
import { Link } from "react-router-dom"
const { Content } = Layout

function VoirlesGroupes({ history }) {
  const [allGroups, setAllGroups] = useState([])
  const [selectedGroup, setSelecetedGroup] = useState(null)

  useEffect(() => {
    _getAllGroups()
  }, [])

  const keywordHander = async (event) => {
    await _getAllGroups({ keyword: event.target.value })
  }
  const keywordHanderEnter = async (event) => {
    if (event.key == 'Enter') {
      await _getAllGroups({ keyword: event.target.value })
    }
  }

  const _getAllGroups = async (params = {}) => {
    await groupServices
      .getAllGroupsAPI(params)
      .then((response) => response.data)
      .then(async (response: any) => {
        let temp: any = []
        response.map((group, index) => {
          temp.push({
            key: index,
            name: group.group_name,
            platform: group.count,
            edit: group,
          })
        })
        setAllGroups(temp)
      })
      .catch((err) => console.error(err))
  }

  const columns = [
    { title: "Nom de groupe", dataIndex: "name", key: "name" },
    { title: "Nombre de personnes", dataIndex: "platform", key: "platform" },
    {
      title: "Éditer",
      dataIndex: "edit",
      key: "edit",
      render: (group: any) => (
        //<a onClick={() => history.push(`/dashboard/creerungroupe/${group.id}`)}>
        <a onClick={() => history.push(`/dashboard/editvoirlesgroupes/${group.id}`)}>
          <EditOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        </a>
      ),
    },
  ]

  const ExpandedRowRender = (record) => {
    const [allGroupMembers, setAllGroupMembers] = useState([])

    const _getMembers = async (id) => {
      await groupServices
        .getGroupByID(id)
        .then((response) => response.data)
        .then(async (response: any) => {
          let temp: any = []
          response.map((member, index) => {
            temp.push({
              date: member.first_name + " " + member.last_name,
              name: moment(member.created_at).format("DD/MM/YYYY HH:mm"),
              edit: member,
            })
          })
          setAllGroupMembers(temp)
        })
    }

    useEffect(() => {
      _getMembers(record.edit.id)
    }, [record.edit.id])

    if (record.platform == "0") {
      return null
    }

    if (allGroupMembers.length === 0) {
      return null
    }

    let columns = [
      { title: "Huissiers", dataIndex: "date", key: "date" },
      { title: "Dernière Activité", dataIndex: "name", key: "name" },
      {
        title: "Éditer",
        dataIndex: "edit",
        key: "edit",
        render: (member) => (
          <a
            onClick={() =>
              history.push(`/dashboard/seetheaccount/${member.user_id}`)
            }
          >
            <EditOutlined className="inner" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </a>
        ),
      },
    ]

    return (
      <Table
        className="voirlesgroupes_tableinner"
        columns={columns}
        dataSource={allGroupMembers || []}
        pagination={false}
      />
    )
  }

  return (
    <div className="dashboard-right-section voirlesgroupes_wrapper_mask">
      <Content style={{ marginLeft: "0px" }}>
        <Breadcrumb>
          <Breadcrumb.Item>Comptes</Breadcrumb.Item>
          <Breadcrumb.Item>Voir les groupes</Breadcrumb.Item>
        </Breadcrumb>
      </Content>
      <div className="form_tableouter_wrapper">
        <h2 className="innerpages_title">VOIR LES GROUPES</h2>
        <div className="tabletop_individual">
          <Link to='/dashboard/creerungroupe'>
            <Button style={{ marginRight: '1em', height: '100%' }}>Créer un groupe</Button>
          </Link>
          <Input
            size="large"
            className="table-search-input"
            placeholder="Rechercher..."
            prefix={<SearchOutlined className="searchiconalign" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
            onBlur={keywordHander}
            onKeyPress={keywordHanderEnter}
          />
        </div>
        <Table
          className="components-table-demo-nested voirlesgroupes_table"
          columns={columns}
          dataSource={allGroups || []}
          expandable={{
            expandedRowRender: (record: any) => <ExpandedRowRender {...record} />,
            expandRowByClick: true,
            expandIconColumnIndex: -1
          }}
          locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Aucun groupe.' /> }}
        />
      </div>
      {/* {allGroupMembers.length > 0 && expandedRowRender()} */}
    </div>
  )
}

export default VoirlesGroupes
