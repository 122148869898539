import globals from "../helpers/globals";
const axios = require("axios").default;
const URL = globals.domain;

const getAllNotificationAPI = (params) => {
  const token = localStorage.getItem("token")
  return axios
    .create({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .get(`${URL}/api/v1/notification`, { params })
}

const getNotificationByIdAPI = (id) => {
  const token = localStorage.getItem("token")
  return axios
    .create({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .get(`${URL}/api/v1/notification${id}`)
}

const updateNotificationAPI = (id, data) => {
  const token = localStorage.getItem("token")
  return axios
    .create({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .patch(`${URL}/api/v1/notification/${id}`, data)
}

export const notificationServices = {
  getAllNotificationAPI,
  getNotificationByIdAPI,
  updateNotificationAPI,
}
