import React, { useEffect, useState } from 'react'
import packageJson from "../../package.json";
import { notification, Button } from 'antd';
global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

const refreshCacheAndReload = () => {
  console.log('Clearing cache and hard reloading...')
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function (names) {
      for (let name of names) caches.delete(name);
    });
  }
  // delete browser cache and hard reload
  window.location.reload(true);
}

export const CacheBuster = () => {
  const [loading, setLoading] = useState(true);
  const [messageShown, setMessageShown] = useState(false);
  const [reloading, setReloading] = useState(false);
  const [isLatestVersion, setIsLatestVersion] = useState(false);

  useEffect(() => {
    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          // We have a new version - ${latestVersion}. Should force refresh`
          setIsLatestVersion(false)
        } else {
          // You already have the latest version - ${latestVersion}. No cache refresh needed.`);
          setIsLatestVersion(true)
        }
        setLoading(false)
      });
  }, [])

  useEffect(() => {
    if (loading === false) {
      if (isLatestVersion === false) {
        if (!messageShown) {
          notification.open({
            key: `open${Date.now()}`,
            duration: 0,
            message: "Mise à jour",
            description:
              "La dernière version de l’application a été téléchargée. Veuillez la mettre à jour.",
            btn: (
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  setReloading(true)
                  refreshCacheAndReload()
                }}
                loading={reloading}
              >
                Redémarrer
              </Button>
            ),
          });
          setMessageShown(true)
        }
      }
    }
  }, [loading, isLatestVersion, messageShown]);

  return (
    <span id="CacheBuster">
      <span style={{ display: "none" }}>CacheBuster</span>
    </span>
  );
}
