import { Button, Form, InputNumber, Modal, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

const ModalSetupBilling = ({ visible, onClose, group, onDone }) => {
  const [form] = Form.useForm();
  const [filteredReports, setFilteredReports]: any = useState([]);
  const [selectedReports, setSelectedReports]: any = useState([]);

  useEffect(() => {
    if (group?.bill) {
      setSelectedReports(group?.bill?.reports?.map((report) => report.id));
      setFilteredReports(
        group.reports?.filter((report) =>
          group?.bill?.reports?.find(
            (billReport) => billReport.id === report.id
          )
        )
      );
      form.setFieldsValue({
        discount: group?.bill?.discount?.toString(),
        ...group?.reports?.reduce((acc, report) => {
          const findReport = group?.bill?.reports?.find(
            (billReport) => billReport.id === report.id
          );

          if (findReport) {
            acc["discount_" + report.id] = findReport.discount;
          } else {
            acc["discount_" + report.id] = 0;
          }

          return acc;
        }, {}),
      });
    } else {
      setSelectedReports([]);
      setFilteredReports(group.reports);
      form.setFieldsValue({
        discount: 0,
        ...group?.reports?.reduce((acc, report) => {
          acc["discount_" + report.id] = 0;
          return acc;
        }, {}),
      });
    }
  }, [group]);

  const _onSubmit = () => {
    form.validateFields().then((values) => {
      const dataSetupBilling = selectedReports.map((report) => {
        const newReport = group.reports.find(
          (groupReport) => groupReport.id === report
        );

        return {
          ...newReport,
          discount: values["discount_" + newReport.id],
        };
      });

      onDone({
        ...group,
        amount_without_taxes: dataSetupBilling.reduce(
          (acc, report) =>
            acc +
            report.amount_without_taxes_pages +
            report.amount_without_taxes_photos,
          0
        ),
        total_count_pages: dataSetupBilling.reduce(
          (acc, report) => acc + report.count_pages_billing,
          0
        ),
        total_count_photos: dataSetupBilling.reduce(
          (acc, report) => acc + report.count_photos_billing,
          0
        ),
        discount: values.discount,
        reports: dataSetupBilling,
      });
    });
  };

  const columns = [
    {
      title: "Date",
      key: "date",
      render: (report) => {
        return (
          <span>
            {moment(report.createdAt).format("DD/MM/YYYY")}{" "}
            {moment(report.createdAt).format("HH:mm")}
          </span>
        );
      },
    },
    {
      title: "Nom constat",
      width: "auto",
      render: (report) => {
        return (
          <span
            style={{
              display: "inline-block",
              width: "100%",
              wordWrap: "break-word",
            }}
          >
            {report.name}
          </span>
        );
      },
    },
    {
      title: "Nb de pages",
      width: "auto",
      render: (report) => {
        return <span>{report.count_pages_billing}</span>;
      },
    },
    {
      title: "Nb de photos",
      width: "auto",
      render: (report) => {
        return <span>{report.count_photos_billing}</span>;
      },
    },
    {
      title: "Remises",
      width: "auto",
      render: (report) => {
        return (
          <Form.Item
            name={"discount_" + report.id}
            rules={[
              {
                required: selectedReports.find(
                  (selectedReport) => selectedReport === report.id
                )
                  ? true
                  : false,
                message: "Remise is required",
              },
            ]}
            initialValue={form.getFieldValue("discount_" + report.id) || 0}
            style={{ marginBottom: 0 }}
          >
            <InputNumber
              disabled={
                group?.bill
                  ? true
                  : selectedReports.find(
                    (selectedReport) => selectedReport === report.id
                  )
                    ? false
                    : true
              }
              defaultValue={form.getFieldValue("discount_" + report.id) || 0}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              min={0}
              max={100}
              onChange={(value) =>
                form.setFieldValue("discount_" + report.id, value)
              }
            />
            <span
              style={{
                marginLeft: 8,
              }}
            >
              %
            </span>
          </Form.Item>
        );
      },
    },
    {
      title: "Montant HT",
      width: "auto",
      render: (report) => {
        return (
          <Form.Item shouldUpdate style={{ margin: "0px" }}>
            {() => {
              const amountWithoutTaxesPages =
                form.getFieldValue("discount_" + report.id) > 0
                  ? report.amount_without_taxes_pages -
                  report.amount_without_taxes_pages *
                  (form.getFieldValue("discount_" + report.id) / 100)
                  : report.amount_without_taxes_pages;
              const finalAmountWithoutTaxesPages =
                form.getFieldValue("discount") > 0
                  ? amountWithoutTaxesPages -
                  amountWithoutTaxesPages *
                  (form.getFieldValue("discount") / 100)
                  : amountWithoutTaxesPages;

              const amountWithoutTaxesPhotos =
                form.getFieldValue("discount_" + report.id) > 0
                  ? report.amount_without_taxes_photos -
                  report.amount_without_taxes_photos *
                  (form.getFieldValue("discount_" + report.id) / 100)
                  : report.amount_without_taxes_photos;
              const finalAmountWithoutTaxesPhotos =
                form.getFieldValue("discount") > 0
                  ? amountWithoutTaxesPhotos -
                  amountWithoutTaxesPhotos *
                  (form.getFieldValue("discount") / 100)
                  : amountWithoutTaxesPhotos;

              return (
                <span>
                  {(
                    finalAmountWithoutTaxesPages + finalAmountWithoutTaxesPhotos
                  ).toFixed(2)}
                  €
                </span>
              );
            }}
          </Form.Item>
        );
      },
    },
  ];

  return (
    <Modal
      centered
      footer={null}
      visible={visible}
      onCancel={onClose}
      destroyOnClose={true}
      style={{
        display: "inline-flex",
      }}
    >
      <Form form={form}>
        <Form.Item>
          {group?.bill ? (
            <Table
              rowKey={(report) => report.id}
              columns={columns}
              dataSource={filteredReports}
              pagination={false}
            />
          ) : (
            <Table
              rowKey={(report) => report.id}
              rowSelection={{
                type: "checkbox",
                selectedRowKeys: selectedReports,
                onChange: (selectedRows) => {
                  if (!group?.bill) setSelectedReports(selectedRows);
                },
              }}
              columns={columns}
              dataSource={filteredReports}
              pagination={false}
            />
          )}
        </Form.Item>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 16,
          }}
        >
          <Form.Item
            name="discount"
            rules={[
              {
                required: true,
                message: "Remise is required",
              },
            ]}
            initialValue={form.getFieldValue("discount")}
            style={{ marginBottom: 0 }}
          >
            <span
              style={{
                marginRight: 8,
              }}
            >
              Remises
            </span>
            <InputNumber
              defaultValue={form.getFieldValue("discount")}
              disabled={group?.bill ? true : false}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              min={0}
              max={100}
              onChange={(value) => form.setFieldValue("discount", value)}
            />
            <span
              style={{
                marginLeft: 8,
              }}
            >
              %
            </span>
          </Form.Item>
          {!group?.bill && (
            <Form.Item>
              <Button
                type="primary"
                className="validate-btn"
                htmlType="button"
                onClick={_onSubmit}
              >
                Valider
              </Button>
            </Form.Item>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default ModalSetupBilling;
