import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import billingService from "../../pages/services/billing";
import { progressEventPerformance } from "../../utils/redux";

const initialState = {
  progress: {
    section: "",
    id: "",
    percentage: 0,
  },
  billsBailiff: [],
  billsSecretaries: [],
  billsSecretary: [],
  billsDocumentsSecretary: [],
  billsDocumentsSecretaries: [],
  availablesGroupsBillings: [],
  nextBillNumber: null,
  //get Availables Billings
  loadingGetAvailablesGroupsBillings: false,
  successGetAvailablesGroupsBillings: false,
  errorGetAvailablesGroupsBillings: false,
  //create Bill
  loadingCreateBill: false,
  successCreateBill: false,
  errorCreateBill: false,
  // generate bill pdf
  loadingGenerateBillPdf: false,
  successGenerateBillPdf: false,
  errorGenerateBillPdf: false,
  // get next bill number
  loadingGetNextBillNumber: false,
  successGetNextBillNumber: false,
  errorGetNextBillNumber: false,
  //get All Bills
  loadingGetAllBills: false,
  successGetAllBills: false,
  errorGetAllBills: false,
  //send Bill To Email
  loadingSendBillToEmail: false,
  successSendBillToEmail: false,
  errorSendBillToEmail: false,
  //set Bill Paid
  loadingSetBillPaid: false,
  successSetBillPaid: false,
  errorSetBillPaid: false,
  // get All Bills Secretaries
  loadingGetAllBillsSecretaries: false,
  successGetAllBillsSecretaries: false,
  errorGetAllBillsSecretaries: false,
  // get All Bills Secretary
  loadingGetAllBillsSecretary: false,
  successGetAllBillsSecretary: false,
  errorGetAllBillsSecretary: false,
  // create Bill Secretary
  loadingCreateBillSecretary: false,
  successCreateBillSecretary: false,
  errorCreateBillSecretary: false,
  // remove Bill Secretary
  loadingRemoveBillSecretary: false,
  successRemoveBillSecretary: false,
  errorRemoveBillSecretary: false,
};

export const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    resetBillingState: (state) => initialState,
    resetCreateBillState: (state) => {
      state.loadingCreateBill = false;
      state.successCreateBill = false;
      state.errorCreateBill = false;
    },
    resetGenerateBillPdfState: (state) => {
      state.loadingGenerateBillPdf = false;
      state.successGenerateBillPdf = false;
      state.errorGenerateBillPdf = false;
    },
    resetGetNextBillNumberState: (state) => {
      state.loadingGetNextBillNumber = false;
      state.successGetNextBillNumber = false;
      state.errorGetNextBillNumber = false;
    },
    resetSendBillToEmailState: (state) => {
      state.loadingSendBillToEmail = false;
      state.successSendBillToEmail = false;
      state.errorSendBillToEmail = false;
    },
    resetSetBillPaidState: (state) => {
      state.loadingSetBillPaid = false;
      state.successSetBillPaid = false;
      state.errorSetBillPaid = false;
    },
    resetGetAllBillsSecretariesState: (state) => {
      state.loadingGetAllBillsSecretaries = false;
      state.successGetAllBillsSecretaries = false;
      state.errorGetAllBillsSecretaries = false;
    },
    resetCreateBillSecretaryState: (state) => {
      state.loadingCreateBillSecretary = false;
      state.successCreateBillSecretary = false;
      state.errorCreateBillSecretary = false;
    },
    setPercentageProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    //get Availables Groups Billings
    builder.addCase(getAvailablesGroupsBillings.pending, (state) => {
      state.loadingGetAvailablesGroupsBillings = true;
      state.successGetAvailablesGroupsBillings = false;
      state.errorGetAvailablesGroupsBillings = false;
    });
    builder.addCase(getAvailablesGroupsBillings.fulfilled, (state, action) => {
      state.loadingGetAvailablesGroupsBillings = false;
      state.successGetAvailablesGroupsBillings = true;
      state.errorGetAvailablesGroupsBillings = false;
      state.availablesGroupsBillings = action.payload;
    });
    builder.addCase(getAvailablesGroupsBillings.rejected, (state) => {
      state.loadingGetAvailablesGroupsBillings = false;
      state.successGetAvailablesGroupsBillings = false;
      state.errorGetAvailablesGroupsBillings = true;
    });
    //create Bill
    builder.addCase(createBill.pending, (state) => {
      state.loadingCreateBill = true;
      state.successCreateBill = false;
      state.errorCreateBill = false;
    });
    builder.addCase(createBill.fulfilled, (state: any, action) => {
      state.loadingCreateBill = false;
      state.successCreateBill = action.payload;
      state.errorCreateBill = false;
    });
    builder.addCase(createBill.rejected, (state) => {
      state.loadingCreateBill = false;
      state.successCreateBill = false;
      state.errorCreateBill = true;
    });
    //generate bill pdf
    builder.addCase(generateBillPdf.pending, (state) => {
      state.loadingGenerateBillPdf = true;
      state.successGenerateBillPdf = false;
      state.errorGenerateBillPdf = false;
    });
    builder.addCase(generateBillPdf.fulfilled, (state: any, action) => {
      state.loadingGenerateBillPdf = false;
      state.successGenerateBillPdf = action.payload;
      state.errorGenerateBillPdf = false;
    });
    builder.addCase(generateBillPdf.rejected, (state) => {
      state.loadingGenerateBillPdf = false;
      state.successGenerateBillPdf = false;
      state.errorGenerateBillPdf = true;
    });
    //get next bill number
    builder.addCase(getNextBillNumber.pending, (state) => {
      state.loadingGetNextBillNumber = true;
      state.successGetNextBillNumber = false;
      state.errorGetNextBillNumber = false;
    });
    builder.addCase(getNextBillNumber.fulfilled, (state: any, action) => {
      state.loadingGetNextBillNumber = false;
      state.successGetNextBillNumber = true;
      state.errorGetNextBillNumber = false;
      state.nextBillNumber = action.payload;
    });
    builder.addCase(getNextBillNumber.rejected, (state) => {
      state.loadingGetNextBillNumber = false;
      state.successGetNextBillNumber = false;
      state.errorGetNextBillNumber = true;
    });
    //get All Bills
    builder.addCase(getAllBillsBailiff.pending, (state) => {
      state.loadingGetAllBills = true;
      state.successGetAllBills = false;
      state.errorGetAllBills = false;
    });
    builder.addCase(getAllBillsBailiff.fulfilled, (state, action) => {
      state.loadingGetAllBills = false;
      state.successGetAllBills = true;
      state.errorGetAllBills = false;
      state.billsBailiff = action.payload;
    });
    builder.addCase(getAllBillsBailiff.rejected, (state) => {
      state.loadingGetAllBills = false;
      state.successGetAllBills = false;
      state.errorGetAllBills = true;
    });
    //send Bill To Email
    builder.addCase(sendBillToEmail.pending, (state) => {
      state.loadingSendBillToEmail = true;
      state.successSendBillToEmail = false;
      state.errorSendBillToEmail = false;
    });
    builder.addCase(sendBillToEmail.fulfilled, (state, action) => {
      state.loadingSendBillToEmail = false;
      state.successSendBillToEmail = true;
      state.errorSendBillToEmail = false;
    });
    builder.addCase(sendBillToEmail.rejected, (state) => {
      state.loadingSendBillToEmail = false;
      state.successSendBillToEmail = false;
      state.errorSendBillToEmail = true;
    });
    //set Bill Paid
    builder.addCase(setBillPaid.pending, (state) => {
      state.loadingSetBillPaid = true;
      state.successSetBillPaid = false;
      state.errorSetBillPaid = false;
    });
    builder.addCase(setBillPaid.fulfilled, (state, action) => {
      state.loadingSetBillPaid = false;
      state.successSetBillPaid = true;
      state.errorSetBillPaid = false;
    });
    builder.addCase(setBillPaid.rejected, (state) => {
      state.loadingSetBillPaid = false;
      state.successSetBillPaid = false;
      state.errorSetBillPaid = true;
    });
    //get All Bills Secretaries
    builder.addCase(getAllBillsSecretaries.pending, (state) => {
      state.loadingGetAllBillsSecretaries = true;
      state.successGetAllBillsSecretaries = false;
      state.errorGetAllBillsSecretaries = false;
    });
    builder.addCase(getAllBillsSecretaries.fulfilled, (state, action) => {
      state.loadingGetAllBillsSecretaries = false;
      state.successGetAllBillsSecretaries = true;
      state.errorGetAllBillsSecretaries = false;
      state.billsSecretaries = action.payload.bills;
      state.billsDocumentsSecretaries = action.payload.bills_secretaries;
    });
    builder.addCase(getAllBillsSecretaries.rejected, (state) => {
      state.loadingGetAllBillsSecretaries = false;
      state.successGetAllBillsSecretaries = false;
      state.errorGetAllBillsSecretaries = true;
    });
    //get All Bills Secretary
    builder.addCase(getAllBillsSecretary.pending, (state) => {
      state.loadingGetAllBillsSecretary = true;
      state.successGetAllBillsSecretary = false;
      state.errorGetAllBillsSecretary = false;
    });
    builder.addCase(getAllBillsSecretary.fulfilled, (state, action) => {
      state.loadingGetAllBillsSecretary = false;
      state.successGetAllBillsSecretary = true;
      state.errorGetAllBillsSecretary = false;
      state.billsSecretary = action.payload.bills;
      state.billsDocumentsSecretary = action.payload.bills_secretary;
    });
    builder.addCase(getAllBillsSecretary.rejected, (state) => {
      state.loadingGetAllBillsSecretary = false;
      state.successGetAllBillsSecretary = false;
      state.errorGetAllBillsSecretary = true;
    });
    // create Bill Secretary
    builder.addCase(createBillSecretary.pending, (state) => {
      state.loadingCreateBillSecretary = true;
      state.successCreateBillSecretary = false;
      state.errorCreateBillSecretary = false;
    });
    builder.addCase(createBillSecretary.fulfilled, (state, action) => {
      state.loadingCreateBillSecretary = false;
      state.successCreateBillSecretary = true;
      state.errorCreateBillSecretary = false;
    });
    builder.addCase(createBillSecretary.rejected, (state) => {
      state.loadingCreateBillSecretary = false;
      state.successCreateBillSecretary = false;
      state.errorCreateBillSecretary = true;
    });
    // remove Bill Secretary
    builder.addCase(removeBillSecretary.pending, (state) => {
      state.loadingRemoveBillSecretary = true;
      state.successRemoveBillSecretary = false;
      state.errorRemoveBillSecretary = false;
    });
    builder.addCase(removeBillSecretary.fulfilled, (state, action) => {
      state.loadingRemoveBillSecretary = false;
      state.successRemoveBillSecretary = true;
      state.errorRemoveBillSecretary = false;
    });
    builder.addCase(removeBillSecretary.rejected, (state) => {
      state.loadingRemoveBillSecretary = false;
      state.successRemoveBillSecretary = false;
      state.errorRemoveBillSecretary = true;
    });
  },
});

const getAvailablesGroupsBillings = createAsyncThunk(
  "billing/getAvailablesGroupsBillings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await billingService.getAvailablesGroupsBillings();
      return response.data.availablesGroupsBillings;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createBill = createAsyncThunk(
  "billing/createBill",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await billingService.createBill(params);
      return response.data.bill;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const generateBillPdf = createAsyncThunk(
  "billing/generateBill",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await billingService.generateBillPdf(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getNextBillNumber = createAsyncThunk(
  "billing/getNextBillNumber",
  async (_, { rejectWithValue }) => {
    try {
      const response = await billingService.getNextBillNumber();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getAllBillsBailiff = createAsyncThunk(
  "billing/getAllBills",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await billingService.getAllBillsBailiff(params);
      return response.data.bills;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const sendBillToEmail = createAsyncThunk(
  "billing/sendBillToEmail",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await billingService.sendBillToEmail(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const setBillPaid = createAsyncThunk(
  "billing/setBillPaid",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await billingService.setBillPaid(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getAllBillsSecretaries = createAsyncThunk(
  "billing/getAllBillsSecretaries",
  async (_, { rejectWithValue }) => {
    try {
      const response = await billingService.getAllBillsSecretaries();
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getAllBillsSecretary = createAsyncThunk(
  "billing/getAllBillsSecretary",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await billingService.getAllBillsSecretary(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createBillSecretary = createAsyncThunk(
  "billing/createBillSecretary",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await billingService.createBillSecretary(
        params.data,
        (progressEvent) =>
          progressEventPerformance(
            progressEvent,
            params?.dispatch,
            params?.setPercentageProgress,
            params?.section,
            params?.id
          )
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const removeBillSecretary = createAsyncThunk(
  "billing/removeBillSecretary",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await billingService.removeBillSecretary(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const {
  resetBillingState,
  resetCreateBillState,
  resetGenerateBillPdfState,
  resetGetNextBillNumberState,
  resetSendBillToEmailState,
  resetSetBillPaidState,
  setPercentageProgress,
} = billingSlice.actions;

export {
  getAvailablesGroupsBillings,
  createBill,
  generateBillPdf,
  getNextBillNumber,
  getAllBillsBailiff,
  sendBillToEmail,
  setBillPaid,
  getAllBillsSecretaries,
  getAllBillsSecretary,
  createBillSecretary,
  removeBillSecretary,
};

export default billingSlice;
