import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import reportService from "../../pages/services/report";
import { progressEventPerformance } from "../../utils/redux";
import { Dispatch } from "react";

const initialState = {
  reports: [],
  headersReports: {},
  progress: {
    section: "",
    id: "",
    percentage: 0,
  },
  // Create Report
  loadingCreateReport: false,
  successCreateReport: false,
  errorCreateReport: false,
  // Get All Reports
  loadingGetAllReports: false,
  errorGetAllReports: false,
  // Get Headers Reports
  loadingGetHeadersReports: false,
  errorGetHeadersReports: false,
  // Update Secretary Report
  loadingUpdateSecretaryReport: false,
  successUpdateSecretaryReport: false,
  errorUpdateSecretaryReport: false,
  // Add Files Report
  loadingAddFilesReport: false,
  successAddFilesReport: false,
  errorAddFilesReport: false,
  // Remove Files Report
  loadingRemoveFilesReport: false,
  successRemoveFilesReport: false,
  errorRemoveFilesReport: false,
  // Set Billing Report
  loadingSetBillingReport: false,
  successSetBillingReport: false,
  errorSetBillingReport: false,
  // Set Retrocession Report
  loadingSetRetrocessionReport: false,
  successSetRetrocessionReport: false,
  errorSetRetrocessionReport: false,
  // Set Archive Report
  loadingSetArchiveReport: false,
  successSetArchiveReport: false,
  errorSetArchiveReport: false,
  // Set Unarchive Report
  loadingSetUnarchiveReport: false,
  successSetUnarchiveReport: false,
  errorSetUnarchiveReport: false,
  // Add Comment Report
  loadingAddCommentReport: false,
  successAddCommentReport: false,
  errorAddCommentReport: false,
  // Mark As Read Comment Report
  loadingMarkAsReadCommentReport: false,
  successMarkAsReadCommentReport: false,
  errorMarkAsReadCommentReport: false,
};

const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    resetReportState: (state) => (state = initialState),
    setPercentageProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Create Report
    builder.addCase(createReport.pending, (state) => {
      state.loadingCreateReport = true;
      state.successCreateReport = false;
      state.errorCreateReport = false;
    });
    builder.addCase(createReport.fulfilled, (state, action: any) => {
      state.loadingCreateReport = false;
      state.successCreateReport = action.payload;
      state.errorCreateReport = false;
    });
    builder.addCase(createReport.rejected, (state, action: any) => {
      state.loadingCreateReport = false;
      state.successCreateReport = false;
      state.errorCreateReport = action.payload.error;
    });
    // Get All Reports
    builder.addCase(getReports.pending, (state) => {
      state.loadingGetAllReports = true;
      state.errorGetAllReports = false;
    });
    builder.addCase(getReports.fulfilled, (state, action: any) => {
      state.loadingGetAllReports = false;
      state.reports = action.payload;
      state.errorGetAllReports = false;
    });
    builder.addCase(getReports.rejected, (state, action: any) => {
      state.loadingGetAllReports = false;
      state.errorGetAllReports = action.payload.error;
    });
    // Get Headers Reports
    builder.addCase(getHeadersReports.pending, (state) => {
      state.loadingGetHeadersReports = true;
      state.errorGetHeadersReports = false;
    });
    builder.addCase(getHeadersReports.fulfilled, (state, action: any) => {
      state.loadingGetHeadersReports = false;
      state.headersReports = action.payload;
      state.errorGetHeadersReports = false;
    });
    builder.addCase(getHeadersReports.rejected, (state, action: any) => {
      state.loadingGetHeadersReports = false;
      state.errorGetHeadersReports = action.payload.error;
    });
    // Update Secretary Report
    builder.addCase(updateSecretaryReport.pending, (state) => {
      state.loadingUpdateSecretaryReport = true;
      state.successUpdateSecretaryReport = false;
      state.errorUpdateSecretaryReport = false;
    });
    builder.addCase(updateSecretaryReport.fulfilled, (state, action: any) => {
      state.loadingUpdateSecretaryReport = false;
      state.successUpdateSecretaryReport = action.payload;
      state.errorUpdateSecretaryReport = false;
    });
    builder.addCase(updateSecretaryReport.rejected, (state, action: any) => {
      state.loadingUpdateSecretaryReport = false;
      state.successUpdateSecretaryReport = false;
      state.errorUpdateSecretaryReport = action.payload.error;
    });
    // Add Files Report
    builder.addCase(addFilesReport.pending, (state) => {
      state.loadingAddFilesReport = true;
      state.successAddFilesReport = false;
      state.errorAddFilesReport = false;
    });
    builder.addCase(addFilesReport.fulfilled, (state, action: any) => {
      state.loadingAddFilesReport = false;
      state.successAddFilesReport = action.payload;
      state.errorAddFilesReport = false;
    });
    builder.addCase(addFilesReport.rejected, (state, action: any) => {
      state.loadingAddFilesReport = false;
      state.successAddFilesReport = false;
      state.errorAddFilesReport = action.payload.error;
    });
    // Remove Files Report
    builder.addCase(removeFilesReport.pending, (state) => {
      state.loadingRemoveFilesReport = true;
      state.successRemoveFilesReport = false;
      state.errorRemoveFilesReport = false;
    });
    builder.addCase(removeFilesReport.fulfilled, (state, action: any) => {
      state.loadingRemoveFilesReport = false;
      state.successRemoveFilesReport = action.payload;
      state.errorRemoveFilesReport = false;
    });
    builder.addCase(removeFilesReport.rejected, (state, action: any) => {
      state.loadingRemoveFilesReport = false;
      state.successRemoveFilesReport = false;
      state.errorRemoveFilesReport = action.payload.error;
    });
    // Set Billing Report
    builder.addCase(setBillingReport.pending, (state) => {
      state.loadingSetBillingReport = true;
      state.successSetBillingReport = false;
      state.errorSetBillingReport = false;
    });
    builder.addCase(setBillingReport.fulfilled, (state, action: any) => {
      state.loadingSetBillingReport = false;
      state.successSetBillingReport = action.payload;
      state.errorSetBillingReport = false;
    });
    builder.addCase(setBillingReport.rejected, (state, action: any) => {
      state.loadingSetBillingReport = false;
      state.successSetBillingReport = false;
      state.errorSetBillingReport = action.payload.error;
    });
    // Set Retrocession Report
    builder.addCase(setRetrocessionReport.pending, (state) => {
      state.loadingSetRetrocessionReport = true;
      state.successSetRetrocessionReport = false;
      state.errorSetRetrocessionReport = false;
    });
    builder.addCase(setRetrocessionReport.fulfilled, (state, action: any) => {
      state.loadingSetRetrocessionReport = false;
      state.successSetRetrocessionReport = action.payload;
      state.errorSetRetrocessionReport = false;
    });
    builder.addCase(setRetrocessionReport.rejected, (state, action: any) => {
      state.loadingSetRetrocessionReport = false;
      state.successSetRetrocessionReport = false;
      state.errorSetRetrocessionReport = action.payload.error;
    });
    // Set Archive Report
    builder.addCase(setArchiveReport.pending, (state) => {
      state.loadingSetArchiveReport = true;
      state.successSetArchiveReport = false;
      state.errorSetArchiveReport = false;
    });
    builder.addCase(setArchiveReport.fulfilled, (state, action: any) => {
      state.loadingSetArchiveReport = false;
      state.successSetArchiveReport = action.payload;
      state.errorSetArchiveReport = false;
    });
    builder.addCase(setArchiveReport.rejected, (state, action: any) => {
      state.loadingSetArchiveReport = false;
      state.successSetArchiveReport = false;
      state.errorSetArchiveReport = action.payload.error;
    });
    // Set Unarchive Report
    builder.addCase(setUnarchiveReport.pending, (state) => {
      state.loadingSetUnarchiveReport = true;
      state.successSetUnarchiveReport = false;
      state.errorSetUnarchiveReport = false;
    });
    builder.addCase(setUnarchiveReport.fulfilled, (state, action: any) => {
      state.loadingSetUnarchiveReport = false;
      state.successSetUnarchiveReport = action.payload;
      state.errorSetUnarchiveReport = false;
    });
    builder.addCase(setUnarchiveReport.rejected, (state, action: any) => {
      state.loadingSetUnarchiveReport = false;
      state.successSetUnarchiveReport = false;
      state.errorSetUnarchiveReport = action.payload.error;
    });
    // Add Comment Report
    builder.addCase(addCommentReport.pending, (state) => {
      state.loadingAddCommentReport = true;
      state.successAddCommentReport = false;
      state.errorAddCommentReport = false;
    });
    builder.addCase(addCommentReport.fulfilled, (state, action: any) => {
      state.loadingAddCommentReport = false;
      state.successAddCommentReport = action.payload;
      state.errorAddCommentReport = false;
    });
    builder.addCase(addCommentReport.rejected, (state, action: any) => {
      state.loadingAddCommentReport = false;
      state.successAddCommentReport = false;
      state.errorAddCommentReport = action.payload.error;
    });
    // Mark As Read Comment Report
    builder.addCase(markAsReadCommentReport.pending, (state) => {
      state.loadingMarkAsReadCommentReport = true;
      state.successMarkAsReadCommentReport = false;
      state.errorMarkAsReadCommentReport = false;
    });
    builder.addCase(markAsReadCommentReport.fulfilled, (state, action: any) => {
      state.loadingMarkAsReadCommentReport = false;
      state.successMarkAsReadCommentReport = action.payload;
      state.errorMarkAsReadCommentReport = false;
    });
    builder.addCase(markAsReadCommentReport.rejected, (state, action: any) => {
      state.loadingMarkAsReadCommentReport = false;
      state.successMarkAsReadCommentReport = false;
      state.errorMarkAsReadCommentReport = action.payload.error;
    });
  },
});

interface ICreateReport {
  data: any;
  cancelToken?: any;
  dispatch?: Dispatch<any>;
  setPercentageProgress?: any;
  section?: string;
  id?: string;
}

const createReport = createAsyncThunk(
  "report/createReport",
  async (params: ICreateReport, { rejectWithValue }) => {
    try {
      const response = await reportService.createReport({
        params: params.data,
        progress: (progressEvent) =>
          progressEventPerformance(
            progressEvent,
            params?.dispatch,
            params?.setPercentageProgress,
            params?.section,
            params?.id
          ),
        cancelToken: params?.cancelToken,
      });
      return response?.data;
    } catch (error) {
      return rejectWithValue({
        error,
      });
    }
  }
);

const getReports = createAsyncThunk(
  "report/getReports",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await reportService.getReports(params);
      return response?.data?.reports;
    } catch (error) {
      return rejectWithValue({
        error,
      });
    }
  }
);

const getHeadersReports = createAsyncThunk(
  "report/getHeadersReports",
  async (_, { rejectWithValue }) => {
    try {
      const response = await reportService.getHeaders();
      return response?.data?.headers;
    } catch (error) {
      return rejectWithValue({
        error,
      });
    }
  }
);

const updateSecretaryReport = createAsyncThunk(
  "report/updateSecretary",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await reportService.updateSecretary(params);
      return response?.data;
    } catch (error) {
      return rejectWithValue({
        error,
      });
    }
  }
);

const addFilesReport = createAsyncThunk(
  "report/addFilesReport",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await reportService.addFilesReport(
        params.data,
        (progressEvent) =>
          progressEventPerformance(
            progressEvent,
            params?.dispatch,
            params?.setPercentageProgress,
            params?.section,
            params?.id
          )
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue({
        error,
      });
    }
  }
);

const removeFilesReport = createAsyncThunk(
  "report/removeFilesReport",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await reportService.removeFilesReport(params);
      return response?.data;
    } catch (error) {
      return rejectWithValue({
        error,
      });
    }
  }
);

const setBillingReport = createAsyncThunk(
  "report/setBillingReport",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await reportService.setBillingReport(params);
      return response?.data;
    } catch (error) {
      return rejectWithValue({
        error,
      });
    }
  }
);

const setRetrocessionReport = createAsyncThunk(
  "report/setRetrocessionReport",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await reportService.setRetrocessionReport(params);
      return response?.data;
    } catch (error) {
      return rejectWithValue({
        error,
      });
    }
  }
);

const setArchiveReport = createAsyncThunk(
  "report/setArchiveReport",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await reportService.setArchiveReport(params);
      return response?.data;
    } catch (error) {
      return rejectWithValue({
        error,
      });
    }
  }
);

const setUnarchiveReport = createAsyncThunk(
  "report/setUnarchiveReport",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await reportService.setUnarchiveReport(params);
      return response?.data;
    } catch (error) {
      return rejectWithValue({
        error,
      });
    }
  }
);

const addCommentReport = createAsyncThunk(
  "report/addCommentReport",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await reportService.addCommentReport(params);
      return response?.data;
    } catch (error) {
      return rejectWithValue({
        error,
      });
    }
  }
);

const markAsReadCommentReport = createAsyncThunk(
  "report/markAsReadCommentReport",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await reportService.markAsReadCommentReport(params);
      return response?.data;
    } catch (error) {
      return rejectWithValue({
        error,
      });
    }
  }
);

export const { resetReportState, setPercentageProgress } = reportSlice.actions;

export {
  createReport,
  getReports,
  updateSecretaryReport,
  addFilesReport,
  removeFilesReport,
  setBillingReport,
  setRetrocessionReport,
  setArchiveReport,
  addCommentReport,
  markAsReadCommentReport,
  setUnarchiveReport,
  getHeadersReports,
};

export default reportSlice;
