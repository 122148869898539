import React, { useState } from 'react'
import { Form, Input, Button, Result } from "antd";
import { MailOutlined, UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';

import styles from "./styles.module.scss";
// import logo from '../../assets/logo-crc-extranet-white.png';
import authServices from '../../redux/auth/services';

import {BASE_URL} from "../../helpers/api";

import { useHistory } from 'react-router-dom';
function NewPassword() {
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(undefined);
	const [loading, setLoading] = useState(false);
  const history = useHistory();
	// const onFinish = async ({ email } : { email: string }) => {
	// 	try {
	// 		setError(undefined);
	// 		setLoading(true);
	// 		await authServices.requestRecovery(email);
	// 		setSuccess(true);
	// 		setLoading(false);
	// 	} catch (err) {
	// 		setError(err.response.data.errors.message);
	// 		setLoading(false);
	// 	}
  // };

  const onFinish = async(values) => {
    let data = {
      email: values.email,
      password: values.password,
    };
    
      const postData = Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&');	
      // return;
          let showNotification = {};
          let modalCloseStatus = 1;
          const response = await fetch(BASE_URL+'/api/v1/auth/forgot-password', {
        method: "POST",
        headers: new Headers({
          'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
        }),
              // headers: generalAuthTokenHeader(),
              body: postData,
          })
              .then(response => {
                  // if (response.status === 400) {
                  //     showNotification = {
                  //         title: 'Discharge Planner',
                  //         message: 'Bad response from server',
                  //         type: "danger"
                  //     };
                  // } else if (response.ok) {
                  // } else {
                  //     showNotification = {
                  //         title: 'Discharge Planner',
                  //         message: 'Bad response from server.',
                  //         type: "danger"
                  //     };
                  // }
                  return response.json();
              })
              .then(data => {
                  if (data.status == '400' && data.message == 'error.idexists') {
                      // modalCloseStatus = 0;
                      // showNotification = {
                      //     title: "Discharge Planner",
                      //     message: data.title,
                      //     type: 'danger'
                      // };
                  } else if (data.msg) {
                      // showNotification = {
                      //     title: "Success",
                      //     message: "Status updated successfully",
                      //     type: "success"
            // };
                    history.push("/forgotpassword");
                    // message.success('Inscrivez-vous avec succès.');
                      return true;
                  }
              })
              .catch(error => {
                  // showNotification = {
                  //     title: 'Discharge Planner',
                  //     message: 'Something went wrong.Please try after sometime..',
                  //     type: "danger"
                  // };
              });
    };

	if (success) {
		return (
			<Result
				status="success"
				title="Debe haber llegado un link de recuperación a tu email"
				style={{ paddingTop: 200 }}
				extra={[
					<Link to="/login">
						<Button type="primary" key="login">
							Volver a la página principal
						</Button>
					</Link>
				]}
			/>
		);
	}

	return (
    <section className={styles.login_wrapper}>
       <div className={styles.login_form_container}>
    <div className={styles.login_form_container}>
      {/* <img src={logo} alt="Logo" className={styles.login_form_logo} /> */}
      <div className={styles.login_form_logo}>
          <img src={'/images/logo.svg'} alt="Logo" className={styles.responsive_img} /> 
        </div>
      <Form id="password_request" name="password_request" onFinish={onFinish} className={styles.forgot_passform}>
   
        <div className="formtoptitles-wrappers">Si vous modifiez votre ancien mot de passe, vous ne pourrez plus l'utiliser.</div>
        <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}
          type="password"
          placeholder="Entrez un nouveau mot de passe"
        />
      </Form.Item>

        <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
          type="password"
          placeholder="Répétéz le nouveau mot de passe"
        />
      </Form.Item>



        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            //className="login_form-submit"
            className={styles.login_form_submit_btn}
            disabled={loading}
          >
            Changer le mot de passe
          </Button>
        </Form.Item>
      </Form>
    </div>
    </div>
    </section>
  );
}

export default NewPassword;

