import React, { useState, useEffect } from "react"
import { useHistory, Link } from 'react-router-dom'
import {
  Layout,
  Input,
  Row,
  Col,
  Form,
  Select,
  Breadcrumb,
  Button,
  Upload,
  message,
} from "antd"
import {
  UserOutlined,
  CloudUploadOutlined,
  PlusOutlined,
  EuroCircleOutlined,
} from "@ant-design/icons"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { useSelector } from "react-redux"
import { usersService } from "../../../_services/usersSerives"
import { ISendUserDataI } from "../../../interfaces/users.interface"
import { filesUploadServices } from "../../../_services/uploadFIles"
import { groupServices } from "../../../_services/groupsServices"
import FileUtils from "../../../utils/files"

const { Dragger } = Upload

const { Content } = Layout
const { Option } = Select

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
  if (!isJpgOrPng) {
    message.error("Vous ne pouvez télécharger que des fichiers JPG / PNG!")
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error("L'image doit être inférieure à 2 Mo!")
  }
  return isJpgOrPng && isLt2M
}

const validPassword = RegExp(
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
)
const validEmailRegex = RegExp(
  /^(([^<>()\\[\]\\.,;:\s@\\"]+(\.[^<>()\\[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i
)
const validPhone = RegExp(
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
)
const onlyNoAllowed = RegExp(/^[0-9]*$/)
const validIdentify = RegExp(/^[A-Za-z0-9]{3,}$/)
const validZipCode = RegExp(/^\d{5}(?:[-\s]\d{4})?$/)

function EditBaliffAccount(props) {
  const { match } = props
  const [fileUploads, setFileUploads] = useState({
    selectedFile: null,
    selectedFileList: [],
  })
  useEffect(() => {
    const _getData = async () => {
      await _getAllGroups()
      await _getUser(match.params.id)
    }
    _getData()
  }, [match.params.id])

  const imguploadicon = require("../../../pages/images/uploadimage-icon.svg")
  const [imageUrl, setImage] = useState(
    require("../../../pages/images/uploadimage.svg")
  )
  const { user } = useSelector((state: any) => state.auth)
  const [allGroups, setAllGroups] = useState([])
  const [bailifForm, setBailifForm] = useState(null)

  const [createAccountForm, setCreateAccountForm]: any = useState({
    type: "",
    civility: "",
    lastname: "",
    firstname: "",
    phone: "",
    studyname: "",
    studyphone: "",
    siret: "",
    accountingemail: "",
    address: "",
    pricepage: "",
    zipcode: null,
    city: "",
    pricephoto: "",
    useremail: "",
    userID: "",
    password: "",
    confirm_password: "",
    uploads: [],
    description: "",
    linkto: "",
    errors: {
      type: "",
      civility: "",
      lastname: "",
      firstname: "",
      phone: "",
      studyname: "",
      studyphone: "",
      siret: "",
      accountingemail: "",
      address: "",
      pricepage: "",
      zipcode: "",
      city: "",
      pricephoto: "",
      useremail: "",
      userID: "",
      password: "",
      confirm_password: "",
      uploads: "",
      description: "",
      linkto: "",
    },
  })

  const _getUser = async (id) => {
    await usersService
      .getUsersByIDAPI(id)
      .then((response) => response.data)
      .then((response) => {
        let temp = createAccountForm
        createAccountForm.type = response.role_name
        createAccountForm.civility = response.civility
        createAccountForm.lastname = response.last_name
        createAccountForm.firstname = response.first_name
        createAccountForm.phone = response.telephone
        createAccountForm.useremail = response.email
        createAccountForm.userID = response.identity
        createAccountForm.password = response.password
        createAccountForm.description = response.description
        createAccountForm.linkto = response.group_id
        setCreateAccountForm({ ...createAccountForm, ...{ temp } })
        setBailifForm(response)
      })
      .catch((err) => console.error(err))
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok")
    }, 0)
  }
  const uploadFileHere = async (file) => {
    let data = new FormData()
    data.append("profile", file)
    return await filesUploadServices
      .uploadFileAPI(data)
      .then((response) => response.data.url)
  }
  const onChangeDragger = async (info) => {
    const nextState: any = {}
    switch (info.file.status) {
      case "uploading":
        nextState.selectedFileList = info.fileList
        break
      case "done":
        nextState.selectedFile = info.fileList
        nextState.selectedFileList = info.fileList
        let promise: any = []
        promise = await Promise.all(
          info.fileList.map(async (file) => {
            let innerPromise = await uploadFileHere(file.originFileObj)
            return innerPromise
          })
        )
        let temp: any = createAccountForm.uploads
        let errors = createAccountForm.errors
        errors.uploads = ""
        setCreateAccountForm({
          ...createAccountForm,
          ...{ errors, ["uploads"]: promise },
        })

        message.success(`Fichier téléchargé avec succès.`)
        break
      default:
        // error or removed
        nextState.selectedFile = info.fileList
        nextState.selectedFileList = info.fileList
    }
    setFileUploads(nextState)
  }

  useEffect(() => {
    if (allGroups.length > 0) {
      let temp: any = createAccountForm
      console.log('temp useEffect: ', temp)
      allGroups.map((group: any) => {
        if (group.id == createAccountForm.linkto) {
          temp.studyname = group.group_name
          temp.studyphone = group.group_telephone
          temp.siret = group.group_street
          temp.accountingemail = group.group_email
          temp.address = group.group_address
          temp.pricepage = group.group_price_page
          temp.zipcode = group.group_zip
          temp.city = group.group_city
          temp.pricephoto = group.group_price_photo
          setCreateAccountForm({ ...createAccountForm, ...temp })
        }
      })
    }
  }, [createAccountForm.linkto])

  const _getAllGroups = async () => {
    await groupServices
      .getAllGroupsAPI()
      .then((response) => response.data)
      .then((response: any) => {
        setAllGroups(response)
      })
      .catch((err) => console.error(err))
  }

  const uploadButton = (
    <div>
      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const handleChangeImage = async (info) => {
    if (info.file.status === "done") {
      let data = new FormData()
      data.append("profile", info.file.originFileObj)
      await filesUploadServices
        .uploadFileAPI(data)
        .then((response) => {
          setImage(response.data.url)
        })
        .catch((err) => console.error(err))
    }
  }

  const handleChange = (e) => {
    switch (e) {
      case "huissier":
        props.history.push("/dashboard/bailiffaccount")
        break
      case "admin":
        props.history.push("/dashboard/adminaccount")
        break
      case "secrétaire":
        props.history.push("/dashboard/secretary")
        break
    }
  }

  const notFilledPasswordField = () => {
    let errors = createAccountForm.errors
    // debugger
    if (createAccountForm.civility === "") {
      errors.civility = "Champ requis"
    }
    if (createAccountForm.lastname === "") {
      errors.lastname = "Champ requis"
    }
    if (createAccountForm.firstname === "") {
      errors.firstname = "Champ requis"
    }
    if (createAccountForm.phone === "") {
      errors.phone = "Champ requis"
    }
    if (createAccountForm.studyname === "") {
      errors.studyname = "Champ requis"
    }
    if (createAccountForm.studyphone === "") {
      errors.studyphone = "Champ requis"
    }
    if (createAccountForm.siret === "") {
      errors.siret = "Champ requis"
    }
    if (createAccountForm.accountingemail === "") {
      errors.accountingemail = "Champ requis"
    }
    if (createAccountForm.address === "") {
      errors.address = "Champ requis"
    }
    if (createAccountForm.pricepage === "") {
      errors.pricepage = "Champ requis"
    }
    if (createAccountForm.zipcode === "") {
      errors.zipcode = "Champ requis"
    }
    if (createAccountForm.city === "") {
      errors.city = "Champ requis"
    }
    if (createAccountForm.pricephoto === "") {
      errors.pricephoto = "Champ requis"
    }
    if (createAccountForm.useremail === "") {
      errors.useremail = "Champ requis"
    }
    if (createAccountForm.userID === "") {
      errors.userID = "Champ requis"
    }
    /* if (createAccountForm.password === "") {
       errors.password = "Champ requis"
     } 
     if (createAccountForm.confirm_password === "") {
       errors.confirm_password = "Champ requis"
     }*/
    if (createAccountForm.description === "") {
      errors.description = "Champ requis"
    }

    if (createAccountForm.linkto === "") {
      errors.linkto = "Champ requis"
    }
    /* if (createAccountForm.uploads.length === 0) {
       errors.uploads = "Champ requis"
     } */
    setCreateAccountForm({ ...createAccountForm, ...{ errors } })
  }

  const validateForm = (formFields) => {
    notFilledPasswordField()
    let valid = true
    Object.values(formFields.errors).forEach(
      (val: any) => val.length > 0 && (valid = false)
    )
    return valid
  }
  const _submitHandler = async () => {
    if (validateForm(createAccountForm)) {
      let data: any = {}
      data["role_id"] = "2"
      data["civility"] = createAccountForm.civility
      data["last_name"] = createAccountForm.lastname
      data["first_name"] = createAccountForm.firstname
      data["telephone"] = createAccountForm.phone
      data["street"] = createAccountForm.siret
      data["address"] = createAccountForm.address
      data["city"] = createAccountForm.city
      if (createAccountForm.password != "") {
        data["password"] = createAccountForm.password
      }
      data["profile"] = imageUrl
      data["Instructions_to_secretaries"] = createAccountForm.description
      data["matrices_associated"] = createAccountForm.uploads
      data["identity"] = createAccountForm.userID
      data["zip"] = createAccountForm.zipcode
      data["group_id"] = String(createAccountForm.linkto)
      //update
      await usersService
        .updateUsersAPI(match.params.id, data)
        .then((response) => response.data)
        .then((response: ISendUserDataI) => {
          message.success("Compte modifié avec succès.")
          props.history.push('/dashboard/voirlesgroupes')
        })
        .catch((err) => console.error(err))
    }
  }

  const changeHandler = (value, name) => {
    setCreateAccountForm({
      ...createAccountForm,
      ...{ [name]: value },
    })
  }

  const _changeHandler = (event, name) => {
    let errors = createAccountForm.errors
    const { value } = event.target
    switch (name) {
      case "last_name":
        errors.civility =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères"
        break
      case "lastname":
        errors.lastname =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères"
        break
      case "firstname":
        errors.firstname =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères"
        break
      case "phone":
        errors.phone = validPhone.test(value) ? "" : "Numéro de téléphone invalide."
        break
      case "studyname":
        errors.studyname =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères"
        break
      case "studyphone":
        errors.studyphone = validPhone.test(value)
          ? ""
          : "Numéro de téléphone invalide."
        break
      case "siret":
        errors.siret = value.length > 2 ? "" : "Doit contenir au moins 3 caractères"
        break
      case "accountingemail":
        errors.accountingemail = validEmailRegex.test(value)
          ? ""
          : "Doit contenir au moins 3 caractères"
        break
      case "address":
        errors.address =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères"
        break
      case "pricepage":
        errors.pricepage = onlyNoAllowed.test(value)
          ? ""
          : "Doit contenir au moins 3 caractères"
        break
      case "zipcode":
        errors.zipcode = validZipCode.test(value)
          ? ""
          : "Doit contenir au moins 5 caractères"
        break
      case "city":
        errors.city = value.length > 2 ? "" : "Doit contenir au moins 3 caractères"
        break
      case "pricephoto":
        errors.pricephoto = onlyNoAllowed.test(value)
          ? ""
          : "Doit contenir au moins 3 caractères"
        break
      case "useremail":
        errors.useremail = validEmailRegex.test(value)
          ? ""
          : "Doit contenir au moins 3 caractères"
        break
      case "userID":
        errors.userID = validIdentify.test(value)
          ? ""
          : "Doit contenir au moins 3 caractères"
        break
      case "password":
        errors.password = validPassword.test(value) ? "" : "Mauvais mot de passe."
        break
      case "confirm_password":
        errors.confirm_password =
          value === createAccountForm.password ? "" : "Mauvais mot de passe."
        break
      case "description":
        errors.description =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères"
        break
      default:
        break
    }

    setCreateAccountForm({
      ...createAccountForm,
      ...{ [name]: event?.target?.value },
    })
  }

  const descriptionChangeHandler = (data) => {
    setCreateAccountForm({
      ...createAccountForm,
      ...{ ["description"]: data },
    })
  }

  return (
    <div className="dashboard-right-section">
      {!bailifForm && (
        <Content style={{ marginLeft: "0px" }}>
          <Breadcrumb>
            <Breadcrumb.Item>Comptes</Breadcrumb.Item>
            <Breadcrumb.Item>Voir les comptes</Breadcrumb.Item>
          </Breadcrumb>
        </Content>
      )}
      <div
        className="form_outer_wrapper"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <h2>
          <span>
            <UserOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </span>
          {bailifForm ? "MODIFICATION" : "CRÉATION"} D’UN COMPTE UTILISATEUR
        </h2>
        <Form className="create_account editbailiffaccount_form">
          <Row>
            <Col xs={24} md={24} lg={12} className="only-for-web">
              <Form.Item
                label="Type de compte"
                name="typeofaccount"
                valuePropName="typeofaccount"
                rules={[
                  {
                    required: true,
                    message: "Veuillez sélectionner Type de compte",
                  },
                ]}
              >
                <Select
                  defaultValue="Huissier"
                  onChange={handleChange}
                  disabled={bailifForm ? true : false}
                >
                  <Option value="huissier">Huissier</Option>
                  <Option value="secrétaire">Secrétaire</Option>
                  <Option value="admin">Admin</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <div className="profile_uploadimg_wrapper">
                <Form.Item>
                  <Upload
                    name="avatar"
                    showUploadList={false}
                    listType="picture-card"
                    className="avatar-uploader"
                    customRequest={FileUtils.generalDummyRequestLocal}
                    onChange={handleChangeImage}
                  >
                    <div className="uploadimg-wrapper">
                      {imageUrl ? (
                        <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
                      ) : (
                        uploadButton
                      )}
                      <div className="uploadimg-icons">
                        <img src={imguploadicon} alt="avatar-icon" />
                      </div>
                    </div>
                  </Upload>
                  <Input.Group>
                    <Row>
                      <Col xs={24} md={12} lg={10}>
                        {/* <label className="uploadimg-label">Lier à :</label> */}
                        {/*<Form.Item
                          className="uploadimage-bottom-select"
                          label="Lier à"
                          name="linkto"
                          valuePropName="linkto"
                          rules={[
                            {
                              required: true,
                              message: "Veuillez entér Civilité",
                            },
                          ]}
                        >
                          <Select
                            defaultValue={
                              Number(createAccountForm.linkto) || "Année"
                            }
                            onChange={(e) => changeHandler(e, "linkto")}
                          >
                            {allGroups.length > 0 &&
                              allGroups.map((group: any) => (
                                <Option value={group.id}>{group.group_name}</Option>
                              ))}
                          </Select>
                              </Form.Item>*/}
                        <Form.Item
                          className="uploadimage-bottom-select"
                          label="Lier à"
                          valuePropName="linkto"
                          validateStatus={
                            createAccountForm.errors.linkto.length > 0
                              ? "error"
                              : "success"
                          }
                          help={createAccountForm.errors.linkto}
                        >
                          <Select
                            value={
                              Number(createAccountForm.linkto) || "Groupes existants"
                            }
                            onChange={(e) => changeHandler(e, "linkto")}
                          // disabled={bailifForm ? !true : !false}
                          >
                            {allGroups.length > 0 &&
                              allGroups.map((group: any) => (
                                <Option value={group.id}>{group.group_name}</Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12} lg={10}>
                        <Form.Item className="groupe-button">
                          <Button
                            className="validate-btn"
                            onClick={() =>
                              props.history.push("/dashboard/creerungroupe")
                            }
                          >
                            <PlusOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                            Créer nouveau groupe
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Input.Group>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12} className="only-for-mobile">
              <Form.Item
                label="Type de compte"
                name="typeofaccount"
                valuePropName="typeofaccount"
                rules={[
                  {
                    required: true,
                    message: "Veuillez sélectionner Type de compte",
                  },
                ]}
              >
                <Select
                  defaultValue="huissier"
                  onChange={handleChange}
                  disabled={bailifForm ? true : false}
                >
                  <Option value="huissier">Huissier</Option>
                  <Option value="secrétaire">Secrétaire</Option>
                  <Option value="admin">Admin</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Civilité"
                name="civility"
                valuePropName="civility"
                validateStatus={
                  createAccountForm.errors.civility.length > 0 ? "error" : "success"
                }
                help={createAccountForm.errors.civility}
              >
                <Input
                  value={createAccountForm.civility}
                  onChange={(e) => _changeHandler(e, "civility")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Nom"
                name="lastname"
                valuePropName="lastname"
                validateStatus={
                  createAccountForm.errors.lastname.length > 0 ? "error" : "success"
                }
                help={createAccountForm.errors.lastname}
              >
                <Input
                  value={createAccountForm.lastname}
                  onChange={(e) => _changeHandler(e, "lastname")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Prénom"
                name="firstname"
                valuePropName="firstname"
                validateStatus={
                  createAccountForm.errors.firstname.length > 0 ? "error" : "success"
                }
                help={createAccountForm.errors.firstname}
              >
                <Input
                  value={createAccountForm.firstname}
                  onChange={(e) => _changeHandler(e, "firstname")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Téléphone"
                name="phone"
                valuePropName="phone"
                validateStatus={
                  createAccountForm.errors.phone.length > 0 ? "error" : "success"
                }
                help={createAccountForm.errors.phone}
              >
                <Input
                  value={createAccountForm.phone}
                  onChange={(e) => _changeHandler(e, "phone")}
                />
              </Form.Item>
            </Col>
          </Row>

          {createAccountForm.linkto !== "" && (
            <div className="infoalready-exesting">
              <Row>
                <Col xs={24} md={24} lg={12}>
                  <Form.Item
                    label="Nom de l’etude"
                    name="studyname"
                    valuePropName="studyname"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    validateStatus={
                      createAccountForm.errors.studyname.length > 0
                        ? "error"
                        : "success"
                    }
                    help={createAccountForm.errors.studyname}
                  >
                    <Input
                      disabled={true}
                      onChange={(e) => _changeHandler(e, "studyname")}
                      value={createAccountForm.studyname}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={12}>
                  <div className="input-currenct">
                    <Form.Item
                      label="Téléphone étude"
                      name="studyphone"
                      valuePropName="studyphone"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez entér Téléphone étude",
                        },
                      ]}
                      validateStatus={
                        createAccountForm.errors.studyphone.length > 0
                          ? "error"
                          : "success"
                      }
                      help={createAccountForm.errors.studyphone}
                    >
                      <Input
                        disabled={true}
                        onChange={(e) => _changeHandler(e, "studyphone")}
                        value={createAccountForm.studyphone}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={24} lg={12}>
                  <Form.Item
                    label="Siret"
                    name="siret"
                    valuePropName="siret"
                    rules={[{ required: true, message: "Veuillez entér Siret" }]}
                    validateStatus={
                      createAccountForm.errors.siret.length > 0 ? "error" : "success"
                    }
                    help={createAccountForm.errors.siret}
                  >
                    <Input
                      disabled={true}
                      onChange={(e) => _changeHandler(e, "siret")}
                      value={createAccountForm.siret}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={12}>
                  <div className="input-currenct">
                    <Form.Item
                      label="Email comptabilité"
                      name="accountingemail"
                      valuePropName="accountingemail"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez entér Email comptabilité",
                        },
                      ]}
                      validateStatus={
                        createAccountForm.errors.accountingemail.length > 0
                          ? "error"
                          : "success"
                      }
                      help={createAccountForm.errors.accountingemail}
                    >
                      <Input
                        disabled={true}
                        onChange={(e) => _changeHandler(e, "accountingemail")}
                        value={createAccountForm.accountingemail}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={24} lg={12}>
                  <Form.Item
                    label="Adresse postale"
                    name="address"
                    valuePropName="address"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez entér Adresse postale",
                      },
                    ]}
                    validateStatus={
                      createAccountForm.errors.address.length > 0
                        ? "error"
                        : "success"
                    }
                    help={createAccountForm.errors.address}
                  >
                    <Input
                      value={createAccountForm.address}
                      disabled={true}
                      onChange={(e) => _changeHandler(e, "address")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={12}>
                  <div className="input-currenct">
                    <Form.Item
                      label="Tarif/page"
                      name="pricepage"
                      valuePropName="pricepage"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez entér Tarif/page",
                        },
                      ]}
                      validateStatus={
                        createAccountForm.errors.pricepage.length > 0
                          ? "error"
                          : "success"
                      }
                      help={createAccountForm.errors.pricepage}
                    >
                      <Input
                        disabled={true}
                        onChange={(e) => _changeHandler(e, "pricepage")}
                        value={createAccountForm.pricepage}
                      />
                      <EuroCircleOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={24} lg={12}>
                  <Form.Item
                    label="CP/ Ville"
                    name="cpcity"
                    valuePropName="cpcity"
                    rules={[{ required: true, message: "Veuillez entér CP/ Ville" }]}
                    validateStatus={
                      createAccountForm.errors.zipcode.length > 0 ||
                        createAccountForm.errors.city.length > 0
                        ? "error"
                        : "success"
                    }
                    help={
                      createAccountForm.errors.zipcode ||
                      createAccountForm.errors.city
                    }
                  >
                    <Input.Group>
                      <Row gutter={8}>
                        <Col span={5}>
                          <Input
                            value={createAccountForm.zipcode || ""}
                            disabled={true}
                            onChange={(e) => _changeHandler(e, "zipcode")}
                          />
                        </Col>
                        <Col span={8}>
                          <Input
                            value={createAccountForm.city}
                            disabled={true}
                            onChange={(e) => _changeHandler(e, "city")}
                          />
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={12}>
                  <div className="input-currenct">
                    <Form.Item
                      label="Tarif/photo"
                      name="pricephoto"
                      valuePropName="pricephoto"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez entér Tarif/photo",
                        },
                      ]}
                      validateStatus={
                        createAccountForm.errors.pricephoto.length > 0
                          ? "error"
                          : "success"
                      }
                      help={createAccountForm.errors.pricephoto}
                    >
                      <Input
                        disabled={true}
                        onChange={(e) => _changeHandler(e, "pricephoto")}
                        value={createAccountForm.pricephoto}
                      />
                      <EuroCircleOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Email utilisateur"
                name="useremail"
                valuePropName=""
                rules={[
                  {
                    required: true,
                    message: "Veuillez entér Email utilisateur",
                  },
                ]}
                validateStatus={
                  createAccountForm.errors.useremail.length > 0 ? "error" : "success"
                }
                help={createAccountForm.errors.useremail}
              >
                <Input
                  disabled={bailifForm ? true : false}
                  value={createAccountForm.useremail}
                  onChange={(e) => _changeHandler(e, "useremail")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Identifiant"
                name="userID"
                valuePropName="userID"
                rules={[{ required: true, message: "Veuillez entér Identifiant" }]}
                validateStatus={
                  createAccountForm.errors.userID.length > 0 ? "error" : "success"
                }
                help={createAccountForm.errors.userID}
              >
                <Input
                  onChange={(e) => _changeHandler(e, "userID")}
                  value={createAccountForm.userID}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Mot de passe"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Veuillez saisir votre mot de passe!",
                  },
                ]}
              >
                <Input.Group>
                  <Row gutter={8}>
                    <Col span={12}>
                      {/* <Input.Password  defaultValue=""/> */}
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Veuillez saisir votre mot de passe!",
                          },
                        ]}
                        style={{ display: "inline-block" }}
                        validateStatus={
                          createAccountForm.errors.password.length > 0
                            ? "error"
                            : "success"
                        }
                        help={createAccountForm.errors.password}
                      >
                        <Input.Password
                          placeholder=""
                          className="form_password"
                          onChange={(e) => _changeHandler(e, "password")}
                          value={createAccountForm.password}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="confirm_password"
                        rules={[
                          {
                            required: true,
                            message:
                              "Veuillez saisir votre mot de passe de confirmation!",
                          },
                        ]}
                        validateStatus={
                          createAccountForm.errors.confirm_password.length > 0
                            ? "error"
                            : "success"
                        }
                        help={createAccountForm.errors.confirm_password}
                        style={{ display: "inline-block" }}
                      >
                        <Input.Password
                          placeholder="Confirmez*"
                          onChange={(e) => _changeHandler(e, "confirm_password")}
                          value={createAccountForm.confirm_password}
                        />
                      </Form.Item>
                      {/* <Input.Password  defaultValue="" placeholder="Confirmez*"/> */}
                    </Col>
                  </Row>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Matrices associées à l’utilisateur"
                name="body"
                valuePropName="data"
                rules={[{ required: true, message: "Veuillez entrer le corps" }]}
                validateStatus={
                  createAccountForm.errors.uploads.length > 0 ? "error" : "success"
                }
                help={createAccountForm.errors.uploads}
              >
                <Dragger
                  multiple={true}
                  fileList={fileUploads.selectedFileList}
                  customRequest={FileUtils.generalDummyRequestLocal}
                  onChange={onChangeDragger}
                >
                  <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                  </p>
                  <p className="ant-upload-hint">
                    Cliquez ou faites glisser pour envoyer vos fichiers{" "}
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col xs={24} md={24} lg={12}>
            <div className="ckeditor_label_wrapper">
              <Form.Item
                label="Consignes aux secrétaries"
                name="body"
                valuePropName="data"
                getValueFromEvent={(event, editor) => {
                  const data = editor.getData()
                  return data
                }}
                rules={[{ required: true, message: "Veuillez entrer le corps" }]}
                validateStatus={
                  createAccountForm.errors.description.length > 0
                    ? "error"
                    : "success"
                }
                help={createAccountForm.errors.description}
              >
                <CKEditor
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    descriptionChangeHandler(data)
                  }}
                  data={createAccountForm.description}
                  config={{
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "link",
                      "bulletedList",
                      "numberedList",
                      "blockQuote",
                    ],
                    heading: {
                      options: [
                        {
                          model: "paragraph",
                          title: "Paragraph",
                          class: "ck-heading_paragraph",
                        },
                        {
                          model: "heading1",
                          view: "h1",
                          title: "Heading 1",
                          class: "ck-heading_heading1",
                        },
                        {
                          model: "heading2",
                          view: "h2",
                          title: "Heading 2",
                          class: "ck-heading_heading2",
                        },
                      ],
                    },
                  }}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row className="create_account">
          <Col xs={24} md={24} lg={12} className="register-btn-mask">
            <Form.Item label="  ">
              <Button
                type="primary"
                className="validate-btn"
                onClick={_submitHandler}
              >
                Valider
              </Button>
              <Link to='/dashboard/voirlesgroupes'>
                <Button className="cancel-btn">Annuler</Button>
              </Link>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default EditBaliffAccount
