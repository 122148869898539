import React from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Layout,
  Menu,
  Row,
  Col,
  Breadcrumb,
  Dropdown,
  notification,
  message,
} from "antd";
import {
  DownOutlined,
  UserOutlined,
  SmileOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

import styles from "./styles.module.scss";

import Seethegroup from "../seethegroup";
import Leftmenu from "../leftmenu";

import { useDispatch } from "react-redux";

const { Header, Content } = Layout;
const { SubMenu } = Menu;

function SeeTheGroup() {
  //const currentselectindex = false;
  const dispatch = useDispatch();
  const history = useHistory();

  const onLogout = () => {
    history.push("/login");
    message.success("Has cerrado sesión");
  };

  const logout = () => {
    // dispatch(logoutUser(onLogout))
    localStorage.clear();
    history.push("/login");
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="#">
          1st menu item
        </a>
      </Menu.Item>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="#">
          2nd menu item
        </a>
      </Menu.Item>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="#">
          3rd menu item
        </a>
      </Menu.Item>
      <Menu.Item icon={<LogoutOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />} onClick={logout}>
        Cerrar sesión
      </Menu.Item>
    </Menu>
  );

  const openNotification = () => {
    notification.open({
      message: "Notification Title",
      description:
        "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
      icon: <SmileOutlined style={{ color: "#108ee9" }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />,
    });
  };

  return (
    <div className="dashboard-wrapper">
      <Header
        className={styles.header}
        style={{ position: "fixed", zIndex: 1, width: "100%" }}
      >
        <div className={styles.dashboard_logo}>
          <img
            src={"/images/dashboard-logo.svg"}
            alt="Logo"
            className={styles.responsive_img}
          />
        </div>

        <Row justify="end">
          <Col xs={2} md={1} lg={1}>
            <img
              src={"/images/notification-icon.svg"}
              alt="Logo"
              className={styles.responsive_img}
              onClick={openNotification}
            />
          </Col>
          <Col xs={18} md={12} lg={5}>
            <Dropdown overlay={menu} className="profile-dropdown">
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <img
                  src={"/images/profile-avatar.svg"}
                  alt="Logo"
                  className={styles.responsive_img}
                />
                Laurence Theillac <DownOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              </a>
            </Dropdown>
          </Col>
        </Row>
      </Header>
      <Row>
        <Col xs={24} md={3} lg={3}>
          <div className="dashboard-left-section">
            <Leftmenu />
          </div>
        </Col>
        <Col xs={24} md={21} lg={21}>
          <div className="dashboard-right-section">
            <Content style={{ marginLeft: "0px" }}>
              <Breadcrumb>
                <Breadcrumb.Item>Constats</Breadcrumb.Item>
                <Breadcrumb.Item>Constats en cours </Breadcrumb.Item>
              </Breadcrumb>
            </Content>
            <Seethegroup />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default SeeTheGroup;
