import adminConstants from "./constants";

const {
  SET_LOADING_ADMINS,
  SET_ADMINS,
  SET_CREATING_ADMIN,
  SET_CREATED_ADMIN,
  SET_DELETING_ADMIN,
  REMOVE_ADMIN
} = adminConstants;

const initialState = {
  loading: false,
  data: [],
};

const adminsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LOADING_ADMINS: {
      return {
        ...state,
        loading: action.isLoadingAdmins,
      };
    }
    case SET_ADMINS: {
      return {
        ...state,
        data: action.admins,
      };
    }
    case SET_CREATING_ADMIN: {
      return {
        ...state,
        creating: action.isCreatingAdmin,
      };
    }
    case SET_CREATED_ADMIN: {
      return {
        ...state,
        data: [action.newAdmin, ...state.data],
      };
    }
    case SET_DELETING_ADMIN: {
      return {
        ...state,
        deleting: action.isDeletingAdmin,
      };
    }
    case REMOVE_ADMIN: {
      return {
        ...state,
        data: state.data.filter((admin: any) => admin.id !== action.deletedAdminId),
      };
    }
    default: {
      return state;
    }
  }
};

export default adminsReducer;
