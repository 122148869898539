
const authConstants = {
  SET_LOADING_ADMINS: "SET_LOADING_ADMINS",
  SET_ADMINS: "SET_ADMINS",
  SET_CREATING_ADMIN: "SET_CREATING_ADMIN",
  SET_CREATED_ADMIN: "SET_CREATED_ADMIN",
  SET_DELETING_ADMIN: "SET_DELETING_ADMIN",
  REMOVE_ADMIN: "REMOVE_ADMIN",
};

export default authConstants;