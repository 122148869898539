import React, { useEffect, useState } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import {
  ConfigProvider,
  Layout,
  Table,
  Input,
  Badge,
  Menu,
  Dropdown,
  Space,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Breadcrumb,
  Button,
  DatePicker,
  message,
} from "antd";
import {
  VerticalAlignBottomOutlined,
  DownloadOutlined,
  PlusOutlined,
  CaretRightOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import enUS from "antd/lib/locale-provider/en_US";
import frFR from "antd/lib/locale/fr_FR";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import "moment/locale/fr-ch";
import { calendarServices } from "../../../_services/calendarServices";

import styles from "../styles.module.scss";

import Leftmenu from "../leftmenu";
import Topmenu from "../topmenu";

import Commonbailiffheader from "../commonbailiffheader";
import Commonsecretaryheader from "../commonsecretaryheader";
import { start } from "repl";

moment.locale("fr");

const { Content } = Layout;
const { Option } = Select;

const handleChange = () => {
  console.log(`selected`);
};

function CreateAnAlert() {
  const history = useHistory();
  const { user } = useSelector((state: any) => state.auth);
  const [startDate, setStart]: any = useState({ startDate: "" });
  const [endDate, setEnd]: any = useState({ endDate: "" });
  const [inputMessage, setInput] = useState({ input: "" });
  const [createCalendar, setCreateCalendar] = useState({
    errors: {
      start_date: "",
      end_date: "",
      comments: "",
    },
  });
  const [activateValider, setActivateValider] = useState(true);

  useEffect(() => {
    compareDates();
  }, [startDate, endDate]);

  useEffect(() => {
    compareDates();
  }, [activateValider]);

  const dateFormat = "DD/MM/YYYY";
  const notFilledFields = () => {
    const errors: any = createCalendar.errors;
    if (startDate.startDate === "") {
      errors.start_date = "Need start date";
    }
    if (endDate.endDate === "") {
      errors.end_date = "Need end date";
    }
    if (inputMessage.input === "") {
      errors.comments = "Need a comment";
    }
    setCreateCalendar({ ...createCalendar, ...{ errors } });
  };

  /*const validateForm = (formFields) => {
    notFilledFields()
    let valid = true
    Object.values(formFields.errors).forEach(
      (val: any) => val.length > 0 && (valid = false)
    )
    return valid
  }*/

  const _submitHandler = async () => {
    const data: any = {};
    data.start_date = startDate.startDate;
    data.end_date = endDate.endDate;
    data.comment = inputMessage.input || " ";
    await calendarServices
      .createCalendarAPI(data)
      .then((response) => response.data)
      .then((response: any) => {
        message.success("Indisponibilité créée avec succès.");
        if (user.roleId === 2) {
          history.push("/dashboard/bailiffcalendar");
        } else {
          history.push("/dashboard/secretarycalendar");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  function setStartDate(date, dateString) {
    try {
      const newDate = date.format("YYYY/MM/DD");
      setStart({ startDate: newDate });
    } catch (error) {
      setStart({ startDate: null });
      console.log(error);
    }
    compareDates();
  }

  function setEndDate(date, dateString) {
    try {
      const newDate = date.format("YYYY/MM/DD");
      setEnd({ endDate: newDate });
    } catch (error) {
      setEnd({ endDate: null });
      console.log(error);
    }
    compareDates();
  }

  const compareDates = () => {
    const dateOne = moment(startDate.startDate, "YYYY-MM-DD");
    const dateTwo = moment(endDate.endDate, "YYYY-MM-DD");
    if (
      startDate.startDate == "" ||
      endDate.endDate == "" ||
      endDate.endDate == null ||
      startDate.startDate == null
    ) {
      setActivateValider(true);
    }
    if (dateOne < dateTwo && dateOne != null && dateTwo != null) {
      setActivateValider(false);
    } else if (dateOne > dateTwo && dateOne != null && dateTwo != null) {
      setActivateValider(true);
    } else if (dateOne.diff(dateTwo, "days") < 1) {
      setActivateValider(false);
    }
  };

  const handleInput = (e) => {
    console.log(e.target.value);
    setInput({ input: e.target.value });
  };
  const testHandler = () => {};

  return (
    <Layout className="creer-constat-wrapper noleftmenu_section">
      <Topmenu />
      <div className="mesconstats-table">
        <Row>
          {/* <Topmenu/> */}
          {/* <Col xs={24} md={4} lg={4} >
          <div className="dashboard-left-section"> 
            <Leftmenu/>
          </div>
        </Col> */}
          <Col xs={24} md={24} lg={24}>
            <div className="dashboard-right-section">
              {/* <Content>
              <Breadcrumb>
                <Breadcrumb.Item>Comptes</Breadcrumb.Item>
                <Breadcrumb.Item>Constats en cours</Breadcrumb.Item>
              </Breadcrumb>
            </Content> */}
              <div className="form_outer_wrapper">
                {user.role_id == 2 ? (
                  <Commonbailiffheader />
                ) : (
                  <Commonsecretaryheader />
                )}
                <div className="form_tableouter_wrapper">
                  <h2>Signaler une absence ou un congé</h2>
                  <div className="createanalert-wrapper">
                    <div className="createanalert-mask">
                      <ConfigProvider locale={frFR}>
                        <Form className="form-create">
                          <Row gutter={16}>
                            <Col xs={18} md={18} lg={18}>
                              <Form.Item
                                className="item-inline-form"
                                label="Date de début"
                              >
                                <DatePicker
                                  className="item-inline-form"
                                  format={dateFormat}
                                  onChange={setStartDate}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col xs={18} md={18} lg={18}>
                              <Form.Item
                                className="item-inline-form"
                                label="Date de fin"
                              >
                                <DatePicker
                                  format={dateFormat}
                                  onChange={setEndDate}
                                />
                                <br></br>
                                {activateValider ? (
                                  startDate.startDate != "" &&
                                  endDate.endDate != "" ? (
                                    <span style={{ color: "red" }}>
                                      La date doit être postérieure à la date de
                                      début.
                                    </span>
                                  ) : (
                                    <div></div>
                                  )
                                ) : (
                                  <div></div>
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col xs={18} md={18} lg={18}>
                              <Form.Item
                                className="item-inline-form"
                                label="Commentaire"
                              >
                                <Input.TextArea
                                  placeholder="Message..."
                                  onChange={handleInput}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Form.Item className="register-btn-mask">
                            <Button
                              type="primary"
                              className="validate-btn"
                              onClick={_submitHandler}
                              disabled={activateValider}
                            >
                              Valider
                            </Button>
                            <Link to="/dashboard">
                              <Button className="cancel-btn">Annuler</Button>
                            </Link>
                          </Form.Item>
                        </Form>
                      </ConfigProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default CreateAnAlert;
