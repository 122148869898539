import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Progress,
  Row,
  Space,
  Spin,
  Table,
  Upload,
} from "antd";
import React, { useState, useEffect } from "react";
import { reportServices } from "../../_services/reportServices";
import {
  VerticalAlignBottomOutlined,
  CaretRightOutlined,
  EyeOutlined,
  PaperClipOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { saveAs } from "file-saver";
import { fileTypeReturn } from "../../utils/functions/fileTypeReturn";
import FileUtils from "../../utils/files";
import { formatRecName } from "../../utils/functions/formatRecName";
import {
  addFilesReport,
  setBillingReport,
  setPercentageProgress,
  setRetrocessionReport,
} from "../../redux/report";
import { useDispatch, useSelector } from "react-redux";

interface ExpandedRowConstatProps {
  record: any;
  enableUploadDeposited?: boolean;
  enableUploadReturned?: boolean;
  enableDeleteDeposited?: boolean;
  enableDeleteReturned?: boolean;
  isBilling?: boolean;
  isRetrocession?: boolean;
  showDataRetrocession?: boolean;
  showDataBilling?: boolean;
  onOpenFile?: (file: any) => void;
  onDeleteFile?: (report_id: any, file: any, type: FileTypeConstat) => void;
  onOpenComments?: (report_id: any, comments: any) => void;
  onArchive?: (report_id: any) => void;
}

export enum FileTypeConstat {
  deposited,
  returned,
}

enum FormFields {
  provisionalPages = "provisional_pages",
  provisionalPhotos = "provisional_photos",
  retrocessionPages = "retrocession_pages",
  retrocessionPhotos = "retrocession_photos",
  billingPages = "billing_pages",
  billingPhotos = "billing_photos",
  comment = "comment",
}

const ExpandedRowConstat = ({
  record,
  enableUploadDeposited = true,
  enableUploadReturned = true,
  enableDeleteDeposited = true,
  enableDeleteReturned = true,
  isBilling = false,
  isRetrocession = false,
  showDataRetrocession = false,
  showDataBilling = false,
  onOpenFile,
  onDeleteFile,
  onOpenComments,
  onArchive,
}: ExpandedRowConstatProps) => {
  const dispatch = useDispatch();
  const { progress, loadingAddFilesReport, loadingSetRetrocessionReport } =
    useSelector((state: any) => state.report);
  const [form] = Form.useForm();
  const [check, setCheck] = useState({
    retrocession: false,
    billing: false,
  });

  useEffect(() => {
    form.setFieldsValue({
      [FormFields.provisionalPages]: record.count_pages_provisional,
      [FormFields.provisionalPhotos]: record.count_photos_provisional,
      [FormFields.retrocessionPages]: record?.count_pages_retrocession,
      [FormFields.retrocessionPhotos]: record?.count_photos_retrocession,
      [FormFields.billingPages]: record?.count_pages_billing,
      [FormFields.billingPhotos]: record?.count_photos_billing,
      [FormFields.comment]: "",
    });
  }, []);

  const _onSubmitBilling = () => {
    form.validateFields().then((values) => {
      dispatch(
        setBillingReport({
          report_id: record.id,
          count_pages_retrocession: values[FormFields.retrocessionPages],
          count_photos_retrocession: values[FormFields.retrocessionPhotos],
          count_pages_billing: values[FormFields.billingPages],
          count_photos_billing: values[FormFields.billingPhotos],
        })
      );
    });
  };

  const _onSubmitRetrocession = () => {
    form.validateFields().then((values) => {
      dispatch(
        setRetrocessionReport({
          report_id: record.id,
          count_pages_provisional: values[FormFields.provisionalPages],
          count_photos_provisional: values[FormFields.provisionalPhotos],
          comment: values[FormFields.comment],
        })
      );
    });
  };

  const columns = [
    {
      dataIndex: "date",
      key: "date",
      width: 100,
      render: () => (
        <div className="tabletoggle-section">
          <Space size="middle" className="tabletoggle-section-title">
            <h2>ÉLÉMENTS DÉPOSÉS</h2>
            {enableUploadDeposited && (
              <Upload
                accept=".doc, .docx, .odt, .pdf, .txt"
                name="deposited_items"
                showUploadList={false}
                listType="picture-card"
                className="avatar-uploader"
                customRequest={FileUtils.generalDummyRequestLocal}
                onChange={(info: any) => {
                  switch (info.file.status) {
                    case "done":
                      const formData = new FormData();
                      formData.append("report_id", record.id);
                      formData.append(
                        "deposited_items",
                        info.file.originFileObj
                      );
                      dispatch(
                        addFilesReport({
                          data: formData,
                          setPercentageProgress,
                          dispatch,
                          section: FileTypeConstat.deposited,
                          id: record.id,
                        })
                      );
                      break;
                  }
                }}
              >
                <Button
                  icon={<PaperClipOutlined rotate={-40} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                  type="primary"
                  style={{ marginRight: "1em" }}
                >
                  Ajouter un fichier
                </Button>
              </Upload>
            )}
          </Space>

          <div
            className="tabletoggle-section-list-mask"
            style={{ display: "flex", flexFlow: "column" }}
          >
            {record.deposited_items.length > 0 &&
              record.deposited_items.map((file: any, index) => (
                <div className="tabletoggle-section-list" key={index + "rec"}>
                  <div
                    className="tabletoggle-section-info"
                    style={{ display: "flex", height: "max-content" }}
                  >
                    <div>
                      <h2
                        style={{
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                        }}
                      >
                        {file.originalname.length > 80
                          ? file.originalname.substring(0, 80) +
                          ".." +
                          file.originalname.substring(
                            file.originalname.lastIndexOf(".")
                          )
                          : file.originalname}
                      </h2>
                      <div className="table-autio-date">
                        <ul>
                          <li>
                            {moment(file.created_at).format("DD/MM/YYYY HH:mm")}{" "}
                          </li>
                          <li>{FileUtils.bytesToMb(file.size) + "Mo"}</li>
                        </ul>
                      </div>
                    </div>
                    <div style={{ alignSelf: "center", marginLeft: "auto" }}>
                      <div className="table-audio-icons">
                        <ul style={{ display: "flex" }}>
                          <li>
                            <a href={file.linkUrl} target="_blank">
                              <CaretRightOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                            </a>
                          </li>
                          <li>
                            <div
                              onClick={() =>
                                saveAs(file.linkUrl, file.originalname)
                              }
                            >
                              <a>
                                <VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                              </a>
                            </div>
                          </li>
                          {enableDeleteDeposited && (
                            <li>
                              <div
                                onClick={() =>
                                  onDeleteFile &&
                                  onDeleteFile(
                                    record.id,
                                    [{ linkUrl: file.linkUrl }],
                                    FileTypeConstat.deposited
                                  )
                                }
                              >
                                <a>
                                  <DeleteOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                </a>
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {loadingAddFilesReport &&
              progress.id === record.id &&
              progress.section === FileTypeConstat.deposited && (
                <Progress percent={progress.percentage} status="active" />
              )}
          </div>
        </div>
      ),
    },
    {
      dataIndex: "name",
      key: "name",
      width: 100,
      render: () => (
        <div className="tabletoggle-section">
          <Space size="middle" className="tabletoggle-section-title">
            <h2>ÉLÉMENTS RETOURNÉS</h2>
            {enableUploadReturned && (
              <Upload
                accept=".doc, .docx, .odt, .pdf, .txt"
                name="returned_items"
                showUploadList={false}
                listType="picture-card"
                className="avatar-uploader"
                customRequest={FileUtils.generalDummyRequestLocal}
                onChange={(info: any) => {
                  switch (info.file.status) {
                    case "done":
                      const formData = new FormData();
                      formData.append("report_id", record.id);
                      formData.append(
                        "returned_items",
                        info.file.originFileObj
                      );
                      dispatch(
                        addFilesReport({
                          data: formData,
                          setPercentageProgress,
                          dispatch,
                          section: FileTypeConstat.returned,
                          id: record.id,
                        })
                      );
                      break;
                  }
                }}
              >
                <Button
                  icon={<PaperClipOutlined rotate={-40} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                  type="primary"
                  style={{ marginRight: "1em" }}
                >
                  Ajouter un fichier
                </Button>
              </Upload>
            )}
          </Space>
          <div
            className="tabletoggle-section-list-mask"
            style={{ display: "flex", flexFlow: "column" }}
          >
            {record.returned_items.length > 0 &&
              record.returned_items.map((file, index) => (
                <div className="tabletoggle-section-list" key={index + "rec"}>
                  <div
                    className="tabletoggle-section-info"
                    style={{ display: "flex", height: "max-content" }}
                  >
                    <h2
                      style={{
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                      }}
                    >
                      {file.originalname.length > 80
                        ? file.originalname.substring(0, 80) +
                        ".." +
                        file.originalname.substring(
                          file.originalname.lastIndexOf(".")
                        )
                        : file.originalname}
                    </h2>
                    <div className="table-audio-icons">
                      <ul style={{ display: "flex" }}>
                        <li>
                          <a>
                            <EyeOutlined
                              onClick={() => onOpenFile && onOpenFile({ link: file.linkUrl })} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                          </a>
                        </li>
                        <li>
                          <div
                            onClick={() =>
                              saveAs(file.linkUrl, file.originalname)
                            }
                          >
                            <a>
                              <VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                            </a>
                          </div>
                        </li>
                        {enableDeleteReturned && (
                          <li>
                            <div
                              onClick={() =>
                                onDeleteFile &&
                                onDeleteFile(
                                  record.id,
                                  [{ linkUrl: file.linkUrl }],
                                  FileTypeConstat.returned
                                )
                              }
                            >
                              <a>
                                <DeleteOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                              </a>
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="table-autio-date">
                    <ul>
                      <li>
                        {moment(file.created_at).format("DD/MM/YYYY HH:mm")}{" "}
                      </li>
                      <li>{FileUtils.bytesToMb(file.size) + "Mo"}</li>
                    </ul>
                  </div>
                </div>
              ))}
            {loadingAddFilesReport &&
              progress.id === record.id &&
              progress.section === FileTypeConstat.returned && (
                <Progress
                  percent={progress.percentage}
                  status="active"
                  style={{ display: "flow-root" }}
                />
              )}
            {isBilling && (
              <Form className="table-statu-fact" form={form}>
                <h2>STATUT FACTURATION</h2>
                <Row>
                  <table>
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th
                          style={{ textAlign: "center", fontWeight: "normal" }}
                        >
                          Provisoire
                        </th>
                        <th
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            textAlignLast: "center",
                          }}
                        >
                          <Checkbox
                            defaultChecked={false}
                            onChange={(e) => {
                              if (e.target.checked) {
                                const provisionalPages = form.getFieldValue(
                                  FormFields.provisionalPages
                                );
                                const provisionalPhotos = form.getFieldValue(
                                  FormFields.provisionalPhotos
                                );

                                form.setFieldsValue({
                                  [FormFields.retrocessionPages]:
                                    provisionalPages,
                                  [FormFields.retrocessionPhotos]:
                                    provisionalPhotos,
                                });
                              } else {
                                form.setFieldsValue({
                                  [FormFields.retrocessionPages]: 0,
                                  [FormFields.retrocessionPhotos]: 0,
                                });
                              }

                              setCheck({
                                ...check,
                                retrocession: e.target.checked,
                              });
                            }}
                          >
                            Rétrocession
                          </Checkbox>
                        </th>
                        <th
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            textAlignLast: "center",
                          }}
                        >
                          <Checkbox
                            defaultChecked={false}
                            onChange={(e) => {
                              if (e.target.checked) {
                                const retrocessionPages = form.getFieldValue(
                                  FormFields.retrocessionPages
                                );
                                const retrocessionPhotos = form.getFieldValue(
                                  FormFields.retrocessionPhotos
                                );

                                form.setFieldsValue({
                                  [FormFields.billingPages]: retrocessionPages,
                                  [FormFields.billingPhotos]:
                                    retrocessionPhotos,
                                });
                              } else {
                                form.setFieldsValue({
                                  [FormFields.billingPages]: 0,
                                  [FormFields.billingPhotos]: 0,
                                });
                              }

                              setCheck({ ...check, billing: e.target.checked });
                            }}
                          >
                            Facturation
                          </Checkbox>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Nombre de pages</td>
                        <td
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          <Form.Item
                            name={FormFields.provisionalPages}
                            style={{ margin: "0px" }}
                          >
                            <Input
                              style={{ textAlignLast: "center" }}
                              disabled={true}
                            />
                          </Form.Item>
                        </td>
                        <td
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          <Form.Item
                            name={FormFields.retrocessionPages}
                            style={{ margin: "0px" }}
                          >
                            <Input
                              style={{ textAlignLast: "center" }}
                              disabled={check.retrocession}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(input) => {
                                form.setFieldValue(
                                  FormFields.retrocessionPages,
                                  input.target.value
                                );

                                if (check.billing) {
                                  form.setFieldValue(
                                    FormFields.billingPages,
                                    input.target.value
                                  );
                                }
                              }}
                            />
                          </Form.Item>
                        </td>
                        <td
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          <Form.Item
                            name={FormFields.billingPages}
                            style={{ margin: "0px" }}
                          >
                            <Input
                              style={{ textAlignLast: "center" }}
                              disabled={check.billing}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(input) => {
                                form.setFieldValue(
                                  FormFields.billingPages,
                                  input.target.value
                                );
                              }}
                            />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Nombre de photos</td>
                        <td
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          <Form.Item
                            name={FormFields.provisionalPhotos}
                            style={{ margin: "0px" }}
                          >
                            <Input
                              style={{ textAlignLast: "center" }}
                              disabled={true}
                            />
                          </Form.Item>
                        </td>
                        <td
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          <Form.Item
                            name={FormFields.retrocessionPhotos}
                            style={{ margin: "0px" }}
                          >
                            <Input
                              style={{ textAlignLast: "center" }}
                              disabled={check.retrocession}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(input) => {
                                form.setFieldValue(
                                  FormFields.retrocessionPhotos,
                                  input.target.value
                                );

                                if (check.billing) {
                                  form.setFieldValue(
                                    FormFields.billingPhotos,
                                    input.target.value
                                  );
                                }
                              }}
                            />
                          </Form.Item>
                        </td>
                        <td
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          <Form.Item
                            name={FormFields.billingPhotos}
                            style={{ margin: "0px" }}
                          >
                            <Input
                              style={{ textAlignLast: "center" }}
                              disabled={check.billing}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(input) => {
                                form.setFieldValue(
                                  FormFields.billingPhotos,
                                  input.target.value
                                );
                              }}
                            />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td
                          style={{
                            textAlignLast: "end",
                          }}
                        >
                          <Form.Item shouldUpdate style={{ margin: "0px" }}>
                            {() => (
                              <button
                                className={
                                  form.getFieldValue(FormFields.billingPages) ==
                                    0
                                    ? "btn-disabled"
                                    : "envoyer-btn"
                                }
                                disabled={
                                  form.getFieldValue(FormFields.billingPages) ==
                                  0
                                }
                                style={{
                                  marginTop: "5px",
                                  marginRight: "10px",
                                }}
                                onClick={() => {
                                  if (record?.secretary_id != null) {
                                    _onSubmitBilling();
                                  } else {
                                    message.error(
                                      "Veuillez sélectionner un secrétaire"
                                    );
                                  }
                                }}
                              >
                                Envoyer
                              </button>
                            )}
                          </Form.Item>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Row>
              </Form>
            )}

            {isRetrocession && (
              <Form className="table-statu-fact" form={form}>
                <h2>RÉTROCESSION</h2>
                <div
                  style={{
                    marginTop: "20px",
                    textAlign: "start",
                    fontWeight: "bold",
                  }}
                >
                  Indiquez ici le nombre de pages total et le nombre de photos
                  du constat.
                </div>
                <Row>
                  <table>
                    <tbody>
                      <Row
                        style={{
                          marginTop: "20px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Col xs={6}>Nombre de pages</Col>
                        <Col
                          xs={7}
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          <Form.Item
                            name={FormFields.provisionalPages}
                            style={{ margin: "0px" }}
                          >
                            <Input
                              style={{ textAlignLast: "center" }}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Col xs={6}>Nombre de photos</Col>
                        <Col
                          xs={7}
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          <Form.Item
                            name={FormFields.provisionalPhotos}
                            style={{ margin: "0px" }}
                          >
                            <Input
                              style={{ textAlignLast: "center" }}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </tbody>
                  </table>
                </Row>
                <h3
                  style={{
                    textAlign: "start",
                    fontSize: "17px",
                    lineHeight: "22px",
                    color: "#595959",
                    margin: "0px 0px 1em 0px",
                    fontWeight: 700,
                    paddingBottom: "1em",
                    borderBottom: "1px solid #E9E9E9",
                  }}
                >
                  Commentaire
                </h3>
                <Row>
                  <Col xs={17}>
                    <Form.Item
                      name={FormFields.comment}
                      style={{ margin: "0px" }}
                    >
                      <Input.TextArea
                        style={{ width: "100%" }}
                        rows={4}
                        placeholder="Commentaire"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={6}
                    style={{
                      textAlign: !loadingSetRetrocessionReport
                        ? "end"
                        : "center",
                      alignSelf: "center",
                    }}
                  >
                    {loadingSetRetrocessionReport ? (
                      <Spin />
                    ) : (
                      <Form.Item shouldUpdate style={{ margin: "0px" }}>
                        {() => (
                          <button
                            className={
                              form.getFieldValue(FormFields.provisionalPages) ==
                                0
                                ? "btn-disabled"
                                : "envoyer-btn"
                            }
                            disabled={
                              form.getFieldValue(FormFields.provisionalPages) ==
                              0
                            }
                            style={{ marginTop: "5px", marginRight: "10px" }}
                            onClick={() => _onSubmitRetrocession()}
                          >
                            Envoyer
                          </button>
                        )}
                      </Form.Item>
                    )}
                  </Col>
                </Row>
              </Form>
            )}
            {showDataRetrocession && (
              <div className="table-statu-fact">
                <Row
                  className="title-expanded-subsection"
                  style={{ justifyContent: "start", paddingBottom: "0.8em" }}
                >
                  Rétrocession
                </Row>
                <Row style={{ justifyContent: "space-between" }}>
                  <Col xs={10}>
                    <Row
                      style={{
                        borderTop: "1px",
                        borderLeft: "1px",
                        borderRight: "1px",
                        borderBottom: "1px",
                        borderColor: "#D9D9D9",
                        borderStyle: "solid",
                      }}
                    >
                      <Col
                        xs={18}
                        style={{
                          textAlign: "center",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          borderRight: "1px solid #D9D9D9",
                        }}
                      >
                        Nombre de pages
                      </Col>
                      <Col
                        xs={6}
                        style={{
                          textAlign: "center",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                      >
                        {record?.count_pages_retrocession}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={10}>
                    <Row
                      style={{
                        borderTop: "1px",
                        borderLeft: "1px",
                        borderRight: "1px",
                        borderBottom: "1px",
                        borderColor: "#D9D9D9",
                        borderStyle: "solid",
                      }}
                    >
                      <Col
                        xs={18}
                        style={{
                          textAlign: "center",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          borderRight: "1px solid #D9D9D9",
                        }}
                      >
                        Nombre de photos
                      </Col>
                      <Col
                        xs={6}
                        style={{
                          textAlign: "center",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                      >
                        {record?.count_photos_retrocession}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            )}
            {showDataBilling && (
              <div className="table-statu-fact">
                <Row
                  className="title-expanded-subsection"
                  style={{ justifyContent: "start", paddingBottom: "0.8em" }}
                >
                  Facturation
                </Row>
                <Row style={{ justifyContent: "space-between" }}>
                  <Col xs={10}>
                    <Row
                      style={{
                        borderTop: "1px",
                        borderLeft: "1px",
                        borderRight: "1px",
                        borderBottom: "1px",
                        borderColor: "#D9D9D9",
                        borderStyle: "solid",
                      }}
                    >
                      <Col
                        xs={18}
                        style={{
                          textAlign: "center",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          borderRight: "1px solid #D9D9D9",
                        }}
                      >
                        Nombre de pages
                      </Col>
                      <Col
                        xs={6}
                        style={{
                          textAlign: "center",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                      >
                        {record?.count_pages_billing}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={10}>
                    <Row
                      style={{
                        borderTop: "1px",
                        borderLeft: "1px",
                        borderRight: "1px",
                        borderBottom: "1px",
                        borderColor: "#D9D9D9",
                        borderStyle: "solid",
                      }}
                    >
                      <Col
                        xs={18}
                        style={{
                          textAlign: "center",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          borderRight: "1px solid #D9D9D9",
                        }}
                      >
                        Nombre de photos
                      </Col>
                      <Col
                        xs={6}
                        style={{
                          textAlign: "center",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                      >
                        {record?.count_photos_billing}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
      ),
    },
  ];

  const data = [] as any;
  for (let i = 0; i < 1; ++i) {
    data.push({
      key: i,
      date: "24/12/2014",
      name: "This is production name",
      upgradeNum: "Upgraded: 56",
    });
  }
  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default ExpandedRowConstat;
