import React, { useState, useEffect } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import {
  Layout,
  Table,
  Input,
  Badge,
  Menu,
  Dropdown,
  Space,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Breadcrumb,
  Modal,
  Button,
  Comment,
  Avatar,
  message,
  Empty,
} from "antd";
import { AlignType } from 'rc-table/lib/interface'
import {
  UserOutlined,
  VerticalAlignBottomOutlined,
  MailOutlined,
  FolderViewOutlined,
  DownOutlined,
  PlusOutlined,
  CaretRightOutlined,
  SearchOutlined,
  RollbackOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";

import styles from "../styles.module.scss";

import Leftmenu from "../leftmenu";
import Topmenu from "../topmenu";
import { usersService } from "../../../_services/usersSerives";
import { reportServices } from "../../../_services/reportServices";
import moment from "moment";
import "moment/locale/fr-ch";
import AdvanceFilterOptions from "../../../components/AdvanceFilterOptions";
import { group } from "console";
import axios from "axios";
import {
  addCommentReport,
  getReports,
  resetReportState,
} from "../../../redux/report";
import { getAllUsers } from "../../../redux/user";
import { useDispatch, useSelector } from "react-redux";
import ModalInstructions from "../../../components/ModalInstructions/ModalInstructions";
import ModalDocument from "../../../components/ModalDocument/ModalDocument";
import FileUtils from "../../../utils/files";
import { saveAs } from "file-saver";
import ExpandedRowConstat from "../../../components/ExpandedRowConstat/ExpandedRowConstat";
import ModalComments from "../../../components/ModalComments";

moment.locale("fr");
const { Content } = Layout;
const { Option } = Select;

function BillingAdmin() {
  const dispatch = useDispatch();
  const { reports, successAddCommentReport, errorAddCommentReport } =
    useSelector((state: any) => state.report);
  const { users } = useSelector((state: any) => state.user);
  const [reportsFiltered, setReportsFiltered]: any = useState([]);
  const [filters, setFilters] = useState({
    month: moment().format("MMMM"),
    year: moment().format("YYYY"),
    secretary_id: "",
  });
  const [modalInstructions, setModalInstructions] = useState({
    visible: false,
    data: {},
  });
  const [modalViewDocument, setModalViewDocument] = useState({
    visible: false,
    data: {},
  });
  const [modalComments, setModalComments]: any = useState({
    visible: false,
    data: {},
  });

  useEffect(() => {
    dispatch(resetReportState());
    dispatch(getAllUsers());
    dispatch(getReports({ states: ["TE"], without_filter_archived: true }));
  }, [dispatch, successAddCommentReport, errorAddCommentReport]);

  useEffect(() => {
    if (reports?.length > 0) {
      let reportsFiltered = reports.filter((report) => {
        const date = moment(report.createdAt);
        return (
          date.format("MMMM") === filters.month &&
          date.format("YYYY") === filters.year
        );
      });

      if (filters.secretary_id && filters.secretary_id !== "") {
        reportsFiltered = reportsFiltered.filter((report) => {
          return report.secretary_id === filters.secretary_id;
        });
      }

      reportsFiltered = reportsFiltered.map((report: any) => {
        const secretary = users?.find(
          (user: any) => user.id === report.secretary_id
        );

        return {
          ...report,
          amount_without_taxes_pages:
            Math.round(
              report.count_pages_retrocession -
              report.count_photos_retrocession / 2
            ) * (secretary?.secretary_price_page || 0),
          amount_without_taxes_photos:
            report.count_photos_retrocession *
            (secretary?.secretary_price_photo || 0),
        };
      });

      setReportsFiltered(reportsFiltered);
    }
  }, [reports, filters]);

  useEffect(() => {
    if (
      modalComments.visible &&
      reportsFiltered &&
      reportsFiltered.length > 0
    ) {
      const report = reportsFiltered.find(
        (report: any) => report.id === modalComments.data.id
      );
      setModalComments({
        ...modalComments,
        data: report,
      });
    }
  }, [reportsFiltered]);

  const columns: any = [
    {
      title: "Date",
      key: "date",
      align: "left" as AlignType,
      render: (report) => {
        return (
          <span>
            {moment(report.createdAt).format("DD/MM/YYYY")}{" "}
            {moment(report.createdAt).format("HH:mm")}
          </span>
        );
      },
    },
    {
      title: "Huissier",
      key: "name",
      align: "left" as AlignType,
      render: (report) => {
        const user = users?.filter(
          (user: any) => user.id === report.bailiff_id
        )[0];
        return (
          <Space size="middle">
            <a
              style={{ textDecoration: "underline" }}
              onClick={(e) => {
                e.stopPropagation();
                setModalInstructions({
                  data: user,
                  visible: true,
                });
              }}
            >
              {user?.last_name}
            </a>
          </Space>
        );
      },
    },
    {
      title: "Secrétaire",
      key: "secretaire",
      align: "left" as AlignType,
      render: (report) => {
        const secretary = users?.find(
          (user: any) => user.id === report.secretary_id
        );

        return (
          <Space size="middle">
            {users && (
              <span style={{ textAlign: "center" }}>
                {secretary
                  ? secretary.first_name + " " + secretary.last_name
                  : "-"}
              </span>
            )}
          </Space>
        );
      },
    },
    {
      title: "Nom du constat",
      key: "name",
      align: "left" as AlignType,
      render: (report) => report.name,
    },
    {
      title: "Éléments déposés",
      key: "deposited_items",
      align: "left" as AlignType,
      width: "5%",
      render: (report) => (
        <div style={{ textAlign: "left" }}>
          <Space
            size="middle"
            onClick={(e) => {
              e.stopPropagation();
              report.deposited_items.forEach((file) => {
                saveAs(file.linkUrl, file.originalname);
              });
            }}
          >
            <a>{report.deposited_items.length || "-"}</a>
            {report.deposited_items.length > 0 && (
              <>
                <div>
                  <a>
                    <VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                  </a>
                </div>
                <a href={report.deposited_items[0].file_url} download></a>
                <a>
                  {FileUtils.bytesToMb(
                    report.deposited_items.reduce((acc: any, item: any) => {
                      return acc + item.size;
                    }, 0)
                  ) + "Mo"}
                </a>
              </>
            )}
          </Space>
        </div>
      ),
    },
    {
      title: "Date retour",
      key: "date",
      align: "left" as AlignType,
      render: (report) => {
        return (
          <span>
            {moment(report.updatedAt).format("DD/MM/YYYY")}{" "}
            {moment(report.updatedAt).format("HH:mm")}
          </span>
        );
      },
    },
    {
      title: "€",
      key: "amount",
      align: "left" as AlignType,
      width: "5%",
      render: (report) =>
        (
          report.amount_without_taxes_pages + report.amount_without_taxes_photos
        ).toFixed(2) + "€",
    },
  ];

  return (
    <div className="dashboard-right-section retrocessionadminreports_wrapper">
      <Content style={{ marginLeft: "0px" }}>
        <Breadcrumb>
          <Breadcrumb.Item>Rétrocession</Breadcrumb.Item>
          <Breadcrumb.Item>Constats</Breadcrumb.Item>
        </Breadcrumb>
      </Content>
      <ModalInstructions
        visible={modalInstructions.visible}
        onClose={() => setModalInstructions({ visible: false, data: {} })}
        data={modalInstructions.data}
        onOpenFile={(data) =>
          setModalViewDocument({ visible: true, data: { ...data } })
        }
      />
      <ModalDocument
        visible={modalViewDocument.visible}
        data={modalViewDocument.data}
        onClose={() => setModalViewDocument({ visible: false, data: {} })}
      />
      <ModalComments
        visible={modalComments.visible}
        onClose={() => setModalComments({ visible: false, data: {} })}
        onSend={(data) => {
          dispatch(addCommentReport(data));
        }}
        data={modalComments.data}
      />
      <div className="form_tableouter_wrapper">
        <h2>CONSTATS</h2>
        <div className="tabletop-filter-section">
          <Row style={{ justifyContent: "space-between" }}>
            <Col className="gutter-row" lg={2}>
              <Select
                value={filters.year}
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    year: value,
                  });
                }}
              >
                {Array.from(Array(moment().year() - 2019).keys()).map(
                  (year) => (
                    <Option value={`${2020 + year}`}>{2020 + year}</Option>
                  )
                )}
              </Select>
            </Col>
            <Col className="gutter-row" lg={2}>
              <Select
                value={filters.month}
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    month: value,
                  });
                }}
              >
                {moment.months().map((month) => (
                  <Option value={month}>
                    {month.charAt(0).toUpperCase() + month.slice(1)}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col className="gutter-row" lg={6}>
              <Select
                defaultValue=""
                className="table-rows-select-two"
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    secretary_id: value,
                  });
                }}
              >
                <Option value="">Sélectionner la secrétaire</Option>
                {users &&
                  users
                    ?.filter((user) => user.role_name === "Secretary")
                    ?.map((user: any, index) => (
                      <Option value={user?.id} key={"op" + index}>
                        {user?.first_name + " " + user?.last_name}
                      </Option>
                    ))}
              </Select>
            </Col>
            <Col className="gutter-row" xs={24} md={7} lg={14}>
              <div className="table-toptable-layout">
                <div className="left-section">
                  <div className="left-section-top-title">
                    <div className="top-title-left-section">CUMUL PÉRIODE</div>
                    <div className="top-title-right-section">Total</div>
                  </div>
                  <div className="left-section-content">
                    <div className="left-section-content-left">
                      Nb de constats
                    </div>
                    <div className="left-section-content-right">
                      {reportsFiltered?.length || 0}
                    </div>
                  </div>
                  <div className="left-section-content">
                    <div className="left-section-content-left">
                      Nb de pages texte
                    </div>
                    <div className="left-section-content-right">
                      {reportsFiltered?.reduce((acc, report) => {
                        return acc + report.count_pages_retrocession;
                      }, 0)}
                    </div>
                  </div>
                  <div className="left-section-content">
                    <div className="left-section-content-left">
                      Nb de photos
                    </div>
                    <div className="left-section-content-right">
                      {reportsFiltered?.reduce((acc, report) => {
                        return acc + report.count_photos_retrocession;
                      }, 0)}
                    </div>
                  </div>
                </div>
                <div className="right-section">
                  <div className="left-section-top-title">
                    <div className="top-title-right-section">Total HT</div>
                  </div>
                  <div className="left-section-content">
                    <div className="left-section-content-total">
                      {reportsFiltered
                        ?.reduce((acc, report) => {
                          return (
                            acc +
                            (report.amount_without_taxes_pages +
                              report.amount_without_taxes_photos)
                          );
                        }, 0)
                        ?.toFixed(2) + " €"}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Table
          columns={columns}
          className="constatsencours-tables manageiconforalign"
          rowKey={(record) => record.id}
          expandable={{
            expandedRowRender: (record) => (
              <ExpandedRowConstat
                record={record}
                enableUploadDeposited={false}
                enableUploadReturned={false}
                enableDeleteDeposited={false}
                enableDeleteReturned={false}
                showDataRetrocession
                onOpenFile={(data: any) =>
                  setModalViewDocument({
                    visible: true,
                    data: { ...data },
                  })
                }
                onOpenComments={(report_id: any, comments: any) =>
                  setModalComments({
                    visible: true,
                    data: { ...comments },
                  })
                }
              />
            ),
            rowExpandable: (record: any) => true,
            expandRowByClick: true,
            expandIconColumnIndex: -1,
          }}
          dataSource={reportsFiltered || []}
          pagination={{
            pageSize: 10,
          }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Aucun constat."
              />
            ),
          }}
        />
      </div>
    </div>
  );
}

export default BillingAdmin;
