import React, { useEffect, useState } from "react";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import {
  FileDoneOutlined,
  SwapOutlined,
  FileTextOutlined,
  TeamOutlined,
  MessageOutlined,
  BarChartOutlined,
  DiffOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import styles from "../styles.module.scss";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RoutesDashboard } from "../../../helpers/routes";

const { SubMenu } = Menu;
const { Sider } = Layout;

enum subMenuKeys {
  sub1 = "sub1",
  sub2 = "sub2",
  sub3 = "sub3",
  sub4 = "sub4",
  sub5 = "sub5",
}

const Linkmenu = () => {
  const history = useHistory();
  const [openKeys, setOpenKeys] = React.useState([subMenuKeys.sub1]);
  const [selectedKeys, setSelectedKeys] = React.useState([
    RoutesDashboard.reportsInProgessAdmin,
  ]);
  const { user } = useSelector((state: any) => state.auth);

  useEffect(() => {
    const path = history.location.pathname;
    setSelectedKeys([path]);
  }, [history.location.pathname]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (Object.values(subMenuKeys).indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <Layout>
      <div className="dashboard-left-section">
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
          style={{ backgroundColor: "white", position: "fixed" }}
        >
          <div className="logo" />
          <div
            key="sub5"
            className={styles.menulink}
            id="leftmenu-profileimg"
            style={{ padding: " 10px 0 0 20px" }}
          >
            <img
              src={
                user?.profile ||
                "https://w7.pngwing.com/pngs/81/570/png-transparent-profile-logo-computer-icons-user-user-blue-heroes-logo-thumbnail.png"
              }
              alt="profile-image"
            />
            <b>{user.first_name + " " + user.last_name}</b>
          </div>
          <Menu
            mode="inline"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            selectedKeys={selectedKeys}
          >
            <SubMenu
              key={subMenuKeys.sub1}
              icon={<FileDoneOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
              title="Constats"
              className={styles.menulink}
            >
              <Menu.Item
                className={styles.menulink_sub}
                key={RoutesDashboard.reportsInProgessAdmin}
                onClick={() =>
                  history.push(RoutesDashboard.reportsInProgessAdmin)
                }
              >
                Constats en cours
              </Menu.Item>
              <Menu.Item
                className={styles.menulink_sub}
                key={RoutesDashboard.reportsTerminatedAdmin}
                onClick={() =>
                  history.push(RoutesDashboard.reportsTerminatedAdmin)
                }
              >
                Constats terminés
              </Menu.Item>
              <Menu.Item
                className={styles.menulink_sub}
                key={RoutesDashboard.reportsArchivedAdmin}
                onClick={() =>
                  history.push(RoutesDashboard.reportsArchivedAdmin)
                }
              >
                Constats archivés
              </Menu.Item>
              <Menu.Item
                className={styles.menulink_sub}
                key={RoutesDashboard.createReportAdmin}
                onClick={() => history.push(RoutesDashboard.createReportAdmin)}
              >
                Créer un constat
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key={subMenuKeys.sub2}
              icon={<SwapOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
              title="Rétrocession"
              className={styles.menulink}
            >
              <Menu.Item
                className={styles.menulink_sub}
                key={RoutesDashboard.retrocessionsReportsAdmin}
                onClick={() =>
                  history.push(RoutesDashboard.retrocessionsReportsAdmin)
                }
              >
                Constats
              </Menu.Item>
              <Menu.Item
                className={styles.menulink_sub}
                key={RoutesDashboard.retrocessionsBillsAdmin}
                onClick={() =>
                  history.push(RoutesDashboard.retrocessionsBillsAdmin)
                }
              >
                Factures
              </Menu.Item>
            </SubMenu>
            <Menu.Item
              key="/dashboard/facturation"
              icon={<DiffOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
              className={styles.menulink}
              onClick={() => history.push("/dashboard/facturation")}
            >
              Facturation
            </Menu.Item>
            <SubMenu
              key={subMenuKeys.sub3}
              icon={<TeamOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
              title="Comptes"
              className={styles.menulink}
            >
              <Menu.Item
                className={styles.menulink_sub}
                key="/dashboard/seetheaccount"
                onClick={() => history.push("/dashboard/seetheaccount")}
              >
                Voir les comptes
              </Menu.Item>
              <Menu.Item
                className={styles.menulink_sub}
                key="/dashboard/voirlesgroupes"
                onClick={() => history.push("/dashboard/voirlesgroupes")}
              >
                Voir les groupes
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key={subMenuKeys.sub4}
              icon={<MessageOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
              title="Actualités"
              className={styles.menulink}
            >
              <Menu.Item
                className={styles.menulink_sub}
                key="/dashboard/viewnews"
                onClick={() => history.push("/dashboard/viewnews")}
              >
                Tous les actualités
              </Menu.Item>
              <Menu.Item
                className={styles.menulink_sub}
                key="/dashboard/createanews"
                onClick={() => history.push("/dashboard/createanews")}
              >
                Créer actualité
              </Menu.Item>
            </SubMenu>
            <Menu.Item
              key="/dashboard/statistiques"
              icon={<BarChartOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
              className={styles.menulink}
              onClick={() => history.push("/dashboard/statistiques")}
            >
              Statistiques
            </Menu.Item>
            <Menu.Item
              key="/dashboard/absences"
              icon={<CalendarOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
              className={styles.menulink}
              onClick={() => history.push("/dashboard/allAbsences")}
            >
              Absences
            </Menu.Item>
          </Menu>
        </Sider>
      </div>
    </Layout>
  );
};
export default Linkmenu;
