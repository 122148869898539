import React, { useState, useEffect } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import {
  Layout,
  Table,
  Input,
  Badge,
  Menu,
  Dropdown,
  Space,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Breadcrumb,
  Modal,
  Empty,
} from "antd";
import { AlignType } from 'rc-table/lib/interface'
import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import styles from "../styles.module.scss";
import Grid from "@material-ui/core/Grid";

import Leftmenu from "../leftmenu";
import Topmenu from "../topmenu";
import { reportServices } from "../../../_services/reportServices";
import { usersService } from "../../../_services/usersSerives";
import AdvanceFilterOptions from "../../../components/AdvanceFilterOptions";
import { facturesServices } from "../../../_services/facturesServices";
import FileViewer from "react-file-viewer";
import moment from "moment";
import { saveAs } from "file-saver";
import axios from "axios";
import { resetReportState } from "../../../redux/report";
import { getAllUsers } from "../../../redux/user";
import { useDispatch, useSelector } from "react-redux";
import { getAllBillsSecretaries } from "../../../redux/billing";
import { VerticalAlignBottomOutlined } from "@mui/icons-material";
import ModalDocument from "../../../components/ModalDocument/ModalDocument";
import {
  TemplateBillingSecretary,
} from "../../../helpers/billing";

const { Content } = Layout;
const { Option } = Select;

function RetrocessionsBillingsAdmin() {
  const dispatch = useDispatch();
  const { billsSecretaries, billsDocumentsSecretaries } = useSelector(
    (state: any) => state.billing
  );
  const { users } = useSelector((state: any) => state.user);
  const [billsSecretariesFiltered, setBillsSecretariesFiltered]: any = useState(
    []
  );
  const [filters, setFilters] = useState({
    month: moment().format("MMMM"),
    year: moment().format("YYYY"),
    secretary_id: "",
  });
  const [modalViewDocument, setModalViewDocument] = useState({
    visible: false,
    data: {},
  });
  const [modalDataBilling, setModalDataBilling]: any = useState({
    visible: false,
    data: {},
  });

  useEffect(() => {
    dispatch(resetReportState());
    dispatch(getAllUsers());
    dispatch(getAllBillsSecretaries());
  }, [dispatch]);

  useEffect(() => {
    if (billsSecretaries?.length > 0) {
      let billsFiltered = billsSecretaries.filter((bill) => {
        const reports = bill.reports.filter((report) => {
          const date = moment(report.createdAt);
          return (
            date.format("MMMM") === filters.month &&
            date.format("YYYY") === filters.year
          );
        });

        return reports.length > 0;
      });

      billsFiltered = billsFiltered.map((bill) => {
        const reports = bill.reports.filter((report) => {
          const date = moment(report.createdAt);
          return (
            date.format("MMMM") === filters.month &&
            date.format("YYYY") === filters.year
          );
        });

        return {
          ...bill,
          reports: reports,
        };
      });

      if (filters.secretary_id && filters.secretary_id !== "") {
        billsFiltered = billsFiltered.filter((bill) => {
          const reports = bill.reports.filter((report) => {
            return report.secretary_id === filters.secretary_id;
          });

          return reports.length > 0;
        });
      }

      billsFiltered = billsFiltered.map((bill) => {
        const total_count_pages = bill.reports.reduce(
          (acc: any, report: any) => acc + report.count_pages_retrocession,
          0
        );
        const total_count_photos = bill.reports.reduce(
          (acc: any, report: any) => acc + report.count_photos_retrocession,
          0
        );

        const reports = bill.reports.map((report: any) => {
          return {
            ...report,
            amount_without_taxes_pages:
              (
                report.count_pages_billing - Math.round(report.count_photos_billing / 2)
              ) *
              bill.secretary_price_page *
              (report.discount > 0 ? report.discount / 100 : 1),
            amount_without_taxes_photos:
              report.count_photos_billing *
              bill.secretary_price_photo *
              (report.discount > 0 ? report.discount / 100 : 1),
            bailiff: users.find((user: any) => user.id === report.bailiff_id),
            secretary: users.find(
              (user: any) => user.id === report.secretary_id
            ),
          };
        });

        return {
          ...bill,
          total_count_pages: total_count_pages,
          total_count_photos: total_count_photos,
          secretary: users.find((user: any) => user.id === bill.secretary_id),
          amount_without_taxes:
            (bill.discount > 0
              ? total_count_pages *
              bill.secretary_price_page *
              (bill.discount / 100)
              : total_count_pages * bill.secretary_price_page) +
            (bill.discount > 0
              ? total_count_photos *
              bill.secretary_price_photo *
              (bill.discount / 100)
              : total_count_photos * bill.secretary_price_photo),
          reports: reports,
        };
      });

      billsFiltered = billsFiltered.map((bill: any) => {
        const billDocument = billsDocumentsSecretaries.find(
          (billDocument: any) => {
            const report = bill.reports.find((report: any) => {
              return (
                moment(report.createdAt).format("YYYY-MM") ===
                moment(billDocument.createdAt).format("YYYY-MM") &&
                billDocument.user_id === report.secretary_id
              );
            });

            return report;
          }
        );

        return {
          ...bill,
          bill: billDocument,
        };
      });

      setBillsSecretariesFiltered(billsFiltered);
    }
  }, [billsSecretaries, filters, users]);

  const columns = [
    {
      title: "Secrétaire",
      key: "secretaire",
      align: "left" as AlignType,
      render: (bill: any) => {
        const secretaries = bill?.reports?.map((report: any) => {
          const secretary = users.find(
            (user: any) => user.id === report?.secretary_id
          );

          return secretary?.first_name + " " + secretary?.last_name;
        });

        const uniqueSecretaries = [...new Set(secretaries)];

        return (
          <Space size="middle" style={{ display: "inline-block" }}>
            {uniqueSecretaries.map((secretary: any) => (
              <span>{secretary}</span>
            ))}
          </Space>
        );
      },
    },
    {
      title: "Nombre pages",
      key: "nombre_pages",
      align: "left" as AlignType,
      render: (bill: any) => {
        return <span>{bill?.total_count_pages}</span>;
      },
    },
    {
      title: "Nombre photos",
      key: "nombre_photos",
      align: "left" as AlignType,
      render: (bill: any) => {
        return <span>{bill?.total_count_photos}</span>;
      },
    },
    {
      title: "Montant HT",
      key: "amount",
      align: "left" as AlignType,
      render: (bill: any) => {
        return <span>{bill?.amount_without_taxes?.toFixed(2)}€</span>;
      },
    },
    {
      title: "Voir",
      key: "view",
      render: (bill) => {
        const enabled = bill?.bill ? true : false;

        return (
          <Space size="middle">
            <EyeOutlined
              style={enabled ? { opacity: 1 } : { opacity: 0.3 }}
              onClick={(event) => {
                event.stopPropagation();
                if (!enabled) return;

                setModalViewDocument({
                  ...modalViewDocument,
                  visible: true,
                  data: {
                    link: bill.bill.files[0].linkUrl,
                  },
                });
              }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </Space>
        );
      },
    },
    {
      title: "Télécharger",
      key: "download",
      render: (bill) => {
        const enabled = bill?.bill ? true : false;

        return (
          <Space size="middle">
            <VerticalAlignBottomOutlined
              className="clickable"
              style={enabled ? { opacity: 1 } : { opacity: 0.3 }}
              onClick={(event) => {
                event.stopPropagation();
                if (!enabled) return;

                saveAs(
                  bill.bill.files[0].linkUrl,
                  bill.bill.files[0].originalname
                );
              }}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <div className="dashboard-right-section retrocessionadminreports_wrapper">
      <Content style={{ marginLeft: "0px" }}>
        <Breadcrumb>
          <Breadcrumb.Item>Rétrocession</Breadcrumb.Item>
          <Breadcrumb.Item>Factures</Breadcrumb.Item>
        </Breadcrumb>
      </Content>
      <ModalDocument
        visible={modalViewDocument.visible}
        data={modalViewDocument.data}
        onClose={() => setModalViewDocument({ visible: false, data: {} })}
      />
      <div className="form_tableouter_wrapper">
        <h2>FACTURES</h2>
        <div className="tabletop-filter-section">
          <Row style={{ justifyContent: "space-between" }}>
            <Col className="gutter-row" lg={2}>
              <Select
                value={filters.year}
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    year: value,
                  });
                }}
              >
                {Array.from(Array(moment().year() - 2019).keys()).map(
                  (year) => (
                    <Option value={`${2020 + year}`}>{2020 + year}</Option>
                  )
                )}
              </Select>
            </Col>
            <Col className="gutter-row" lg={2}>
              <Select
                value={filters.month}
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    month: value,
                  });
                }}
              >
                {moment.months().map((month) => (
                  <Option value={month}>
                    {month.charAt(0).toUpperCase() + month.slice(1)}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col className="gutter-row" lg={6}>
              <Select
                defaultValue=""
                className="table-rows-select-two"
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    secretary_id: value,
                  });
                }}
              >
                <Option value="">Sélectionner la secrétaire</Option>
                {users &&
                  users
                    ?.filter((user) => user.role_name === "Secretary")
                    ?.map((user: any, index) => (
                      <Option value={user?.id} key={"op" + index}>
                        {user?.first_name + " " + user?.last_name}
                      </Option>
                    ))}
              </Select>
            </Col>
            <Col className="gutter-row" xs={24} md={7} lg={14}>
              <div className="table-toptable-layout">
                <div className="left-section">
                  <div className="left-section-top-title">
                    <div className="top-title-left-section">CUMUL PÉRIODE</div>
                    <div className="top-title-right-section">Total</div>
                  </div>
                  <div className="left-section-content">
                    <div className="left-section-content-left">
                      Nb de constats
                    </div>
                    <div className="left-section-content-right">
                      {billsSecretariesFiltered.reduce((acc, billing) => {
                        return acc + billing.reports.length;
                      }, 0)}
                    </div>
                  </div>
                  <div className="left-section-content">
                    <div className="left-section-content-left">
                      Nb de pages texte
                    </div>
                    <div className="left-section-content-right">
                      {billsSecretariesFiltered.reduce((acc, billing) => {
                        return acc + billing.total_count_pages;
                      }, 0)}
                    </div>
                  </div>
                  <div className="left-section-content">
                    <div className="left-section-content-left">
                      Nb de photos
                    </div>
                    <div className="left-section-content-right">
                      {billsSecretariesFiltered.reduce((acc, billing) => {
                        return acc + billing.total_count_photos;
                      }, 0)}
                    </div>
                  </div>
                </div>
                <div className="right-section">
                  <div className="left-section-top-title">
                    <div className="top-title-right-section">Total HT</div>
                  </div>
                  <div className="left-section-content">
                    <div className="left-section-content-total">
                      {billsSecretariesFiltered
                        .reduce((acc, billing) => {
                          return acc + billing.amount_without_taxes;
                        }, 0)
                        ?.toFixed(2) + " €"}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Table
          className="facturation-table"
          columns={columns}
          dataSource={
            billsSecretariesFiltered.filter((bill: any) => bill.bill) || []
          }
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Aucune facture."
              />
            ),
          }}
        />
        {modalDataBilling?.data &&
          Object.keys(modalDataBilling?.data).length > 0 && (
            <div
              style={{
                contentVisibility: "hidden",
              }}
            >
              <TemplateBillingSecretary data={modalDataBilling.data} />
            </div>
          )}
        <Modal
          centered
          footer={null}
          open={modalDataBilling.visible}
          onCancel={() => setModalDataBilling({ visible: false, data: {} })}
          destroyOnClose={true}
          width="auto !important"
        >
          <TemplateBillingSecretary data={modalDataBilling.data} />
        </Modal>
      </div>
    </div>
  );
}

export default RetrocessionsBillingsAdmin;
