import React, { useState, useEffect } from "react";
import {
  Layout,
  Input,
  Row,
  Col,
  Form,
  Select,
  Breadcrumb,
  Button,
  Upload,
  message,
  Progress,
  Empty,
  Spin,
} from "antd";
import { CloudUploadOutlined, CloseOutlined } from "@ant-design/icons";
import { usersService } from "../../../_services/usersSerives";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/fr-ch";
import axios from "axios";
import { getAllUsers } from "../../../redux/user";
import {
  createReport,
  resetReportState,
  setPercentageProgress,
} from "../../../redux/report";
import FileUtils from "../../../utils/files";
import "./styles.module.scss";
import { RoutesDashboard } from "../../../helpers/routes";

moment.locale("fr");
const globals = require("../../../helpers/globals");
const URL = globals.domain;

const { Dragger } = Upload;

const { Content } = Layout;
const { Option } = Select;

function CreateReport({ history }) {
  const dispatch = useDispatch();
  const {
    loadingCreateReport,
    successCreateReport,
    errorCreateReport,
    progress,
  } = useSelector((state: any) => state.report);
  const { users, loadingGetAllUsers } = useSelector((state: any) => state.user);

  const [form] = Form.useForm();
  const [filesUploaded, setFilesUploaded]: any = useState([]);
  const [actualCancelToken, setActualCancelToken]: any = useState();

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const _onSubmit = () =>
    form.validateFields().then((values) => {
      const cancelToken = axios.CancelToken.source();
      setActualCancelToken(cancelToken);
      const formData = new FormData();
      formData.append("bailiff_id", values.baillif);
      formData.append("name", values.name);
      filesUploaded.forEach((file) => {
        formData.append("deposited_items", file);
      });
      dispatch(
        createReport({
          data: formData,
          setPercentageProgress,
          dispatch,
          section: "",
          id: "",
          cancelToken: cancelToken.token,
        })
      );
    });

  useEffect(() => {
    if (successCreateReport) {
      message.success("Constat créé avec succès");
      dispatch(resetReportState());
      history.push(RoutesDashboard.reportsInProgessAdmin);
    }

    if (errorCreateReport && errorCreateReport.message) {
      message.error("Essayer plus tard");
    }
  }, [successCreateReport, errorCreateReport]);

  return loadingGetAllUsers ? (
    <Spin />
  ) : (
    <div className="dashboard-right-section creerunconstat-wrapper">
      <Content style={{ marginLeft: "0px" }}>
        <Breadcrumb>
          <Breadcrumb.Item>Constats</Breadcrumb.Item>
          <Breadcrumb.Item>Créer un constat</Breadcrumb.Item>
        </Breadcrumb>
      </Content>

      <h2 className="pagewrapper_top">CRÉER UN CONSTAT</h2>
      <div className="form_outer_onlyscreenwrapper">
        <h2 className="toptitlewithunderline">NOUVEAU CONSTAT</h2>
        <div>
          {!loadingCreateReport ? (
            <Form className="form-create" form={form}>
              <Row gutter={16}>
                <Col xs={12} md={12} lg={12}>
                  <Form.Item
                    className="item-inline-form"
                    label="HUISSIER"
                    name={"baillif"}
                    rules={[
                      {
                        required: true,
                        message: "Veuillez sélectionner un huissier",
                      },
                    ]}
                  >
                    <Select
                      defaultValue=""
                      notFoundContent={{
                        emptyText: (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="Aucun huissier."
                          />
                        ),
                      }}
                    >
                      {users &&
                        users
                          .filter((user) => user.role_name === "Bailiff")
                          .map((bailiff: any, key) => (
                            <Option value={bailiff.id} key={key + "fd"}>
                              {bailiff.first_name + " " + bailiff.last_name}
                            </Option>
                          ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={12} md={12} lg={12}>
                  <Form.Item
                    className="item-inline-form remove-margin-bottom"
                    label="NOM DU CONSTAT"
                    name={"name"}
                    rules={[
                      {
                        required: true,
                        message: "Veuillez entrer un nom pour le constat",
                      },
                    ]}
                  >
                    <Input placeholder="90 caractéres max." />
                  </Form.Item>
                  <span className="input-bottom-text item-inline-form">
                    Attention ce champ ne sera plus modifiable, aprés
                    validation.
                  </span>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={12} md={12} lg={12}>
                  <Form.Item
                    className="item-inline-form"
                    label="JOINDRE FICHIERS"
                    name="filesUpload"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                      {
                        validator: async (_, value) => {
                          if (value?.fileList?.length || 0 > 0) {
                            return Promise.resolve();
                          }
                          return Promise.reject("Veuillez entrer un fichier");
                        },
                        validateTrigger: "onSubmit",
                      },
                    ]}
                  >
                    <Dragger
                      multiple={true}
                      fileList={filesUploaded}
                      beforeUpload={(file) => {
                        setFilesUploaded([...filesUploaded, file]);
                        return false;
                      }}
                      onRemove={(file) => {
                        const index = filesUploaded.indexOf(file);
                        const newFileList = filesUploaded.slice();
                        newFileList.splice(index, 1);
                        setFilesUploaded(newFileList);
                      }}
                    >
                      <p className="ant-upload-drag-icon">
                        <CloudUploadOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </p>
                      <p className="ant-upload-hint">
                        Glissez-déposez vos fichiers ici{" "}
                      </p>
                    </Dragger>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={13} md={13} lg={13} className="register-btn-mask">
                  <Form.Item>
                    <Button
                      type="primary"
                      className="validate-btn"
                      htmlType="button"
                      onClick={_onSubmit}
                    >
                      Valider
                    </Button>
                    <Button
                      className="cancel-btn"
                      onClick={() => history.push("/dashboard/constatsencours")}
                    >
                      Annuler
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          ) : (
            <div
              className="form-create"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
                flexFlow: "column",
              }}
            >
              <Progress percent={progress.percentage} status="active" />
              <div style={{ marginTop: "20px" }}>
                <Button
                  className="cancel-btn"
                  style={{
                    color: "#ff0000",
                  }}
                  onClick={() => actualCancelToken?.cancel()}
                >
                  Annuler
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateReport;
