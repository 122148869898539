import React from "react";
import { Modal } from "antd";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const ModalDocument = ({ visible, onClose, data }) => {
  return (
    <Modal
      centered
      footer={null}
      visible={visible}
      onCancel={onClose}
      destroyOnClose={true}
      width="80vw"
      bodyStyle={{
        height: "80vh",
      }}
    >
      {data.link != "" && (
        <DocViewer
          pluginRenderers={DocViewerRenderers}
          style={{ width: "100%", height: "100%" }}
          documents={[
            {
              uri: data.link,
            },
          ]}
          config={{
            header: {
              disableHeader: false,
              disableFileName: true,
              retainURLParams: false,
            },
          }}
        />
      )}
    </Modal>
  );
};

export default ModalDocument;
