import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Table,
  Input,
  Space,
  Row,
  Col,
  Select,
  Breadcrumb,
  message,
  Modal,
  Comment,
  Avatar,
  Upload,
  Progress,
  Empty,
  Badge,
} from "antd";
import { AlignType } from 'rc-table/lib/interface'
import {
  VerticalAlignBottomOutlined,
  MailOutlined,
  FolderViewOutlined,
  PlusOutlined,
  CaretRightOutlined,
  SearchOutlined,
  RollbackOutlined,
  EyeOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { reportServices } from "../../../_services/reportServices";
import moment from "moment";
import "moment/locale/fr-ch";
import BailiffHeader from "../../components/bailiffHeader";
import Commonbailiffheader from "../commonbailiffheader";
import { useDispatch, useSelector } from "react-redux";
import AdvanceFilterOptions from "../../../components/AdvanceFilterOptions";
import axios from "axios";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { Grid } from "@mui/material";
import styles from "./styles.module.scss";
import { fileTypeReturn } from "../../../utils/functions/fileTypeReturn";
import { formatRecName } from "../../../utils/functions/formatRecName";
import FileUtils from "../../../utils/files";
import { saveAs } from "file-saver";
import {
  addCommentReport,
  getReports,
  resetReportState,
  setArchiveReport,
} from "../../../redux/report";
import { getAllUsers } from "../../../redux/user";
import ModalArchiveConstat from "../../../components/ModalArchiveConstat/ModalArchiveConstat";
import ModalDocument from "../../../components/ModalDocument/ModalDocument";
import ExpandedRowConstat from "../../../components/ExpandedRowConstat/ExpandedRowConstat";
import ModalComments, { userRoles } from "../../../components/ModalComments";
import { commentUtils } from "../../../utils/functions/comments";

moment.locale("fr");
const globals = require("../../../helpers/globals");
const URL = globals.domain;

const { Option } = Select;

function ReportsInProgressMonthBailiff({ history }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.auth);
  const {
    reports,
    loadingUpdateSecretaryReport,
    successUpdateSecretaryReport,
    errorUpdateSecretaryReport,
    successAddFilesReport,
    successRemoveFilesReport,
    successSetArchiveReport,
    successSetBillingReport,
    errorSetBillingReport,
    successAddCommentReport,
    errorAddCommentReport,
    successMarkAsReadCommentReport
  } = useSelector((state: any) => state.report);
  const { users, loadingGetAllUsers, successGetAllUsers, errorGetAllUsers } =
    useSelector((state: any) => state.user);
  const [reportsFiltered, setReportsFiltered]: any = useState([]);
  const [filters, setFilters]: any = useState({
    visible: false,
    itemsPerPage: 10,
    searchText: "",
  });
  const [modalInstructions, setModalInstructions] = useState({
    visible: false,
    data: {},
  });
  const [modalViewDocument, setModalViewDocument] = useState({
    visible: false,
    data: {},
  });
  const [modalDeleteFileConstat, setModalDeleteFileConstat]: any = useState({
    visible: false,
    data: {},
  });
  const [modalComments, setModalComments]: any = useState({
    visible: false,
    data: {},
  });
  const [modalArchiveConstat, setModalArchiveConstat]: any = useState({
    visible: false,
    data: {},
  });

  useEffect(() => {
    dispatch(resetReportState());
    dispatch(getAllUsers());
    dispatch(getReports({ states: ["EC", "RS", "TE"], bailiff_id: user.id }));
  }, [
    dispatch,
    successUpdateSecretaryReport,
    successAddFilesReport,
    successRemoveFilesReport,
    successSetBillingReport,
    successSetArchiveReport,
    successAddCommentReport,
    errorAddCommentReport,
    successMarkAsReadCommentReport
  ]);

  useEffect(() => {
    let reportsFiltered = reports;

    reportsFiltered = reportsFiltered.map((report: any) => {
      let newReport = report;
      if (
        report.status === "NA" ||
        report.status === "TE" ||
        report.status === "AR"
      ) {
        newReport = {
          ...newReport,
          status_alert: "default",
        };
      } else if (moment().diff(report.createdAt || 0, "hours") < 30) {
        newReport = {
          ...newReport,
          status_alert: "success",
        };
      } else if (
        moment().diff(report.createdAt || 0, "hours") <= 36 &&
        moment().diff(report.createdAt || 0, "hours") >= 30
      ) {
        newReport = {
          ...newReport,
          status_alert: "warning",
        };
      } else {
        newReport = {
          ...newReport,
          status_alert: "error",
        };
      }

      if (report.status !== "TE") {
        newReport.returned_items = [];
      }

      return newReport;
    });

    if (filters?.baliff_id && filters?.baliff_id != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) => report.bailiff_id === filters.baliff_id
      );
    }

    if (filters?.secretary_id && filters?.secretary_id != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) => report.secretary_id === filters.secretary_id
      );
    }

    if (filters?.year && filters?.year != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) =>
          moment(report.updatedAt).format("YYYY") === filters.year
      );
    }

    if (filters?.month && filters?.month != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) =>
          moment(report.created_at).format("MM") === filters.month
      );
    }

    if (filters?.status && filters?.status != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) => report.status === filters.status
      );
    }

    if (filters?.searchText && filters?.searchText != "") {
      reportsFiltered = reportsFiltered.filter((report: any) =>
        report?.name?.toLowerCase().includes(filters.searchText.toLowerCase())
      );
    }

    setReportsFiltered(reportsFiltered);
  }, [reports, filters]);

  useEffect(() => {
    if (
      modalComments.visible &&
      reportsFiltered &&
      reportsFiltered.length > 0
    ) {
      const report = reportsFiltered.find(
        (report: any) => report.id === modalComments.data.id
      );
      setModalComments({
        ...modalComments,
        data: report,
      });
    }
  }, [reportsFiltered]);

  useEffect(() => {
    if (successSetArchiveReport) {
      message.success("Constat archivé avec succès");
    }
  }, [successSetArchiveReport]);

  const columns: any = [
    {
      title: "Date",
      key: "date",
      align: "left" as AlignType,
      render: (report) => {
        return <span>{moment(report.createdAt).format("DD/MM/YYYY")}</span>;
      },
    },
    {
      title: "Nom du constat",
      dataIndex: "name",
      key: "name",
      align: "left" as AlignType,
      className: "nomDoConstats-table",
    },
    {
      title: "Éléments déposés",
      key: "deposited_items",
      align: "left" as AlignType,
      render: (report) => (
        <div>
          <Space
            size="middle"
            onClick={(e) => {
              e.stopPropagation();
              report.deposited_items.forEach((file) => {
                saveAs(file.linkUrl, file.originalname);
              });
            }}
          >
            <a>{report.deposited_items.length || "-"}</a>
            {report.deposited_items.length > 0 && (
              <>
                <div>
                  <a>
                    <VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                  </a>
                </div>
                <a href={report.deposited_items[0].file_url} download></a>
              </>
            )}
          </Space>
        </div>
      ),
    },
    {
      title: "Éléments retournés",
      key: "returned_items",
      align: "left" as AlignType,
      render: (report) => (
        <div>
          <Space
            size="middle"
            onClick={(e) => {
              if (report.status != "TE") return;
              e.stopPropagation();
              report.returned_items.forEach((file) => {
                saveAs(file.linkUrl, file.originalname);
              });
            }}
          >
            {report.returned_items.length > 0 && report.status == "TE" && (
              <>
                <Button
                  htmlType="submit"
                  style={{
                    height: "2rem",
                    border: "1px solid #40A9FF",
                    color: "#ffffff",
                    backgroundColor: "#40A9FF",
                    borderRadius: "4px",
                  }}
                >
                  Télécharger
                </Button>
              </>
            )}
          </Space>
        </div>
      ),
    },
    {
      title: "Pages",
      dataIndex: "count_pages_billing",
      key: "count_pages",
      align: "center" as AlignType,
      className: "pageConstats-table",
      render: (dataIndex) => <p>{dataIndex}</p>,
    },
    {
      title: "Photos",
      dataIndex: "count_photos_billing",
      key: "count_photos",
      align: "center" as AlignType,
      className: "pageConstats-table",
      render: (dataIndex) => <p>{dataIndex}</p>,
    },
    {
      title: "Montant HT",
      key: "amount",
      align: "center" as AlignType,
      render: (report: any) => {
        const value =
          ((report.count_pages_billing - Math.round(report.count_photos_billing / 2)) * report.group_price_page) +
          report.count_photos_billing * report.group_price_photo;

        return <span>{value > 0 ? `${value.toFixed(2)}€` : "-"}</span>;
      },
    },
    {
      title: "Statut",
      key: "status",
      align: "center" as AlignType,
      render: (report) => (
        <p>{report.status === "RS" ? "EC" : report.status}</p>
      ),
    },
    {
      title: "Commentaire",
      key: "operation",
      align: "center" as AlignType,
      render: (data, info, index, showOne = false) => {
        const comments = commentUtils.filterByRole({
          comments: data?.comments,
          role: userRoles.bailiff,
          user,
          users,
        });

        const commentsWithoutRead = comments.filter((comment: any) =>
          data.comments_without_read.includes(comment.id)
        );

        return (
          <Space size="small">
            <a
              className={commentsWithoutRead?.length > 0 ? "red-dot-container" : ""}
              onClick={(e) => {
                e.stopPropagation();
                setModalComments({
                  visible: true,
                  data: data,
                });
              }}
            >
              {commentsWithoutRead?.length > 0 ? <div className="red-dot-alert" /> : null}
              <MailOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </a>
          </Space>
        );
      },
    },
    {
      title: "Archiver",
      key: "archiver",
      align: "center" as AlignType,
      render: (report) => (
        <Space size="small">
          {report.status === "TE" && (
            <a
              onClick={(e) => {
                e.stopPropagation();
                setModalArchiveConstat({
                  visible: true,
                  data: { report_id: report.id },
                });
              }}
            >
              <FolderViewOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </a>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div
      className="dashboard-right-section creer-constat-wrapper noleftmenu_section dashboard-bailiff"
      style={{ justifyContent: "center", display: "flex", width: "100%" }}
    >
      <ModalDocument
        visible={modalViewDocument.visible}
        data={modalViewDocument.data}
        onClose={() => setModalViewDocument({ visible: false, data: {} })}
      />
      <ModalArchiveConstat
        visible={modalArchiveConstat.visible}
        onClose={() => setModalArchiveConstat({ visible: false, data: {} })}
        onArchive={() => {
          dispatch(
            setArchiveReport({ report_id: modalArchiveConstat.data.report_id })
          );
          setModalArchiveConstat({ visible: false, data: {} });
        }}
      />
      <ModalComments
        visible={modalComments.visible}
        onClose={() => setModalComments({ visible: false, data: {} })}
        onSend={(data) => {
          dispatch(addCommentReport(data));
        }}
        data={modalComments.data}
      />
      <Grid container>
        <Commonbailiffheader />
        <Grid item lg={12} xs={12}>
          <h2 className="pagetitle-table">MES CONSTATS EN COURS</h2>
        </Grid>
        <Grid item lg={4} xs={12}>
          <Select
            defaultValue="one"
            className="table-rows-select"
            onChange={(value: any) =>
              setFilters({ ...filters, itemsPerPage: Number(value) })
            }
          >
            <Option value="one">10</Option>
            <Option value="two">20</Option>
            <Option value="three">30</Option>
            <Option value="four">40</Option>
            <Option value="five">50</Option>
          </Select>
        </Grid>
        <Grid lg={3} xs={12} className="GridItem">
          <Link to="/dashboard/createanalert">
            <Button
              htmlType="submit"
              style={{
                height: "2rem",
                border: "1px solid #40A9FF",
                color: "#40A9FF",
                backgroundColor: "#ffffff",
                borderRadius: "4px",
              }}
            >
              Signaler une absence ou un congé
            </Button>
          </Link>
        </Grid>

        <Grid lg={3} xs={12} className="GridItem">
          <Input
            size="large"
            style={{ height: "2rem" }}
            placeholder="Rechercher..."
            prefix={<SearchOutlined className="searchiconalign" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
            onChange={(value) =>
              setFilters({ ...filters, searchText: value.target.value })
            }
          />
        </Grid>
        <Grid lg={2} xs={12} md={2} className="GridItem">
          <Link
            to="#"
            onClick={() =>
              setFilters({ ...filters, visible: !filters.visible })
            }
            className="tabletop-filter-options"
          >
            Recherche avancée
          </Link>
        </Grid>

        {/*on click div to show and hide*/}

        {filters.visible && (
          <Grid lg={12} style={{ marginTop: "15px" }}>
            <AdvanceFilterOptions
              isNew={true}
              handleChangeAdvance={(value) => {
                setFilters({ ...filters, ...value });
              }}
            />
          </Grid>
        )}
        <Grid lg={12} xs={12} style={{ marginTop: "2%" }}>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            expandable={{
              expandedRowRender: (record) => (
                <ExpandedRowConstat
                  record={record}
                  enableUploadDeposited={
                    record.status !== "TE" && !record.is_archived
                  }
                  enableUploadReturned={false}
                  enableDeleteDeposited={false}
                  enableDeleteReturned={false}
                  onOpenFile={(data: any) =>
                    setModalViewDocument({
                      visible: true,
                      data: { ...data },
                    })
                  }
                  onOpenComments={(report_id: any, comments: any) =>
                    setModalComments({
                      visible: true,
                      data: { ...comments },
                    })
                  }
                  onArchive={(report_id: any) =>
                    setModalArchiveConstat({
                      visible: true,
                      data: { report_id },
                    })
                  }
                />
              ),
              rowExpandable: (record: any) => true,
              expandRowByClick: true,
              expandIconColumnIndex: -1,
            }}
            dataSource={reportsFiltered || []}
            pagination={{
              pageSize: filters.itemsPerPage,
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Aucun constat."
                />
              ),
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default ReportsInProgressMonthBailiff;
