import React, { useState, useEffect } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import {
  Layout,
  Table,
  Input,
  Badge,
  Menu,
  Dropdown,
  Space,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Breadcrumb,
  Button,
  Upload,
  message,
} from "antd";
import { TeamOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { usersService } from "../../../_services/usersSerives";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import styles from "../styles.module.scss";

import Leftmenu from "../leftmenu";
import Topmenu from "../topmenu";
import { groupServices } from "../../../_services/groupsServices";
import { ISendUserDataI } from "../../../interfaces/users.interface";

const { Dragger } = Upload;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
const props = {
  name: "file",
  multiple: false,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} fichier téléchargé avec succès.`);
    } else if (status === "error") {
      message.error(
        `${info.file.name} le téléchargement du fichier a échoué..`
      );
    }
  },
};

const { Content } = Layout;
const { Option } = Select;

const handleChange = () => {
  console.log(`selected`);
};

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Vous ne pouvez télécharger que des fichiers JPG / PNG!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("L'image doit être inférieure à 2 Mo!");
  }
  return isJpgOrPng && isLt2M;
}

const EditCreerungroupe = ({ match, history }) => {
  const { user } = useSelector((state: any) => state.auth);
  const [createGroupForm, setCreateGroupForm] = useState({
    studyName: "",
    street: "",
    address: "",
    zip: "",
    city: "",
    studyPhone: "",
    accoutningEmail: "",
    pricePage: "",
    pricePhoto: "",
    errors: {
      studyName: "",
      street: "",
      address: "",
      zip: "",
      city: "",
      studyPhone: "",
      accoutningEmail: "",
      pricePage: "",
      pricePhoto: "",
    },
  });

  useEffect(() => {
    const _getData = async () => {
      await getGroupByID(match.params.id);
    };
    _getData();
  }, [match.params.id]);

  const getGroupByID = async (id) => {
    await groupServices
      .getGroupByID(id)
      .then((response) => response.data)
      .then(async (response: any) => {
        let temp: any = createGroupForm;
        // debugger
        temp.studyName = response[0].group_name;
        temp.street = response[0].group_street;
        temp.address = response[0].group_address;
        temp.zip = response[0].group_zip;
        temp.city = response[0].group_city;
        temp.studyPhone = response[0].group_telephone;
        temp.accoutningEmail = response[0].group_email;
        temp.pricePage = response[0].group_price_page;
        temp.pricePhoto = response[0].group_price_photo;
        setCreateGroupForm({ ...temp });
      });
  };

  const [imageUrl, setImage] = useState(
    "https://www.kindpng.com/picc/m/163-1636340_user-avatar-icon-avatar-transparent-user-icon-png.png"
  );
  const uploadButton = (
    <div>
      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChangeImage = (info) => {
    if (info.file.status === "uploading") {
      // this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (imageUrl) => {
          setImage(imageUrl);
        }

        // this.setState({
        //   imageUrl,
        //   loading: false,
        // }),
      );
    }
  };

  const notFilledPasswordField = () => {
    let errors = createGroupForm.errors;
    if (createGroupForm.studyName === "") {
      errors.studyName = "Champ requis";
    }
    if (createGroupForm.street === "") {
      errors.street = "Champ requis";
    }
    if (createGroupForm.address === "") {
      errors.address = "Champ requis";
    }
    if (createGroupForm.address === "") {
      errors.address = "Champ requis";
    }
    if (createGroupForm.city === "") {
      errors.city = "Champ requis";
    }
    if (createGroupForm.zip === "") {
      errors.zip = "Champ requis";
    }
    if (createGroupForm.studyPhone === "") {
      errors.studyPhone = "Champ requis";
    }
    if (createGroupForm.accoutningEmail === "") {
      errors.accoutningEmail = "Champ requis";
    }
    if (createGroupForm.pricePage === "") {
      errors.pricePage = "Champ requis";
    }
    if (createGroupForm.pricePhoto === "") {
      errors.pricePhoto = "Champ requis";
    }
    setCreateGroupForm({ ...createGroupForm, ...{ errors } });
  };

  const validateForm = (formFields) => {
    notFilledPasswordField();
    let valid = true;
    Object.values(formFields.errors).forEach(
      (val: any) => val.length > 0 && (valid = false)
    );
    return valid;
  };
  const _changeHandler = (event, name) => {
    let errors = createGroupForm.errors;

    const { value } = event.target;

    switch (name) {
      case "studyName":
        errors.studyName =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;

      case "street":
        errors.street =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "address":
        errors.address =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "city":
        errors.city =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "zip":
        errors.zip =
          value.length > 4 ? "" : "Doit contenir au moins 5 caractères";
        break;
      case "studyPhone":
        errors.studyPhone =
          value.length > 8 ? "" : "Doit contenir au moins 9 caractères";
        break;
      case "accoutningEmail":
        errors.accoutningEmail =
          value.length > 2 ? "" : "Invalide adresse e-mail";
        break;
      case "pricePage":
        errors.pricePage = value.length > 0 ? "" : "Invalid characters";
        break;
      case "pricePhoto":
        errors.pricePhoto = value.length > 0 ? "" : "Invalid characters";
        break;
      default:
        break;
    }

    setCreateGroupForm({
      ...createGroupForm,
      ...{ errors, [name]: value },
    });
  };

  const _submitHandler = async () => {
    if (validateForm(createGroupForm)) {
      let data: any = {};
      data["group_email"] = createGroupForm.accoutningEmail;
      data["group_name"] = createGroupForm.studyName;
      data["group_telephone"] = createGroupForm.studyPhone;
      data["group_address"] = createGroupForm.address;
      data["group_city"] = createGroupForm.city;
      data["group_street"] = createGroupForm.street;
      data["group_zip"] = createGroupForm.zip;
      data["group_price_page"] = createGroupForm.pricePage;
      data["group_price_photo"] = createGroupForm.pricePhoto;
      await groupServices
        .updateGroupByIdAPI(match.params.id, data)
        .then((response) => response.data)
        .then((response: any) => {
          message.success("Groupe modifié avec succès.");
          history.push("/dashboard/voirlesgroupes");
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <div className="dashboard-right-section">
      <Content style={{ marginLeft: "0px" }}>
        <Breadcrumb>
          <Breadcrumb.Item>Comptes</Breadcrumb.Item>
          <Breadcrumb.Item>Créer un groupe</Breadcrumb.Item>
        </Breadcrumb>
      </Content>

      <h2 className="pagewrapper_top">CRÉER UN GROUPE</h2>
      <div className="form_outer_onlyscreenwrapper">
        <div className="form_outer_wrapper">
          <h2>
            <TeamOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            CRÉATION D’UN GROUPE
          </h2>
          <Form className="create_account creerungroupe_form">
            <Row gutter={16}>
              <Col xs={24} md={22} lg={20}>
                <Form.Item
                  className="validatestar"
                  label="Nom de l’étude"
                  valuePropName="studyName"
                  validateStatus={
                    createGroupForm.errors.studyName.length > 0
                      ? "error"
                      : "success"
                  }
                  help={createGroupForm.errors.studyName}
                >
                  <Input
                    placeholder="Nom de l’étude"
                    className="studyName"
                    value={createGroupForm.studyName || ""}
                    onChange={(e) => _changeHandler(e, "studyName")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={22} lg={20}>
                <Form.Item
                  className="validatestar"
                  label="Siret"
                  valuePropName="street"
                  validateStatus={
                    createGroupForm.errors.street.length > 0
                      ? "error"
                      : "success"
                  }
                  help={createGroupForm.errors.street}
                >
                  <Input
                    placeholder="Siret"
                    className="studyName"
                    value={createGroupForm.street || ""}
                    onChange={(e) => _changeHandler(e, "street")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={22} lg={20}>
                <Form.Item
                  className="validatestar"
                  label="Adresse postale"
                  valuePropName="address"
                  validateStatus={
                    createGroupForm.errors.address.length > 0
                      ? "error"
                      : "success"
                  }
                  help={createGroupForm.errors.address}
                >
                  <Input
                    placeholder="Adresse postale"
                    className="studyName"
                    value={createGroupForm.address || ""}
                    onChange={(e) => _changeHandler(e, "address")}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} md={22} lg={20}>
                <Form.Item className="validatestar" label="CP/ Ville*">
                  <Input.Group>
                    <Row gutter={8}>
                      <Col span={5}>
                        <Form.Item
                          name="zip"
                          valuePropName="zip"
                          validateStatus={
                            createGroupForm.errors.zip.length > 0
                              ? "error"
                              : "success"
                          }
                          help={createGroupForm.errors.zip}
                        >
                          <Input
                            placeholder="Code"
                            className="inputfield-middle"
                            value={createGroupForm.zip || ""}
                            onChange={(e) => _changeHandler(e, "zip")}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name="city"
                          valuePropName="city"
                          validateStatus={
                            createGroupForm.errors.city.length > 0
                              ? "error"
                              : "success"
                          }
                          help={createGroupForm.errors.city}
                        >
                          <Input
                            placeholder="Ville"
                            className="inputfield-middle"
                            value={createGroupForm.city || ""}
                            onChange={(e) => _changeHandler(e, "city")}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Input.Group>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} md={22} lg={20}>
                <Form.Item
                  className="validatestar"
                  label="Téléphone étude"
                  valuePropName="studyPhone"
                  validateStatus={
                    createGroupForm.errors.studyPhone.length > 0
                      ? "error"
                      : "success"
                  }
                  help={createGroupForm.errors.studyPhone}
                >
                  <Input
                    placeholder="Téléphone étude"
                    className="inputfield-middle"
                    value={createGroupForm.studyPhone || ""}
                    onChange={(e) => _changeHandler(e, "studyPhone")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={22} lg={20}>
                <Form.Item
                  className="validatestar"
                  label="Email comptabilité"
                  valuePropName="email"
                  validateStatus={
                    createGroupForm.errors.accoutningEmail.length > 0
                      ? "error"
                      : "success"
                  }
                  help={createGroupForm.errors.accoutningEmail}
                >
                  <Input
                    placeholder="Email comptabilité"
                    className="inputfield-middle"
                    value={createGroupForm.accoutningEmail || ""}
                    onChange={(e) => _changeHandler(e, "accoutningEmail")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={22} lg={20}>
                <Form.Item
                  className="validatestar"
                  label="Tarif/page"
                  valuePropName="pricePage"
                  validateStatus={
                    createGroupForm.errors.pricePage.length > 0
                      ? "error"
                      : "success"
                  }
                  help={createGroupForm.errors.pricePage}
                >
                  <Input
                    placeholder="Tarif/page"
                    className="inputfield-middle"
                    value={createGroupForm.pricePage || ""}
                    onChange={(e) => _changeHandler(e, "pricePage")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={22} lg={20}>
                <Form.Item
                  className="validatestar"
                  label="Tarif/photo"
                  valuePropName="pricePhoto"
                  validateStatus={
                    createGroupForm.errors.pricePhoto.length > 0
                      ? "error"
                      : "success"
                  }
                  help={createGroupForm.errors.pricePhoto}
                >
                  <Input
                    placeholder="Tarif/photo"
                    className="inputfield-middle"
                    value={createGroupForm.pricePhoto || ""}
                    onChange={(e) => _changeHandler(e, "pricePhoto")}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} md={22} lg={20} className="register-btn-mask">
                <Form.Item className="creerungroupe_btn_gropup">
                  <div>
                    <Button
                      type="primary"
                      className="validate-btn"
                      onClick={_submitHandler}
                    >
                      Valider
                    </Button>
                    <Button className="cancel-btn">
                      <Link to="/dashboard/facturation">Annuler</Link>
                    </Button>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EditCreerungroupe;
