import React, { useState } from 'react';
import { Form, Input, Button, Result, Layout, Alert } from "antd";
import { MailOutlined, CheckCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';

import styles from "./styles.module.scss";
// import logo from '../../assets/logo-crc-extranet-white.png';
import authServices from '../../redux/auth/services';


function RecoverRequest() {
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(undefined);
	const [loading, setLoading] = useState(false);

	const onFinish = async ({ email }: { email: string }) => {
		try {
			setError(undefined);
			setLoading(true);
			await authServices.requestRecovery(email);
			setSuccess(true);
			setLoading(false);
		} catch (err: any) {
			setError(err.response.data.errors.message);
			setLoading(false);
		}
	};

	if (success) {
		return (
			<Result
				status="success"
				title="Debe haber llegado un link de recuperación a tu email"
				style={{ paddingTop: 200 }}
				extra={[
					<Link to="/login">
						<Button type="primary" key="login">
							Volver a la página principal
						</Button>
					</Link>
				]}
			/>
		);
	}

	return (
		<Layout className="recoverypassword">

			<section className={styles.login_wrapper}>
				<div className={styles.login_form_container}>
					<div className={styles.login_form_container}>
						{/* <img src={logo} alt="Logo" className={styles.login_form_logo} /> */}
						<div className="recovery-password">
							<div className={styles.login_form_logo}>
								<img src={'/images/logo.svg'} alt="Logo" className={styles.responsive_img} />
							</div>
							<div >
								<div >
									<Alert
										message="Email envoyé avec succès."
										type="success"
										showIcon
										closable
									/>
								</div>
							</div>
							<Link className={styles.success_msg_alter_link} to="/recover/request" >
								Quelques minutes se sont écoulées et le message n'a pas été envoyé ?
						</Link>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}

export default RecoverRequest;

