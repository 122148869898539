import { combineReducers } from "redux";

import auth from "./auth/reducer";
import admins from "./admins/reducer";
import categories from "./categories/reducer";
import services from "./services/reducer";
import report from "./report";
import user from "./user";
import billing from "./billing";
import statistics from "./statistics";

// Use ES6 object literal shorthand syntax to define the object shape
const rootReducer = combineReducers({
  auth,
  admins,
  categories,
  services,
  report: report.reducer,
  user: user.reducer,
  billing: billing.reducer,
  statistics: statistics.reducer,
});

export default rootReducer;