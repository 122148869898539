import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Layout,
  Table,
  Input,
  Badge,
  Menu,
  Space,
  Row,
  Col,
  Form,
  Select,
  Button,
  message,
  Modal,
  Comment,
  Avatar,
  Carousel,
  Upload,
  Progress,
  Card,
  Empty,
} from "antd";
import { AlignType } from 'rc-table/lib/interface'
import {
  VerticalAlignBottomOutlined,
  MailOutlined,
  PlusOutlined,
  CaretRightOutlined,
  SearchOutlined,
  RollbackOutlined,
  UserOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import moment from "moment";
import Commonsecretaryheader from "../commonsecretaryheader";
import { reportServices } from "../../../_services/reportServices";
import { filesUploadServices } from "../../../_services/uploadFIles";
import { useDispatch, useSelector } from "react-redux";
import AdvanceFilterOptions from "../../../components/AdvanceFilterOptions";
import axios from "axios";
import "moment/locale/fr-ch";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import FileViewer from "react-file-viewer";
import { formatRecName } from "../../../utils/functions/formatRecName";
import { fileTypeReturn } from "../../../utils/functions/fileTypeReturn";
import {
  addCommentReport,
  getReports,
  resetReportState,
} from "../../../redux/report";
import { getAllUsers } from "../../../redux/user";
import { saveAs } from "file-saver";
import FileUtils from "../../../utils/files";
import ModalInstructions from "../../../components/ModalInstructions/ModalInstructions";
import ModalDocument from "../../../components/ModalDocument/ModalDocument";
import ExpandedRowConstat from "../../../components/ExpandedRowConstat/ExpandedRowConstat";
import ModalComments, { userRoles } from "../../../components/ModalComments";
import { commentUtils } from "../../../utils/functions/comments";

moment.locale("fr");

const globals = require("../../../helpers/globals");
const URL = globals.domain;

const { Option } = Select;

function ReportsMonthSecretary() {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.auth);
  const {
    reports,
    loadingUpdateSecretaryReport,
    successUpdateSecretaryReport,
    errorUpdateSecretaryReport,
    successAddFilesReport,
    successRemoveFilesReport,
    successSetArchiveReport,
    successSetRetrocessionReport,
    errorSetRetrocessionReport,
    successAddCommentReport,
    errorAddCommentReport,
    successMarkAsReadCommentReport
  } = useSelector((state: any) => state.report);
  const { users, loadingGetAllUsers, successGetAllUsers, errorGetAllUsers } =
    useSelector((state: any) => state.user);
  const [reportsFiltered, setReportsFiltered] = useState([]);
  const [filters, setFilters]: any = useState({
    visible: false,
    itemsPerPage: 10,
    searchText: "",
  });
  const [modalInstructions, setModalInstructions] = useState({
    visible: false,
    data: {},
  });
  const [modalViewDocument, setModalViewDocument] = useState({
    visible: false,
    data: {},
  });
  const [modalComments, setModalComments]: any = useState({
    visible: false,
    data: {},
  });

  useEffect(() => {
    dispatch(resetReportState());
    dispatch(getAllUsers());
    dispatch(
      getReports({ states: ["EC", "RS", "TE", "AR"], secretary_id: user.id })
    );
  }, [dispatch, successAddCommentReport, errorAddCommentReport, successMarkAsReadCommentReport]);

  useEffect(() => {
    let reportsFiltered = reports;

    reportsFiltered = reportsFiltered.map((report: any) => {
      let newReport = report;
      if (
        report.status === "NA" ||
        report.status === "TE" ||
        report.status === "AR"
      ) {
        newReport = {
          ...newReport,
          status_alert: "default",
        };
      } else if (moment().diff(report.createdAt || 0, "hours") < 30) {
        newReport = {
          ...newReport,
          status_alert: "success",
        };
      } else if (
        moment().diff(report.createdAt || 0, "hours") <= 36 &&
        moment().diff(report.createdAt || 0, "hours") >= 30
      ) {
        newReport = {
          ...newReport,
          status_alert: "warning",
        };
      } else {
        newReport = {
          ...newReport,
          status_alert: "error",
        };
      }
      return newReport;
    });

    if (filters?.year && filters?.year != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) =>
          moment(report.updatedAt).format("YYYY") === filters.year
      );
    }

    if (filters?.month && filters?.month != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) =>
          moment(report.created_at).format("MM") === filters.month
      );
    }

    if (filters?.searchText && filters?.searchText != "") {
      reportsFiltered = reportsFiltered.filter((report: any) =>
        report?.name?.toLowerCase().includes(filters.searchText.toLowerCase())
      );
    }

    setReportsFiltered(reportsFiltered);
  }, [reports, filters]);

  useEffect(() => {
    if (
      modalComments.visible &&
      reportsFiltered &&
      reportsFiltered.length > 0
    ) {
      const report = reportsFiltered.find(
        (report: any) => report.id === modalComments.data.id
      );
      setModalComments({
        ...modalComments,
        data: report,
      });
    }
  }, [reportsFiltered]);

  const columns: any = [
    {
      title: "",
      key: "bullet",
      align: "center" as AlignType,
      width: "5%",
      render: (report) => {
        return (
          <span>
            <Badge status={report.status_alert} />
          </span>
        );
      },
    },
    {
      title: "Date",
      key: "updatedAt",
      align: "left" as AlignType,
      width: "15%",
      render: (report) => {
        return moment(report.updatedAt).format("DD/MM/YYYY HH:mm");
      },
    },
    {
      title: "Huissier",
      key: "name",
      align: "left" as AlignType,
      width: "20%",
      render: (report) => {
        const user = users?.filter(
          (user: any) => user.id === report.bailiff_id
        )[0];
        return (
          <Space size="middle">
            <a
              style={{ textDecoration: "underline" }}
              onClick={(e) => {
                e.stopPropagation();
                setModalInstructions({
                  data: user,
                  visible: true,
                });
              }}
            >
              {user?.last_name}
            </a>
          </Space>
        );
      },
    },
    {
      title: "Nom du constat",
      key: "name",
      align: "left" as AlignType,
      width: "33%",
      render: (data) => data.name,
    },
    {
      title: "Éléments déposés",
      key: "name",
      align: "left" as AlignType,
      render: (report) => (
        <Space size="middle">
          <a>{report.deposited_items.length}</a>
          {report.deposited_items.length > 0 && (
            <>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  report.deposited_items.forEach((file) => {
                    saveAs(file.linkUrl, file.originalname);
                  });
                }}
              >
                <a>
                  <VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </a>
              </div>
              <a href={report.deposited_items[0].file_url} download></a>
              <a>
                {FileUtils.bytesToMb(
                  report.deposited_items.reduce((acc: any, item: any) => {
                    return acc + item.size;
                  }, 0)
                ) + "Mo"}
              </a>
            </>
          )}
        </Space>
      ),
    },
    {
      title: "Commentaire",
      key: "operation",
      align: "center" as AlignType,
      render: (data, info, index, showOne = false) => {
        const comments = commentUtils.filterByRole({
          comments: data?.comments,
          role: userRoles.secretary,
          user,
          users,
        });

        const commentsWithoutRead = comments.filter((comment: any) =>
          data.comments_without_read.includes(comment.id)
        );

        return (
          <Space size="small">
            <a
              className={commentsWithoutRead?.length > 0 ? "red-dot-container" : ""}
              onClick={(e) => {
                e.stopPropagation();
                setModalComments({
                  visible: true,
                  data: data,
                });
              }}
            >
              {commentsWithoutRead?.length > 0 ? (
                <div className="red-dot-alert" />
              ) : null}
              <MailOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </a>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="dashboard-right-section creer-constat-wrapper noleftmenu_section dashboard-secretary">
      <ModalInstructions
        visible={modalInstructions.visible}
        onClose={() => setModalInstructions({ visible: false, data: {} })}
        data={modalInstructions.data}
        onOpenFile={(data) =>
          setModalViewDocument({ visible: true, data: { ...data } })
        }
      />
      <ModalDocument
        visible={modalViewDocument.visible}
        data={modalViewDocument.data}
        onClose={() => setModalViewDocument({ visible: false, data: {} })}
      />
      <ModalComments
        visible={modalComments.visible}
        onClose={() => setModalComments({ visible: false, data: {} })}
        onSend={(data) => {
          dispatch(addCommentReport(data));
        }}
        data={modalComments.data}
      />
      <div className="form_outer_wrapper secretarydashboard">
        <Commonsecretaryheader />
        <div className="form_tableouter_wrapper">
          <h2>TOUS MES CONSTATS</h2>

          <div className="flow-expand">
            <div className="secretarytabletop-select">
              <Select
                defaultValue="10"
                className="table-rows-select"
                onChange={(value: any) =>
                  setFilters({ ...filters, itemsPerPage: Number(value) })
                }
              >
                <Option value="10">10</Option>
                <Option value="20">20</Option>
                <Option value="30">30</Option>
                <Option value="40">40</Option>
                <Option value="50">50</Option>
                <Option value="60">60</Option>
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
              className="secretarytabletop-filters"
            >
              <div>
                <Link to="/dashboard/createanalert">
                  <Button className="tabletop_addbtn">
                    Signaler une absence ou un congé
                  </Button>
                </Link>
              </div>
              <div
                style={{ marginLeft: "10px" }}
                className="secretarytabletop-search"
              >
                <Input
                  size="large"
                  className="table-search-input"
                  placeholder="Rechercher..."
                  prefix={<SearchOutlined className="searchiconalign" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                  onChange={(value) =>
                    setFilters({ ...filters, searchText: value.target.value })
                  }
                />
              </div>
              <div style={{ marginLeft: "10px" }}>
                <Link
                  to="#"
                  onClick={() =>
                    setFilters({ ...filters, visible: !filters.visible })
                  }
                  className="tabletop-filter-options"
                >
                  Recherche avancée
                </Link>
              </div>
            </div>
          </div>
          {filters.visible && (
            <AdvanceFilterOptions
              isNew={true}
              handleChangeAdvance={(value) => {
                setFilters({ ...filters, ...value });
              }}
            />
          )}
          {reportsFiltered && (
            <Table
              columns={columns}
              className="constatsencours-tables manageiconforalign"
              rowKey={(record) => record.id}
              expandable={{
                expandedRowRender: (record) => (
                  <ExpandedRowConstat
                    record={record}
                    enableUploadDeposited={false}
                    enableUploadReturned={false}
                    enableDeleteDeposited={false}
                    enableDeleteReturned={false}
                    showDataRetrocession
                    onOpenFile={(data: any) =>
                      setModalViewDocument({
                        visible: true,
                        data: { ...data },
                      })
                    }
                    onOpenComments={(report_id: any, comments: any) =>
                      setModalComments({
                        visible: true,
                        data: { ...comments },
                      })
                    }
                  />
                ),
                rowExpandable: (record: any) => true,
                expandRowByClick: true,
                expandIconColumnIndex: -1,
              }}
              dataSource={reportsFiltered || []}
              pagination={{
                pageSize: filters.itemsPerPage,
              }}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Aucun constat."
                  />
                ),
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ReportsMonthSecretary;
