import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Layout,
  Input,
  Row,
  Col,
  Form,
  Select,
  Breadcrumb,
  Button,
  Upload,
  message,
  Checkbox,
} from "antd";
import { reportServices } from "../../../_services/reportServices";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { newsServices } from "../../../_services/newsServices";
import { Base64 } from "js-base64";
const { Content } = Layout;

function CreerUnGroupe(props) {
  const [isEdit, setIsEdit] = useState(false);
  const [createReport, setCreateReport] = useState({
    description: "",
    title: "",
    important: false,
    errors: {
      title: "",
      description: "",
    },
  });

  const notFilledPasswordField = () => {
    let errors: any = createReport.errors;
    if (createReport.title === "") {
      errors.title = "Entrez votre Titre de l’actualité, s'il vous plaît !";
    }
    if (createReport.description === "") {
      errors.titdescriptionle = "Champ requis";
    }
    setCreateReport({ ...createReport, ...{ errors } });
  };

  const validateForm = (formFields) => {
    notFilledPasswordField();
    let valid = true;
    Object.values(formFields.errors).forEach(
      (val: any) => val.length > 0 && (valid = false)
    );
    return valid;
  };

  const _submitHandler = async () => {
    if (validateForm(createReport)) {
      let data: any = {};
      data.title = createReport.title;
      data.description = Base64.encode(createReport.description)
      data.important = createReport.important;
      await newsServices
        .createNewsAPI(data)
        .then((response) => response.data)
        .then((response) => {
          message.success("Actualités envoyées aux secrétaires avec succès");
          props.history.push("/dashboard/viewnews");
        })
        .catch((err) => console.error(err));
    }
  };

  const _changeHandler = (event, name) => {
    let errors = createReport.errors;
    let { value } = event.target;
    if (name === "important") {
      value = event.target.checked;
    }
    switch (name) {
      case "title":
        errors.title =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      default:
        break;
    }

    setCreateReport({
      ...createReport,
      ...{ errors, [name]: value },
    });
  };
  const descriptionChangeHandler = (data) => {
    setCreateReport({
      ...createReport,
      ...{ ["description"]: data },
    });
  };
  return (
    <div className="dashboard-right-section createanews-wrapper">
      <Content style={{ marginLeft: "0px" }}>
        <Breadcrumb>
          <Breadcrumb.Item>Actualités</Breadcrumb.Item>
          <Breadcrumb.Item>Créer une actualité</Breadcrumb.Item>
        </Breadcrumb>
      </Content>
      <div className="form_outer_onlyscreenwrapper">
        <div className="form_onlyscreenwrapper">
          <h2
            className="pagewrapper_top"
            style={{
              marginLeft: "0px",
              marginBottom: "1.5em",
            }}
          >
            CRÉER UNE ACTUALITÉ
          </h2>
          <Form className="create_news">
            <Row gutter={16}>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  label="Titre de l’actualité"
                  valuePropName="title"
                  validateStatus={
                    createReport.errors.title.length > 0 ? "error" : "success"
                  }
                  help={createReport.errors.title}
                >
                  <Input
                    className="news-headline"
                    value={createReport.title}
                    onChange={(e) => _changeHandler(e, "title")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={24} lg={24}>
                <div className="ckeditor_label_wrapper">
                  <Form.Item
                    className="createnews-editor"
                    validateStatus={
                      createReport.errors.description.length > 0
                        ? "error"
                        : "success"
                    }
                    help={createReport.errors.description}
                    valuePropName="description"
                  >
                    <CKEditor
                      editor={ClassicEditor}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        descriptionChangeHandler(data);
                      }}
                      data={createReport.description}
                      config={{
                        toolbar: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "link",
                          "bulletedList",
                          "numberedList",
                          "blockQuote",
                        ],

                        heading: {
                          options: [
                            {
                              model: "paragraph",
                              title: "Paragraph",
                              class: "ck-heading_paragraph",
                            },
                            {
                              model: "heading1",
                              view: "h1",
                              title: "Heading 1",
                              class: "ck-heading_heading1",
                            },
                            {
                              model: "heading2",
                              view: "h2",
                              title: "Heading 2",
                              class: "ck-heading_heading2",
                            },
                          ],
                        },
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Row>
                <Form.Item>
                  <Checkbox
                    value={createReport.important}
                    onChange={(e) => _changeHandler(e, "important")}
                  >
                    IMPORTANT
                  </Checkbox>
                </Form.Item>
              </Row>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={24} lg={24} className="register-btn-mask">
                <Form.Item>
                  <Button
                    type="primary"
                    className="validate-btn"
                    onClick={_submitHandler}
                  >
                    Valider
                  </Button>
                  <Link to="/dashboard">
                    <Button className="cancel-btn">Annuler</Button>
                  </Link>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default CreerUnGroupe;
