import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userService, usersService } from "../../_services/usersSerives";

const userSlice = createSlice({
  name: "users",
  initialState: {
    users: [],
    loadingGetAllUsers: false,
    successGetAllUsers: false,
    errorGetAllUsers: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.pending, (state) => {
      state.loadingGetAllUsers = true;
      state.successGetAllUsers = false;
      state.errorGetAllUsers = false;
    });
    builder.addCase(getAllUsers.fulfilled, (state, action: any) => {
      state.users = action.payload;
      state.loadingGetAllUsers = false;
      state.successGetAllUsers = true;
      state.errorGetAllUsers = false;
    });
    builder.addCase(getAllUsers.rejected, (state, action: any) => {
      state.users = [];
      state.loadingGetAllUsers = false;
      state.successGetAllUsers = false;
      state.errorGetAllUsers = action.payload.error;
    });
  },
});

const getAllUsers = createAsyncThunk(
  "user/getAll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await userService.getAll();
      return response?.data;
    } catch (error) {
      return rejectWithValue({
        error,
      });
    }
  }
);

export const {} = userSlice.reducer;

export { getAllUsers };

export default userSlice;
