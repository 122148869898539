import React, { useEffect, useState } from "react";
import {
  Badge,
  Carousel,
  Col,
  Empty,
  Input,
  Layout,
  Row,
  Select,
  Space,
  Table,
  Breadcrumb,
  message,
} from "antd";
import AdvanceFilterOptions from "../../../components/AdvanceFilterOptions";
import { useDispatch, useSelector } from "react-redux";
import {
  addCommentReport,
  getHeadersReports,
  getReports,
  removeFilesReport,
  resetReportState,
  setArchiveReport,
  updateSecretaryReport,
} from "../../../redux/report";
import { Link } from "react-router-dom";
import {
  UserOutlined,
  VerticalAlignBottomOutlined,
  MailOutlined,
  FolderViewOutlined,
  DownOutlined,
  PlusOutlined,
  CaretRightOutlined,
  SearchOutlined,
  RollbackOutlined,
  EyeOutlined,
  PaperClipOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import styles from "../styles.module.scss";
import moment from "moment";
import { getAllUsers } from "../../../redux/user";
import { saveAs } from "file-saver";
import ModalInstructions from "../../../components/ModalInstructions/ModalInstructions";
import ModalDocument from "../../../components/ModalDocument/ModalDocument";
import FileUtils from "../../../utils/files";
import ExpandedRowConstat, {
  FileTypeConstat,
} from "../../../components/ExpandedRowConstat/ExpandedRowConstat";
import ModalDeleteFileConstat from "../../../components/ModalDeleteFileConstat/ModalDeleteFileConstat";
import ModalArchiveConstat from "../../../components/ModalArchiveConstat/ModalArchiveConstat";
import ModalComments from "../../../components/ModalComments";

const { Content } = Layout;
const { Option } = Select;

function ReportsInProgress() {
  const dispatch = useDispatch();
  const {
    headersReports,
    reports,
    loadingUpdateSecretaryReport,
    successUpdateSecretaryReport,
    errorUpdateSecretaryReport,
    successAddFilesReport,
    successRemoveFilesReport,
    successSetArchiveReport,
    successSetBillingReport,
    errorSetBillingReport,
    successAddCommentReport,
    errorAddCommentReport,
    successMarkAsReadCommentReport
  } = useSelector((state: any) => state.report);
  const { users, loadingGetAllUsers, successGetAllUsers, errorGetAllUsers } =
    useSelector((state: any) => state.user);
  const [reportsFiltered, setReportsFiltered]: any = useState([]);
  const [filters, setFilters]: any = useState({
    visible: false,
    itemsPerPage: 10,
    searchText: "",
  });
  const [modalInstructions, setModalInstructions] = useState({
    visible: false,
    data: {},
  });
  const [modalViewDocument, setModalViewDocument] = useState({
    visible: false,
    data: {},
  });
  const [modalDeleteFileConstat, setModalDeleteFileConstat]: any = useState({
    visible: false,
    data: {},
  });
  const [modalComments, setModalComments]: any = useState({
    visible: false,
    data: {},
  });
  const [modalArchiveConstat, setModalArchiveConstat]: any = useState({
    visible: false,
    data: {},
  });

  useEffect(() => {
    dispatch(resetReportState());
    dispatch(getAllUsers());
    dispatch(getHeadersReports());
    dispatch(getReports({ states: ["EC", "RS"] }));
  }, [
    dispatch,
    successUpdateSecretaryReport,
    successAddFilesReport,
    successRemoveFilesReport,
    successSetBillingReport,
    errorSetBillingReport,
    successSetArchiveReport,
    successAddCommentReport,
    errorAddCommentReport,
    successMarkAsReadCommentReport
  ]);

  useEffect(() => {
    let reportsFiltered = reports;

    reportsFiltered = reportsFiltered.map((report: any) => {
      let newReport = report;
      if (
        report.status === "NA" ||
        report.status === "TE" ||
        report.status === "AR"
      ) {
        newReport = {
          ...newReport,
          status_alert: "default",
        };
      } else if (moment().diff(report.createdAt || 0, "hours") < 30) {
        newReport = {
          ...newReport,
          status_alert: "success",
        };
      } else if (
        moment().diff(report.createdAt || 0, "hours") <= 36 &&
        moment().diff(report.createdAt || 0, "hours") >= 30
      ) {
        newReport = {
          ...newReport,
          status_alert: "warning",
        };
      } else {
        newReport = {
          ...newReport,
          status_alert: "error",
        };
      }
      return newReport;
    });

    reportsFiltered = reportsFiltered.map((report: any) => {
      let hours = moment().diff(report.last_status_change || 0, "hours");

      return {
        ...report,
        delay: hours,
      };
    });

    if (filters?.baliff_id && filters?.baliff_id != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) => report.bailiff_id === filters.baliff_id
      );
    }

    if (filters?.secretary_id && filters?.secretary_id != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) => report.secretary_id === filters.secretary_id
      );
    }

    if (filters?.year && filters?.year != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) =>
          moment(report.updatedAt).format("YYYY") === filters.year
      );
    }

    if (filters?.month && filters?.month != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) =>
          moment(report.created_at).format("MM") === filters.month
      );
    }

    if (filters?.status && filters?.status != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) => report.status === filters.status
      );
    }

    if (filters?.searchText && filters?.searchText != "") {
      reportsFiltered = reportsFiltered.filter((report: any) =>
        report?.name?.toLowerCase().includes(filters.searchText.toLowerCase())
      );
    }

    setReportsFiltered(reportsFiltered);
  }, [reports, filters]);

  useEffect(() => {
    if (
      modalComments.visible &&
      reportsFiltered &&
      reportsFiltered.length > 0
    ) {
      const report = reportsFiltered.find(
        (report: any) => report.id === modalComments.data.id
      );
      setModalComments({
        ...modalComments,
        data: report,
      });
    }
  }, [reportsFiltered]);

  useEffect(() => {
    if (successSetBillingReport) {
      message.success("Rapport facturé");
    }
    if (errorSetBillingReport) {
      message.error("Essayer plus tard");
    }
  }, [successSetBillingReport, errorSetBillingReport]);

  useEffect(() => {
    if (successUpdateSecretaryReport) {
      message.success("Constat mis à jour avec succès");
    }
  }, [successUpdateSecretaryReport]);

  useEffect(() => {
    if (successSetArchiveReport) {
      message.success("Constat archivé avec succès");
    }
  }, [successSetArchiveReport]);

  const columns: any = [
    {
      title: "",
      key: "bullet",
      width: "5%",
      render: (report) => {
        return (
          <span>
            <Badge status={report.status_alert} />
          </span>
        );
      },
    },
    {
      title: "Date",
      key: "date",
      align: "left",
      width: "15%",
      render: (report) => {
        return (
          <span>
            {moment(report.createdAt).format("DD/MM/YYYY")}{" "}
            {moment(report.createdAt).format("HH:mm")}
          </span>
        );
      },
    },
    {
      title: "Huissier",
      key: "name",
      align: "left",
      width: "15%",
      render: (report) => {
        const user = users?.filter(
          (user: any) => user.id === report.bailiff_id
        )[0];
        return (
          <Space size="middle">
            <a
              style={{ textDecoration: "underline" }}
              onClick={(e) => {
                e.stopPropagation();
                setModalInstructions({
                  data: user,
                  visible: true,
                });
              }}
            >
              {user?.last_name}
            </a>
          </Space>
        );
      },
    },
    {
      title: "Nom du constat",
      key: "name",
      align: "left",
      width: "35%",
      render: (data) => data.name,
    },
    {
      title: "Éléments déposés",
      key: "deposited_items",
      align: "left",
      width: "5%",
      render: (report) => (
        <div>
          <Space
            size="middle"
            onClick={(e) => {
              e.stopPropagation();
              report.deposited_items.forEach((file) => {
                saveAs(file.linkUrl, file.originalname);
              });
            }}
          >
            <a>{report.deposited_items.length || "-"}</a>
            {report.deposited_items.length > 0 && (
              <>
                <div>
                  <a>
                    <VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                  </a>
                </div>
                <a href={report.deposited_items[0].file_url} download></a>
                <a>
                  {FileUtils.bytesToMb(
                    report.deposited_items.reduce((acc: any, item: any) => {
                      return acc + item.size;
                    }, 0)
                  ) + "Mo"}
                </a>
              </>
            )}
          </Space>
        </div>
      ),
    },
    {
      title: "Secrétaire",
      key: "secretaire",
      width: "10%",
      render: (report) => {
        return (
          <Space size="middle">
            {users && (
              <Select
                onClick={(e) => {
                  e.stopPropagation();
                }}
                defaultValue={
                  report?.secretary_id ? report?.secretary_id : "Sélectionnez"
                }
                style={{ width: 185 }}
                className={report?.secretary_id ? "" : "red-border"}
                onChange={(value) => {
                  const updatedReportsFiltered = reportsFiltered.map(
                    (reportFiltered: any) => {
                      if (reportFiltered.id === report.id) {
                        return {
                          ...reportFiltered,
                          secretary_id: value,
                        };
                      }
                      return reportFiltered;
                    }
                  );
                  setReportsFiltered(updatedReportsFiltered);
                }}
              >
                {users &&
                  users
                    ?.filter((user) => user.role_name === "Secretary")
                    ?.map((user: any, index) => (
                      <Option value={user?.id} key={"op" + index}>
                        {user?.first_name + " " + user?.last_name}
                      </Option>
                    ))}
              </Select>
            )}
            <a
              className="okbtn"
              onClick={(e) => {
                e.stopPropagation();
                if (report?.secretary_id) {
                  dispatch(
                    updateSecretaryReport({
                      report_id: report.id,
                      secretary_id: report?.secretary_id,
                    })
                  );
                }
              }}
            >
              ok
            </a>
          </Space>
        );
      },
    },
    {
      title: "Statut",
      key: "status",
      width: "5%",
      render: (report) => (
        <p
          style={
            report.status === "RS" ? { color: "red", fontWeight: "bold" } : {}
          }
        >
          {report.status}
        </p>
      ),
    },
    {
      title: "Commentaire",
      key: "operation",
      align: "center",
      width: "5%",
      render: (data, info, index, showOne = false) => (
        <Space size="small">
          <a
            className={data?.comments_without_read?.length > 0 ? "red-dot-container" : ""}
            onClick={(e) => {
              e.stopPropagation();
              setModalComments({
                visible: true,
                data: data,
              });
            }}
          >
            {data?.comments_without_read?.length > 0 ? (
              <div className="red-dot-alert" />
            ) : null}
            <MailOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </a>
        </Space>
      ),
    },
    {
      title: "Archiver",
      key: "archiver",
      width: "5%",
      render: (report) => (
        <Space size="small">
          <a
            onClick={(e) => {
              e.stopPropagation();
              setModalArchiveConstat({
                visible: true,
                data: { report_id: report.id },
              });
            }}
          >
            <FolderViewOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} style={{ right: 0 }} />
          </a>
        </Space>
      ),
    },
  ];

  return (
    <div className="dashboard-right-section constatsencours_wrapper">
      <Content style={{ marginLeft: "0px" }}>
        <Breadcrumb>
          <Breadcrumb.Item>Constats</Breadcrumb.Item>
          <Breadcrumb.Item>Constats en cours</Breadcrumb.Item>
        </Breadcrumb>
      </Content>
      <ModalInstructions
        visible={modalInstructions.visible}
        onClose={() => setModalInstructions({ visible: false, data: {} })}
        data={modalInstructions.data}
        onOpenFile={(data) =>
          setModalViewDocument({ visible: true, data: { ...data } })
        }
      />
      <ModalDocument
        visible={modalViewDocument.visible}
        data={modalViewDocument.data}
        onClose={() => setModalViewDocument({ visible: false, data: {} })}
      />
      <ModalDeleteFileConstat
        visible={modalDeleteFileConstat.visible}
        onClose={() => setModalDeleteFileConstat({ visible: false, data: {} })}
        onDelete={() => {
          dispatch(
            modalDeleteFileConstat.data.type === FileTypeConstat.deposited
              ? removeFilesReport({
                report_id: modalDeleteFileConstat.data.report_id,
                deposited_items: modalDeleteFileConstat.data.files,
              })
              : removeFilesReport({
                report_id: modalDeleteFileConstat.data.report_id,
                returned_items: modalDeleteFileConstat.data.files,
              })
          );
          setModalDeleteFileConstat({ visible: false, data: {} });
        }}
      />
      <ModalArchiveConstat
        visible={modalArchiveConstat.visible}
        onClose={() => setModalArchiveConstat({ visible: false, data: {} })}
        onArchive={() => {
          dispatch(
            setArchiveReport({ report_id: modalArchiveConstat.data.report_id })
          );
          setModalArchiveConstat({ visible: false, data: {} });
        }}
      />
      <ModalComments
        visible={modalComments.visible}
        onClose={() => setModalComments({ visible: false, data: {} })}
        onSend={(data) => {
          dispatch(addCommentReport(data));
        }}
        data={modalComments.data}
      />
      <div className="topfour-coloum">
        <Row gutter={[24, 24]}>
          <Col className="gutter-row" xs={24} md={12} lg={6}>
            <div className="dashboard-top-one">
              <Row gutter={[2, 24]}>
                <Col className="gutter-row" xs={4} md={4} lg={4}>
                  <div className="dashboard-icon-center">
                    <img
                      src={"/images/dashboard-top-icon-one.svg"}
                      alt="icon-one"
                      className={styles.responsive_img_card}
                    />
                  </div>
                </Col>
                <Col className="gutter-row" xs={20} md={16} lg={20}>
                  <p>CA en cours</p>
                  <h2>
                    {reports
                      ?.reduce(
                        (acc, report) =>
                          acc +
                          (report.count_pages_retrocession *
                            report?.group_price_page +
                            report.count_photos_retrocession *
                            report?.group_price_photo),
                        0
                      )
                      ?.toFixed(2)}
                    €
                  </h2>
                </Col>
              </Row>
            </div>
          </Col>
          <Col className="gutter-row" xs={24} md={12} lg={6}>
            <div className="dashboard-top-two">
              <Row gutter={[2, 24]}>
                <Col className="gutter-row" xs={4} md={8} lg={4}>
                  <div className="dashboard-icon-center">
                    <img
                      src={"/images/dashboard-top-icon-two.svg"}
                      alt="icon-two"
                      className={styles.responsive_img_card}
                    />
                  </div>
                </Col>
                <Col className="gutter-row" xs={20} md={16} lg={20}>
                  <p>Nombre de constats</p>
                  <h2>{headersReports?.total_reports || 0}</h2>
                </Col>
              </Row>
            </div>
          </Col>
          <Col className="gutter-row" xs={24} md={12} lg={6}>
            <div className="dashboard-top-three">
              <Row gutter={[2, 24]}>
                <Col className="gutter-row" xs={4} md={8} lg={4}>
                  <div className="dashboard-icon-center">
                    <img
                      src={"/images/dashboard-top-icon-three.svg"}
                      alt="icon-three"
                      className={styles.responsive_img_card}
                    />
                  </div>
                </Col>
                <Col className="gutter-row" xs={20} md={16} lg={20}>
                  <p>Constats en alerte</p>
                  <h2>{headersReports?.total_reports_alert || 0}</h2>
                </Col>
              </Row>
            </div>
          </Col>
          <Col className="gutter-row" xs={24} md={12} lg={6}>
            <div className="dashboard-top-four">
              <Row gutter={[2, 24]}>
                <Col className="gutter-row" xs={4} md={8} lg={4}>
                  <div className="dashboard-icon-center">
                    <img
                      src={"/images/dashboard-top-icon-four.svg"}
                      alt="icon-four"
                      className={styles.responsive_img_card}
                    />
                  </div>
                </Col>
                <Col className="gutter-row" xs={20} md={16} lg={20}>
                  <p>Délai moyen de retour</p>
                  <h2>
                    {(headersReports?.time_delay?.hours
                      ? headersReports?.time_delay?.hours + "h"
                      : "") +
                      (headersReports?.time_delay?.minutes
                        ? headersReports?.time_delay?.hours &&
                          headersReports?.time_delay?.hours > 0
                          ? headersReports?.time_delay?.minutes
                          : headersReports?.time_delay?.minutes + "min"
                        : headersReports?.time_delay?.hours
                          ? ""
                          : "0min")}
                  </h2>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className="form_tableouter_wrapper">
        <h2 className="pagetitle-table">CONSTATS EN COURS</h2>
        <div className="tabletop-filter-section">
          <Row align={"middle"}>
            <Col className="gutter-row" xs={8} md={10} lg={6}>
              <Select
                defaultValue="10"
                className="table-rows-select"
                onChange={(value: any) =>
                  setFilters({ ...filters, itemsPerPage: Number(value) })
                }
              >
                <Option value="10">10</Option>
                <Option value="20">20</Option>
                <Option value="30">30</Option>
                <Option value="40">40</Option>
                <Option value="50">50</Option>
                <Option value="60">60</Option>
              </Select>
            </Col>
            <Col className="gutter-row" xs={16} md={7} lg={15}>
              <Input
                size="large"
                className="table-search-input"
                placeholder="Rechercher..."
                prefix={<SearchOutlined className="searchiconalign" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                onChange={(value) =>
                  setFilters({ ...filters, searchText: value.target.value })
                }
              />
            </Col>
            <Col className="gutter-row" xs={24} md={7} lg={3}>
              {/* <button  className="tabletop-filter-options"></button> */}
              {/*on click function to show hide div*/}
              <Link
                to="#"
                onClick={() =>
                  setFilters({ ...filters, visible: !filters.visible })
                }
                className="tabletop-filter-options"
              >
                Recherche avancée
              </Link>
              {/*on click function to show hide div*/}
            </Col>
          </Row>
        </div>
        {filters.visible && (
          <AdvanceFilterOptions
            isNew={true}
            handleChangeAdvance={(value) => {
              setFilters({ ...filters, ...value });
            }}
          />
        )}
        {reportsFiltered && (
          <Table
            columns={columns}
            className="constatsencours-tables manageiconforalign"
            rowKey={(record) => record.id}
            expandable={{
              expandedRowRender: (record) => (
                <ExpandedRowConstat
                  record={record}
                  isBilling={true}
                  onOpenFile={(data: any) =>
                    setModalViewDocument({
                      visible: true,
                      data: { ...data },
                    })
                  }
                  onDeleteFile={(report_id: any, files: any, type) =>
                    setModalDeleteFileConstat({
                      visible: true,
                      data: { report_id, files, type },
                    })
                  }
                  onOpenComments={(report_id: any, comments: any) =>
                    setModalComments({
                      visible: true,
                      data: { ...comments },
                    })
                  }
                  onArchive={(report_id: any) =>
                    setModalArchiveConstat({
                      visible: true,
                      data: { report_id },
                    })
                  }
                />
              ),
              rowExpandable: (record: any) => true,
              expandRowByClick: true,
              expandIconColumnIndex: -1,
            }}
            dataSource={reportsFiltered || []}
            pagination={{
              pageSize: filters.itemsPerPage,
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Aucun constat."
                />
              ),
            }}
          />
        )}
      </div>
    </div>
  );
}

export default ReportsInProgress;
