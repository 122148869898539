import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import {
  Input,
  Row,
  Col,
  Form,
  Select,
  Button,
  Upload,
  message,
} from "antd"
import {
  UserOutlined,
} from "@ant-design/icons"

import axios from 'axios'

import styles from "../styles.module.scss"
import { BASE_URL } from "../../../helpers/api"

/* RegExp */
import { isValidCP, isValidPassword, isValidPhone } from '../../../utils/regexp/regexp';
import FileUtils from "../../../utils/files"

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result))
  reader.readAsDataURL(img)
}

const { Option } = Select

function CreateAccount() {
  const history = useHistory()

  const imguploadicon = require("../../../pages/images/uploadimage-icon.svg")
  const [imageUrl, setImage] = useState(
    require("../../../pages/images/uploadimage.svg")
  )

  const uploadButton = (
    <div>
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )
  const handleChangeImage = (info) => {
    if (info.file.status === "uploading") {
      return
    }
    if (info.file.status === "done") {
      getBase64(
        info.file.originFileObj,
        (imageUrl) => {
          setImage(imageUrl)
        }
      )
    }
  }
  const [selectCivility, setSelectCivility] = useState('Monsieur')

  const onFinish = async (values) => {
    let data = {
      civility: selectCivility,
      first_name: values.firstname,
      last_name: values.lastname,
      telephone: values.telephone,
      address: values.address,
      zip: values.cp,
      city: values.ville,
      role_id: 2,
      profile: "",
      identity: values.identifiant,
      email: values.email,
      password: values.confirm_password,
    }
    const postData = Object.keys(data)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`)
      .join("&")

    try {
      const response = await axios.post(`${BASE_URL}/api/v1/auth/register`,
        postData,
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } },
      )
      const data = response.data
      if (data.m === 'Email already taken') {
        message.error('Adresse e-mail ou identidiant déjà utilisé(e).')
      }
      else if (data.tokens != null) {
        message.success(
          "La demande de création de compte a été envoyée à l'administrateur pour validation. Vous serez averti par e-mail lors de sa validation."
        )
        history.push("/login")
      } else {
        message.error('Une erreur est survenue, veuillez réessayer.')
      }
    } catch (error: any) {
      if (error.message === "Request failed with status code 409") {
        message.error('Adresse e-mail ou identifiant déjà utilisé(e).')
      } else {
        message.error('Une erreur est survenue, veuillez réessayer.')
      }
    }
  }

  const handleChange_civility = (e) => {
    if (e === "") {
      setSelectCivility("Monsieur")
    } else {
      setSelectCivility(e)
    }

  }


  /*function validateMobileNumber(e) {
    if (e.target.value !== "") {
      console.log("this is a mobil validator", e.target.value)
    }

    if (e.target.value.toString().length === 10) {
      dispatch(setPhoneLengthValidation(null))
      console.log("numéro de mobile valide")
    } else {
      console.log("Type de formulaire non valide")
      dispatch(
        setPhoneLengthValidation("Le téléphone doit être composé de 10 chiffres")
      )
    }
  }*/

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok")
    }, 0)
  }

  return (
    <div className="dashboard-right-section onlyscreen-template registration_wrapper">
      <div className="login_form_logo">
        <Link to="/">
          <img
            src={"/images/logo.svg"}
            alt="Logo"
            className={styles.responsive_img}
          />
        </Link>
      </div>

      <div className="form_outer_wrapper">
        <div className="onlyscreen_template_inner">
          <div className="form_outer_onlyscreenwrapper">
            <h2>
              <UserOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              CRÉER MON COMPTE
            </h2>
            <div className="form_onlyscreenwrapper">
              <Form className="create_account rigisteruser" onFinish={onFinish}>
                <Row>
                  <Col xs={24} md={24} lg={16}></Col>
                  <Col xs={24} md={24} lg={5}>
                    <div className="profile_uploadimg_wrapper">
                      <Form.Item>
                        <Upload
                          name="avatar"
                          showUploadList={false}
                          listType="picture-card"
                          className="avatar-uploader"
                          customRequest={FileUtils.generalDummyRequestLocal}
                          onChange={handleChangeImage}
                        >
                          <div className="uploadimg-wrapper">
                            {imageUrl ? (
                              <img
                                src={imageUrl}
                                alt="avatar"
                                style={{ width: "100%" }}
                              />
                            ) : (
                              uploadButton
                            )}
                            <div className="uploadimg-icons">
                              <img src={imguploadicon} alt="avatar-icon" />
                            </div>
                          </div>
                        </Upload>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} md={24} lg={4}></Col>
                  <Col xs={24} md={24} lg={12}>
                    <Form.Item
                      label="Civilité"
                      name="civility"
                    >
                      <Select
                        defaultValue="Monsieur"
                        onChange={handleChange_civility}
                      >
                        <Option value="Monsieur">Monsieur</Option>
                        <Option value="Madame">Madame</Option>
                        <Option value="Maître">Maître</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={24} lg={4}></Col>
                  <Col xs={24} md={24} lg={12}>
                    <Form.Item
                      label="Nom"
                      name="lastname"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez saisir votre nom.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24} lg={5}></Col>
                </Row>
                <Row>
                  <Col xs={24} md={24} lg={4}></Col>
                  <Col xs={24} md={24} lg={12}>
                    <Form.Item
                      label="Prénom"
                      name="firstname"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez saisir votre prénom.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24} lg={5}></Col>
                </Row>
                <Row>
                  <Col xs={24} md={24} lg={4}></Col>
                  <Col xs={24} md={24} lg={12}>
                    <Form.Item
                      label="Téléphone"
                      name="telephone"
                      rules={[
                        {
                          required: true,
                          message:
                            "Veuillez entrer un numéro de téléphone.",
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (isValidPhone.test(value)) {
                              return Promise.resolve()
                            } else {
                              return Promise.reject(
                                "Le numéro de téléphone doit être composé de 10 chiffres."
                              )
                            }
                          },
                        }),
                      ]}
                    >
                      <Input
                        className="telephone_no"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24} lg={5}></Col>
                </Row>
                <Row>
                  <Col xs={24} md={24} lg={4}></Col>
                  <Col xs={24} md={24} lg={12}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "Veuillez saisir votre email.",
                        },
                        {
                          required: true,
                          message: "Veuillez saisir votre email.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24} lg={5}></Col>
                </Row>
                <Row>
                  <Col xs={24} md={24} lg={4}></Col>
                  <Col xs={24} md={24} lg={12}>
                    <Form.Item
                      label="Adresse postale"
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez saisir votre adresse.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24} lg={5}></Col>
                </Row>
                <Row>
                  <Col xs={24} md={24} lg={4}></Col>
                  <Col xs={24} md={24} lg={12}>
                    <Form.Item
                      className="validatestar"
                      label="CP/ Ville"
                      name="city"
                    >
                      <Input.Group>
                        <Row gutter={8}>
                          <Col xs={8} md={8} lg={8}>
                            <Form.Item
                              name="cp"
                              rules={[
                                {
                                  required: true,
                                  message: "Veuillez saisir votre code postal.",
                                },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (
                                      isValidCP.test(value)
                                    ) {
                                      return Promise.resolve()
                                    }
                                    return Promise.reject(
                                      "le code postal doit comporter 5 chiffres."
                                    )
                                  },
                                }),
                              ]}
                              style={{ display: "inline-block" }}
                              className="cpvillage-two"
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col xs={16} md={16} lg={16}>
                            <Form.Item
                              name="ville"
                              rules={[
                                {
                                  required: true,
                                  message: "Veuillez saisir votre ville.",
                                },
                              ]}
                              style={{ display: "inline-block" }}
                              className="cpvillage-two"
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24} lg={5}></Col>
                </Row>
                <Row>
                  <Col xs={24} md={24} lg={4}></Col>
                  <Col xs={24} md={24} lg={12}>
                    <Form.Item
                      label="Identifiant"
                      name="identifiant"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez saisir votre identifiant.",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24} lg={5}></Col>
                </Row>
                <Row>
                  <Col xs={24} md={24} lg={4}></Col>
                  <Col xs={24} md={24} lg={12}>
                    <Form.Item
                      className="validatestar"
                      label="Mot de passe"
                      name="password"
                    >
                      <Input.Group>
                        <Row gutter={8}>
                          <Col span={24}>
                            <Form.Item
                              className="register-user-pass"
                              name="password"
                              rules={[
                                {
                                  required: true,
                                  message: "Veuillez saisir votre mot de passe.",
                                },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (
                                      isValidPassword.test(value)
                                    ) {
                                      return Promise.resolve()
                                    }
                                    return Promise.reject(
                                      "Le mot de passe doit comporter des majuscules, des minuscules, des chiffres et un symbole."
                                    )
                                  },
                                }),
                              ]}
                              style={{ display: "inline-block" }}
                            >
                              <Input.Password
                                placeholder=""
                                className="form_password"
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        {/*  */}
                      </Input.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24} lg={5}></Col>
                </Row>

                <Row>
                  <Col xs={24} md={24} lg={4}></Col>
                  <Col xs={24} md={24} lg={12}>
                    <Form.Item
                      className="validatestar"
                      label={<label style={{ whiteSpace: "initial", fontSize: 'inherit' }}>Confirmer mot de passe</label>}
                      name="confirm_password"
                    >
                      <Input.Group>
                        <Row gutter={8}>
                          <Col span={24}>
                            <Form.Item
                              name="confirm_password"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Veuillez saisir la confirmation de votre mot de passe.",
                                },
                                ({ getFieldValue }) => ({
                                  validator(rule, value) {
                                    if (
                                      !value ||
                                      getFieldValue("password") === value
                                    ) {
                                      return Promise.resolve()
                                    }
                                    return Promise.reject(
                                      "Les deux mots de passe que vous avez saisis ne correspondent pas!"
                                    )
                                  },
                                }),
                              ]}
                              style={{ display: "inline-block" }}
                            >
                              <Input.Password placeholder="" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <div className="register-btn-mask">
                            <Form.Item className="formsubmit-accform">
                              <Button
                                type="primary"
                                className="validate-btn"
                                htmlType="submit"
                              >
                                Valider
                              </Button>
                              <Button className="cancel-btn">
                                <Link to="/login">Annuler</Link>
                              </Button>
                            </Form.Item>
                          </div>
                        </Row>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateAccount
