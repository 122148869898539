import api from "../../configuration/api";

const reportService = {
  createReport: ({ params, progress, cancelToken = undefined }) =>
    api.post("report/create", params, {
      onUploadProgress: progress,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      cancelToken,
    }),
  getReports: (params) =>
    api.get("report/get_all", {
      params: params,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  getHeaders: () =>
    api.get("report/get_headers", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  updateSecretary: (params) =>
    api.post("report/update_secretary", params, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  addFilesReport: (params, progress) =>
    api.post("report/add_files", params, {
      onUploadProgress: progress,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  removeFilesReport: (params) =>
    api.post("report/remove_files", params, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  setBillingReport: (params) =>
    api.post("report/set_billing", params, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  setRetrocessionReport: (params) =>
    api.post("report/set_retrocession", params, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  setArchiveReport: (params) =>
    api.post("report/set_archive", params, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  setUnarchiveReport: (params) =>
    api.post("report/set_unarchive", params, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  addCommentReport: (params) =>
    api.post("report/add_comment", params, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  markAsReadCommentReport: (params) =>
    api.post("report/mark_as_read", params, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
};

export default reportService;
