import { userRoles } from "../../components/ModalComments";

export const commentUtils =  {
  filterByRole: ({ comments, role, user, users }) => {
    let newComments = [...comments];

    if (user.roleId == userRoles.admin) {
      if (role != userRoles.all) {
        newComments = newComments?.filter((comment: any) => {
          const userComment = users.find((user) => user.id == comment.id);
          const receiverComment = users.find(
            (user) => user.id == comment.receiver_id
          );
          return (
            userComment?.roleId == role ||
            (userComment?.roleId == userRoles.admin &&
              receiverComment.roleId == role)
          );
        });
      }
    } else {
      newComments = newComments?.filter((comment: any) => {
        return comment.id == user.id || comment.receiver_id == user.id;
      });
    }

    return newComments;
  }
};
