import authConstants from "./constants";

const { CHECKING_SESSION, LOGGING_IN, LOGGING_OUT, SET_USER, SET_LOGIN_ERROR, PHONE_LENGTH} = authConstants;

const initialState = {};

const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CHECKING_SESSION: {
      return {
        ...state,
        checkingSession: action.isChecking,
      }
    }
    case LOGGING_IN: {
      return {
        ...state,
        loggingIn: action.isLoggingIn,
      }
    }
    case LOGGING_OUT: {
      return {
        ...state,
        loggingOut: action.isLoggingOut,
      }
    }
    case SET_LOGIN_ERROR: {
      return {
        ...state,
        loginError: action.loginError,
      }
    }
    case SET_USER: {
      return {
        ...state,
        user: action.user,
      }
    }
    case PHONE_LENGTH: {
      return {
        ...state,
        isPhoneLength: action.isPhoneLength,
      }
    }
    default: {
      return state;
    }
  }
};

export default authReducer;
