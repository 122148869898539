import React from "react"
import { Link, Route, Switch, useHistory } from "react-router-dom"
import {
  Layout,
  Table,
  Input,
  Badge,
  Menu,
  Dropdown,
  Space,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Breadcrumb,
} from "antd"
import {
  UserOutlined,
  VerticalAlignBottomOutlined,
  MailOutlined,
  FolderViewOutlined,
  DownOutlined,
  PlusOutlined,
  CaretRightOutlined,
  SearchOutlined,
} from "@ant-design/icons"

import styles from "../styles.module.scss"

import Leftmenu from "../leftmenu"
import Topmenu from "../topmenu"

const { Content } = Layout
const { Option } = Select

const handleChange = () => {
  console.log(`selected`)
}

const menu = (
  <Menu>
    <Menu.Item>Action 1</Menu.Item>
    <Menu.Item>Action 2</Menu.Item>
  </Menu>
)

function RetrocessionsReportsSecretary() {
  const expandedRowRender = () => {
    const columns = [
      {
        dataIndex: "date",
        key: "date",
        render: () => (
          <div className="tabletoggle-section">
            <Space size="middle" className="tabletoggle-section-title">
              <h2>ÉLÉMENTS DÉPOSÉS</h2>
              <a className="ajouter-un-fichier-btn">
                <PlusOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} /> Ajouter un fichier
              </a>
            </Space>
            <div className="tabletoggle-section-list-mask">
              <div className="tabletoggle-section-list">
                <div className="tabletoggle-section-info">
                  <h2>UC_013.mp3</h2>
                  <div className="table-audio-icons">
                    <ul>
                      <li>
                        <CaretRightOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </li>
                      <li>
                        <VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-autio-date">
                  <ul>
                    <li>03/10/2017 </li>
                    <li>802ko</li>
                    <li>2.03 min</li>
                  </ul>
                </div>
              </div>
              <div className="tabletoggle-section-list">
                <div className="tabletoggle-section-info">
                  <h2>UC_013.mp3</h2>
                  <div className="table-audio-icons">
                    <ul>
                      <li>
                        <CaretRightOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </li>
                      <li>
                        <VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-autio-date">
                  <ul>
                    <li>03/10/2017</li>
                    <li>802ko</li>
                    <li>2.03 min</li>
                  </ul>
                </div>
              </div>
              <div className="tabletoggle-section-list">
                <div className="tabletoggle-section-info">
                  <h2>UC_013.mp3</h2>
                  <div className="table-audio-icons">
                    <ul>
                      <li>
                        <CaretRightOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </li>
                      <li>
                        <VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-autio-date">
                  <ul>
                    <li>03/10/2017</li>
                    <li>802ko</li>
                    <li>2.03 min</li>
                  </ul>
                </div>
              </div>
              <div className="tabletoggle-section-list">
                <div className="tabletoggle-section-info">
                  <h2>UC_013.mp3</h2>
                  <div className="table-audio-icons">
                    <ul>
                      <li>
                        <CaretRightOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </li>
                      <li>
                        <VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-autio-date">
                  <ul>
                    <li>03/10/2017</li>
                    <li>802ko</li>
                    <li>2.03 min</li>
                  </ul>
                </div>
              </div>
              <div className="tabletoggle-section-list">
                <div className="tabletoggle-section-info">
                  <h2>UC_013.mp3</h2>
                  <div className="table-audio-icons">
                    <ul>
                      <li>
                        <CaretRightOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </li>
                      <li>
                        <VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-autio-date">
                  <ul>
                    <li>03/10/2017</li>
                    <li>802ko</li>
                    <li>2.03 min</li>
                  </ul>
                </div>
              </div>
              <div className="tabletoggle-section-list">
                <div className="tabletoggle-section-info">
                  <h2>UC_013.mp3</h2>
                  <div className="table-audio-icons">
                    <ul>
                      <li>
                        <CaretRightOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </li>
                      <li>
                        <VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-autio-date">
                  <ul>
                    <li>03/10/2017</li>
                    <li>802ko</li>
                    <li>2.03 min</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        dataIndex: "name",
        key: "name",
        render: () => (
          <div className="tabletoggle-section">
            <Space size="middle" className="tabletoggle-section-title">
              <h2>ÉLÉMENTS RETOURNÉS</h2>
              <a className="ajouter-un-fichier-btn">
                <PlusOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                Ajouter un fichier
              </a>
            </Space>
            <div className="tabletoggle-section-list-mask">
              <div className="tabletoggle-section-list">
                <div className="tabletoggle-section-info">
                  <h2>UC_013.mp3</h2>
                  <div className="table-audio-icons">
                    <ul>
                      {/* <li><CaretRightOutlined /></li> */}
                      <li>
                        <VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="table-autio-date">
                  <ul>
                    <li>03/10/2017</li>
                    <li>802ko</li>
                    <li>2.03 min</li>
                  </ul>
                </div>
              </div>
              <div className="table-statu-fact">
                <h2>STATUT FACTURATION</h2>
                <Row gutter={[24, 24]}>
                  <Col className="gutter-row" xs={24} md={6} lg={6}>
                    <div className="table-statu-fact-info-title">
                      <Row gutter={[3, 24]} className="nopadding">
                        <Col
                          className="gutter-row info-title"
                          xs={24}
                          md={24}
                          lg={24}
                        >
                          <div className="title">&nbsp;</div>
                        </Col>
                        <Col
                          className="gutter-row info-title"
                          xs={24}
                          md={24}
                          lg={24}
                        >
                          <div className="title">Nombre de pages</div>
                        </Col>
                        <Col
                          className="gutter-row info-title"
                          xs={24}
                          md={24}
                          lg={24}
                        >
                          <div className="title">Nombre de photos</div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col className="gutter-row" xs={24} md={14} lg={14}>
                    <div className="table-statu-fact-info">
                      <Row gutter={[3, 24]}>
                        <Col className="gutter-row" xs={24} md={8} lg={8}>
                          <div className="title">
                            {" "}
                            <span>Provisoire</span>{" "}
                          </div>
                        </Col>
                        <Col className="gutter-row" xs={24} md={8} lg={8}>
                          <div className="title">
                            <Form.Item name="remember" valuePropName="checked">
                              <Checkbox>Rétrocession</Checkbox>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col className="gutter-row" xs={24} md={8} lg={8}>
                          <div className="title">
                            <Form.Item name="remember" valuePropName="checked">
                              <Checkbox>Facturation</Checkbox>
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>

                      <div className="statut_facturation_wrapper">
                        <Row gutter={[24, 24]}>
                          <Col className="gutter-row" xs={24} md={8} lg={8}>
                            <div className="table-info">36</div>
                          </Col>
                          <Col className="gutter-row" xs={24} md={8} lg={8}>
                            <div className="table-info">36</div>
                          </Col>
                          <Col className="gutter-row" xs={24} md={8} lg={8}>
                            <div className="table-info">36</div>
                          </Col>
                        </Row>
                        <Row gutter={[24, 24]}>
                          <Col className="gutter-row" xs={24} md={8} lg={8}>
                            <div className="table-info">45</div>
                          </Col>
                          <Col className="gutter-row" xs={24} md={8} lg={8}>
                            <div className="table-info">36</div>
                          </Col>
                          <Col className="gutter-row" xs={24} md={8} lg={8}>
                            <div className="table-info">36</div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col className="gutter-row" xs={24} md={4} lg={4}>
                    <button className="envoyer-btn">Envoyer</button>
                  </Col>
                </Row>
                <div className="elements-retournes-wrapper">
                  <Row>
                    <Col className="gutter-row" xs={24} md={12} lg={12}>
                      <Row>
                        <Col className="gutter-row" xs={24} md={18} lg={18}>
                          <div className="elements-retournes-text">
                            Nombre de pages confirmées
                          </div>
                        </Col>
                        <Col className="gutter-row" xs={24} md={6} lg={6}>
                          <div className="elements-retournes-text">10</div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="gutter-row" xs={24} md={18} lg={18}>
                          <div className="elements-retournes-text">
                            Nombre de photos confirmées
                          </div>
                        </Col>
                        <Col className="gutter-row" xs={24} md={6} lg={6}>
                          <div className="elements-retournes-text">4</div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        ),
      },
    ]

    const data = [] as any
    for (let i = 0; i < 1; ++i) {
      data.push({
        key: i,
        date: "24/12/2014",
        name: "This is production name",
        upgradeNum: "Upgraded: 56",
      })
    }
    return <Table columns={columns} dataSource={data} pagination={false} />
  }

  const columns = [
    {
      title: "",
      dataIndex: "bullet",
      key: "bullet",
      render: () => (
        <span>
          <Badge status="success" />
        </span>
      ),
    },
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Nom du constat", dataIndex: "nomduconstat", key: "nomduconstat" },
    {
      title: "Éléments déposés",
      dataIndex: "elementsdeposes",
      key: "elementsdeposes",
      render: () => (
        <Space size="middle">
          <a>2</a>
          <a>
            <VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </a>
          <a>11.61 Kb</a>
        </Space>
      ),
    },
    {
      title: "Secrétaire",
      dataIndex: "secretaire",
      key: "secretaire",
      render: () => (
        // <Space size="middle">
        //   <Dropdown overlay={menu} className="table-dropdown">
        //     <a>
        //     Sélectionnez <DownOutlined />
        //     </a>
        //   </Dropdown>
        //   <a className="okbtn">ok</a>
        // </Space>
        <Space size="middle">
          <Select
            defaultValue="Sélectionnez"
            style={{ width: 120 }}
            onChange={handleChange}
          >
            <Option value="Sélectionnez">Sélectionnez</Option>
            <Option value="Laurence">Laurence</Option>
            <Option value="Martin">Martin</Option>
          </Select>
          <a className="okbtn">ok</a>
        </Space>
      ),
    },
    { title: "Statut", dataIndex: "statut", key: "statut" },
    {
      title: "Commentaire",
      key: "operation",
      render: () => (
        <Space size="middle">
          <a>
            <MailOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </a>
          <a>
            <FolderViewOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </a>
        </Space>
      ),
    },
  ]

  const data = [] as any
  for (let i = 0; i < 3; ++i) {
    data.push({
      key: i,
      date: "24/12/2014",
      name: "Diebold",
      nomduconstat:
        "edg-201-rue-st.kd-hilton-constat-1pl-jkl-www-hilton-hilton-madrid-302-kls-pql-90-carac-max",
      upgradeNum: 500,
      creator: "Jack",
      statut: "NA",
      createdAt: "24/12/2014",
    })
  }

  return (
    <div className="dashboard-right-section">
      <Content style={{ marginLeft: "0px" }}>
        <Breadcrumb>
          <Breadcrumb.Item>Rétrocession</Breadcrumb.Item>
          <Breadcrumb.Item>Constats</Breadcrumb.Item>
        </Breadcrumb>
      </Content>
      <div className="form_tableouter_wrapper">
        <h2>CONSTATS EN COURS</h2>
        <Row gutter={[3, 24]}>
          <Col className="gutter-row" xs={8} md={11} lg={3}>
            <Select
              defaultValue="one"
              className="table-rows-select"
              onChange={handleChange}
            >
              <Option value="one">Février 2019</Option>
              <Option value="two">20</Option>
            </Select>
          </Col>
          <Col className="gutter-row" xs={8} md={11} lg={4}>
            <Select
              defaultValue="one"
              className="table-rows-select"
              onChange={handleChange}
            >
              <Option value="one">Secrétaire</Option>
              <Option value="two">20</Option>
            </Select>
          </Col>
          <Col className="gutter-row" xs={8} md={11} lg={17}>
            <Select
              defaultValue="one"
              className="table-rows-select"
              onChange={handleChange}
            >
              <Option value="one">Février 2019</Option>
              <Option value="two">20</Option>
            </Select>
          </Col>
        </Row>
        {/* <div className="tableselect_toprow">
                <Row gutter={[3, 24]}>
                  <Col className="gutter-row" xs={12} md={3} lg={3}>&nbsp;</Col>
                  <Col className="gutter-row" xs={12} md={3} lg={3}>&nbsp;</Col>
                  <Col className="gutter-row" xs={12} md={3} lg={3}>
                  <Select defaultValue="one" className="table-rows-select" onChange={handleChange}>
                      <Option value="one">Nom huissier</Option>
                      <Option value="two">20</Option>
                      <Option value="three">30</Option>
                      <Option value="four">40</Option>
                      <Option value="five">50</Option>
                    </Select>
                  </Col>
                  <Col className="gutter-row" xs={12} md={3} lg={3}>
                  <Select defaultValue="one" className="table-rows-select" onChange={handleChange}>
                      <Option value="one">Nom Secrétaire</Option>
                      <Option value="two">20</Option>
                      <Option value="three">30</Option>
                      <Option value="four">40</Option>
                      <Option value="five">50</Option>
                    </Select>
                  </Col>
                  <Col className="gutter-row" xs={12} md={3} lg={3}>
                  <Select defaultValue="one" className="table-rows-select" onChange={handleChange}>
                      <Option value="one">Année</Option>
                      <Option value="two">20</Option>
                      <Option value="three">30</Option>
                      <Option value="four">40</Option>
                      <Option value="five">50</Option>
                    </Select>
                  </Col>
                  <Col className="gutter-row" xs={12} md={3} lg={3}>
                  <Select defaultValue="one" className="table-rows-select" onChange={handleChange}>
                      <Option value="one">Période</Option>
                      <Option value="two">20</Option>
                      <Option value="three">30</Option>
                      <Option value="four">40</Option>
                      <Option value="five">50</Option>
                    </Select>
                  </Col>
                  <Col className="gutter-row" xs={12} md={3} lg={3}>
                  <Select defaultValue="one" className="table-rows-select" onChange={handleChange}>
                      <Option value="one">Statut</Option>
                      <Option value="two">20</Option>
                      <Option value="three">30</Option>
                      <Option value="four">40</Option>
                      <Option value="five">50</Option>
                    </Select>
                  </Col>
                  <Col className="gutter-row" xs={12} md={3} lg={3}>
                  <Select defaultValue="one" className="table-rows-select" onChange={handleChange}>
                      <Option value="one">Mots clés</Option>
                      <Option value="two">20</Option>
                      <Option value="three">30</Option>
                      <Option value="four">40</Option>
                      <Option value="five">50</Option>
                    </Select>
                  </Col>
                </Row>
              </div> */}
        <Table
          className="components-table-demo-nested constatsencours-table"
          columns={columns}
          scroll={{ "x": 400 }}
          expandable={{ expandedRowRender }}
          dataSource={data}
        />
      </div>
    </div>
  )
}

export default RetrocessionsReportsSecretary
