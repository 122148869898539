import React, { useState, useEffect } from "react"
import { Form, Input, Checkbox, Button, Result, message } from "antd"
import { LockOutlined, MailOutlined } from "@ant-design/icons"
import { Link, useParams, useHistory } from "react-router-dom"

import styles from "./styles.module.scss"
// import logo from '../../assets/logo-crc-extranet-white.png';
import authServices from "../../redux/auth/services"
const validPassword = RegExp(
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
)
function RecoverToken(props) {
  const token = props.location.search.split("=")[1]
  const history = useHistory()
  const [checking, setChecking] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [msgErrOne, setMsgErrOne] = useState('')
  const [msgErrTwo, setMsgErrTwo] = useState('')
  const [adminForm, setAdminForm] = useState({
    password: "",
    confirmPassword: "",
  })

  const onFinish = async (event) => {
    const msg_one = "Le mot de passe doit comporter des majuscules, des minuscules, des chiffres et un symbole."
    const msg_two = "Le mot de passe doit être le même."

    if (
      adminForm.password &&
      adminForm.confirmPassword &&
      adminForm.password === adminForm.confirmPassword &&
      validPassword.test(adminForm.password) &&
      validPassword.test(adminForm.confirmPassword)
    ) {
      try {
        setError(undefined)
        setLoading(true)
        await authServices.createPassword(token, adminForm.password)
        setSuccess(true)
        setLoading(false)
        message.success("Mot de passe créé avec succès.")
        history.push("/login")
      } catch (err: any) {
        setError(err.response.data.errors.message)
        setLoading(false)
      }
    } else if (validPassword.test(adminForm.password) == false ||
      validPassword.test(adminForm.confirmPassword) == false) {
      message.error(msg_one)
      setMsgErrOne(msg_one)
    }
    else {
      message.error(msg_two)
      setMsgErrTwo(msg_two)
    }
  }

  const _changeHandler = (event, name) => {
    setAdminForm({
      ...adminForm,
      ...{ [name]: event.target.value },
    })
  }

  return (
    <section className={styles.login_wrapper}>
      <div className={styles.login_form_container}>
        <div className={styles.login_form_container}>
          {/* <img src={logo} alt="Logo" className={styles.login_form_logo} /> */}
          <div className={styles.login_form_logo}>
            <img
              src={"/images/logo.svg"}
              alt="Logo"
              className={styles.responsive_img}
            />
          </div>
          <Form
            name="recovery_request"
            onFinish={onFinish}
            className={styles.forgot_passform}
          >
            <Form.Item
              label="Si vous modifiez votre ancien mot de passe, vous ne pourrez plus l'utiliser."
              name="email"
              rules={[
                { message: "Veuillez entrer votre email" },
                {
                  type: "email",
                  message: "Veuillez entrer une adresse email valide",
                },
              ]}
              validateStatus={error && "error"}
              help={error || null}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                placeholder="Entrez un nouveau mot de passe"
                disabled={loading}
                value={adminForm.password || ""}
                onChange={(e) => _changeHandler(e, "password")}
              />
              <small style={{ color: "red" }}>{msgErrOne}</small>
              <Input
                style={{ marginTop: '10px' }}
                prefix={<MailOutlined className="site-form-item-icon" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                placeholder="Répétéz le nouveau mot de passe"
                disabled={loading}
                value={adminForm.confirmPassword || ""}
                onChange={(e) => _changeHandler(e, "confirmPassword")}
              />
            </Form.Item>
            <small style={{ color: "red" }}>{msgErrTwo}</small>

            {/* <Form.Item>
          <Link className={styles.login_form_forgot} to="/login">
          Vous avez un compte?
          </Link>
        </Form.Item> */}

            <Form.Item>
              <Button
                style={{ marginTop: '10px' }}
                type="primary"
                htmlType="submit"
                //className="login_form-submit"
                className={styles.login_form_submit_btn}
                disabled={loading}
              >
                Changer le mot de passe
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </section>
  )
}

export default RecoverToken
