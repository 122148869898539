import { Button, Modal } from "antd";
import React from "react";

const ModalArchiveConstat = ({ visible, onClose, onArchive }) => {
  return (
    <Modal centered footer={null} visible={visible} onCancel={onClose} destroyOnClose={true}>
      <div>
        <div style={{ display: "flex", marginTop: "5%" }}>
          <h2>Êtes-vous sûr de vouloir archiver le constat ?</h2>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}
        >
          <Button
            style={{
              backgroundColor: "#40A9FF",
              color: "white",
              marginRight: "3%",
              width: "16%",
            }}
            onClick={onClose}
          >
            Non
          </Button>
          <Button style={{ width: "16%" }} onClick={onArchive}>
            Oui
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalArchiveConstat;
