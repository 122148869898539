import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import { useMediaQuery } from "react-responsive";
import {
  AssessmentOutlined,
  AssignmentOutlined,
  ErrorOutlineOutlined,
  RestoreOutlined,
} from "@mui/icons-material";
import { RoutesDashboard } from "../../../helpers/routes";
import { useDispatch, useSelector } from "react-redux";
import reportService from "../../services/report";
import moment from "moment";

moment.locale("fr");

function SecretaryHeader() {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.auth);
  const { users } = useSelector((state: any) => state.user);
  const [dashboardData, setDashbaordData]: any = useState({
    revence: 0,
    alert: 0,
    totalAverage: "",
    reports: 0,
  });
  const isMobile = useMediaQuery({ maxWidth: 766 });
  const isSmallMobile = useMediaQuery({ maxWidth: 346 });

  const [reports, setReports]: any = useState([]);

  useEffect(() => {
    (async () => {
      await reportService
        .getReports({
          states: ["EC", "RS", "TE"],
          secretary_id: user.id,
          without_filter_archived: true,
        })
        .then((res) => {
          let reportsFiltered = res.data.reports;

          reportsFiltered = reportsFiltered.map((report: any) => {
            let newReport = report;
            if (
              report.status === "NA" ||
              report.status === "TE" ||
              report.status === "AR"
            ) {
              newReport = {
                ...newReport,
                status_alert: "default",
              };
            } else if (
              moment().diff(report.last_status_change || 0, "hours") < 30
            ) {
              newReport = {
                ...newReport,
                status_alert: "success",
              };
            } else if (
              moment().diff(report.last_status_change || 0, "hours") <= 36 &&
              moment().diff(report.last_status_change || 0, "hours") >= 30
            ) {
              newReport = {
                ...newReport,
                status_alert: "warning",
              };
            } else {
              newReport = {
                ...newReport,
                status_alert: "error",
              };
            }
            return newReport;
          });

          reportsFiltered = reportsFiltered.map((report: any) => {
            let hours = moment().diff(report.last_status_change || 0, "hours");

            return {
              ...report,
              delay: hours,
            };
          });

          const secretary = users.find(
            (userUsers: any) => userUsers.id === user.id
          );

          reportsFiltered = reportsFiltered.map((report: any) => {
            return {
              ...report,
              amount_without_taxes_pages:
                Math.round(
                  report.count_pages_retrocession -
                    report.count_photos_retrocession / 2
                ) * (secretary?.secretary_price_page || 0),
              amount_without_taxes_photos:
                report.count_photos_retrocession *
                (secretary?.secretary_price_photo || 0),
            };
          });

          setReports(reportsFiltered);
        });
    })();
  }, [dispatch, users]);

  return (
    <div
      className="topfive-coloum commontopfivewrapper"
      style={{
        marginTop: `${isSmallMobile ? "100px" : isMobile ? "60px" : ""}`,
      }}
    >
      <Row gutter={[24, 24]} style={{ justifyContent: "space-around" }}>
        <Col className="gutter-row" xs={24} md={12} lg={5}>
          <div className="dashboard-top-one">
            <Link to={RoutesDashboard.reportsRetrocessionsSecretary}>
              <Row
                gutter={[2, 24]}
                align="middle"
                style={{
                  paddingBottom: "5px",
                  height: "100%",
                  minBlockSize: `${isMobile ? "57px" : "80px"}`,
                }}
              >
                <Col className="gutter-row" xs={4} md={8} lg={4}>
                  <Row align="middle">
                    <AssessmentOutlined
                      sx={{ color: "#2e6186", fontSize: 30 }}
                    />
                  </Row>
                </Col>
                <Col
                  className="gutter-row"
                  xs={20}
                  md={16}
                  lg={20}
                  style={{ height: "100%" }}
                >
                  <p>Rétrocession</p>
                  <h2>
                    {Math.round(reports
                      ?.filter((report: any) => {
                        const lastStatusChangeDate = moment(report.createdAt);
                        const today = moment();

                        return (
                          report.status === "TE" &&
                          lastStatusChangeDate.month() === today.month() &&
                          lastStatusChangeDate.year() === today.year()
                        );
                      })
                      ?.reduce((acc: any, report: any) => {
                        return (
                          acc +
                          report.amount_without_taxes_pages +
                          report.amount_without_taxes_photos
                        );
                      }, 0))}
                    €
                  </h2>
                </Col>
              </Row>
            </Link>
          </div>
        </Col>
        <Col className="gutter-row" xs={24} md={12} lg={5}>
          <div className="dashboard-top-two">
            <Link to={RoutesDashboard.reportsInProgressSecretary}>
              <Row
                gutter={[2, 24]}
                align="middle"
                style={{
                  paddingBottom: "5px",
                  height: "100%",
                  minBlockSize: `${isMobile ? "57px" : "80px"}`,
                }}
              >
                <Col className="gutter-row" xs={4} md={8} lg={4}>
                  <Row align="middle">
                    <AssignmentOutlined
                      sx={{ color: "#019BCD", fontSize: 30 }}
                    />
                  </Row>
                </Col>
                <Col
                  className="gutter-row"
                  xs={20}
                  md={16}
                  lg={20}
                  style={{ height: "100%" }}
                >
                  <p>Constats en cours</p>
                  <h2>
                    {
                      reports.filter(
                        (report) =>
                          report.status === "EC" || report.status === "RS"
                      ).length
                    }
                  </h2>
                </Col>
              </Row>
            </Link>
          </div>
        </Col>
        <Col className="gutter-row" xs={24} md={12} lg={5}>
          <div className="dashboard-top-two">
            <Link to={RoutesDashboard.reportsMonthSecretary}>
              <Row
                gutter={[2, 24]}
                align="middle"
                style={{
                  paddingBottom: "5px",
                  height: "100%",
                  minBlockSize: `${isMobile ? "57px" : "80px"}`,
                }}
              >
                <Col className="gutter-row" xs={4} md={8} lg={4}>
                  <Row align="middle">
                    <AssignmentOutlined
                      sx={{ color: "#019BCD", fontSize: 30 }}
                    />
                  </Row>
                </Col>
                <Col
                  className="gutter-row"
                  xs={20}
                  md={16}
                  lg={20}
                  style={{ height: "100%" }}
                >
                  <p>Tous mes constats</p>
                </Col>
              </Row>
            </Link>
          </div>
        </Col>
        <Col className="gutter-row" xs={24} md={12} lg={5}>
          <div className="dashboard-top-three">
            <Row
              gutter={[2, 24]}
              align="middle"
              style={{
                paddingBottom: "5px",
                height: "100%",
                minBlockSize: `${isMobile ? "57px" : "80px"}`,
              }}
            >
              <Col className="gutter-row" xs={4} md={8} lg={4}>
                <Row align="middle">
                  <ErrorOutlineOutlined
                    sx={{ color: "#70528d", fontSize: 30 }}
                  />
                </Row>
              </Col>
              <Col
                className="gutter-row"
                xs={20}
                md={16}
                lg={20}
                style={{ height: "100%" }}
              >
                <p>Constats en alerte</p>
                <h2>
                  {
                    reports.filter((report: any) => {
                      return (
                        report.status_alert === "error" ||
                        report.status_alert === "warning"
                      );
                    }).length
                  }
                </h2>
              </Col>
            </Row>
          </div>
        </Col>
        <Col className="gutter-row" xs={24} md={12} lg={4}>
          <div className="dashboard-top-four">
            <Row
              gutter={[2, 24]}
              align="middle"
              style={{
                paddingBottom: "5px",
                height: "100%",
                minBlockSize: `${isMobile ? "57px" : "80px"}`,
              }}
            >
              <Col className="gutter-row" xs={4} md={8} lg={4}>
                <Row align="middle">
                  <RestoreOutlined sx={{ color: "#70528d", fontSize: 30 }} />
                </Row>
              </Col>
              <Col
                className="gutter-row"
                xs={20}
                md={16}
                lg={20}
                style={{ height: "100%" }}
              >
                <p>Délai moyen de retour</p>
                <h2>
                  {(
                    reports
                      .filter((report: any) => {
                        return report.status === "RS" || report.status === "EC";
                      })
                      .reduce((acc: any, report: any) => {
                        return acc + report.delay;
                      }, 0) /
                      reports.filter((report: any) => {
                        return report.status === "RS" || report.status === "EC";
                      }).length || 0
                  )?.toFixed(0) || 0}
                </h2>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default SecretaryHeader;
