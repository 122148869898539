import React, { useState, useEffect } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import {
  Layout,
  Table,
  Input,
  Badge,
  Menu,
  Dropdown,
  Space,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Breadcrumb,
  Button,
  Upload,
  message,
} from "antd";
import {
  UserOutlined,
  DownOutlined,
  InboxOutlined,
  CloudUploadOutlined,
  PlusOutlined,
  EuroCircleOutlined,
  EuroOutlined,
} from "@ant-design/icons";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import styles from "../styles.module.scss";

import Leftmenu from "../leftmenu";
import Topmenu from "../topmenu";
import { usersService } from "../../../_services/usersSerives";
import { ISendUserDataI } from "../../../interfaces/users.interface";
import { filesUploadServices } from "../../../_services/uploadFIles";
import { useSelector } from "react-redux";
import FileUtils from "../../../utils/files";
const validPassword = RegExp(
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
);
const validEmailRegex = RegExp(
  /^(([^<>()\\[\]\\.,;:\s@\\"]+(\.[^<>()\\[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i
);
const validIdentify = RegExp(/^[A-Za-z0-9]{3,}$/);
const menu = (
  <Menu>
    <Menu.Item>Huissier</Menu.Item>
    <Menu.Item>Secrétaire</Menu.Item>
    <Menu.Item>Admin</Menu.Item>
  </Menu>
);
const { Dragger } = Upload;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
const props = {
  name: "file",
  multiple: false,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} fichier téléchargé avec succès.`);
    } else if (status === "error") {
      message.error(`${info.file.name} le téléchargement du fichier a échoué.`);
    }
  },
};

const { Content } = Layout;

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Vous ne pouvez télécharger que des fichiers JPG / PNG!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("L'image doit être inférieure à 2 Mo!");
  }
  return isJpgOrPng && isLt2M;
}

function CreateMyAccount({ secretaryForm, isDoneEdit }) {
  // const { user } = useSelector((state: any) => state.auth)
  // const [createSecretaryForm, setCreateSecretaryForm] = useState({
  //   civility: secretaryForm?.civility || user?.civility || "",
  //   last_name: secretaryForm?.last_name || user?.last_name || "",
  //   first_name: secretaryForm?.first_name || user?.first_name || "",
  //   phone: secretaryForm?.telephone || user?.telephone || "",
  //   email: secretaryForm?.email || user?.email || "",
  //   siret: secretaryForm?.street || user?.street || "",
  //   address: secretaryForm?.address || user?.address || "",
  //   city: secretaryForm?.city || user?.city || "",
  //   password: secretaryForm?.password || user?.password || "",
  //   confirmPassword: secretaryForm?.confirmPassword || user?.civility || "",
  //   rib: secretaryForm?.rib || user?.rib || "",
  // })

  const { user } = useSelector((state: any) => state.auth);
  const [myAccountForm, setmyAccountForm] = useState({
    civility: secretaryForm?.civility || "",
    last_name: secretaryForm?.last_name || "",
    first_name: secretaryForm?.first_name || "",
    phone: secretaryForm?.telephone || "",
    email: secretaryForm?.email || "",
    siret: secretaryForm?.street || "",
    address: secretaryForm?.address || "",
    zipCode: user?.zip || "",
    city: secretaryForm?.city || "",
    password: "",
    confirmPassword: "",
    userID: secretaryForm?.identity || "",
    rib: secretaryForm?.rib || "",
    matrices_associated: secretaryForm?.matrices_associated || [],
    pricepage: secretaryForm?.secretary_price_page || "",
    pricephoto: secretaryForm?.secretary_price_photo || "",
    errors: {
      civility: "",
      last_name: "",
      first_name: "",
      phone: "",
      email: "",
      siret: "",
      address: "",
      city: "",
      password: "",
      confirmPassword: "",
      userID: "",
      rib: "",
    },
  });

  useEffect(() => {}, []);
  const notFilledPasswordField = () => {
    let errors = myAccountForm.errors;
    if (myAccountForm.civility === "") {
      errors.civility = "Champ requis";
    }
    if (myAccountForm.last_name === "") {
      errors.last_name = "Champ requis";
    }
    if (myAccountForm.first_name === "") {
      errors.first_name = "Champ requis";
    }
    if (myAccountForm.phone === "") {
      errors.phone = "Champ requis";
    }
    if (myAccountForm.email === "") {
      errors.email = "Champ requis";
    }
    if (myAccountForm.siret === "") {
      errors.siret = "Champ requis";
    }
    if (myAccountForm.address === "") {
      errors.address = "Champ requis";
    }
    if (myAccountForm.city === "") {
      errors.city = "Champ requis";
    }
    if (myAccountForm.userID === "") {
      errors.userID = "Field is required";
    }
    // if (myAccountForm.password === "") {
    //   errors.password = "Champ requis"
    // }
    // if (myAccountForm.confirmPassword === "") {
    //   errors.confirmPassword = "Champ requis"
    // }
    //if (myAccountForm.rib === "") {
    //  errors.rib = "Champ requis"
    //}
    setmyAccountForm({ ...myAccountForm, ...{ errors } });
  };

  const validateForm = (formFields) => {
    notFilledPasswordField();
    let valid = true;
    Object.values(formFields.errors).forEach(
      (val: any) => val.length > 0 && (valid = false)
    );
    return valid;
  };

  const imguploadicon = require("../../../pages/images/uploadimage-icon.svg");

  const [imageUrl, setImage] = useState(
    secretaryForm?.profile || require("../../../pages/images/uploadimage.svg")
  );

  const uploadButton = (
    <div>
      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const [fileName, setFileName] = useState("");
  const handleChangeImage = async (info) => {
    setFileName(info.file.name);
    if (info.file.status === "done") {
      let data = new FormData();
      data.append("profile", info.file.originFileObj);
      await filesUploadServices
        .uploadFileAPI(data)
        .then((response) => {
          setImage(response.data.url);
        })
        .catch((err) => console.error(err));
    }
  };
  let dataImg;
  let url = "/static/media/uploadimage.aeda1424.svg";

  if (secretaryForm?.profile !== url) {
    dataImg = secretaryForm?.profile;
  } else if (fileName !== "") {
    dataImg = fileName;
  } else {
    dataImg = "";
  }

  const _submitHandler = async () => {
    if (validateForm(myAccountForm)) {
      let data: any = {};
      data["role_id"] = secretaryForm ? "3" : user.role_id;
      data["civility"] = myAccountForm.civility;
      data["last_name"] = myAccountForm.last_name;
      data["first_name"] = myAccountForm.first_name;
      data["telephone"] = myAccountForm.phone;
      data["street"] = myAccountForm.siret;
      data["address"] = myAccountForm.address;
      data["city"] = myAccountForm.city;
      if (myAccountForm.password !== "") {
        data["password"] = myAccountForm.password;
      }
      data["profile"] = imageUrl;
      data["rib"] = myAccountForm.rib;
      data["secretary_price_page"] = myAccountForm.pricepage;
      data["secretary_price_photo"] = myAccountForm.pricephoto;
      if (myAccountForm.pricepage === "") {
        data["secretary_price_page"] = "0.00";
      }
      if (myAccountForm.pricephoto === "") {
        data["secretary_price_photo"] = "0.00";
      }

      data["identity"] = myAccountForm.userID;
      // if (secretaryForm) {
      //update
      await usersService
        .updateUsersAPI(secretaryForm?.id || user.id, data)
        .then((response) => response.data)
        .then((response: ISendUserDataI) => {
          message.success("Compte modifié avec succès.");
          isDoneEdit();
        })
        .catch((err) => console.error(err));
      // } else {
      //   //new
      //   data["email"] = createSecretaryForm.email

      //   await usersService
      //     .createUsersAPI(data)
      //     .then((response) => response.data)
      //     .then((response: ISendUserDataI) => {})
      //     .catch((err) => console.error(err))
      // }
    }
  };

  // const _changeHandler = (event, name) => {
  //   setmyAccountForm({
  //     ...myAccountForm,
  //     ...{ [name]: event.target.value },
  //   })
  // }

  const _changeHandler = (event, name) => {
    let errors = myAccountForm.errors;

    const { value } = event.target;
    switch (name) {
      case "civility":
        errors.civility =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "last_name":
        errors.last_name =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "first_name":
        errors.first_name =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "phone":
        errors.phone =
          value.length === 10 ? "" : "Numéro de téléphone invalide.";
        break;
      case "email":
        errors.email = validEmailRegex.test(value)
          ? ""
          : "Identifiant de messagerie non valide.";
        break;
      case "siret":
        errors.siret =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "address":
        errors.address =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "city":
        errors.city =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "password":
        errors.password = validPassword.test(value)
          ? ""
          : "Le mot de passe doit comporter des majuscules, des minuscules, des chiffres et un symbole.";
        break;
      case "confirmPassword":
        errors.confirmPassword =
          myAccountForm.password === value ? "" : "Mot de passe incorrect.";
        break;
      case "userID":
        errors.userID = validIdentify.test(value)
          ? ""
          : "Doit contenir au moins 3 caractères";
        break;
      case "rib":
        errors.rib =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      default:
        break;
    }

    setmyAccountForm({
      ...myAccountForm,
      ...{ errors, [name]: value },
    });
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <div className="dashboard-right-section">
      {!secretaryForm && (
        <Content style={{ marginLeft: "0px" }}>
          <Breadcrumb>
            <Breadcrumb.Item>Comptes</Breadcrumb.Item>
            <Breadcrumb.Item>Voir les comptes</Breadcrumb.Item>
          </Breadcrumb>
        </Content>
      )}

      <div className="form_outer_wrapper">
        <h2>
          <UserOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          {secretaryForm
            ? "MODIFICATION D’UN COMPTE UTILISATEUR"
            : "CRÉER MON COMPTE"}
        </h2>
        <Form className="create_account createmyaccount_form">
          <Row>
            <Col xs={24} md={24} lg={9}></Col>
            <Col xs={24} md={24} lg={12}>
              <div className="profile_uploadimg_wrapper">
                <Form.Item>
                  <Upload
                    name="avatar"
                    showUploadList={false}
                    listType="picture-card"
                    className="avatar-uploader"
                    customRequest={FileUtils.generalDummyRequestLocal}
                    onChange={handleChangeImage}
                  >
                    <div className="uploadimg-wrapper">
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton
                      )}
                      {dataImg ? (
                        <></>
                      ) : (
                        <div className="uploadimg-icons">
                          <img src={imguploadicon} alt="avatar-icon" />
                        </div>
                      )}
                    </div>
                  </Upload>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Civilité"
                valuePropName="civility"
                validateStatus={
                  myAccountForm.errors.civility.length > 0 ? "error" : "success"
                }
                help={myAccountForm.errors.civility}
              >
                <Input
                  placeholder="Civilité"
                  className="inputfield-full"
                  value={myAccountForm.civility}
                  onChange={(e) => _changeHandler(e, "civility")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Nom"
                valuePropName="last_name"
                validateStatus={
                  myAccountForm.errors.last_name.length > 0
                    ? "error"
                    : "success"
                }
                help={myAccountForm.errors.last_name}
              >
                <Input
                  placeholder="Nom"
                  className="inputfield-full"
                  value={myAccountForm.last_name}
                  onChange={(e) => _changeHandler(e, "last_name")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Prénom"
                valuePropName="first_name"
                validateStatus={
                  myAccountForm.errors.first_name.length > 0
                    ? "error"
                    : "success"
                }
                help={myAccountForm.errors.first_name}
              >
                <Input
                  placeholder="Prénom"
                  className="inputfield-full"
                  value={myAccountForm.first_name}
                  onChange={(e) => _changeHandler(e, "first_name")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Téléphone"
                valuePropName="phone"
                validateStatus={
                  myAccountForm.errors.phone.length > 0 ? "error" : "success"
                }
                help={myAccountForm.errors.phone}
              >
                <Input
                  placeholder="Téléphone"
                  className="inputfield-full"
                  value={myAccountForm.phone}
                  onChange={(e) => _changeHandler(e, "phone")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Email"
                valuePropName="email"
                validateStatus={
                  myAccountForm.errors.email.length > 0 ? "error" : "success"
                }
                help={myAccountForm.errors.email}
              >
                <Input
                  placeholder="Email"
                  className="inputfield-full"
                  value={myAccountForm.email}
                  onChange={(e) => _changeHandler(e, "email")}
                  disabled={secretaryForm ? true : false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <div className="input-currenct">
                <Form.Item
                  label="Tarif/page"
                  name="pricepage"
                  valuePropName="pricepage"
                  rules={[
                    {
                      required: false,
                      message: "Veuillez entér Tarif/page",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => _changeHandler(e, "pricepage")}
                    value={myAccountForm.pricepage}
                    className="inputfield-small"
                    style={{ marginRight: "5px" }}
                  />
                  €
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="RIB"
                valuePropName="rib"
                validateStatus={
                  myAccountForm.errors.rib.length > 0 ? "error" : "success"
                }
                help={myAccountForm.errors.rib}
              >
                <Input
                  placeholder="RIB"
                  className="inputfield-full"
                  value={myAccountForm.rib}
                  onChange={(e) => _changeHandler(e, "rib")}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <div className="input-currenct">
                <Form.Item
                  label="Tarif/photo"
                  name="pricephoto"
                  valuePropName="pricephoto"
                  rules={[
                    {
                      required: false,
                      message: "Veuillez entér Tarif/photo",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => _changeHandler(e, "pricephoto")}
                    value={myAccountForm.pricephoto}
                    className="inputfield-small"
                    style={{ marginRight: "5px" }}
                  />
                  €
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Siret"
                valuePropName="siret"
                validateStatus={
                  myAccountForm.errors.siret.length > 0 ? "error" : "success"
                }
                help={myAccountForm.errors.siret}
              >
                <Input
                  placeholder="Siret"
                  className="inputfield-full"
                  value={myAccountForm.siret}
                  onChange={(e) => _changeHandler(e, "siret")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Adresse postale"
                valuePropName="address"
                validateStatus={
                  myAccountForm.errors.address.length > 0 ? "error" : "success"
                }
                help={myAccountForm.errors.address}
              >
                <Input
                  placeholder="Adresse postale"
                  className="inputfield-full"
                  value={myAccountForm.address}
                  onChange={(e) => _changeHandler(e, "address")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="CP/ Ville"
                valuePropName="cpville"
                validateStatus={
                  myAccountForm.errors.city.length > 0 ? "error" : "success"
                }
                help={myAccountForm.errors.city}
                className="inputfield-full"
              >
                <Input.Group>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Input
                        placeholder="Code postal"
                        value={myAccountForm.city}
                        onChange={(e) => _changeHandler(e, "city")}
                      />
                    </Col>
                    <Col span={12}>
                      <Input
                        placeholder="Ville"
                        value={secretaryForm.zipCode || ""}
                        onChange={(e) => _changeHandler(e, "zipCode")}
                      />
                    </Col>
                  </Row>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Identifiant"
                valuePropName="userID"
                validateStatus={
                  myAccountForm.errors.userID.length > 0 ? "error" : "success"
                }
                help={myAccountForm.errors.userID}
              >
                <Input
                  placeholder="Identifiant"
                  className="inputfield-full"
                  value={myAccountForm.userID || ""}
                  onChange={(e) => _changeHandler(e, "userID")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Mot de passe"
                valuePropName="password"
                validateStatus={
                  myAccountForm.errors.password.length > 0 ? "error" : "success"
                }
                help={myAccountForm.errors.password}
              >
                <Input.Password
                  placeholder="Mot de passe"
                  className="inputfield-full"
                  value={myAccountForm.password}
                  onChange={(e) => _changeHandler(e, "password")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Confirmer mot de passe"
                //name="confirm_password"
                valuePropName="password"
                validateStatus={
                  myAccountForm.errors.confirmPassword.length > 0
                    ? "error"
                    : "success"
                }
                help={myAccountForm.errors.confirmPassword}
              >
                <Input.Password
                  placeholder="confirm_password"
                  className="inputfield-full"
                  value={myAccountForm.confirmPassword}
                  onChange={(e) => _changeHandler(e, "confirmPassword")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12} className="register-btn-mask">
              <Form.Item label="  ">
                <Button
                  type="primary"
                  className="validate-btn"
                  onClick={_submitHandler}
                >
                  Valider
                </Button>
                <Button className="cancel-btn" onClick={isDoneEdit}>
                  Annuler
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default CreateMyAccount;
