import React, { useEffect, useState } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import {
  Layout,
  Table,
  Input,
  Badge,
  Menu,
  Dropdown,
  Space,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Breadcrumb,
} from "antd";
import {
  UserOutlined,
  VerticalAlignBottomOutlined,
  MailOutlined,
  FolderViewOutlined,
  DownOutlined,
  PlusOutlined,
  CaretRightOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import styles from "../styles.module.scss";
import { Line } from "react-chartjs-2";

import Leftmenu from "../leftmenu";
import Topmenu from "../topmenu";
import { reportServices } from "../../../_services/reportServices";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getStatistics } from "../../../redux/statistics";
import { listDaysOfMonth } from "../../../utils/functions/moment";

const { Content } = Layout;
const { Option } = Select;

function Statistiques() {
  const dispatch = useDispatch();
  const { statistics } = useSelector((state: any) => state.statistics);
  const [filters, setFilters] = useState({
    month: "",
    year: moment().format("YYYY"),
  });
  const [graphData, setGraphData]: any = useState({
    labels: moment.months(),
    datasets: [],
  });

  useEffect(() => {
    dispatch(
      getStatistics({
        month: filters.month === "" ? -1 : filters.month,
        year: moment(filters.year, "YYYY").format("YYYY"),
      })
    );
  }, [dispatch, filters]);

  useEffect(() => {
    const graphData = {
      labels:
        filters.month === ""
          ? moment.months()
          : listDaysOfMonth(filters.year, filters.month),
      datasets: [
        {
          label: "Secrétaire",
          data:
            filters.month === ""
              ? moment.months().map((month) =>
                statistics
                  .filter((report: any) => {
                    const monthNumber = moment(month, "MMMM").format("MM");

                    return (
                      moment(report.createdAt).format("MM-YYYY") ===
                      `${monthNumber}-${filters.year}`
                    );
                  })
                  .map((report: any) => {
                    return (
                      report.count_pages_retrocession *
                      report.secretary_price_page +
                      report.count_photos_retrocession *
                      report.secretary_price_photo
                    );
                  })
                  .reduce((a: any, b: any) => a + b, 0)
              )
              : listDaysOfMonth(filters.year, filters.month).map((day) =>
                statistics
                  .filter((report: any) => {
                    return (
                      moment(report.createdAt).format("DD/MM/YYYY") ===
                      `${day}-${filters.month}-${filters.year}`
                    );
                  })
                  .map((report: any) => {
                    return (
                      report.count_pages_retrocession *
                      report.secretary_price_page +
                      report.count_photos_retrocession *
                      report.secretary_price_photo
                    );
                  })
                  .reduce((a: any, b: any) => a + b, 0)
              ),
          fill: false,
          borderColor: "#FF6382",
          backgroundColor: "#FF6382",
        },
        {
          label: "Huissier",
          data:
            filters.month === ""
              ? moment.months().map((month) =>
                statistics
                  .filter((report: any) => {
                    const monthNumber = moment(month, "MMMM").format("MM");

                    return (
                      moment(report.createdAt).format("MM-YYYY") ===
                      `${monthNumber}-${filters.year}`
                    );
                  })
                  .map((report: any) => {
                    return (
                      report.count_pages_billing * report.group_price_page +
                      report.count_photos_billing * report.group_price_photo
                    );
                  })
                  .reduce((a: any, b: any) => a + b, 0)
              )
              : listDaysOfMonth(filters.year, filters.month).map((day) =>
                statistics
                  .filter((report: any) => {
                    return (
                      moment(report.createdAt).format("DD/MM/YYYY") ===
                      `${day}-${filters.month}-${filters.year}`
                    );
                  })
                  .map((report: any) => {
                    return (
                      report.count_pages_billing * report.group_price_page +
                      report.count_photos_billing * report.group_price_photo
                    );
                  })
                  .reduce((a: any, b: any) => a + b, 0)
              ),
          fill: false,
          borderColor: "#36A2EA",
          backgroundColor: "#36A2EA",
        },
      ],
    };

    setGraphData(graphData);
  }, [statistics, filters]);

  const options = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
      },
    },
    scales: {
      y: {
        type: "linear" as const,
        display: true,
        position: "left" as const,
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="dashboard-right-section" style={{ marginLeft: "-2.2%" }}>
      <Content>
        <Breadcrumb>
          <Breadcrumb.Item>Statistiques</Breadcrumb.Item>
        </Breadcrumb>
      </Content>
      <div className="chart-top-select-wrapper">
        <h2>STATISTIQUES</h2>
        <Row className="chart-top-select">
          <Col className="gutter-row" xs={8} md={11} lg={3}>
            <Select
              defaultValue=""
              className="table-rows-select"
              onChange={(e) => setFilters({ ...filters, month: e })}
            >
              <Option value="">Tous</Option>
              {moment.months().map((e) => {
                const monthNumber = moment(e, "MMMM").format("MM");
                return <Option value={monthNumber}>{e}</Option>;
              })}
            </Select>
          </Col>

          <Col className="gutter-row" xs={8} md={11} lg={2}>
            <Select
              defaultValue={filters.year}
              className="table-rows-select"
              onChange={(e) => setFilters({ ...filters, year: e })}
            >
              {Array.from(
                new Array(Number(moment().format("YYYY")) - 2000 + 1),
                (v, i) => i + 2000
              ).map((e) => {
                return <Option value={e}>{e}</Option>;
              })}
            </Select>
          </Col>
        </Row>
      </div>
      <div className="statistiques_wrapper" style={{ marginBottom: "5%" }}>
        {graphData && (
          <Line data={graphData} options={options} width={400} height={400} />
        )}
      </div>
    </div>
  );
}

export default Statistiques;
