import React, { useState, useEffect } from "react";
import {
  Layout,
  Input,
  Row,
  Col,
  Form,
  Select,
  Breadcrumb,
  Button,
  Upload,
  Checkbox,
  message,
} from "antd";
import { reportServices } from "../../../_services/reportServices";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { newsServices } from "../../../_services/newsServices";
import { Base64 } from "js-base64";
const { Content } = Layout;

function EditNews(props) {
  const [title, setTitle] = useState("");
  const [important, setImportant] = useState(false);
  const [description, setDescription] = useState("");
  const [createReport, setCreateReport] = useState({
    errors: {
      title: "",
      description: "",
    },
  });

  useEffect(() => {
    const id = props?.match?.params?.id;
    if (id) {
      _getNewsById(id);
    }
  }, []);

  const _getNewsById = async (id) => {
    await newsServices
      .getNewsByIdAPI(id)
      .then((response) => response.data)
      .then((response) => {
        response = response[0];
        setDescription(
          response.description.replace(/&lt;/g, "<").replace(/&gt;/g, ">")
        );
        setImportant(response.important);
        setTitle(response.title);
      })
      .catch((err) => console.error(err));
  };

  const notFilledPasswordField = () => {
    let errors: any = createReport.errors;
    if (title === "") {
      errors.title = "Champ requis";
    }
    if (description === "") {
      errors.description = "Champ requis";
    }
    setCreateReport({ ...createReport, ...{ errors } });
  };

  const validateForm = (formFields) => {
    notFilledPasswordField();
    let valid = true;
    Object.values(formFields.errors).forEach(
      (val: any) => val.length > 0 && (valid = false)
    );
    return valid;
  };

  const _submitHandler = async () => {
    if (validateForm(createReport)) {
      let data: any = {};
      data.title = title;
      data.description = Base64.encode(description)
      data.important = important;
      await newsServices
        .updateNewsByIdAPI(props?.match?.params?.id, data)
        .then((response) => response.data)
        .then((response) => {
          message.success("Actualités mises à jour avec succès.");
          props.history.push("/dashboard/viewnews");
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <div className="dashboard-right-section createanews-wrapper">
      <Content style={{ marginLeft: "0px" }}>
        <Breadcrumb>
          <Breadcrumb.Item>Actualités</Breadcrumb.Item>
          <Breadcrumb.Item>Créer une actualité</Breadcrumb.Item>
        </Breadcrumb>
      </Content>
      <div className="form_outer_onlyscreenwrapper">
        <div className="form_onlyscreenwrapper">
          <h2
            className="pagewrapper_top"
            style={{
              marginLeft: "0px",
              marginBottom: "1.5em",
            }}
          >
            CRÉER UNE ACTUALITÉ
          </h2>
          <Form className="create_news">
            <Row gutter={16}>
              <Col xs={24} md={24} lg={24}>
                <Form.Item
                  label="Titre de l’actualité"
                  valuePropName="title"
                  validateStatus={
                    createReport.errors.title.length > 0 ? "error" : "success"
                  }
                  help={createReport.errors.title}
                >
                  <Input
                    className="news-headline"
                    value={title || ""}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={24} lg={24}>
                <div className="ckeditor_label_wrapper">
                  <Form.Item
                    className="createnews-editor"
                    validateStatus={
                      createReport.errors.description.length > 0
                        ? "error"
                        : "success"
                    }
                    help={createReport.errors.description}
                    valuePropName="description"
                  >
                    <CKEditor
                      editor={ClassicEditor}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDescription(data);
                      }}
                      data={description}
                      config={{
                        toolbar: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "link",
                          "bulletedList",
                          "numberedList",
                          "blockQuote",
                        ],
                        heading: {
                          options: [
                            {
                              model: "paragraph",
                              title: "Paragraph",
                              class: "ck-heading_paragraph",
                            },
                            {
                              model: "heading1",
                              view: "h1",
                              title: "Heading 1",
                              class: "ck-heading_heading1",
                            },
                            {
                              model: "heading2",
                              view: "h2",
                              title: "Heading 2",
                              class: "ck-heading_heading2",
                            },
                          ],
                        },
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Row>
                <Form.Item>
                  <Checkbox
                    checked={important}
                    value={important}
                    onChange={(e) => setImportant(e.target.checked)}
                  >
                    Importante
                  </Checkbox>
                </Form.Item>
              </Row>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={24} lg={24} className="register-btn-mask">
                <Form.Item>
                  <Button
                    type="primary"
                    className="validate-btn"
                    onClick={_submitHandler}
                  >
                    Valider
                  </Button>
                  <Button
                    className="cancel-btn"
                    onClick={() => props.history.push("/dashboard/viewnews")}
                  >
                    Annuler
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default EditNews;
