import globals from "../helpers/globals";
const axios = require("axios").default;
const URL = globals.domain;

const createCalendarAPI = (data) => {
  const token = localStorage.getItem("token")
  return axios
    .create({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .post(`${URL}/api/v1/calendar`, data)
}

const getCalendarsAPI = (params) => {
  const token = localStorage.getItem("token")
  return axios
    .create({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .get(`${URL}/api/v1/calendar`, {params})
}

const getCalendarByIdAPI = (id) => {
  const token = localStorage.getItem("token")
  return axios
    .create({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .get(`${URL}/api/v1/calendar/getOneCalendar/${id}`)
}

const updateCalendarByIdAPI = (id, data) => {
  const token = localStorage.getItem("token")
  return axios
    .create({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .patch(`${URL}/api/v1/calendar/getOneCalendar/${id}`, data)
}

const getAdminCalendarAPI = (params) => {
  const token = localStorage.getItem("token")
  return axios
    .create({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .get(`${URL}/api/v1/calendar/getAllAbsences`, {params})
}

export const calendarServices = {
  createCalendarAPI,
  getCalendarsAPI,
  getCalendarByIdAPI,
  updateCalendarByIdAPI,
  getAdminCalendarAPI
}