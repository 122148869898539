import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Row, Col } from "antd";
import {
  AssessmentOutlined,
  AssignmentOutlined,
  ErrorOutlineOutlined,
  Add,
  DescriptionOutlined
} from "@mui/icons-material";

import styles from "../styles.module.scss";
import { RoutesDashboard } from "../../../helpers/routes";

function BailifHeader() {
  const isMobile = useMediaQuery({ maxWidth: 766 });
  const isSmallMobile = useMediaQuery({ maxWidth: 346 });

  return (
    <div className="topfour-coloum commontopfourwrapper bailiffdas-bord-mask" style={{ width: "100%"}}>
      <Row gutter={[24, 24]}>
        <Col className="gutter-row" xs={24} md={12} lg={6}>
          <div className="dashboard-top-two">
            <Link to={RoutesDashboard.reportsInProgessMonthBailiff}>
              <Row
                gutter={[2, 24]}
                align="middle"
                justify="space-between"
                style={{
                  paddingBottom: "5px",
                  height: "100%",
                  width: "100%",
                  minBlockSize: `${isMobile ? "57px" : "80px"}`,
                }}
              >
                <Col
                  className="gutter-row"
                  xs={4}
                  md={8}
                  lg={7}
                  style={{ height: "100%" }}
                >
                  <Row align="middle" style={{ height: "100%" }}>
                    <AssessmentOutlined
                      sx={{ color: "#2d6186", fontSize: 50 }}
                    />
                  </Row>
                </Col>
                <Col
                  className="gutter-row"
                  xs={20}
                  md={16}
                  lg={17}
                  style={{ height: "100%" }}
                >
                  <Row align="middle" style={{ height: "100%" }}>
                    <p style={{ fontSize: "15px", fontWeight: 500 }}>
                      MES CONSTATS EN COURS
                    </p>
                  </Row>
                </Col>
              </Row>
            </Link>
          </div>
        </Col>
        <Col className="gutter-row" xs={24} md={12} lg={6}>
          <div className="dashboard-top-two">
            <Link to={RoutesDashboard.reportsArchivedMonthBailiff}>
              <Row
                gutter={[2, 24]}
                align="middle"
                justify="space-between"
                style={{
                  paddingBottom: "5px",
                  height: "100%",
                  width: "100%",
                  minBlockSize: `${isMobile ? "57px" : "80px"}`,
                }}
              >
                <Col
                  className="gutter-row"
                  xs={4}
                  md={8}
                  lg={7}
                  style={{ height: "100%" }}
                >
                  <Row align="middle" style={{ height: "100%" }}>
                    <AssignmentOutlined
                      sx={{ color: "#019bcd", fontSize: 50 }}
                    />
                  </Row>
                </Col>
                <Col
                  className="gutter-row"
                  xs={20}
                  md={16}
                  lg={17}
                  style={{ height: "100%" }}
                >
                  <Row align="middle" style={{ height: "100%" }}>
                    <p style={{ fontSize: "15px", fontWeight: 500 }}>
                      MES CONSTATS ARCHIVÉS
                    </p>
                  </Row>
                </Col>
              </Row>
            </Link>
          </div>
        </Col>
        <Col className="gutter-row" xs={24} md={12} lg={6}>
          <div className="dashboard-top-one">
            <Link to={RoutesDashboard.createReportBailiff}>
              <Row
                gutter={[2, 24]}
                align="middle"
                justify="space-between"
                style={{
                  paddingBottom: "5px",
                  height: "100%",
                  width: "100%",
                  minBlockSize: `${isMobile ? "57px" : "80px"}`,
                }}
              >
                <Col
                  className="gutter-row"
                  xs={4}
                  md={8}
                  lg={7}
                  style={{ height: "100%" }}
                >
                  <Row align="middle" style={{ height: "100%" }}>
                    <Add sx={{ color: "#a9709f", fontSize: 50 }} />
                  </Row>
                </Col>
                <Col
                  className="gutter-row"
                  xs={20}
                  md={16}
                  lg={17}
                  style={{ height: "100%" }}
                >
                  <Row align="middle" style={{ height: "100%" }}>
                    <p style={{ fontSize: "15px", fontWeight: 500 }}>
                      CRÉER CONSTAT
                    </p>
                  </Row>
                </Col>
              </Row>
            </Link>
          </div>
        </Col>
        <Col className="gutter-row" xs={24} md={12} lg={6}>
          <div className="dashboard-top-three">
            <Link to={RoutesDashboard.billsMonthBailiff}>
              <Row
                gutter={[2, 24]}
                align="middle"
                justify="space-between"
                style={{
                  paddingBottom: "5px",
                  height: "100%",
                  width: "100%",
                  minBlockSize: `${isMobile ? "57px" : "80px"}`,
                }}
              >
                <Col
                  className="gutter-row"
                  xs={4}
                  md={8}
                  lg={7}
                  style={{ height: "100%" }}
                >
                  <Row align="middle" style={{ height: "100%" }}>
                    <DescriptionOutlined sx={{ color: "#715190", fontSize: 50 }} />
                  </Row>
                </Col>
                <Col
                  className="gutter-row"
                  xs={20}
                  md={16}
                  lg={17}
                  style={{ height: "100%" }}
                >
                  <Row align="middle" style={{ height: "100%" }}>
                    <p style={{ fontSize: "15px", fontWeight: 500 }}>
                      MES FACTURES
                    </p>
                  </Row>
                </Col>
              </Row>
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default BailifHeader;
