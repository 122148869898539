import React, { useEffect, useState } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import {
  ConfigProvider,
  Layout,
  Alert,
  Menu,
  Dropdown,
  Space,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Breadcrumb,
  Button,
  Upload,
  message,
  InputNumber,
} from "antd";
import {
  CheckCircleOutlined,
  CloudUploadOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector, useDispatch } from "react-redux";
import { setPhoneLengthValidation } from "../../../redux/auth/actions";
import { calendarServices } from "../../../_services/calendarServices";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import frFR from "antd/lib/locale/fr_FR";
import "moment/locale/fr-ch";
import styles from "../styles.module.scss";
import { BASE_URL } from "../../../helpers/api";
// import Leftmenu from '../leftmenu';
import Topmenu from "../topmenu";
import { RoutesDashboard } from "../../../helpers/routes";

moment.locale("fr");

const menu = (
  <Menu>
    <Menu.Item>Huissier</Menu.Item>
    <Menu.Item>Secrétaire</Menu.Item>
    <Menu.Item>Admin</Menu.Item>
  </Menu>
);
const { Dragger } = Upload;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
const props = {
  name: "file",
  multiple: false,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} fichier téléchargé avec succès.`);
    } else if (status === "error") {
      message.error(`${info.file.name} le téléchargement du fichier a échoué.`);
    }
  },
};

//const { Content } = Layout;
const { Header, Footer, Sider, Content } = Layout;
const { Option } = Select;

const handleChange = () => {
  console.log(`selected`);
};

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Vous ne pouvez télécharger que des fichiers JPG / PNG!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("L'image doit être inférieure à 2 Mo!");
  }
  return isJpgOrPng && isLt2M;
}

function SecretaryCalendar() {
  const imguploadicon = require("../../../pages/images/uploadimage-icon.svg");
  const [events, setEvents]: any = useState([]);
  const [imageUrl, setImage] = useState(
    require("../../../pages/images/uploadimage.svg")
  );
  const { isPhoneLength } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const localizer = momentLocalizer(moment);
  //const [imageUrl, setImage] = useState('https://www.kindpng.com/picc/m/163-1636340_user-avatar-icon-avatar-transparent-user-icon-png.png');
  const uploadButton = (
    <div>
      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const handleChangeImage = (info) => {
    if (info.file.status === "uploading") {
      // this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (imageUrl) => {
          setImage(imageUrl);
        }

        // this.setState({
        //   imageUrl,
        //   loading: false,
        // }),
      );
    }
  };
  const onFinish = async (values) => {
    let data = {
      civility: values.civility,
      first_name: values.firstname,
      last_name: values.lastname,
      telephone: values.telephone,
      address: values.address,
      city: values.city,
      role_id: 2,
      profile:
        "http://localhost:8000/public/images/profile-2020-11-05T20:06:46.916Z-gbepvjettqttzqlz_1593258788.jpeg",
      identity: 787877,
      // rib: null,
      email: values.email,
      password: values.confirm_password,
      zip: 564009,
    };
    const postData = Object.keys(data)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`)
      .join("&");
    // return;
    let showNotification = {};
    let modalCloseStatus = 1;
    const response = await fetch(BASE_URL + "/api/v1/auth/register", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded", // <-- Specifying the Content-Type
      }),
      // headers: generalAuthTokenHeader(),
      body: postData,
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Discharge Planner",
            message: "Mauvaise réponse du serveur",
            type: "danger",
          };
        } else if (response.ok) {
        } else {
          showNotification = {
            title: "Discharge Planner",
            message: "Mauvaise réponse du serveur.",
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        if (data.status == "400" && data.message == "error.idexists") {
          // modalCloseStatus = 0;
          // showNotification = {
          //     title: "Discharge Planner",
          //     message: data.title,
          //     type: 'danger'
          // };
        } else if (data.user) {
          // showNotification = {
          //     title: "Success",
          //     message: "Status updated successfully",
          //     type: "success"
          // };
          message.success("Inscrivez-vous avec succès.");
          return true;
        }
      })
      .catch((error) => {
        // showNotification = {
        //     title: 'Discharge Planner',
        //     message: 'Something went wrong.Please try after sometime..',
        //     type: "danger"
        // };
      });
  };

  // const onSelect = value => {
  //   useState({
  //     value,
  //     selectedValue: value,
  //   });
  // };

  // const onPanelChange = value => {
  //   useState({ value });
  // };
  //  const handleNumberChange = value => {
  // 	const validateStatus = value.length === 10;
  // 	this.setState({
  // 	  validateStatus,
  // 	  value,
  // 	  errorMsg: isValid ? null : 'Invalid Mobile number'
  // 	});
  //   };
  function validateMobileNumber(value) {
    // const{ form } = this.props;
    // let mnumber = form.getFieldValue('phone');
    if (value !== "") {
      console.log("this is a mobil validator", value, value.toString().length);
    }

    if (value.toString().length === 10) {
      dispatch(setPhoneLengthValidation(null));
      console.log("valid mobile nuumber");
    } else {
      console.log("invalid mobile nuumber");
      dispatch(
        setPhoneLengthValidation(
          "Le téléphone doit être composé de 10 chiffres"
        )
      );
    }
  }

  const _getCalendarDates = async () => {
    await calendarServices
      .getCalendarsAPI()
      .then((response) => response.data)
      .then((response: any) => {
        let datesToCompare: any = [];
        response.forEach((date) => {
          let newEvent: any = {
            start: moment(date.start_date, "YYYY-MM-DD").toDate(),
            end: moment(date.end_date, "YYYY-MM-DD").toDate(),
            title: date.comment,
          };
          datesToCompare.push(newEvent);
          setEvents(...events, datesToCompare);
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    _getCalendarDates();
    // console.log('month state variable', month)
  }, []);

  return (
    //   <Table
    //   columns={columns}
    //   expandable={{
    //     expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
    //     rowExpandable: record => record.date !== 'Not Expandable',
    //   }}
    //   dataSource={data}
    //   className="constats-table"
    // />
    <Layout className="creer-constat-wrapper noleftmenu_section">
      <Topmenu />
      <Row>
        <Col xs={24} md={24} lg={24}>
          <div className="dashboard-right-section profile_bailiff_wrapper secaritertcal-mask">
            <div className="form_outer_wrapper">
              <div className="onlyscreen_template_inner">
                <div className="onlyscreen_template_wrapper">
                  <div className="form_outer_onlyscreenwrapper">
                    <div className="form_onlyscreenwrapper">
                      <div className="userprofile-layout-wrapper">
                        <Layout>
                          <Sider>
                            <Menu
                              defaultSelectedKeys={["3"]}
                              defaultOpenKeys={["sub3"]}
                              mode="inline"
                              theme="light"
                            >
                              <Menu.Item>
                                <Link to="/dashboard/">Accueil</Link>
                              </Menu.Item>
                              <Menu.Item>
                                <Link to="/dashboard/secretaryprofile">
                                  Mon Profil
                                </Link>
                              </Menu.Item>
                              <Menu.Item>
                                <Link to={RoutesDashboard.billsMonthSecretary}>
                                  Mes Factures
                                </Link>
                              </Menu.Item>
                              <Menu.Item key="3">
                                <Link to="/dashboard/secretarycalendar">
                                  Calendrier
                                </Link>
                              </Menu.Item>
                            </Menu>
                          </Sider>
                          <Layout>
                            <Header>
                              <h2>MON PROFIL</h2>
                            </Header>
                            <Content style={{ marginLeft: 0 }}>
                              <div className="nouvellecalender-wrapper">
                                {/*<div className="nouvellecalender">
                                  <Link to="/dashboard/createanalert">Signaler une absence ou un congé</Link>
                                </div>*/}
                                <Link to="/dashboard/createanalert">
                                  <Button className="nouvellecalender">
                                    Signaler une absence ou un congé
                                  </Button>
                                </Link>
                              </div>
                              <ConfigProvider locale={frFR}>
                                <div className="content-section-wrapper">
                                  {/* <Alert
                                    message={`You selected date: ${selectedValue && selectedValue.format('YYYY-MM-DD')}`}
                                  /> */}
                                  <div className="indisponibilité-link-wrapper">
                                    <div className="indisponibilité-link">
                                      Indisponibilité
                                    </div>
                                  </div>
                                  <Calendar
                                    localizer={localizer}
                                    defaultDate={new Date()}
                                    defaultView="month"
                                    events={events}
                                    style={{ height: "100vh" }}
                                    eventPropGetter={(event) => ({
                                      style: {
                                        backgroundColor: "#FFB8B8",
                                        height: "100vh",
                                      },
                                    })}
                                    messages={{
                                      next: "Suivant",
                                      previous: "Précédent",
                                      today: "Aujourd'hui",
                                      month: "Mois",
                                      week: "Semaine",
                                      day: "Jour",
                                      agenda: "Calendrier",
                                      noEventsInRange:
                                        "Il n'y a aucun événement dans le calendrier.",
                                    }}
                                  />
                                </div>
                              </ConfigProvider>
                            </Content>
                          </Layout>
                        </Layout>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default SecretaryCalendar;
