import React, { useState } from "react"
import {Link} from 'react-router-dom'
import {
  Layout,
  Input,
  Row,
  Col,
  Form,
  Select,
  Breadcrumb,
  Button,
  Upload,
  message,
} from "antd"
import { UserOutlined } from "@ant-design/icons"
import { filesUploadServices } from "../../../_services/uploadFIles"
import { usersService } from "../../../_services/usersSerives"
import { ISendUserDataI } from "../../../interfaces/users.interface"
import { useSelector } from "react-redux"
import FileUtils from "../../../utils/files"

const validPassword = RegExp(
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
)
const validEmailRegex = RegExp(
  /^(([^<>()\\[\]\\.,;:\s@\\"]+(\.[^<>()\\[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i
)

const validIdentify = RegExp(/^[A-Za-z0-9]{3,}$/)
const { Content } = Layout
const { Option } = Select

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!")
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!")
  }
  return isJpgOrPng && isLt2M
}

function AdminAccount({ injectedForm, isDoneEdit, ...props }) {
  // const [adminForm, setAdminForm] = useState({
  //   civility: injectedForm?.civility || "",
  //   last_name: injectedForm?.last_name || "",
  //   first_name: injectedForm?.first_name || "",
  //   phone: injectedForm?.telephone || "",
  //   email: injectedForm?.email || "",
  //   password: injectedForm?.password || "",
  //   confirmPassword: "",
  //   userID: injectedForm?.identity || "",
  // })
  // const { user } = useSelector((state: any) => state.auth)

  const { user } = useSelector((state: any) => state.auth)
  const [adminForm, setAdminForm] = useState({
    civility: injectedForm?.civility || "",
    last_name: injectedForm?.last_name || "",
    first_name: injectedForm?.first_name || "",
    phone: injectedForm?.telephone || "",
    email: injectedForm?.email || "",
    password: injectedForm?.password || "",
    confirmPassword: "",
    userId: injectedForm?.identity || "",
    errors: {
      civility: "",
      last_name: "",
      first_name: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
      userId: "",
    },
  })

  const notFilledPasswordField = () => {
    let errors = adminForm.errors
    if (adminForm.civility === "") {
      errors.civility = "Champ requis"
    }
    if (adminForm.last_name === "") {
      errors.last_name = "Champ requis"
    }
    if (adminForm.first_name === "") {
      errors.first_name = "Champ requis"
    }
    if (adminForm.phone === "") {
      errors.phone = "Champ requis"
    }
    if (adminForm.email === "") {
      errors.email = "Champ requis"
    }
    if (adminForm.password === "") {
      errors.password = "Champ requis"
    }
    if (adminForm.confirmPassword === "") {
      errors.confirmPassword = "Champ requis"
    }
    if (adminForm.userId === "") {
      errors.userId = "Champ requis"
    }
    setAdminForm({ ...adminForm, ...{ errors } })
  }

  const validateForm = (formFields) => {
    notFilledPasswordField()
    let valid = true
    Object.values(formFields.errors).forEach(
      (val: any) => val.length > 0 && (valid = false)
    )
    return valid
  }

  const imguploadicon = require("../../../pages/images/uploadimage-icon.svg")
  const [imageUrl, setImage] = useState(
   injectedForm?.profile || require("../../../pages/images/uploadimage.svg")
  )
        

  const uploadButton = (
    <div>
      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )
  const [fileName, setFileName] = useState('')
    const handleChangeImage = async (info) => {
        const dataFileName = (info.file.name)
        setFileName(dataFileName)
    if (info.file.status === "done") {
      let data = new FormData()
      data.append("profile", info.file.originFileObj)
      await filesUploadServices
        .uploadFileAPI(data)
        .then((response) => {
          setImage(response.data.url)
        })
        .catch((err) => console.error(err))
        }
    }
    
    let dataImg;
    let dataAdmin = injectedForm?.profile
    let url = '/static/media/uploadimage.aeda1424.svg'
        
        if(dataAdmin !== undefined || dataAdmin !== url){
            dataImg = dataAdmin
        }
        if (dataAdmin === url){
            dataImg = fileName
        }

        else if (fileName != ""){
            dataImg = fileName
        }


  // const _changeHandler = (event, name) => {
  //   setAdminForm({
  //     ...adminForm,
  //     ...{ [name]: event.target.value },
  //   })
  // }
        
    let[selectCivitity, setSelectCivility] = useState('')

  const _submitHandler = async () => {
    if (validateForm(adminForm)) {
      let data: any = {}
      data["role_id"] = "1"
      selectCivitity = adminForm.civility
      data["civility"] = adminForm.civility
      data["last_name"] = adminForm.last_name
      data["first_name"] = adminForm.first_name
      data["telephone"] = adminForm.phone
      // data["email"] = adminForm.email
      if (adminForm.password !== "") {
        data["password"] = adminForm.password
      }
      data["profile"] = imageUrl
      data["identity"] = adminForm.userId

      if (injectedForm) {
        await usersService
          .updateUsersAPI(injectedForm?.id, data)
          .then((response) => response.data)
          .then((response: ISendUserDataI) => {
            message.success("Compte modifié avec succès.")
            isDoneEdit()
          })
          .catch((err) => {
            console.error(err)
            message.error(" Tous les champs obligatoires ne sont pas remplis.")
          })

      } else {
        data["email"] = adminForm.email
        data["created_by"] = user.id
        await usersService
          .createUsersAPI(data)
          .then((response) => response.data)
          .then((response: ISendUserDataI) => {
            message.success("Compte créé avec succès")
            props.history.push("/dashboard/seetheaccount")
          })
          .catch((err) => {
            message.error("Adresse e-mail déjà prise")
            console.error(err)
          })
      }
    }else{
      message.error(" Tous les champs obligatoires ne sont pas remplis.")
    }
  }
    const  handleChange_civility = (e) => {
        adminForm.civility = (e)
        setSelectCivility(adminForm.civility)
    }

  const _changeHandler = (event, name) => {
    let errors = adminForm.errors

    const { value } = event.target
    switch (name) {
      case "civility":
        errors.civility =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères"
        break
      case "last_name":
        errors.last_name =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères"
        break
      case "first_name":
        errors.first_name =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères"
        break
      case "phone":
        errors.phone = value.length === 9 ? "" : "Numéro de téléphone invalide."
        break
      case "email":
        errors.email = validEmailRegex.test(value)
          ? ""
          : "Identifiant de messagerie invalide."
        break

      case "password":
        errors.password = validPassword.test(value) ? "" : "Le mot de passe doit comporter des majuscules, des minuscules, des chiffres et un symbole."
        break
      case "confirmPassword":
        errors.confirmPassword =
          adminForm.password == value ? "" : "Mot de passe incorrect."
        break
      case "userId":
        errors.userId = validIdentify.test(value)
          ? ""
          : "Doit contenir au moins 3 caractères"
        break
      default:
        break
    }

    setAdminForm({
      ...adminForm,
      ...{ errors, [name]: value },
    })
  }

  const handleChange = (e) => {
    switch (e) {
      case "huissier":
        props.history.push("/dashboard/bailiffaccount")
        break
      case "admin":
        props.history.push("/dashboard/adminaccount")
        break
      case "secrétaire":
        props.history.push("/dashboard/secretary")
        break
    }
  }
  
  return (
    <div className="dashboard-right-section">
      <Content style={{ marginLeft: "0px" }}>
        <Breadcrumb>
          <Breadcrumb.Item>Comptes</Breadcrumb.Item>
          <Breadcrumb.Item>Créer un compte</Breadcrumb.Item>
        </Breadcrumb>
      </Content>
      <div className="form_outer_wrapper">
        <h2>
          <UserOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          CONSTAT EN COURS
        </h2>
        <Form className="create_account adminaccount_form">
          <Row gutter={16}>
             
              <div className="profile_uploadimg_wrapper profileimgresponsive">
              <Col xs={24} md={24} lg={4}>
                <Form.Item>
                  <Upload
                    name="avatar"
                    showUploadList={false}
                    listType="picture-card"
                    className="avatar-uploader"
                    customRequest={FileUtils.generalDummyRequestLocal}
                    onChange={handleChangeImage}
                  >
                    <div className="uploadimg-wrapper">
                      {imageUrl ? (
                        <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
                      ) : (
                        uploadButton
                      )}
                        {fileName ? (
                            <div></div>
                        ) :(
                            <div className="uploadimg-icons">
                                <img src={imguploadicon} alt="avatar-icon" />
                            </div>
                        )}
                        </div>
                  </Upload>
                </Form.Item>
                </Col>
              </div>
             
            <Col xs={24} md={24} lg={12}>
              <Form.Item label="Type de compte">
                <Select
                  defaultValue="admin"
                  className="select-user-type inputfield-full"
                  onChange={handleChange}
                  disabled={injectedForm ? true : false}
                >
                  <Option value="huissier">Huissier</Option>
                  <Option value="secrétaire">Secrétaire</Option>
                  <Option value="admin">Admin</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={4}>
              <div className="profile_uploadimg_wrapper profileimgweb">
                <Form.Item>
                  <Upload
                    name="avatar"
                    showUploadList={false}
                    listType="picture-card"
                    className="avatar-uploader"
                    customRequest={FileUtils.generalDummyRequestLocal}
                    onChange={handleChangeImage}
                  >
                    <div className="uploadimg-wrapper">
                        {imageUrl ? (
                            
                          <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
                          
                      ) : (
                        uploadButton
                      )}
                        {dataImg ? (
                            <div></div>
                        ) : (
                            <div className="uploadimg-icons">
                                <img src={imguploadicon} alt="avatar-icon" />
                            </div>
                        )}

                        </div>
                  </Upload>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Civilité"
                valuePropName="civility"
                validateStatus={
                  adminForm.errors.civility.length > 0 ? "error" : "success"
                }
                help={adminForm.errors.civility}
              >
                  {/* <Input
                  placeholder="Civilité"
                  className="inputfield-full"
                  value={adminForm.civility || ""}
                  onChange={(e) => _changeHandler(e, "civility")}
                /> */}

                <Select
                  defaultValue={adminForm.civility == '' ? `${adminForm.civility}` : "Monsieur"}
                  onChange={handleChange_civility}
                >
                  <Option value="Monsieur">Monsieur</Option>
                  <Option value="Madame">Madame</Option>
                  <Option value="Maître">Maître</Option>
                </Select>
            
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Nom"
                valuePropName="last_name"
                validateStatus={
                  adminForm.errors.last_name.length > 0 ? "error" : "success"
                }
                help={adminForm.errors.last_name}
              >
                <Input
                  placeholder="Nom"
                  className="inputfield-full"
                  value={adminForm.last_name || ""}
                  onChange={(e) => _changeHandler(e, "last_name")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Prénom"
                valuePropName="first_name"
                validateStatus={
                  adminForm.errors.first_name.length > 0 ? "error" : "success"
                }
                help={adminForm.errors.first_name}
              >
                <Input
                  placeholder="Prénom"
                  className="inputfield-full"
                  value={adminForm.first_name || ""}
                  onChange={(e) => _changeHandler(e, "first_name")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Téléphone"
                valuePropName="phone"
                validateStatus={
                  adminForm.errors.phone.length > 0 ? "error" : "success"
                }
                help={adminForm.errors.phone}
              >
                <Input
                  placeholder="Téléphone"
                  className="inputfield-full"
                  value={adminForm.phone || ""}
                  onChange={(e) => _changeHandler(e, "phone")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Email"
                valuePropName="email"
                validateStatus={
                  adminForm.errors.email.length > 0 ? "error" : "success"
                }
                help={adminForm.errors.email}
              >
                <Input
                  placeholder="Email"
                  className="inputfield-full"
                  value={adminForm.email || ""}
                  onChange={(e) => _changeHandler(e, "email")}
                  disabled={injectedForm ? true : false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Identifiant"
                valuePropName="userId"
                validateStatus={
                  adminForm.errors.userId.length > 0 ? "error" : "success"
                }
                help={adminForm.errors.userId}
              >
                <Input
                  placeholder="Identifiant"
                  className="inputfield-full"
                  value={adminForm.userId || ""}
                  onChange={(e) => _changeHandler(e, "userId")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                className="passwordbottom"
                label="Mot de passe"
                name="password"
                rules={[{ required: true, message: "Please Mot de passe" }]}
              >
                <Input.Group>
                  <Row gutter={8}>
                    <Col span={24}>
                      <Form.Item
                        name="password"
                        valuePropName="password"
                        validateStatus={
                          adminForm.errors.password.length > 0 ? "error" : "success"
                        }
                        help={adminForm.errors.password}
                        // style={{ display: "inline-block" }}
                      >
                        <Input.Password
                          placeholder="Mot de passe"
                          className="inputfield-full"
                          value={adminForm.password || ""}
                          onChange={(e) => _changeHandler(e, "password")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Confirmer mot de passe"
                name="conpassword"
                rules={[
                  { required: true, message: "Please Confirmer mot de passe" },
                ]}
              >
                <Input.Group>
                  <Row gutter={8}>
                    <Col span={24}>
                      {/* <Input.Password  defaultValue=""/> */}
                      <Form.Item
                        name="confirmPassword"
                        valuePropName="confirmPassword"
                        validateStatus={
                          adminForm.errors.confirmPassword.length > 0
                            ? "error"
                            : "success"
                        }
                        help={adminForm.errors.confirmPassword}
                        // style={{ display: "inline-block" }}
                      >
                        <Input.Password
                          placeholder="Confirmer mot de passe"
                          className="inputfield-full"
                          value={adminForm.confirmPassword || ""}
                          onChange={(e) => _changeHandler(e, "confirmPassword")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12} className="register-btn-mask">
              <Form.Item label="  ">
                <Button
                  type="primary"
                  className="validate-btn"
                  onClick={_submitHandler}
                >
                  Valider
              </Button>

                <Link to="/dashboard/seetheaccount">
                    <Button
                        className="cancel-btn"
                        onClick={isDoneEdit}
                        >
                        Annuler
                    </Button>
                </Link>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}

export default AdminAccount
