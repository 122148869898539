import React, { useEffect, useState } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import {
  Layout,
  Table,
  Input,
  Badge,
  Menu,
  Dropdown,
  Space,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Breadcrumb,
  Button,
  Modal,
  Empty,
} from "antd";
import { AlignType } from 'rc-table/lib/interface'
import {
  VerticalAlignBottomOutlined,
  DownloadOutlined,
  PlusOutlined,
  CaretRightOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import styles from "../styles.module.scss";

import Leftmenu from "../leftmenu";
import Topmenu from "../topmenu";
import BailiffHeader from "../../components/bailiffHeader";
import moment from "moment";
import download from "downloadjs";
import domtoimage from "dom-to-image";
import Commonbailiffheader from "../commonbailiffheader";
import { facturesServices } from "../../../_services/facturesServices";
import { useDispatch, useSelector } from "react-redux";
import { resetReportState } from "../../../redux/report";
import { getAllUsers } from "../../../redux/user";
import { getAllBillsBailiff } from "../../../redux/billing";
import { saveAs } from "file-saver";
import ExpandedRowConstat from "../../../components/ExpandedRowConstat/ExpandedRowConstat";
import ModalDocument from "../../../components/ModalDocument/ModalDocument";
import ModalArchiveConstat from "../../../components/ModalArchiveConstat/ModalArchiveConstat";
import { getArrayYearsTwoDates } from "../../../utils/functions/moment";

const { Content } = Layout;
const { Option } = Select;

function BillsMonthBailiff({ history }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.auth);
  const { billsBailiff } = useSelector((state: any) => state.billing);
  const { users } = useSelector((state: any) => state.user);
  const [billsFiltered, setBillsFiltered]: any = useState([]);
  const months = getArrayYearsTwoDates(moment("2019"), moment())
  const [filters, setFilters] = useState({
    pageSize: 10,
    year: moment().format("YYYY"),
  });
  const [modalViewDocument, setModalViewDocument] = useState({
    visible: false,
    data: {},
  });

  useEffect(() => {
    dispatch(resetReportState());
    dispatch(getAllUsers());
    dispatch(
      getAllBillsBailiff({
        group_id: user?.group_id?.toString(),
        bailiff_id: user.id,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (billsBailiff?.length > 0) {
      let billsFiltered = billsBailiff.filter((bill) => {
        const reports = bill.reports.filter((report) => {
          const date = moment(report.last_status_change);
          return date.format("YYYY") === filters.year;
        });

        return reports.length > 0;
      });

      billsFiltered = billsFiltered.map((bill) => {
        const total_count_pages = bill.reports.reduce(
          (acc: any, report: any) => acc + report.count_pages_billing,
          0
        );
        const total_count_photos = bill.reports.reduce(
          (acc: any, report: any) => acc + report.count_photos_billing,
          0
        );

        const reports = bill.reports.map((report: any) => {
          return {
            ...report,
            amount_without_taxes_pages:
              (
                report.count_pages_billing - Math.round(report.count_photos_billing / 2)
              ) *
              bill.group_price_page *
              (report.discount > 0 ? report.discount / 100 : 1),
            amount_without_taxes_photos:
              report.count_photos_billing *
              bill.group_price_photo *
              (report.discount > 0 ? report.discount / 100 : 1),
            bailiff: users.find((user: any) => user.id === report.bailiff_id),
            secretary: users.find(
              (user: any) => user.id === report.secretary_id
            ),
          };
        });

        return {
          ...bill,
          total_count_pages: total_count_pages,
          total_count_photos: total_count_photos,
          amount_without_taxes:
            (bill.discount > 0
              ? total_count_pages *
              bill.group_price_page *
              (bill.discount / 100)
              : total_count_pages * bill.group_price_page) +
            (bill.discount > 0
              ? total_count_photos *
              bill.group_price_photo *
              (bill.discount / 100)
              : total_count_photos * bill.group_price_photo),
          reports: reports,
        };
      });

      setBillsFiltered(billsFiltered);
    }
  }, [billsBailiff, filters, users]);

  const columns = [
    {
      title: "Mois",
      key: "date",
      render: (report) => {
        const month = moment(report.createdAt).format("MMMM");
        return <span>{month.charAt(0).toUpperCase() + month.slice(1)}</span>;
      },
    },
    {
      title: "Nombre pages",
      key: "nombre_pages",
      align: "left" as AlignType,
      render: (bill: any) => {
        console.log(bill);
        return <span>{bill?.total_count_pages}</span>;
      },
    },
    {
      title: "Nombre photos",
      key: "nombre_photos",
      align: "left" as AlignType,
      render: (bill: any) => {
        return <span>{bill?.total_count_photos}</span>;
      },
    },
    {
      title: "Montant HT",
      key: "amount",
      width: "5%",
      render: (report) => report.amount_without_taxes.toFixed(2) + "€",
    },
    {
      title: "Voir",
      key: "view",
      render: (bill) => {
        const enabled = bill?.files?.length > 0 ? true : false;
        return (
          <Space size="middle">
            <EyeOutlined
              style={enabled ? { opacity: 1 } : { opacity: 0.3 }}
              onClick={(event) => {
                event.stopPropagation();
                if (!enabled) return;

                setModalViewDocument({
                  ...modalViewDocument,
                  visible: true,
                  data: {
                    link: bill.files[0].linkUrl,
                  },
                });
              }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </Space>
        );
      },
    },
    {
      title: "Télécharger",
      key: "download",
      align: "left" as AlignType,
      render: (bill) => {
        const enabled = bill?.files?.length > 0 ? true : false;
        return (
          <Space>
            <VerticalAlignBottomOutlined
              style={enabled ? { opacity: 1 } : { opacity: 0.3 }}
              onClick={(event) => {
                event.stopPropagation();
                if (!enabled) return;

                saveAs(bill.files[0].linkUrl, bill.files[0].originalname);
              }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </Space>
        );
      },
    },
  ];

  return (
    <Layout className="creer-constat-wrapper noleftmenu_section">
      <ModalDocument
        visible={modalViewDocument.visible}
        data={modalViewDocument.data}
        onClose={() => setModalViewDocument({ visible: false, data: {} })}
      />
      <Topmenu />
      <div className="mesconstats-table">
        <Row>
          <Col xs={24} md={24} lg={24}>
            <div className="dashboard-right-section mybillsgailif_wrapper dashboard-bailiff">
              <div className="form_outer_wrapper billsgailif_wrapper">
                <Commonbailiffheader />
                <div className="form_tableouter_wrapper">
                  <h2>MES FACTURES</h2>
                  <Row gutter={[3, 24]}>
                    <Col className="gutter-row" xs={24} md={5} lg={5}>
                      <Row>
                        <Col className="gutter-row" xs={4} md={4} lg={6}>
                          <Select
                            defaultValue="10"
                            className="table-rows-select"
                            onChange={(value: any) =>
                              setFilters({
                                ...filters,
                                pageSize: value,
                              })
                            }
                          >
                            <Option value="10">10</Option>
                            <Option value="20">20</Option>
                            <Option value="30">30</Option>
                            <Option value="40">40</Option>
                            <Option value="50">50</Option>
                          </Select>
                        </Col>
                        <Col
                          id="yeardropdown"
                          className="gutter-row"
                          xs={8}
                          md={4}
                          lg={8}
                        >
                          <Select
                            defaultValue={filters.year}
                            className="table-rows-select"
                            onChange={(value: any) =>
                              setFilters({
                                ...filters,
                                year: value,
                              })
                            }
                          >
                            <Option value="">Année</Option>
                            {
                              Object.values(months).map((value) => {
                                return (
                                  <Option value={value.toString()}>{value.toString()}</Option>
                                )
                              })
                            }
                          </Select>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div style={{ marginTop: "2%" }}>
                    <Table
                      columns={columns}
                      className="constatsencours-tables manageiconforalign"
                      rowKey={(record) => record.id}
                      dataSource={billsFiltered || []}
                      pagination={{
                        pageSize: filters.pageSize,
                      }}
                      locale={{
                        emptyText: (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="Aucun constat."
                          />
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default BillsMonthBailiff;
