import React, { useState } from "react"
import { Link, Route, Switch, useHistory } from "react-router-dom"
import {
  Layout,
  Table,
  Input,
  Badge,
  Menu,
  Dropdown,
  Space,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Breadcrumb,
  Button,
  Upload,
  message,
} from "antd"
import {
  UserOutlined,
  DownOutlined,
  InboxOutlined,
  CloudUploadOutlined,
  PlusOutlined,
  EuroCircleOutlined,
} from "@ant-design/icons"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import styles from "../styles.module.scss"

import Leftmenu from "../leftmenu"
import Topmenu from "../topmenu"
import { useSelector, useDispatch } from "react-redux"
import { BASE_URL } from "../../../helpers/api"
import { filesUploadServices } from "../../../_services/uploadFIles"
import { ISendUserDataI } from "../../../interfaces/users.interface"
import { usersService } from "../../../_services/usersSerives"
import FileUtils from "../../../utils/files"

const validPassword = RegExp(
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
)
const validEmailRegex = RegExp(
  /^(([^<>()\\[\]\\.,;:\s@\\"]+(\.[^<>()\\[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i
)
const validPhone = RegExp(
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
)
const onlyNoAllowed = RegExp(/^[0-9]*$/)
const validPrice = RegExp(/^\d{1,3}(\.\d{1,3})?$/)
const validIdentify = RegExp(/^[A-Za-z0-9]{3,}$/)

const validZipCode = RegExp(/^\d{5}(?:[-\s]\d{4})?$/)

const menu = (
  <Menu>
    <Menu.Item>Huissier</Menu.Item>
    <Menu.Item>Secrétaire</Menu.Item>
    <Menu.Item>Admin</Menu.Item>
  </Menu>
)
const { Dragger } = Upload

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result))
  reader.readAsDataURL(img)
}
const props = {
  name: "file",
  multiple: false,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file
    if (status !== "uploading") {
      console.log(info.file, info.fileList)
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`)
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`)
    }
  },
}

const { Content } = Layout
const { Option } = Select

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!")
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!")
  }
  return isJpgOrPng && isLt2M
}

function SecretaryAccount(props) {
  const { user } = useSelector((state: any) => state.auth)

  const [createSecretaryForm, setCreateSecrataryForm] = useState({
    civility: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    rib: "",
    address: "",
    city: "",
    zipCode: "",
    userId: "",
    password: "",
    confirm_password: "",
    pricepage: "",
    pricephoto: "",
    errors: {
      civility: "",
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      rib: "",
      address: "",
      city: "",
      zipCode: "",
      userId: "",
      password: "",
      confirm_password: "",
      pricepage: "",
      pricephoto: "",
    },
  })

  const notFilledPasswordField = () => {
    let errors = createSecretaryForm.errors
    if (createSecretaryForm.firstname === "") {
      errors.firstname = "Field is required"
    }
    if (createSecretaryForm.lastname === "") {
      errors.lastname = "Field is required"
    }
    if (createSecretaryForm.phone === "") {
      errors.phone = "Field is required"
    }
    if (createSecretaryForm.email === "") {
      errors.email = "Field is required"
    }
    if (createSecretaryForm.rib === "") {
      errors.rib = "Field is required"
    }
    if (createSecretaryForm.address === "") {
      errors.address = "Field is required"
    }
    if (createSecretaryForm.city === "") {
      errors.city = "Field is required"
    }
    if (createSecretaryForm.zipCode === "") {
      errors.zipCode = "Field is required"
    }
    if (createSecretaryForm.userId === "") {
      errors.userId = "Field is required"
    }
    if (createSecretaryForm.password === "") {
      errors.password = "Field is required"
    }
    if (createSecretaryForm.confirm_password === "") {
      errors.confirm_password = "Field is required"
    }
    if (createSecretaryForm.pricepage === "") {
      errors.pricepage = "Field is required"
    }
    if (createSecretaryForm.pricephoto === "") {
      errors.pricephoto = "Field is required"
    }
    setCreateSecrataryForm({ ...createSecretaryForm, ...{ errors } })
  }

  const validateForm = (formFields) => {
    notFilledPasswordField()
    let valid = true
    Object.values(formFields.errors).forEach(
      (val: any) => val.length > 0 && (valid = false)
    )
    return valid
  }
  const [fileName, setFileName] = useState('')
  const handleChangeImage = async (info) => {
    const dataFileName = (info.file.name)
    setFileName(dataFileName)
    if (info.file.status === "done") {
      let data = new FormData()
      data.append("profile", info.file.originFileObj)
      await filesUploadServices
        .uploadFileAPI(data)
        .then((response) => {
          setImage(response.data.url)
        })
        .catch((err) => console.error(err))
    }
  }

  // const _submitHandler = async () => {
  //   if (validateForm(createSecretaryForm)) {
  //     let data: any = {}
  //     data["role_id"] = "2"
  //     data["firstname"] = createSecretaryForm.firstname
  //     data["lastname"] = createSecretaryForm.lastname
  //     data["phone"] = createSecretaryForm.phone
  //     data["email"] = createSecretaryForm.email
  //     data["rib"] = createSecretaryForm.rib
  //     data["address"] = createSecretaryForm.address
  //     data["city"] = createSecretaryForm.city
  //     data["zipCode"] = createSecretaryForm.zipCode
  //     data["userId"] = createSecretaryForm.userId
  //     if (createSecretaryForm.password !== "") {
  //       data["password"] = createSecretaryForm.password
  //     }
  //     data["profile"] = imageUrl

  //     await usersService
  //       .updateUsersAPI(user?.id, data)
  //       .then((response) => response.data)
  //       .then((response: ISendUserDataI) => {
  //         message.success("Compte modifié avec succès.")
  //       })
  //       .catch((err) => console.error(err))
  //   }
  // }

  const _changeHandler = (event, name) => {
    let errors = createSecretaryForm.errors

    const { value } = event.target
    switch (name) {
      case "firstname":
        errors.firstname =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères"
        break
      case "lastname":
        errors.lastname =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères"
        break
      case "phone":
        errors.phone = validPhone.test(value) ? "" : "Numéro de téléphone invalide."
        break
      case "email":
        errors.email = validEmailRegex.test(value)
          ? ""
          : "Identifiant de messagerie invalide."
        break
      case "rib":
        //errors.rib = onlyNoAllowed.test(value)
          errors.rib =  value.length > 2
          ? ""
          : "Doit contenir au moins 3 caractères"
        break
      case "address":
        errors.address =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères"
        break
      case "city":
        errors.city = value.length > 2 ? "" : "Doit contenir au moins 3 caractères"
        break

      case "zipCode":
        errors.zipCode = validZipCode.test(value)
          ? ""
          : "Doit contenir au moins 5 caractères"
        break

      case "userId":
        errors.userId = validIdentify.test(value)
          ? ""
          : "Numéros autorisés uniquement"
        break
      case "password":
        errors.password = validPassword.test(value) ? "" : "Le mot de passe doit comporter des majuscules, des minuscules, des chiffres et un symbole."
        break
      case "confirm_password":
        errors.confirm_password =
          createSecretaryForm.password === value ? "" : "Mot de passe incorrect."
        break
      case "pricepage":
        errors.pricepage = validPrice.test(value)
          ? ""
          : "Doit contenir au moins 1 caractères"
        break
      case "pricephoto":
        errors.pricephoto = validPrice.test(value)
          ? ""
          : "Doit contenir au moins 1 caractères"
        break
      default:
        break
    }

    setCreateSecrataryForm({
      ...createSecretaryForm,
      ...{ errors, [name]: value },
    })
  }

  const imguploadicon = require("../../../pages/images/uploadimage-icon.svg")
  const [imageUrl, setImage] = useState(
    require("../../../pages/images/uploadimage.svg")
  )


  // const { user } = useSelector((state: any) => state.auth)
  // const handleChange = (e) => {
  //   switch (e) {
  //     case "huissier":
  //       props.history.push("/dashboard/bailiffaccount")
  //       break
  //     case "admin":
  //       props.history.push("/dashboard/adminaccount")
  //       break
  //     case "secrétaire":
  //       props.history.push("/dashboard/secretary")
  //       break
  //   }
  // }
  const uploadButton = (
    <div>
      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  // const handleChangeImage = async (info) => {
  //   if (info.file.status === "done") {
  //     let data = new FormData()
  //     data.append("profile", info.file.originFileObj)
  //     await filesUploadServices
  //       .uploadFileAPI(data)
  //       .then((response) => {
  //         setImage(response.data.url)
  //       })
  //       .catch((err) => console.error(err))
  //   }
  // }

  // const _changeHandler = (event, name) => {
  //   setCreateSecrataryForm({
  //     ...createSecretaryForm,
  //     ...{ [name]: event.target.value },
  //   })
  // }

  const _submitHandler = async () => {
    if (validateForm(createSecretaryForm)) {
      let data: any = {}
      data["role_id"] = "3"
      data["last_name"] = createSecretaryForm.lastname
      data["first_name"] = createSecretaryForm.firstname
      data["telephone"] = createSecretaryForm.phone
      data["rib"] = createSecretaryForm.rib
      data["address"] = createSecretaryForm.address
      data["city"] = createSecretaryForm.city
      data["zip"] = createSecretaryForm.zipCode
      data["identity"] = createSecretaryForm.userId
      if (createSecretaryForm.password !== "") {
        data["password"] = createSecretaryForm.password
      }

      data["civility"] = createSecretaryForm.civility
      data["profile"] = imageUrl
      data["email"] = createSecretaryForm.email
      data["created_by"] = user.id
      data["secretary_price_page"] = createSecretaryForm.pricepage
      data["secretary_price_photo"] = createSecretaryForm.pricephoto
      if (createSecretaryForm.pricepage === "") {
        data["secretary_price_page"] = "0.00"
      }
      if (createSecretaryForm.pricephoto === "") {
        data["secretary_price_photo"] = "0.00"
      }

      await usersService
        .createUsersAPI(data)
        .then((response) => response.data)
        .then((response: ISendUserDataI) => {
          message.success("Compte créé avec succès.")
          props.history.push("/dashboard/seetheaccount")
        })
        .catch((err) => console.error(err))
    } else {
      message.error(" Tous les champs obligatoires ne sont pas remplis.")
    }
  }
  const handleChange_civility = (e) => {
    createSecretaryForm.civility = (e)
  }

  const handleChange = (e) => {
    switch (e) {
      case "huissier":
        props.history.push("/dashboard/bailiffaccount")
        break
      case "admin":
        props.history.push("/dashboard/adminaccount")
        break
      case "secrétaire":
        props.history.push("/dashboard/secretary")
        break
    }
  }
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok")
    }, 0)
  }

  return (
    <div className="dashboard-right-section">
      <Content style={{ marginLeft: "0px" }}>
        <Breadcrumb>
          <Breadcrumb.Item>Comptes</Breadcrumb.Item>
          <Breadcrumb.Item>Voir les comptes</Breadcrumb.Item>
        </Breadcrumb>
      </Content>

      <div className="form_outer_wrapper">
        <h2>
          <UserOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          CRÉATION D’UN COMPTE UTILISATEUR
        </h2>
        <Form
          initialValues={{
            remember: true,
          }}
          className="create_account secretarys_form"
        >
          <Row>
            {/*<Col xs={24} md={24} lg={12} className="profileimgresponsive">
              <div className="profile_uploadimg_wrapper profileimgresponsive">
                <Form.Item>
                  <Upload
                    name="avatar"
                    showUploadList={false}
                    listType="picture-card"
                    className="avatar-uploader"
                    customRequest={FileUtils.generalDummyRequestLocal}
                    onChange={handleChangeImage}
                  >
                    <div className="uploadimg-wrapper">
                      {imageUrl ? (
                        <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
                      ) : (
                          uploadButton
                        )}
                      <div className="uploadimg-icons">
                        <img src={imguploadicon} alt="avatar-icon" />
                      </div>
                    </div>
                  </Upload>
                </Form.Item>
              </div>
                      </Col>*/}
            <Col xs={24} md={24} lg={12}>
              <Form.Item label="Type de compte">
                <Select
                  defaultValue="secrétaire"
                  className="select-user-type"
                  onChange={handleChange}
                >
                  <Option value="huissier">Huissier</Option>
                  <Option value="secrétaire">Secrétaire</Option>
                  <Option value="admin">Admin</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <div className="profile_uploadimg_wrapper profileimgweb">
                <Form.Item>
                  <Upload
                    name="avatar"
                    showUploadList={false}
                    listType="picture-card"
                    className="avatar-uploader"
                    customRequest={FileUtils.generalDummyRequestLocal}
                    onChange={handleChangeImage}
                  >
                    <div className="uploadimg-wrapper">
                      {imageUrl ? (
                        <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
                      ) : (
                        uploadButton
                      )}
                      {fileName ? (
                        <div></div>
                      ) : (
                        <div className="uploadimg-icons">
                          <img src={imguploadicon} alt="avatar-icon" />
                        </div>
                      )}
                    </div>
                  </Upload>
                </Form.Item>
              </div>

            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Civilité"
                valuePropName="civility"
                validateStatus={
                  createSecretaryForm.errors.civility.length > 0
                    ? "error"
                    : "success"
                }
                help={createSecretaryForm.errors.civility}
              >
                {/*<Input
                  placeholder="Civilité"
                  className="inputfield-full"
                  value={createSecretaryForm.civility}
                  onChange={(e) => _changeHandler(e, "civility")}
              />
                */}
                <Select
                  defaultValue={createSecretaryForm.civility == '' ? `${createSecretaryForm.civility}` : "Monsieur"}
                  onChange={handleChange_civility}

                >
                  <Option value="Monsieur">Monsieur</Option>
                  <Option value="Madame">Madame</Option>
                  <Option value="Maître">Maître</Option>
                </Select>


              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Nom"
                valuePropName="lastname"
                validateStatus={
                  createSecretaryForm.errors.lastname.length > 0
                    ? "error"
                    : "success"
                }
                help={createSecretaryForm.errors.lastname}
                name="lastname"
                rules={[{ required: true }]}
              >
                <Input
                  placeholder="Nom"
                  className="inputfield-full"
                  value={createSecretaryForm.lastname || ""}
                  onChange={(e) => _changeHandler(e, "lastname")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Prénom"
                valuePropName="firstname"
                validateStatus={
                  createSecretaryForm.errors.firstname.length > 0
                    ? "error"
                    : "success"
                }
                help={createSecretaryForm.errors.firstname}
                name="firstname"
                rules={[{ required: true }]}
              >
                <Input
                  placeholder="Prénom"
                  className="inputfield-full"
                  value={createSecretaryForm.firstname || ""}
                  onChange={(e) => _changeHandler(e, "firstname")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Téléphone"
                valuePropName="phone"
                validateStatus={
                  createSecretaryForm.errors.phone.length > 0 ? "error" : "success"
                }
                help={createSecretaryForm.errors.phone}
                name="phone"
                rules={[{ required: true }]}
              >
                <Input
                  placeholder="Téléphone"
                  className="inputfield-full"
                  value={createSecretaryForm.phone || ""}
                  onChange={(e) => _changeHandler(e, "phone")}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={12}>

              <Form.Item
                label="Tarif/page"
                name="pricepage"
                valuePropName="pricepage"
                rules={[
                  {
                    required: true,
                    message: "Veuillez entér Tarif/page",
                  },
                ]}
              // validateStatus={
              // createSecretaryForm.errors.pricepage.length > 0 ? "error" : "success"
              //} 
              //help={
              // createSecretaryForm.errors.pricepage
              //}   

              >
                <Input
                  onChange={(e) => _changeHandler(e, "pricepage")}
                  placeholder='x.xx'
                  value={createSecretaryForm.pricepage}
                  className='inputfield-small'
                  style={{ marginRight: '5px' }}
                />€

              </Form.Item>

            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Email"
                valuePropName="email"
                validateStatus={
                  createSecretaryForm.errors.email.length > 0 ? "error" : "success"
                }
                help={createSecretaryForm.errors.email}
                name="email"
                rules={[{ required: true }]}
              >
                <Input
                  placeholder="Email"
                  className="inputfield-full"
                  value={createSecretaryForm.email || ""}
                  onChange={(e) => _changeHandler(e, "email")}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={12}>

              <Form.Item
                label="Tarif/photo"
                name="pricephoto"
                valuePropName="pricephoto"
                rules={[
                  {
                    required: true,
                    message: "Veuillez entér Tarif/photo",
                  },
                ]}
              //validateStatus={
              //    createSecretaryForm.errors.pricephoto.length > 0 ? "error" : "success"
              //}
              //help={
              //  createSecretaryForm.errors.pricephoto
              //}
              >
                <Input
                  onChange={(e) => _changeHandler(e, "pricephoto")}
                  placeholder='x.xx'
                  className='inputfield-small'
                  value={createSecretaryForm.pricephoto}
                  style={{ marginRight: '5px' }}
                />€

              </Form.Item>

            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="RIB"
                valuePropName="rib"
                validateStatus={
                  createSecretaryForm.errors.rib.length > 0 ? "error" : "success"
                }
                help={createSecretaryForm.errors.rib}
                name="rib"
                rules={[{ required: false }]}
              >
                <Input
                  placeholder="RIB"
                  className="inputfield-full"
                  value={createSecretaryForm.rib || ""}
                  onChange={(e) => _changeHandler(e, "rib")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Adresse postale"
                valuePropName="address"
                validateStatus={
                  createSecretaryForm.errors.address.length > 0 ? "error" : "success"
                }
                help={createSecretaryForm.errors.address}
                name="address"
                rules={[{ required: true }]}
              >
                <Input
                  placeholder="Adresse postale"
                  className="inputfield-full"
                  value={createSecretaryForm.address || ""}
                  onChange={(e) => _changeHandler(e, "address")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="CP/ Ville"
                name="city"
                rules={[{ required: true, message: "Veuillez entér CP/ Ville" }]}
                className="inputfield-full"
              >
                <Input.Group>
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form.Item
                        name="zipCode"
                        valuePropName="zipCode"
                        validateStatus={
                          createSecretaryForm.errors.zipCode.length > 0
                            ? "error"
                            : "success"
                        }
                        help={createSecretaryForm.errors.zipCode}
                      >
                        <Input
                          placeholder="Code postal"
                          value={createSecretaryForm.zipCode || ""}
                          onChange={(e) => _changeHandler(e, "zipCode")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="city"
                        valuePropName="city"
                        validateStatus={
                          createSecretaryForm.errors.city.length > 0
                            ? "error"
                            : "success"
                        }
                        help={createSecretaryForm.errors.city}
                      >
                        <Input
                          placeholder="Ville"
                          value={createSecretaryForm.city || ""}
                          onChange={(e) => _changeHandler(e, "city")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Identifiant"
                valuePropName="userId"
                validateStatus={
                  createSecretaryForm.errors.userId.length > 0 ? "error" : "success"
                }
                help={createSecretaryForm.errors.userId}
                name="userid"
                rules={[{ required: true }]}
              >
                <Input
                  placeholder="Identifiant"
                  className="inputfield-full"
                  value={createSecretaryForm.userId || ""}
                  onChange={(e) => _changeHandler(e, "userId")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                className="passwordbottom"
                label="Mot de passe"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Entrez votre mot de passe, s'il vous plaît!",
                  },
                ]}
              >
                <Input.Group>
                  <Row gutter={8}>
                    <Col span={24}>
                      {/* <Input.Password  defaultValue=""/> */}
                      <Form.Item
                        name="password"
                        valuePropName="password"
                        validateStatus={
                          createSecretaryForm.errors.password.length > 0
                            ? "error"
                            : "success"
                        }
                        help={createSecretaryForm.errors.password}
                      >
                        <Input.Password
                          autoComplete="dont-use-autocomplete"
                          placeholder="Mot de passe"
                          className="inputfield-full"
                          value={createSecretaryForm.password || ""}
                          onChange={(e) => _changeHandler(e, "password")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Confirmer mot de passe"
                name="conpassword"
                rules={[{ required: true }]}
              >
                <Input.Group>
                  <Row gutter={8}>
                    <Col span={24}>
                      {/* <Input.Password  defaultValue=""/> */}
                      <Form.Item
                        name="confirm_password"
                        valuePropName="confirm_password"
                        validateStatus={
                          createSecretaryForm.errors.confirm_password.length > 0
                            ? "error"
                            : "success"
                        }
                        help={createSecretaryForm.errors.confirm_password}
                      >
                        <Input.Password
                          placeholder="Confirmer mot de passe"
                          className="inputfield-full"
                          value={createSecretaryForm.confirm_password || ""}
                          onChange={(e) => _changeHandler(e, "confirm_password")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={12} className="register-btn-mask">
              <Form.Item label="  ">
                <Button
                  type="primary"
                  className="validate-btn"
                  onClick={_submitHandler}
                >
                  Valider
                </Button>
                <Link to="/dashboard/seetheaccount">
                  <Button className="cancel-btn">Annuler</Button>
                </Link>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}

export default SecretaryAccount
