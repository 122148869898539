import api from "../../configuration/api";

const billingService = {
  getAvailablesGroupsBillings: () =>
    api.get("billing/get_availables_groups", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  createBill: (params) =>
    api.post("billing/create", params, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  generateBillPdf: (params) =>
    api.post("billing/generate-pdf", params, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      responseType: 'blob',
    }),
  getNextBillNumber: () =>
    api.post("billing/get-next-bill-number", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    }),
  getAllBillsBailiff: (params) =>
    api.get("billing/get_all_bailiff", {
      params: params,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  sendBillToEmail: (params) =>
    api.post("billing/send_bill_to_email", params, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  setBillPaid: (params) =>
    api.post("billing/set_paid", params, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  getAllBillsSecretaries: () =>
    api.get("billing/get_all_secretaries", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  getAllBillsSecretary: (params) =>
    api.get("billing/get_all_secretary", {
      params: params,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  createBillSecretary: (params, onProgress) =>
    api.post("billing/create_secretary", params, {
      onUploadProgress: onProgress,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
  removeBillSecretary: (params) =>
    api.post("billing/remove_secretary", params, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
};

export default billingService;
