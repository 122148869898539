import globals from "../helpers/globals";
const axios = require("axios").default;
const URL = globals.domain;

const uploadFileAPI = (data) =>
  axios
    .create({
      headers: {
        "Content-type": "multipart/form-data",
      },
    })
    .post(`${URL}/api/v1/users/image`, data)

export const filesUploadServices = {
  uploadFileAPI,
}
