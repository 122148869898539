import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Layout,
  Menu,
  Modal,
  Row,
  Col,
  Dropdown,
  notification,
  message,
  Tabs,
  Button,
  Form,
  Radio,
} from "antd";
import styles from "../styles.module.scss";
import {
  LogoutOutlined,
  DownOutlined,
  FlagOutlined,
  BellOutlined,
  ProfileOutlined,
  EyeOutlined,
  FlagFilled,
} from "@ant-design/icons";
import moment from "moment";
import { logoutUser } from "../../../redux/auth/actions";
import { notificationServices } from "../../../_services/notificationServices";
import { newsServices } from "../../../_services/newsServices";
import { version } from "../../../../package.json";

const { Header, Content } = Layout;
const { SubMenu } = Menu;
const key = "updatable";

const { TabPane } = Tabs;
function TopMenu() {
  const [isNewNotification, setIsNewNotification] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  //const history = useHistory();
  const [allNotifications, setAllNotifications] = useState([]);
  const [allNews, setAllNews] = useState([]);
  const { user } = useSelector((state: any) => state.auth);
  const [showNotification, setShowNotification] = useState(false);
  const [modalData, setModalData] = useState({
    description: "",
    title: "",
    date: "",
    important: "",
  });
  const [notiticationModal, setnotificationModal] = useState({
    date: "",
    body: "",
  });
  useEffect(() => {
    if (user.role_id != "2") {
      _getAllNews();
    }
    _getAllNotification();
    setInterval(() => {
      _getAllNotification();
      if (user.role_id != "2") {
        _getAllNews();
      }
    }, 5000);
  }, []);

  const _getAllNews = async (params = {}) => {
    await newsServices
      .getAllNewsAPI(params)
      .then((response) => response.data)
      .then((response) => {
        response = response.filter((not: any) => !!!not.isRead);
        setAllNews(response);
        if (response.length !== 0) {
          setIsNewNotification(true);
        } else {
          setIsNewNotification(false);
        }
      })
      .catch((err) => {
        //console.error(err)
      });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const _getAllNotification = async () => {
    await notificationServices
      .getAllNotificationAPI({ sendTo: user.id })
      .then((response) => response.data)
      .then((response) => {
        response = response.filter((not: any) => !!!not.isRead);
        setAllNotifications(response);

        if (response.length !== 0) {
          setIsNewNotification(true);
        } else if (response.length === 0) {
          setIsNewNotification(false);
        }
      })
      .catch((err) => {
        //console.error(err)
      });
  };

  const _updateNotification = async (id) => {
    let data = {
      isRead: true,
    };

    await notificationServices
      .updateNotificationAPI(id, data)
      .then((response) => response.data)
      .then((response) => {
        _getAllNews();
        _getAllNotification();
      })
      .catch((err) => {
        //console.error(err)
      });
  };

  const _updateNews = async (id) => {
    let data = {
      isRead: true,
    };
    await newsServices
      .updateNewsByIdAPI(id, data)
      .then((response) => response.data)
      .then((response) => {
        _getAllNews();
        _getAllNotification();
      })
      .catch((err) => {
        //console.error(err)
      });
  };
  const [isActive, setActive] = useState(false);

  const currentselectindex = false;
  const dispatch = useDispatch();
  const history = useHistory();

  const onLogout = () => {
    history.push("/login");
    message.success("Has cerrado sesión");
  };

  const logout = () => {
    closeNotification();
    localStorage.clear();
    history.push("/login");
    dispatch(logoutUser(onLogout));
  };

  const toggleClass = () => {
    setActive(!isActive);
  };

  const menu =
    user.role_id === "1" ? (
      <Menu>
        <Menu.Item onClick={() => history.push("/dashboard/adminprofile")}>
          Profile
        </Menu.Item>
        <Menu.Item icon={<LogoutOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />} onClick={logout}>
          Se déconnecter
        </Menu.Item>
        <Menu.Item disabled={true}>Version: {version}</Menu.Item>
      </Menu>
    ) : user.role_id === "2" ? (
      <Menu>
        <Menu.Item onClick={() => history.push("/dashboard/bailiffprofile")}>
          Profile
        </Menu.Item>
        <Menu.Item icon={<LogoutOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />} onClick={logout}>
          Se déconnecter
        </Menu.Item>
        <Menu.Item disabled={true}>Version: {version}</Menu.Item>
      </Menu>
    ) : (
      <Menu>
        <Menu.Item onClick={() => history.push("/dashboard/secretaryprofile")}>
          Profile
        </Menu.Item>
        <Menu.Item icon={<LogoutOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />} onClick={logout}>
          Se déconnecter
        </Menu.Item>
        <Menu.Item disabled={true}>Version: {version}</Menu.Item>
      </Menu>
    );
  const key = "updatable";
  const showModal = async (news) => {
    let temp = {
      description: news.description.replace(/&lt;/g, "<").replace(/&gt;/g, ">"),
      title: news.title,
      date: moment(news.updated_at).format("DD/MM/YYYY HH:mm"),
      important: news.important,
    };
    setModalData(temp);
    setIsModalVisible(true);
    setShowNotification(false);
    await _updateNews(news.id);
  };

  const showNotificationModal = (notication: any) => {
    let temp: any = {
      date: moment(notication.created_at).format("DD/MM/YYYY HH:mm"),
      body: notication.body,
    };
    setShowNotification(true);
    setIsModalVisible(false);
    setnotificationModal(temp);
  };

  const closeNotification = () => {
    notification.close(key);
  };

  const openNotification = () => {
    notification.open({
      key,
      duration: 5,
      className:
        user.role_id != "2"
          ? "customnotofication"
          : "customnotofication bailiff-notification",
      message: "Notification",
      description: (
        <div
          className="clicktoget"
          onClick={toggleClass}
          style={{ padding: "4%" }}
          onMouseLeave={closeNotification}
        >
          <Tabs defaultActiveKey="1">
            <TabPane
              tab={
                <span className="notificationtitle-resp">
                  <BellOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                  <span className="hidetitleresp">Mes notifications </span>
                  {allNotifications.length
                    ? `(${allNotifications.slice(0, 6).length})`
                    : ""}
                </span>
              }
              key="1"
            >
              <div
                className="news-wrapper"
                style={
                  allNotifications.slice(0, 6).length > 0
                    ? {}
                    : { height: "inherit" }
                }
              >
                {allNotifications.slice(0, 6).map((notification: any) => (
                  <div className="newsinformation">
                    <div className="statusicon">
                      <Radio.Group>
                        <Radio
                          value={notification.id}
                          onChange={() => _updateNotification(notification.id)}
                        ></Radio>
                      </Radio.Group>
                    </div>
                    <div
                      className="statustext"
                      onClick={() => showNotificationModal(notification)}
                    >
                      <h2
                        style={
                          !notification.isRead
                            ? { fontWeight: 900 }
                            : { fontWeight: 300 }
                        }
                      >
                        <div
                          style={{ whiteSpace: "initial" }}
                          dangerouslySetInnerHTML={{
                            __html: notification.body
                              .replace(/&lt;/g, "<")
                              .replace(/&gt;/g, ">"),
                          }}
                        ></div>
                      </h2>
                      <p>
                        {moment(notification.created_at).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="button-news"
                style={
                  allNotifications.slice(0, 6).length > 0 ? {} : { top: "100%" }
                }
              >
                <Form.Item style={{ textAlignLast: "center" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="notificationbtn"
                    onClick={() =>
                      user.role_id == "1"
                        ? history.push("/dashboard/seeallnotifications")
                        : history.push("/dashboard/seeallnotifications")
                    }
                  >
                    <span style={{ marginRight: "0.5em" }}>
                      <EyeOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    </span>
                    Voir toutes
                  </Button>
                </Form.Item>
              </div>
            </TabPane>

            {user.role_id != "2" && (
              <TabPane
                tab={
                  <span className="notificationtitle-resp">
                    <ProfileOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <span className="hidetitleresp">Actualités</span>
                    {allNews.length ? `(${allNews.slice(0, 6).length})` : ""}
                  </span>
                }
                key="2"
              >
                <div
                  className="news-wrapper"
                  style={
                    allNews.slice(0, 6).length > 0 ? {} : { height: "inherit" }
                  }
                >
                  {allNews.map((news: any) => (
                    <div
                      className="newsinformation"
                      onClick={() => showModal(news)}
                    >
                      <div className="statusicon">
                        <div className="stinactive">
                          {news.important ? (
                            <FlagFilled className="modalflaticon" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                          ) : (
                            <FlagOutlined className="modalflaticon-empty" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                          )}
                        </div>
                      </div>
                      <div className="statustext">
                        <h2 style={news.isRead ? { fontWeight: 300 } : {}}>
                          {news.title}
                        </h2>
                        <p>
                          {moment(news.created_at).format("DD/MM/YYYY HH:mm")}
                        </p>
                      </div>
                    </div>
                  ))}

                  <div
                    className="button-news"
                    style={
                      allNews.slice(0, 6).length > 0 ? {} : { top: "100%" }
                    }
                  >
                    <Form.Item style={{ textAlignLast: "center" }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="notificationbtn"
                        onClick={() =>
                          user.role_id == "1"
                            ? history.push("/dashboard/viewnews")
                            : history.push("/dashboard/viewnewsecretarie")
                        }
                      >
                        <span style={{ marginRight: "0.5em" }}>
                          <EyeOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                        </span>
                        Voir toutes
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </TabPane>
            )}
          </Tabs>
        </div>
      ),
    });
  };

  return (
    <Header
      className={styles.header}
      style={{ position: "fixed", zIndex: 1, width: "100%" }}
      onBlur={closeNotification}
    >
      <div className={styles.dashboard_logo} id="dashboardlogo">
        <img
          src={"/images/logo.svg"}
          alt="Logo"
          className={styles.responsive_img}
          onClick={() => history.push("/dashboard")}
          style={{ cursor: "pointer" }}
        />
      </div>

      <Row justify="end" className="header-profile-dropdown">
        <Col
          className={
            user.role_id != "2"
              ? "profile_notification"
              : "profile_notification"
          }
        >
          <img
            src={"/images/notification-icon.svg"}
            alt="Logo"
            key="updatable"
            className={styles.responsive_img}
            onClick={openNotification}
          />
          {isNewNotification && (
            <div
              style={{
                width: "5px",
                height: "5px",
                position: "absolute",
                backgroundColor: "red",
                top: "7px",
                borderRadius: "3px",
              }}
            ></div>
          )}
        </Col>
        <Col className="profile_user">
          <Dropdown overlay={menu} className="profile-dropdown">
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <img
                src={
                  user.profile ||
                  "https://w7.pngwing.com/pngs/81/570/png-transparent-profile-logo-computer-icons-user-user-blue-heroes-logo-thumbnail.png"
                }
                alt="Logo"
                className={styles.responsive_img}
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "15px",
                  objectFit: "cover",
                }}
              />
              <span>
                {user != null ? user.first_name + " " + user.last_name : "User"}
              </span>
              <DownOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </a>
          </Dropdown>
        </Col>
      </Row>

      <Modal
        footer={null}
        className="viewsecretarie-modal"
        centered
        visible={isModalVisible}
        onCancel={handleCancel}
      >
        <div className="modal-container">
          {modalData.important ? (
            <FlagFilled className="modalflaticon" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          ) : (
            <FlagOutlined className="modalflaticon-empty" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          )}
          <h2>{modalData.title}</h2>
          <div className="viewsecretarie-date">{modalData.date}</div>
          <div
            dangerouslySetInnerHTML={{
              __html: modalData.description
                .replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">"),
            }}
          ></div>
        </div>
      </Modal>

      <Modal
        footer={null}
        className="viewsecretarie-modal"
        centered
        visible={showNotification}
        onCancel={() => setShowNotification(false)}
      >
        <div className="modal-container">
          <div className="viewsecretarie-date">{notiticationModal.date}</div>
          <div
            dangerouslySetInnerHTML={{
              __html: notiticationModal.body
                .replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">"),
            }}
          ></div>
        </div>
      </Modal>
    </Header>
  );
}

export default TopMenu;
