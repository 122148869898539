import React, { useState, useEffect } from "react"
import { Link, Route, Switch, useHistory } from "react-router-dom"
import {
  Layout,
  Table,
  Input,
  Badge,
  Menu,
  Dropdown,
  Space,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Breadcrumb,
  Button,
  Upload,
  message,
  Modal,
  Empty
} from "antd"
import {
  EditOutlined,
  SearchOutlined,
  FlagOutlined,
  EyeOutlined,
  FlagFilled,
} from "@ant-design/icons"
import moment from "moment"
import { newsServices } from "../../../_services/newsServices"
//import ReactHtmlParser from "react-html-parser"

const { Content } = Layout
const { Option } = Select

function Viewnews(props) {
  const [paginateNo, setPaginateNo] = useState(10)
  const [allNews, setAllNews] = useState([])

  useEffect(() => {
    _getAllNews()
  }, [])

  const _getAllNews = async (params = {}) => {
    await newsServices
      .getAllNewsAPI(params)
      .then((response) => response.data)
      .then((response) => {
        let temp: any = []
        response.map((news) => {
          temp.push({
            important: news.important,
            date: moment(news.created_at).format("DD/MM/YYYY HH:mm"),
            actualite: news.title,
            edit: news,
          })
        })
        let info: any = []
        temp.map((data) => {
          info = data.edit.title
        })
        let name = JSON.stringify(params['name'])

        setAllNews(temp)

      })
      .catch((err) => console.error(err))
  }


  const keywordHandler = async (event) => {
    if (event.target.value) {
      let data = event.target.value
      if ((!isNaN(data.charAt(0)) === true) && (isNaN(data.charAt(2)) === true)) {
        data = data.slice(2)
        await _getAllNews({ name: data })
      }
      if ((!isNaN(data.charAt(0)) === true) && (!isNaN(data.charAt(1)) === true) && (isNaN(data.charAt(3)) === true)) {
        data = data.slice(3)
        await _getAllNews({ name: data })
      }
      if (isNaN(data.charAt(0)) === true) {
        await _getAllNews({ name: data })
      }
      else {
        await _getAllNews({ date: event.target.value })
      }
    } else {
      await _getAllNews()
    }
  }

  const keywordHanderEnter = async (event) => {
    if (event.key == 'Enter') {
      let data = event.target.value
      if ((!isNaN(data.charAt(0)) === true) && (isNaN(data.charAt(2)) === true)) {
        data = data.slice(2)
        await _getAllNews({ name: data })

      }
      if ((!isNaN(data.charAt(0)) === true) && (!isNaN(data.charAt(1)) === true) && (isNaN(data.charAt(3)) === true)) {
        data = data.slice(3)
        await _getAllNews({ name: data })

      }
      if (data) {
        if (isNaN(data.charAt(0)) === true) {
          await _getAllNews({ name: data })
        }
        else {
          await _getAllNews({ date: event.target.value })
        }
      } else {
        await _getAllNews()
      }
    }


  }

  const columns = [
    {
      title: "Important",
      dataIndex: "important",
      render: (important) => (
        <Space size="middle">
          {important ? (
            <FlagFilled className="modalflaticon" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          ) : (
            <FlagOutlined className="modalflaticon-empty" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          )}
        </Space>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Actualité",
      dataIndex: "actualite",
      render: (desctiption) => (
        <Space size="middle">
          <div
            dangerouslySetInnerHTML={{
              __html: desctiption.replace(/&lt;/g, "<").replace(/&gt;/g, ">"),
            }}
          />
        </Space>
      ),
    },
    {
      title: "Modifier",
      dataIndex: "edit",
      render: (news) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => {
              props.history.push(`/dashboard/createanews/${news.id}`)
            }} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        </Space>
      ),
    },
  ]

  return (
    <div className="dashboard-right-section viewnews_wrapper">
      <Content style={{ marginLeft: "0px" }}>
        <Breadcrumb>
          <Breadcrumb.Item>Actualités</Breadcrumb.Item>
          <Breadcrumb.Item>Tous les actualités</Breadcrumb.Item>
        </Breadcrumb>
      </Content>
      <div className="form_viewnews_wrapper">
        <h2>TOUTES LES ACTUALITÉS</h2>
        <div className="tabletop-filter-section">
          <Row>
            <Col className="gutter-row" xs={8} md={10} lg={8}>
              <Select
                defaultValue="10"
                className="table-rows-select"
                onChange={(value: any) => setPaginateNo(Number(value))}
              >
                <Option value="10">10</Option>
                <Option value="20">20</Option>
                <Option value="30">30</Option>
                <Option value="40">40</Option>
                <Option value="50">50</Option>
                <Option value="60">60</Option>
              </Select>
            </Col>
            <Col className="gutter-row" xs={16} md={7} lg={16}>
              <Input
                size="large"
                className="table-search-input"
                placeholder="Rechercher..."
                prefix={<SearchOutlined className="searchiconalign" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                onBlur={keywordHandler}
                onKeyPress={keywordHanderEnter}
              />
            </Col>
          </Row>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={allNews || []}
        size="middle"
        scroll={{ "x": 400 }}
        className="viewnews-table"
        pagination={{ pageSize: paginateNo }}
        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Aucune actualité associée' /> }}
      />
    </div>
  )
}
export default Viewnews
