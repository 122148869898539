import globals from "../helpers/globals";
const axios = require("axios").default;
const URL = globals.domain;

const getAllNewsAPI = (params) => {
  const token = localStorage.getItem("token")
  return axios
    .create({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .get(`${URL}/api/v1/news`, { params })
}

const getNewsByIdAPI = (id) => {
  const token = localStorage.getItem("token")
  return axios
    .create({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .get(`${URL}/api/v1/news/${id}`)
}

const updateNewsByIdAPI = (id, data) => {
  const token = localStorage.getItem("token")
  return axios
    .create({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .patch(`${URL}/api/v1/news/${id}`, data)
}

const createNewsAPI = (data) => {
  const token = localStorage.getItem("token")
  return axios
    .create({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .post(`${URL}/api/v1/news`, data)
}

export const newsServices = {
  getAllNewsAPI,
  getNewsByIdAPI,
  createNewsAPI,
  updateNewsByIdAPI,
}
