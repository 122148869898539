import React, { useState } from 'react'
import { Form, Input, Button, Result, message } from "antd";
import { MailOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';

import styles from "./styles.module.scss";
// import logo from '../../assets/logo-crc-extranet-white.png';
import authServices from '../../redux/auth/services';
import { authenticationService } from '../../_services/authentication'
import { BASE_URL } from "../../helpers/api";

import { useHistory } from 'react-router-dom';
function RecoverRequest() {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('')
  const history = useHistory();
  // const onFinish = async ({ email } : { email: string }) => {
  // 	try {
  // 		setError(undefined);
  // 		setLoading(true);
  // 		await authServices.requestRecovery(email);
  // 		setSuccess(true);
  // 		setLoading(false);
  // 	} catch (err) {
  // 		setError(err.response.data.errors.message);
  // 		setLoading(false);
  // 	}
  // };

  const onFinish = async (values) => {
    let data = {
      email: values.email
    };

    const postData = Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&');
    await fetch(BASE_URL + '/api/v1/auth/forgot-password', {
      method: "POST",
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
      }),
      // headers: generalAuthTokenHeader(),
      body: postData,
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.status == '400' && data.message == 'error.idexists') {
          message.error(`Une erreur est survenue, veuillez réessayer. Êtes-vous sûr que l'email est correct ?`)
          message.error(data.title)
        } else if (data.msg) {
          message.success(`Un e-mail vous a été envoyé pour récupérer votre mot de passe.`)
          history.push("/forgotpassword");
          return true;
        } else {
          message.error(`Une erreur est survenue, veuillez réessayer. Êtes-vous sûr que l'email est correct ?`)
        }
      })
      .catch(error => {
        message.error(`Une erreur est survenue, veuillez réessayer. Êtes-vous sûr que l'email est correct ?`)
      });
  };

  if (success) {
    return (
      <Result
        status="success"
        title="Debe haber llegado un link de recuperación a tu email"
        style={{ paddingTop: 200 }}
        extra={[
          <Link to="/login">
            <Button type="primary" key="login">
              Volver a la página principal
            </Button>
          </Link>
        ]}
      />
    );
  }

  const handleInput = (e) => {
    const mail = e.target.value
    setEmail(mail)
  }

  return (
    <section className={styles.login_wrapper} id="loginscreenwrapper">
      <div className={styles.login_form_container}>
        <div className={styles.login_form_container}>
          {/* <img src={logo} alt="Logo" className={styles.login_form_logo} /> */}
          <div className={styles.login_form_logo}>
            <img src={'/images/logo.svg'} alt="Logo" className={styles.responsive_img} />
          </div>
          <Form name="recovery_request" onFinish={onFinish} className={styles.forgot_passform}>
            <h5>Veuillez saisir votre email pour recevoir un email de récupération de votre mot de passe</h5>
            <Form.Item
              name="email"
              rules={[
                { message: "Veuillez entrer votre email" },
                { type: "email", message: "Veuillez entrer une adresse email valide" },
              ]}
              validateStatus={error && "error"}
              help={error || null}
            >

              <Input
                prefix={<UserOutlined className="site-form-item-icon" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                placeholder="Votre email"
                disabled={loading}
              />
            </Form.Item>

            <Form.Item className="loginforgotlink">
              <Link className={styles.login_form_forgot} to="/login">
                Vous avez un compte?
              </Link>
              {/* <Link className={styles.login_form_forgot} to="/emailavecsucces/request">
            Email envoyé avec succès.
          </Link> */}
              {/* <Link className={styles.login_form_forgot} to="/passwordnew/request">
           New password
          </Link> */}
            </Form.Item>

            <Form.Item>
              {/*<Link className={styles.login_form_forgot} to="/emailavecsucces/request">*/}
              <Button
                type="primary"
                htmlType="submit"
                //className="login_form-submit"
                className={styles.login_form_submit_btn}
                disabled={loading}
              >
                Envoyer
              </Button>
              {/*</Link>*/}
            </Form.Item>
          </Form>
        </div>
      </div>
    </section>
  );
}

export default RecoverRequest;

