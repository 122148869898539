import axios from "axios";

//export const BASE_URL = "https://api.staging.crc-sarl.fr";
//export const BASE_URL = "http://localhost:8000";
export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export default axios.create({
	baseURL: BASE_URL,
	responseType: "json",
});
