import React from "react"
import { Spin } from "antd"

const spinContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  width: "100vw",
}

const Loader = () => (
  <div style={spinContainerStyle}>
    <Spin tip="Chargement..." />
  </div>
)

export default Loader
