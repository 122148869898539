import moment from "moment";

export const listDaysOfMonth = (year, month) => {
  const numberDays = moment(`${year}-${month}-01 00:00:00`).daysInMonth();

  let days: any = [];
  for (let i = 1; i <= numberDays; i++) {
    days.push(i);
  }
  
  return days;
};

export const getArrayYearsTwoDates = (dateOne, dateTwo) => {
  const diff = dateTwo.year() - dateOne.year() + 1;
  return [...Array(diff).keys()].map(i => i + dateOne.year())
}