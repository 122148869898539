import React, { useState } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import {
  Layout,
  Table,
  Input,
  Badge,
  Menu,
  Dropdown,
  Space,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Breadcrumb,
  Button,
  Upload,
  message,
  InputNumber,
} from "antd";
import {
  CheckCircleOutlined,
  CloudUploadOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector, useDispatch } from "react-redux";
import { setPhoneLengthValidation } from "../../../redux/auth/actions";

import styles from "../styles.module.scss";
import { BASE_URL } from "../../../helpers/api";
// import Leftmenu from '../leftmenu';
import Topmenu from "../topmenu";
import { filesUploadServices } from "../../../_services/uploadFIles";
import { usersService } from "../../../_services/usersSerives";
import { ISendUserDataI } from "../../../interfaces/users.interface";
import FileUtils from "../../../utils/files";
import { RoutesDashboard } from "../../../helpers/routes";
const validZipCode = RegExp(/^\d{5}(?:[-\s]\d{4})?$/);
const validPassword = RegExp(
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
);
const validEmailRegex = RegExp(
  /^(([^<>()\\[\]\\.,;:\s@\\"]+(\.[^<>()\\[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i
);
const validIdentify = RegExp(/^[A-Za-z0-9]{3,}$/);
const { Dragger } = Upload;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const props = {
  name: "file",
  multiple: false,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} fichier téléchargé avec succès.`);
    } else if (status === "error") {
      message.error(`${info.file.name} le téléchargement du fichier a échoué.`);
    }
  },
};

//const { Content } = Layout;
const { Header, Footer, Sider, Content } = Layout;
const { Option } = Select;

const handleChange = () => {
  console.log(`selected`);
};

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

function CreateaReportBailiff() {
  const history = useHistory();
  const { user } = useSelector((state: any) => state.auth);
  const [secretaryForm, setSecretaryForm] = useState({
    civility: user?.civility || "Monsieur",
    last_name: user?.last_name || "",
    first_name: user?.first_name || "",
    phone: user?.telephone || "",
    email: user?.email || "",
    password: user?.password || "",
    confirmPassword: "",
    userID: user?.identity || "",
    rib: user?.rib || "",
    address: user?.address || "",
    zipCode: user?.zip || "",
    city: user?.city || "",
    pricephoto: user?.secretary_price_photo || "",
    pricepage: user?.secretary_price_page || "",
    errors: {
      civility: "",
      last_name: "",
      first_name: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
      userID: "",
      address: "",
      zipCode: "",
      city: "",
      rib: "",
      pricephoto: "",
      pricepage: "",
    },
  });

  const notFilledPasswordField = () => {
    let errors = secretaryForm.errors;
    // debugger
    if (secretaryForm.civility === "") {
      errors.civility = "Ce champs est requis";
    }
    if (secretaryForm.last_name === "") {
      errors.last_name = "Ce champs est requis";
    }
    if (secretaryForm.first_name === "") {
      errors.first_name = "Ce champs est requis";
    }
    if (secretaryForm.phone === "") {
      errors.phone = "Ce champs est requis";
    }
    if (secretaryForm.email === "") {
      errors.email = "Ce champs est requis";
    }
    // if (secretaryForm.password === "") {
    //   errors.password = "Ce champs est requis"
    // }
    // if (secretaryForm.confirmPassword === "") {
    //   errors.confirmPassword = "Ce champs est requis"
    // }
    if (secretaryForm.userID === "") {
      errors.userID = "Ce champs est requis";
    }
    if (secretaryForm.address === "") {
      errors.address = "Ce champs est requis";
    }
    if (secretaryForm.zipCode === "") {
      errors.zipCode = "Ce champs est requis";
    }
    if (secretaryForm.city === "") {
      errors.city = "Ce champs est requis";
    }
    //if (secretaryForm.rib === "") {
    //errors.rib = "Ce champs est requis"
    //}
    setSecretaryForm({ ...secretaryForm, ...{ errors } });
  };

  const validateForm = (formFields) => {
    notFilledPasswordField();
    let valid = true;
    Object.values(formFields.errors).forEach(
      (val: any) => val.length > 0 && (valid = false)
    );
    return valid;
  };

  const [imageUrl, setImage] = useState(
    user.profile || require("../../../pages/images/uploadimage.svg")
  );

  const dispatch = useDispatch();
  //const [imageUrl, setImage] = useState('https://www.kindpng.com/picc/m/163-1636340_user-avatar-icon-avatar-transparent-user-icon-png.png');
  const uploadButton = (
    <div>
      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChangeImage = async (info) => {
    if (info.file.status === "done") {
      let data = new FormData();
      data.append("profile", info.file.originFileObj);
      await filesUploadServices
        .uploadFileAPI(data)
        .then((response) => {
          setImage(response.data.url);
        })
        .catch((err) => console.error(err));
    }
  };

  const handleChange_civility = (e) => {
    secretaryForm.civility = e;
  };

  const _submitHandler = async () => {
    if (validateForm(secretaryForm)) {
      let data: any = {};
      data["role_id"] = "3";
      data["civility"] = secretaryForm.civility;
      data["last_name"] = secretaryForm.last_name;
      data["first_name"] = secretaryForm.first_name;
      data["telephone"] = secretaryForm.phone;
      if (secretaryForm.password !== "") {
        data["password"] = secretaryForm.password;
      }
      data["identity"] = secretaryForm.userID;
      data["profile"] = imageUrl;
      data["address"] = secretaryForm.address;
      if (user.rib != secretaryForm.rib) {
        data["rib"] = secretaryForm.rib;
      }
      data["zip"] = secretaryForm.zipCode;
      data["city"] = secretaryForm.city;
      // data["secretary_price_photo"] = secretaryForm.pricephoto
      // data["secretary_price_page"] = secretaryForm.pricepage
      await usersService
        .updateUsersAPI(user?.id, data)
        .then((response) => response.data)
        .then((response: ISendUserDataI) => {
          message.success("Compte modifié avec succès.");
          history.push("/dashboard");
        })
        .catch((err) => console.error(err));
    }
  };

  const _changeHandler = (event, name) => {
    let errors = secretaryForm.errors;

    const { value } = event.target;
    switch (name) {
      case "last_name":
        errors.last_name =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "first_name":
        errors.first_name =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "phone":
        errors.phone =
          value.length === 10 ? "" : "Numéro de téléphone invalide.";
        break;
      case "password":
        errors.password = validPassword.test(value)
          ? ""
          : "Mauvais mot de passe.";
        break;
      case "confirmPassword":
        errors.confirmPassword =
          secretaryForm.password != value ? "" : "Mot de passe incorrect.";
        break;
      case "first_name":
        errors.first_name =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "address":
        errors.address =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "zipCode":
        errors.zipCode = validZipCode.test(value)
          ? ""
          : "Code postal invalide";
        break;
      case "city":
        errors.city =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      //case "rib":
      //errors.rib = value.length > 2 ? "" : "Doit contenir au moins 3 caractères"
      //break
      case "userID":
        errors.userID = validIdentify.test(value)
          ? ""
          : "Doit contenir au moins 3 caractères";
        break;
      case "civility":
        errors.civility =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "email":
        errors.email = validEmailRegex.test(value)
          ? ""
          : "Identifiant de messagerie invalide.";
        break;
      default:
        break;
    }

    setSecretaryForm({
      ...secretaryForm,
      ...{ errors, [name]: value },
    });
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <Layout className="creer-constat-wrapper noleftmenu_section">
      <Row>
        <Col xs={24} md={24} lg={24}>
          <div className="dashboard-right-section profile_bailiff_wrapper profilesecretarypro">
            <div className="form_outer_wrapper">
              <div className="onlyscreen_template_inner">
                <div className="onlyscreen_template_wrapper">
                  <div className="form_outer_onlyscreenwrapper">
                    <div className="form_onlyscreenwrapper">
                      <div className="userprofile-layout-wrapper">
                        <Layout>
                          <Sider>
                            <Menu
                              defaultSelectedKeys={["1"]}
                              defaultOpenKeys={["sub1"]}
                              mode="inline"
                              theme="light"
                            >
                              <Menu.Item>
                                <Link to="/dashboard/">Accueil</Link>
                              </Menu.Item>
                              <Menu.Item key="1">
                                <Link to="/dashboard/secretaryprofile">
                                  Mon Profil
                                </Link>
                              </Menu.Item>
                              <Menu.Item>
                                <Link to={RoutesDashboard.billsMonthSecretary}>
                                  Mes Factures
                                </Link>
                              </Menu.Item>
                              <Menu.Item>
                                <Link to="/dashboard/secretarycalendar">
                                  Calendrier
                                </Link>
                              </Menu.Item>
                            </Menu>
                          </Sider>
                          <Layout>
                            <Header>
                              <h2>MON PROFIL</h2>
                            </Header>
                            <Content>
                              <div className="content-section-wrapper">
                                <div>
                                  <Form className="secretaryprofile-form">
                                    {/* <div className="topalertonsuccess">
                                      <CheckCircleOutlined className="circlechcek" />
                                      Enregistré{" "}
                                      <CloseOutlined className="close-alert" />
                                    </div> */}
                                    <Row>
                                      <Col xs={24} md={12} lg={12}>
                                        <div className="profile_uploadimg_wrapper profileimgresponsive">
                                          <Form.Item>
                                            <Upload
                                              name="avatar"
                                              showUploadList={false}
                                              listType="picture-card"
                                              className="avatar-uploader"
                                              customRequest={
                                                FileUtils.generalDummyRequestLocal
                                              }
                                              onChange={handleChangeImage}
                                            >
                                              <div className="uploadimg-wrapper">
                                                {imageUrl ? (
                                                  <img
                                                    src={imageUrl}
                                                    alt="avatar"
                                                    style={{ width: "100%" }}
                                                  />
                                                ) : (
                                                  uploadButton
                                                )}
                                                {/* <div className="uploadimg-icons">
                                                  <img src={imguploadicon} alt="avatar-icon" />
                                                </div> */}
                                              </div>
                                            </Upload>
                                            <h2>
                                              Vos identifiants de connexion
                                            </h2>
                                          </Form.Item>
                                        </div>
                                        <Form.Item
                                          label="Civilité"
                                          valuePropName="civility"
                                          validateStatus={
                                            secretaryForm.errors.civility
                                              .length > 0
                                              ? "error"
                                              : "success"
                                          }
                                          help={secretaryForm.errors.civility}
                                        >
                                          <Select
                                            className="inputfield-middle"
                                            defaultValue={
                                              secretaryForm.civility
                                            }
                                            onChange={handleChange_civility}
                                          >
                                            <Option value="Monsieur">
                                              Monsieur
                                            </Option>
                                            <Option value="Madame">
                                              Madame
                                            </Option>
                                            <Option value="Maître">
                                              Maître
                                            </Option>
                                          </Select>
                                        </Form.Item>
                                        <Form.Item
                                          label="Nom"
                                          valuePropName="last_name"
                                          validateStatus={
                                            secretaryForm.errors.last_name
                                              .length > 0
                                              ? "error"
                                              : "success"
                                          }
                                          help={secretaryForm.errors.last_name}
                                        >
                                          <Input
                                            placeholder="Nom"
                                            className="inputfield-middle"
                                            value={
                                              secretaryForm.last_name || ""
                                            }
                                            onChange={(e) =>
                                              _changeHandler(e, "last_name")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Prénom"
                                          valuePropName="first_name"
                                          validateStatus={
                                            secretaryForm.errors.first_name
                                              .length > 0
                                              ? "error"
                                              : "success"
                                          }
                                          help={secretaryForm.errors.first_name}
                                        >
                                          <Input
                                            placeholder="Prénom"
                                            className="inputfield-middle"
                                            value={
                                              secretaryForm.first_name || ""
                                            }
                                            onChange={(e) =>
                                              _changeHandler(e, "first_name")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Téléphone"
                                          valuePropName="phone"
                                          validateStatus={
                                            secretaryForm.errors.phone.length >
                                              0
                                              ? "error"
                                              : "success"
                                          }
                                          help={secretaryForm.errors.phone}
                                        >
                                          <Input
                                            placeholder="Téléphone"
                                            className="inputfield-middle"
                                            value={secretaryForm.phone || ""}
                                            onChange={(e) =>
                                              _changeHandler(e, "phone")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Email"
                                          valuePropName="email"
                                          validateStatus={
                                            secretaryForm.errors.email.length >
                                              0
                                              ? "error"
                                              : "success"
                                          }
                                          help={secretaryForm.errors.email}
                                        >
                                          <Input
                                            placeholder="Email"
                                            className="inputfield-middle"
                                            value={secretaryForm.email || ""}
                                            onChange={(e) =>
                                              _changeHandler(e, "email")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Adresse postale"
                                          valuePropName="address"
                                          validateStatus={
                                            secretaryForm.errors.address
                                              .length > 0
                                              ? "error"
                                              : "success"
                                          }
                                          help={secretaryForm.errors.address}
                                        >
                                          <Input
                                            placeholder="Adresse postale"
                                            className="inputfield-middle"
                                            value={secretaryForm.address || ""}
                                            onChange={(e) =>
                                              _changeHandler(e, "address")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Code postal"
                                          valuePropName="zipCode"
                                          validateStatus={
                                            secretaryForm.errors.zipCode
                                              .length > 0
                                              ? "error"
                                              : "success"
                                          }
                                          help={secretaryForm.errors.zipCode}
                                        >
                                          <Input
                                            placeholder="Code postal"
                                            className="inputfield-middle"
                                            value={secretaryForm.zipCode || ""}
                                            onChange={(e) =>
                                              _changeHandler(e, "zipCode")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Ville"
                                          valuePropName="city"
                                          validateStatus={
                                            secretaryForm.errors.city
                                              .length > 0
                                              ? "error"
                                              : "success"
                                          }
                                          help={secretaryForm.errors.city}
                                        >
                                          <Input
                                            placeholder="Ville"
                                            className="inputfield-middle"
                                            value={secretaryForm.city || ""}
                                            onChange={(e) =>
                                              _changeHandler(e, "city")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="RIB"
                                          valuePropName="rib"
                                        >
                                          <Input
                                            placeholder="RIB"
                                            className="inputfield-middle"
                                            value={secretaryForm.rib || ""}
                                            onChange={(e) =>
                                              _changeHandler(e, "rib")
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} md={12} lg={12}>
                                        <div className="profile_uploadimg_wrapper profileimgweb">
                                          <Form.Item>
                                            <Upload
                                              name="avatar"
                                              showUploadList={false}
                                              listType="picture-card"
                                              className="avatar-uploader"
                                              customRequest={
                                                FileUtils.generalDummyRequestLocal
                                              }
                                              onChange={handleChangeImage}
                                            >
                                              <div className="uploadimg-wrapper">
                                                {imageUrl ? (
                                                  <img
                                                    src={imageUrl}
                                                    alt="avatar"
                                                    style={{ width: "100%" }}
                                                  />
                                                ) : (
                                                  uploadButton
                                                )}
                                                {/* <div className="uploadimg-icons">
                                                  <img src={imguploadicon} alt="avatar-icon" />
                                                </div> */}
                                              </div>
                                            </Upload>
                                            <h2>
                                              Vos identifiants de connexion
                                            </h2>
                                          </Form.Item>
                                        </div>
                                        <Form.Item
                                          label="Identifiant"
                                          valuePropName="userID"
                                          validateStatus={
                                            secretaryForm.errors.userID.length >
                                              0
                                              ? "error"
                                              : "success"
                                          }
                                          help={secretaryForm.errors.userID}
                                        >
                                          <Input
                                            placeholder="Identifiant"
                                            className="inputfield-middle"
                                            value={secretaryForm.userID || ""}
                                            onChange={(e) =>
                                              _changeHandler(e, "userID")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Mot de passe"
                                          valuePropName="password"
                                          validateStatus={
                                            secretaryForm.errors.password
                                              .length > 0
                                              ? "error"
                                              : "success"
                                          }
                                          help={secretaryForm.password.rib}
                                        >
                                          <Input
                                            placeholder="Mot de passe"
                                            className="inputfield-middle"
                                            value={secretaryForm.password || ""}
                                            onChange={(e) =>
                                              _changeHandler(e, "password")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Tarif/page texte"
                                          valuePropName="pricepage"
                                          validateStatus={
                                            secretaryForm.errors.pricepage
                                              .length > 0
                                              ? "error"
                                              : "success"
                                          }
                                          help={secretaryForm.errors.pricepage}
                                        >
                                          <Input
                                            placeholder="Tarif/page texte"
                                            className="inputfield-middle"
                                            value={
                                              secretaryForm.pricepage || ""
                                            }
                                            onChange={(e) =>
                                              _changeHandler(e, "pricepage")
                                            }
                                            disabled={true}
                                            style={{ marginRight: "5px" }}
                                          />
                                          €
                                        </Form.Item>
                                        <Form.Item
                                          label="Tarif/photo"
                                          valuePropName="pricephoto"
                                          validateStatus={
                                            secretaryForm.errors.pricephoto
                                              .length > 0
                                              ? "error"
                                              : "success"
                                          }
                                          help={secretaryForm.errors.pricephoto}
                                        >
                                          <Input
                                            placeholder="Tarif/photo"
                                            className="inputfield-middle"
                                            value={
                                              secretaryForm.pricephoto || ""
                                            }
                                            onChange={(e) =>
                                              _changeHandler(e, "pricephoto")
                                            }
                                            disabled={true}
                                            style={{ marginRight: "5px" }}
                                          />
                                          €
                                        </Form.Item>
                                        <Form.Item>
                                          <Button
                                            type="primary"
                                            className="validate-btn"
                                            onClick={_submitHandler}
                                          >
                                            Valider
                                          </Button>
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </Form>
                                </div>
                              </div>
                            </Content>
                          </Layout>
                        </Layout>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default CreateaReportBailiff;
