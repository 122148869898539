
const authConstants = {
  SET_LOADING_CATEGORIES: "SET_LOADING_CATEGORIES",
  SET_CATEGORIES: "SET_CATEGORIES",
  SET_CREATING_CATEGORY: "SET_CREATING_CATEGORY",
  SET_CREATED_CATEGORY: "SET_CREATED_CATEGORY",
  SET_DELETING_CATEGORIES: "SET_DELETING_CATEGORIES",
  REMOVE_CATEGORY: "REMOVE_CATEGORY",
  SET_EDITING_CATEGORIES: "SET_EDITING_CATEGORIES",
  SET_EDITED_CATEGORIES: "SET_EDITED_CATEGORIES",
};

export default authConstants;