import React, { useState, useEffect, memo } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import {
  Layout,
  Table,
  Input,
  Badge,
  Menu,
  Dropdown,
  Space,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Breadcrumb,
  Upload,
  message,
  Modal,
  Comment,
  Avatar,
  Button,
  Progress,
  Empty,
} from "antd";
import { AlignType } from 'rc-table/lib/interface'
import {
  UserOutlined,
  VerticalAlignBottomOutlined,
  MailOutlined,
  FolderViewOutlined,
  DownOutlined,
  PlusOutlined,
  CaretRightOutlined,
  SearchOutlined,
  EyeOutlined,
  RollbackOutlined,
} from "@ant-design/icons";

import styles from "../styles.module.scss";
import { reportServices } from "../../../_services/reportServices";
import { usersService } from "../../../_services/usersSerives";
import moment from "moment";
import AdvanceFilterOptions from "../../../components/AdvanceFilterOptions";
import axios from "axios";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { RoutesDashboard } from "../../../helpers/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  addCommentReport,
  getReports,
  resetReportState,
  setUnarchiveReport,
} from "../../../redux/report";
import { getAllUsers } from "../../../redux/user";
import ExpandedRowConstat from "../../../components/ExpandedRowConstat/ExpandedRowConstat";
import FileUtils from "../../../utils/files";
import { saveAs } from "file-saver";
import ModalDocument from "../../../components/ModalDocument/ModalDocument";
import ModalComments from "../../../components/ModalComments";
import ModalInstructions from "../../../components/ModalInstructions/ModalInstructions";

const { Content } = Layout;
const { Option } = Select;

function ReportsArchivedAdmin({ history }) {
  const dispatch = useDispatch();
  const {
    reports,
    loadingUpdateSecretaryReport,
    successUpdateSecretaryReport,
    errorUpdateSecretaryReport,
    successAddFilesReport,
    successRemoveFilesReport,
    successSetArchiveReport,
    successSetBillingReport,
    errorSetBillingReport,
    successAddCommentReport,
    errorAddCommentReport,
    successSetUnarchiveReport,
    successMarkAsReadCommentReport
  } = useSelector((state: any) => state.report);
  const { users, loadingGetAllUsers, successGetAllUsers, errorGetAllUsers } =
    useSelector((state: any) => state.user);
  const [reportsFiltered, setReportsFiltered]: any = useState([]);
  const [filters, setFilters]: any = useState({
    visible: false,
    itemsPerPage: 10,
    searchText: "",
  });
  const [modalInstructions, setModalInstructions] = useState({
    visible: false,
    data: {},
  });
  const [modalViewDocument, setModalViewDocument] = useState({
    visible: false,
    data: {},
  });
  const [modalComments, setModalComments]: any = useState({
    visible: false,
    data: {},
  });

  useEffect(() => {
    dispatch(resetReportState());
    dispatch(getAllUsers());
    dispatch(getReports({ states: ["AR"] }));
  }, [
    dispatch,
    successUpdateSecretaryReport,
    successAddFilesReport,
    successRemoveFilesReport,
    successSetBillingReport,
    errorSetBillingReport,
    successSetArchiveReport,
    successAddCommentReport,
    errorAddCommentReport,
    successSetUnarchiveReport,
    successMarkAsReadCommentReport
  ]);

  useEffect(() => {
    let reportsFiltered = reports.slice();

    reportsFiltered = reportsFiltered.sort((firstReport, secondReport) => moment(secondReport.createdAt).unix() - moment(firstReport.createdAt).unix())
      .map((report: any) => {
        let newReport = report;

        if (
          report.status === "NA" ||
          report.status === "TE" ||
          report.status === "AR"
        ) {
          newReport = {
            ...newReport,
            status_alert: "default",
          };
        } else if (moment().diff(report.createdAt || 0, "hours") < 30) {
          newReport = {
            ...newReport,
            status_alert: "success",
          };
        } else if (
          moment().diff(report.createdAt || 0, "hours") <= 36 &&
          moment().diff(report.createdAt || 0, "hours") >= 30
        ) {
          newReport = {
            ...newReport,
            status_alert: "warning",
          };
        } else {
          newReport = {
            ...newReport,
            status_alert: "error",
          };
        }
        return newReport;
      });

    if (filters?.baliff_id && filters?.baliff_id != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) => report.bailiff_id === filters.baliff_id
      );
    }

    if (filters?.secretary_id && filters?.secretary_id != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) => report.secretary_id === filters.secretary_id
      );
    }

    if (filters?.year && filters?.year != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) =>
          moment(report.updatedAt).format("YYYY") === filters.year
      );
    }

    if (filters?.month && filters?.month != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) =>
          moment(report.created_at).format("MM") === filters.month
      );
    }

    if (filters?.status && filters?.status != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) => report.status === filters.status
      );
    }

    if (filters?.searchText && filters?.searchText != "") {
      reportsFiltered = reportsFiltered.filter((report: any) =>
        report?.name?.toLowerCase().includes(filters.searchText.toLowerCase())
      );
    }

    reportsFiltered = reportsFiltered.map((report: any) => {
      return {
        ...report,
        amount_without_taxes_pages:
          (
            report.count_pages_billing - Math.round(report.count_photos_billing / 2)
          ) * report.group_price_page,
        amount_without_taxes_photos:
          report.count_photos_billing * report.group_price_photo
      };
    });

    setReportsFiltered(reportsFiltered);
  }, [reports, filters]);

  useEffect(() => {
    if (
      modalComments.visible &&
      reportsFiltered &&
      reportsFiltered.length > 0
    ) {
      const report = reportsFiltered.find(
        (report: any) => report.id === modalComments.data.id
      );
      setModalComments({
        ...modalComments,
        data: report,
      });
    }
  }, [reportsFiltered]);

  const columns = [
    {
      title: "Facturation",
      key: "bullet",
      width: "5%",
      render: (report) => {
        return (
          <span>
            <Badge status={report.invoiced ? "success" : "error"} />
          </span>
        );
      },
    },
    {
      title: "Date",
      key: "date",
      align: "left" as AlignType,
      width: "15%",
      render: (report) => {
        return (
          <span>
            {moment(report.createdAt).format("DD/MM/YYYY")}{" "}
            {moment(report.createdAt).format("HH:mm")}
          </span>
        );
      },
    },
    {
      title: "Huissier",
      key: "name",
      align: "left" as AlignType,
      width: "15%",
      render: (report) => {
        const user = users?.filter(
          (user: any) => user.id === report.bailiff_id
        )[0];
        return (
          <Space size="middle">
            <a
              style={{ textDecoration: "underline" }}
              onClick={(e) => {
                e.stopPropagation();
                setModalInstructions({
                  data: user,
                  visible: true,
                });
              }}
            >
              {user?.last_name}
            </a>
          </Space>
        );
      },
    },
    {
      title: "Nom du constat",
      key: "name",
      align: "left" as AlignType,
      width: "35%",
      render: (data) => data.name,
    },
    {
      title: "Éléments déposés",
      key: "deposited_items",
      align: "left" as AlignType,
      width: "5%",
      render: (report) => (
        <div>
          <Space
            size="middle"
            onClick={(e) => {
              e.stopPropagation();
              report.deposited_items.forEach((file) => {
                saveAs(file.linkUrl, file.originalname);
              });
            }}
          >
            <a>{report.deposited_items.length || "-"}</a>
            {report.deposited_items.length > 0 && (
              <>
                <div>
                  <a>
                    <VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                  </a>
                </div>
                <a href={report.deposited_items[0].file_url} download></a>
                <a>
                  {FileUtils.bytesToMb(
                    report.deposited_items.reduce((acc: any, item: any) => {
                      return acc + item.size;
                    }, 0)
                  ) + "Mo"}
                </a>
              </>
            )}
          </Space>
        </div>
      ),
    },
    {
      title: "Éléments retournés",
      key: "deposited_items",
      align: "left" as AlignType,
      render: (report) => (
        <div>
          <Space
            size="middle"
            onClick={(e) => {
              e.stopPropagation();
              report.returned_items.forEach((file) => {
                saveAs(file.linkUrl, file.originalname);
              });
            }}
          >
            <a>{report.returned_items.length || "-"}</a>
            {report.returned_items.length > 0 && (
              <>
                <div>
                  <a>
                    <VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                  </a>
                </div>
                <a href={report.returned_items[0].file_url} download></a>
                <a>
                  {FileUtils.bytesToMb(
                    report.returned_items.reduce((acc: any, item: any) => {
                      return acc + item.size;
                    }, 0)
                  ) + "Mo"}
                </a>
              </>
            )}
          </Space>
        </div>
      ),
    },
    {
      title: "Tarif",
      key: "total_billing_amount",
      align: "left" as AlignType,
      render: (report) => {
        const value = report?.amount_without_taxes_pages
          ? (report.amount_without_taxes_pages + report.amount_without_taxes_photos)?.toFixed(2) + "€"
          : "-";
        return <span>{value}</span>;
      },
    },
    {
      title: "Secrétaire",
      key: "secretaire",
      align: "left" as AlignType,
      width: "10%",
      render: (report) => {
        const secretary = users?.find(
          (user: any) => user.id === report.secretary_id
        );

        return (
          <Space size="middle">
            {users && (
              <span style={{ textAlign: "center" }}>
                {secretary
                  ? secretary.first_name + " " + secretary.last_name
                  : "-"}
              </span>
            )}
          </Space>
        );
      },
    },
    {
      title: "Commentaire",
      key: "operation",
      align: "center" as AlignType,
      render: (data) => (
        <Space size="small">
          <a
            className={data?.comments_without_read?.length > 0 ? "red-dot-container" : ""}
            onClick={(e) => {
              e.stopPropagation();
              setModalComments({
                visible: true,
                data: data,
              });
            }}
          >
            {data?.comments_without_read?.length > 0 ? (
              <div className="red-dot-alert" />
            ) : null}
            <MailOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </a>
        </Space>
      ),
    },
    {
      title: "Désarchiver",
      key: "désarchiver",
      align: "center" as AlignType,
      render: (report) => (
        <Space size="small">
          <a
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setUnarchiveReport({ report_id: report.id }));
            }}
          >
            <RollbackOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </a>
        </Space>
      ),
    },
  ];

  return (
    <div className="dashboard-right-section constatsencours_wrapper">
      <Content style={{ marginLeft: "0px" }}>
        <Breadcrumb>
          <Breadcrumb.Item>Constats</Breadcrumb.Item>
          <Breadcrumb.Item>Constats Archivés</Breadcrumb.Item>
        </Breadcrumb>
      </Content>
      <ModalInstructions
        visible={modalInstructions.visible}
        onClose={() => setModalInstructions({ visible: false, data: {} })}
        data={modalInstructions.data}
        onOpenFile={(data) =>
          setModalViewDocument({ visible: true, data: { ...data } })
        }
      />
      <ModalDocument
        visible={modalViewDocument.visible}
        data={modalViewDocument.data}
        onClose={() => setModalViewDocument({ visible: false, data: {} })}
      />
      <ModalComments
        visible={modalComments.visible}
        onClose={() => setModalComments({ visible: false, data: {} })}
        onSend={(data) => {
          dispatch(addCommentReport(data));
        }}
        data={modalComments.data}
      />
      <div className="topfour-coloum">
        <Row gutter={[24, 24]}>
          <Col
            className="gutter-row"
            xs={24}
            md={12}
            lg={8}
            onClick={() => history.push(RoutesDashboard.reportsInProgessAdmin)}
          >
            <div className="dashboard-top-one clickable">
              <Row gutter={[2, 24]}>
                <Col className="gutter-row" xs={4} md={8} lg={4}>
                  <div className="dashboard-icon-center">
                    <img
                      src={"/images/dashboard-top-icon-one.svg"}
                      alt="icon-one"
                      className={styles.responsive_img_card}
                    />
                  </div>
                </Col>
                <Col className="gutter-row" xs={20} md={16} lg={20}>
                  <p>Constats</p>
                  <h2>En cours</h2>
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            md={12}
            lg={8}
            onClick={() => history.push(RoutesDashboard.reportsTerminatedAdmin)}
          >
            <div className="dashboard-top-two clickable">
              <Row gutter={[2, 24]}>
                <Col className="gutter-row" xs={4} md={8} lg={4}>
                  <div className="dashboard-icon-center">
                    <img
                      src={"/images/dashboard-top-icon-two.svg"}
                      alt="icon-two"
                      className={styles.responsive_img}
                    />
                  </div>
                </Col>
                <Col className="gutter-row" xs={20} md={16} lg={20}>
                  <p>Constats</p>
                  <h2>Terminés</h2>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className="form_tableouter_wrapper">
        <h2 className="pagetitle-table">CONSTATS ARCHIVÉS</h2>
        <div className="tabletop-filter-section">
          <Row align={"middle"}>
            <Col className="gutter-row" xs={8} md={10} lg={6}>
              <Select
                defaultValue="10"
                className="table-rows-select"
                onChange={(value: any) =>
                  setFilters({ ...filters, itemsPerPage: Number(value) })
                }
              >
                <Option value="10">10</Option>
                <Option value="20">20</Option>
                <Option value="30">30</Option>
                <Option value="40">40</Option>
                <Option value="50">50</Option>
                <Option value="60">60</Option>
              </Select>
            </Col>
            <Col className="gutter-row" xs={16} md={7} lg={15}>
              <Input
                size="large"
                className="table-search-input"
                placeholder="Rechercher..."
                prefix={<SearchOutlined className="searchiconalign" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                onChange={(value) =>
                  setFilters({ ...filters, searchText: value.target.value })
                }
              />
            </Col>
            <Col className="gutter-row" xs={24} md={7} lg={3}>
              <Link
                to="#"
                onClick={() =>
                  setFilters({ ...filters, visible: !filters.visible })
                }
                className="tabletop-filter-options"
              >
                Recherche avancée
              </Link>
            </Col>
          </Row>
        </div>
        {filters.visible && (
          <AdvanceFilterOptions
            isNew={true}
            handleChangeAdvance={(value) => {
              setFilters({ ...filters, ...value });
            }}
          />
        )}
        {reportsFiltered && (
          <Table
            columns={columns}
            className="constatsencours-tables manageiconforalign"
            rowKey={(record) => record.id}
            expandable={{
              expandedRowRender: (record) => (
                <ExpandedRowConstat
                  record={record}
                  enableUploadDeposited={false}
                  enableUploadReturned={false}
                  enableDeleteDeposited={false}
                  enableDeleteReturned={false}
                  showDataRetrocession
                  showDataBilling
                  onOpenFile={(data: any) =>
                    setModalViewDocument({
                      visible: true,
                      data: { ...data },
                    })
                  }
                  onOpenComments={(report_id: any, comments: any) =>
                    setModalComments({
                      visible: true,
                      data: { ...comments },
                    })
                  }
                />
              ),
              rowExpandable: (record: any) => true,
              expandRowByClick: true,
              expandIconColumnIndex: -1,
            }}
            dataSource={reportsFiltered || []}
            pagination={{
              pageSize: filters.itemsPerPage,
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Aucun constat."
                />
              ),
            }}
          />
        )}
      </div>
    </div>
  );
}

export default ReportsArchivedAdmin;
