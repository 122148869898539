import globals from "../helpers/globals";
const axios = require("axios").default;
const URL = globals.domain;

const getAllGroupsAPI = (params) => {
  const token = localStorage.getItem("token")
  return axios
    .create({
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
    .get(`${URL}/api/v1/group`, { params })
}

const getGroupByID = (id) => {
  const token = localStorage.getItem("token")
  return axios
    .create({
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
    .get(`${URL}/api/v1/group/${id}`)
}

const createGroupAPI = (data) => {
  const token = localStorage.getItem("token")
  return axios
    .create({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .post(`${URL}/api/v1/group`, data)
}

const updateGroupByIdAPI = (id, data) => {
  const token = localStorage.getItem("token")
  return axios
    .create({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .patch(`${URL}/api/v1/group/${id}`, data)
}
export const groupServices = {
  getAllGroupsAPI,
  getGroupByID,
  createGroupAPI,
  updateGroupByIdAPI,
}
