import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Table,
  Input,
  Space,
  Row,
  Col,
  Select,
  Breadcrumb,
  message,
  Modal,
  Comment,
  Avatar,
  Upload,
  Progress,
  Empty,
  Badge,
} from "antd";
import { AlignType } from 'rc-table/lib/interface'
import {
  VerticalAlignBottomOutlined,
  MailOutlined,
  FolderViewOutlined,
  PlusOutlined,
  CaretRightOutlined,
  SearchOutlined,
  RollbackOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { reportServices } from "../../../_services/reportServices";
import moment from "moment";
import "moment/locale/fr-ch";
import BailiffHeader from "../../components/bailiffHeader";
import Commonbailiffheader from "../commonbailiffheader";
import { useDispatch, useSelector } from "react-redux";
import AdvanceFilterOptions from "../../../components/AdvanceFilterOptions";
import axios from "axios";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import Grid from "@material-ui/core/Grid";
import { formatRecName } from "../../../utils/functions/formatRecName";
import { fileTypeReturn } from "../../../utils/functions/fileTypeReturn";
import { saveAs } from "file-saver";
import {
  addCommentReport,
  getReports,
  resetReportState,
  setArchiveReport,
} from "../../../redux/report";
import { getAllUsers } from "../../../redux/user";
import FileUtils from "../../../utils/files";
import ExpandedRowConstat from "../../../components/ExpandedRowConstat/ExpandedRowConstat";
import ModalDocument from "../../../components/ModalDocument/ModalDocument";
import ModalArchiveConstat from "../../../components/ModalArchiveConstat/ModalArchiveConstat";
import ModalComments, { userRoles } from "../../../components/ModalComments";
import { commentUtils } from "../../../utils/functions/comments";

moment.locale("fr");

const { Option } = Select;

function ReportsArchivedMonthBailiff({ history }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.auth);
  const {
    reports,
    successAddFilesReport,
    successRemoveFilesReport,
    successSetArchiveReport,
    successAddCommentReport,
    errorAddCommentReport,
    successMarkAsReadCommentReport
  } = useSelector((state: any) => state.report);
  const { users, loadingGetAllUsers, successGetAllUsers, errorGetAllUsers } =
    useSelector((state: any) => state.user);
  const [reportsFiltered, setReportsFiltered]: any = useState([]);
  const [filters, setFilters]: any = useState({
    visible: false,
    itemsPerPage: 10,
    searchText: "",
  });
  const [modalViewDocument, setModalViewDocument] = useState({
    visible: false,
    data: {},
  });
  const [modalComments, setModalComments]: any = useState({
    visible: false,
    data: {},
  });
  const [modalArchiveConstat, setModalArchiveConstat]: any = useState({
    visible: false,
    data: {},
  });

  useEffect(() => {
    dispatch(resetReportState());
    dispatch(getAllUsers());
    dispatch(getReports({ states: ["AR"], bailiff_id: user.id }));
  }, [
    dispatch,
    successAddFilesReport,
    successRemoveFilesReport,
    successSetArchiveReport,
    successAddCommentReport,
    errorAddCommentReport,
    successMarkAsReadCommentReport
  ]);

  useEffect(() => {
    let reportsFiltered = reports;

    reportsFiltered = reportsFiltered.map((report: any) => {
      let newReport = report;
      if (
        report.status === "NA" ||
        report.status === "TE" ||
        report.status === "AR"
      ) {
        newReport = {
          ...newReport,
          status_alert: "default",
        };
      } else if (moment().diff(report.createdAt || 0, "hours") < 30) {
        newReport = {
          ...newReport,
          status_alert: "success",
        };
      } else if (
        moment().diff(report.createdAt || 0, "hours") <= 36 &&
        moment().diff(report.createdAt || 0, "hours") >= 30
      ) {
        newReport = {
          ...newReport,
          status_alert: "warning",
        };
      } else {
        newReport = {
          ...newReport,
          status_alert: "error",
        };
      }
      return newReport;
    });

    if (filters?.baliff_id && filters?.baliff_id != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) => report.bailiff_id === filters.baliff_id
      );
    }

    if (filters?.secretary_id && filters?.secretary_id != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) => report.secretary_id === filters.secretary_id
      );
    }

    if (filters?.year && filters?.year != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) =>
          moment(report.updatedAt).format("YYYY") === filters.year
      );
    }

    if (filters?.month && filters?.month != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) =>
          moment(report.created_at).format("MM") === filters.month
      );
    }

    if (filters?.status && filters?.status != "") {
      reportsFiltered = reportsFiltered.filter(
        (report: any) => report.status === filters.status
      );
    }

    if (filters?.searchText && filters?.searchText != "") {
      reportsFiltered = reportsFiltered.filter((report: any) =>
        report?.name?.toLowerCase().includes(filters.searchText.toLowerCase())
      );
    }

    setReportsFiltered(reportsFiltered);
  }, [reports, filters]);

  useEffect(() => {
    if (
      modalComments.visible &&
      reportsFiltered &&
      reportsFiltered.length > 0
    ) {
      const report = reportsFiltered.find(
        (report: any) => report.id === modalComments.data.id
      );
      setModalComments({
        ...modalComments,
        data: report,
      });
    }
  }, [reportsFiltered]);

  useEffect(() => {
    if (successSetArchiveReport) {
      message.success("Constat archivé avec succès");
    }
  }, [successSetArchiveReport]);

  const columns: any = [
    {
      title: "Date",
      key: "date",
      width: "15%",
      render: (report) => {
        return (
          <span>
            {moment(report.createdAt).format("DD/MM/YYYY")}{" "}
            {moment(report.createdAt).format("HH:mm")}
          </span>
        );
      },
    },
    { title: "Nom du constat", dataIndex: "name", key: "name" },
    {
      title: "Éléments déposés",
      key: "deposited_items",
      width: "5%",
      render: (report) => (
        <div>
          <Space
            size="middle"
            onClick={(e) => {
              e.stopPropagation();
              report.deposited_items.forEach((file) => {
                saveAs(file.linkUrl, file.originalname);
              });
            }}
          >
            <a>{report.deposited_items.length || "-"}</a>
            {report.deposited_items.length > 0 && (
              <>
                <div>
                  <a>
                    <VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                  </a>
                </div>
                <a href={report.deposited_items[0].file_url} download></a>
                <a>
                  {FileUtils.bytesToMb(
                    report.deposited_items.reduce((acc: any, item: any) => {
                      return acc + item.size;
                    }, 0)
                  ) + "Mo"}
                </a>
              </>
            )}
          </Space>
        </div>
      ),
    },
    {
      title: "Éléments retournés",
      key: "returned_items",
      width: "5%",
      render: (report) => (
        <div>
          <Space
            size="middle"
            onClick={(e) => {
              if (report.status != "TE") return;
              e.stopPropagation();
              report.returned_items.forEach((file) => {
                saveAs(file.linkUrl, file.originalname);
              });
            }}
          >
            <a>
              {report.status == "TE"
                ? report.returned_items.length || "-"
                : "-"}
            </a>
            {report.returned_items.length > 0 && report.status == "TE" && (
              <>
                <div>
                  <a>
                    <VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                  </a>
                </div>
                <a href={report.returned_items[0].file_url} download></a>
                <a>
                  {FileUtils.bytesToMb(
                    report.returned_items.reduce((acc: any, item: any) => {
                      return acc + item.size;
                    }, 0)
                  ) + "Mo"}
                </a>
              </>
            )}
          </Space>
        </div>
      ),
    },
    { title: "Pages", dataIndex: "count_pages_billing", key: "count_pages" },
    { title: "Photos", dataIndex: "count_photos_billing", key: "count_photos" },
    {
      title: "Montant HT",
      key: "amount",
      render: (report: any) => {
        return (
          <span>
            {(
              (report.count_pages_billing - Math.round(report.count_photos_billing / 2) * report.group_price_page) +
              report.count_photos_billing * report.group_price_photo
            ).toFixed(2)}
            €
          </span>
        );
      },
    },
    {
      title: "Commentaire",
      key: "operation",
      align: "center" as AlignType,
      width: "5%",
      render: (data, info, index, showOne = false) => {
        const comments = commentUtils.filterByRole({
          comments: data?.comments,
          role: userRoles.bailiff,
          user,
          users,
        });

        const commentsWithoutRead = comments.filter((comment: any) =>
          data.comments_without_read.includes(comment.id)
        );

        return (
          <Space size="small">
            <a
              className={commentsWithoutRead?.length > 0 ? "red-dot-container" : ""}
              onClick={(e) => {
                e.stopPropagation();
                setModalComments({
                  visible: true,
                  data: data,
                });
              }}
            >
              {commentsWithoutRead?.length > 0 ? <div className="red-dot-alert" /> : null}
              <MailOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </a>
          </Space>
        );
      },
    },
  ];

  return (
    <div
      className="dashboard-right-section creer-constat-wrapper noleftmenu_section dashboard-bailiff"
      style={{ justifyContent: "center", display: "flex", width: "100%" }}
    >
      <ModalDocument
        visible={modalViewDocument.visible}
        data={modalViewDocument.data}
        onClose={() => setModalViewDocument({ visible: false, data: {} })}
      />
      <ModalArchiveConstat
        visible={modalArchiveConstat.visible}
        onClose={() => setModalArchiveConstat({ visible: false, data: {} })}
        onArchive={() => {
          dispatch(
            setArchiveReport({ report_id: modalArchiveConstat.data.report_id })
          );
          setModalArchiveConstat({ visible: false, data: {} });
        }}
      />
      <ModalComments
        visible={modalComments.visible}
        onClose={() => setModalComments({ visible: false, data: {} })}
        onSend={(data) => {
          dispatch(addCommentReport(data));
        }}
        data={modalComments.data}
      />
      <div>
        <Commonbailiffheader />
        <h2 className="pagetitle-table">MES CONSTATS ARCHIVÉS</h2>
        <Grid container>
          <Grid item lg={4} xs={12}>
            <Select
              defaultValue="one"
              className="table-rows-select"
              onChange={(value: any) =>
                setFilters({ ...filters, itemsPerPage: Number(value) })
              }
            >
              <Option value="one">10</Option>
              <Option value="two">20</Option>
              <Option value="three">30</Option>
              <Option value="four">40</Option>
              <Option value="five">50</Option>
            </Select>
          </Grid>
          <Grid lg={3} xs={12} className="GridItem">
            <Link to="/dashboard/createanalert">
              <Button
                htmlType="submit"
                style={{
                  height: "2rem",
                  border: "1px solid #40A9FF",
                  color: "#40A9FF",
                  backgroundColor: "#ffffff",
                  borderRadius: "4px",
                }}
              >
                Signaler une absence ou un congé
              </Button>
            </Link>
          </Grid>

          <Grid lg={3} xs={12} className="GridItem">
            <Input
              size="large"
              style={{ height: "2rem" }}
              className="table-search-input responsiveflaot"
              placeholder="Rechercher..."
              prefix={<SearchOutlined className="searchiconalign" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
              onChange={(value) =>
                setFilters({ ...filters, searchText: value.target.value })
              }
            />
          </Grid>

          {/* <Link to="" className="tabletop-filter-options">
                Recherche avancée
              </Link> */}
          <Grid lg={2} xs={12} md={2} className="GridItem">
            <Link
              to="#"
              className="tabletop-filter-options"
              onClick={() =>
                setFilters({ ...filters, visible: !filters.visible })
              }
            >
              Recherche avancée
            </Link>
          </Grid>

          {/*on click div to show and hide*/}
          {filters.visible && (
            <Grid lg={12} style={{ marginTop: "15px" }}>
              <AdvanceFilterOptions
                isNew={true}
                handleChangeAdvance={(value) => {
                  setFilters({ ...filters, ...value });
                }}
              />
            </Grid>
          )}
          <Grid lg={12} xs={12} style={{ marginTop: "2%" }}>
            <Table
              columns={columns}
              rowKey={(record) => record.id}
              expandable={{
                expandedRowRender: (record) => (
                  <ExpandedRowConstat
                    record={record}
                    enableUploadDeposited={false}
                    enableUploadReturned={false}
                    enableDeleteDeposited={false}
                    enableDeleteReturned={false}
                    onOpenFile={(data: any) =>
                      setModalViewDocument({
                        visible: true,
                        data: { ...data },
                      })
                    }
                    onOpenComments={(report_id: any, comments: any) =>
                      setModalComments({
                        visible: true,
                        data: { ...comments },
                      })
                    }
                    onArchive={(report_id: any) =>
                      setModalArchiveConstat({
                        visible: true,
                        data: { report_id },
                      })
                    }
                  />
                ),
                rowExpandable: (record: any) => true,
                expandRowByClick: true,
                expandIconColumnIndex: -1,
              }}
              dataSource={reportsFiltered || []}
              pagination={{
                pageSize: filters.itemsPerPage,
              }}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Aucun constat."
                  />
                ),
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ReportsArchivedMonthBailiff;
