import React, { ChangeEvent, FormEvent, useState } from "react"
import { Form, Input, Checkbox, Button, message } from "antd"
//import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link, useHistory, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import styles from "./styles.module.scss"
// import logo from '../../assets/logo-crc-extranet-white.png';
import { loginUser, setUser, setLogging } from "../../redux/auth/actions"
import { authenticationService } from "../../_services/authentication"
import {
  ILoginResponse,
  ILogin,
} from "../../interfaces/authenticationInference.interface"
interface Props { }
const Login: React.FC<Props> = ({ }) => {
  const { loggingIn, loginError } = useSelector((state: any) => state.auth)
  const dispatch = useDispatch()
  const history = useHistory()

  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)
  const action = queryParameters.get("action")
  if (action === "denyUser") {
    message.info("La demande de création de compte a été refusée. Le nouveau compte a été supprimé.")
  } else if (action === "approveUser") {
    message.success("La demande de création de compte a été approuvée. Le nouveau compte est maintenant actif.")
  }

  const onLoginSuccess = () => {
    history.push("/dashboard/constatsencours")
  }

  const onFinish = (values: {
    email: string
    password: string
    remember: boolean
  }) => {
    const { email, password, remember } = values
    const data = { email, password }
    dispatch(loginUser(data, remember, onLoginSuccess))
  }

  const handleSubmit = async (values: {
    email: string
    password: string
    remember: boolean
  }) => {
    const { email, password, remember } = values
    dispatch(setLogging(true))
    const logindata: ILogin = {
      email,
      password,
    }
    await authenticationService
      .loginAPI(logindata)
      .then((response: any) => response.data)
      .then((response: ILoginResponse) => {
        if (response.tokens) {
          localStorage.setItem("token", response.tokens.access.token)
          dispatch(setUser(response.user))
          dispatch(setLogging(false))
          history.push("/dashboard")
        }
      })
      .catch((error: any) => {
        console.error(error)
        dispatch(setLogging(false))
      })
  }

  return (
    <section className={styles.login_wrapper} id="loginscreenwrapper">
      <div className={styles.login_form_container}>
        <div className={styles.login_form_logo}>
          <img
            src={"/images/logo.svg"}
            alt="Logo"
            className={styles.responsive_img}
          />
        </div>
        <Form
          name="normal_login"
          initialValues={{ remember: true }}
          // onFinish={onFinish}
          id="loginform_wrapper"
          className={styles.login_form}
          onFinish={handleSubmit}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Veuillez entrer votre email" },
              // { type: "email", message: "Veuillez entrer une adresse email valide" },
            ]}
            validateStatus={loginError && "error"}
          >
            <div className={styles.forminput_wrappers}>
              <img
                src={"/images/login-user.svg"}
                alt="Logo"
                className={styles.responsive_img}

              />
              <Input
                type="text"
                size="large"
                placeholder="Votre identifiant"
                disabled={loggingIn}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Veuillez entrer votre mot de passe",
              },
            ]}
            validateStatus={loginError && "error"}
            help={loginError || null}
          >
            <div className={styles.formpassword_wrappers}>
              <img
                src={"/images/login-password.svg"}
                alt="Logo"
                className={styles.responsive_img}
              />
              <Input.Password
                type="password"
                placeholder="Votre mot de passe"
                disabled={loggingIn}
              />
            </div>
          </Form.Item>
          <Form.Item>
            <Form.Item
              name="remember"
              valuePropName="checked"
              noStyle
              className={styles.rememberlogin_form}
            >
              <Checkbox disabled={loggingIn} className={styles.rememberme_text}>
                Se rappeler de moi
              </Checkbox>
            </Form.Item>

            <Link className={styles.login_form_forgot} to="/recover/request">
              Mot de passe oublié ?
            </Link>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.login_form_submit_btn}
              disabled={loggingIn}
            >
              Connexion
            </Button>
          </Form.Item>
          <Form.Item className="createaccount-link">
            <p className={styles.text_after_loginbtn}>
              <Link className={styles.login_form_forgot} to="/createaccount">
                Pas encore enregistré ?
              </Link>
            </p>
          </Form.Item>
        </Form>
      </div>
    </section>
  )
}


export default Login
