import React from "react"
import { useSelector } from "react-redux"
import { Layout, Row, Col } from "antd"
import Topmenu from "../dashboard/topmenu"
import Leftmenu from "../dashboard/leftmenu"
import { routers } from "../../helpers/routes"

const LandingPage = (props) => {
  const { user } = useSelector((state: any) => state.auth)

  const _getRespectiveRoutes = () => {
    switch (user.role_id) {
      case "1":
        return routers._dashboardAdmin
      case "2":
        return routers._dashboardBailiff
      case "3":
        return routers._dashboardSecretary
    }
  }
  return (
    <Layout style={{maxWidth:"1400px"}}>
      <Row>
        <Topmenu />

        {user.role_id === "1" && (
          <Col xs={24} md={24} lg={3} >
            <div className="dashboard-left-section">
              <Leftmenu />
            </div>
          </Col>
        )}

        {user.role_id === "1" ? (
          <Col xs={24} md={24} lg={21} style={{backgroundColor:""}}>
            {_getRespectiveRoutes()}
          </Col>
        ) : (
          <Col xs={24} md={24} lg={24} >
          <>{_getRespectiveRoutes()}</>
          </Col>
        )}
      </Row>
    </Layout>
  )
}

export default LandingPage
