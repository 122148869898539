import React, { useEffect } from "react"
import { Route, Switch, useLocation, useHistory, Redirect } from "react-router-dom"
import Login from "./login/"
import RecoverRequest from "./recover/request"
import EmailRequest from "./emailavecsucces/request"
import NewPassword from "./passwordnew/request"
import RecoverToken from "./recover/token"
import Forgotpassword from "./forgotpassword/"
import Creermoncomp from "./creermoncomp"
import { useSelector, useDispatch } from "react-redux"
import { fetchMe, setLogging, setUser } from "../redux/auth/actions"
import LandingPage from "./landingPage/landing-page"
import Loader from "../components/AkimadLoader"
import ConfigurePrivateRoute from "../helpers/configure-private"
import { authenticationService } from "../_services/authentication"
import { IUsersResponse } from "../interfaces/authenticationInference.interface"
import CreateAccount from "./dashboard/createaccount"

const loggedOutRoutes = [
  "/login",
  "/forgotpassword",
  "/creermoncomp",
  "/recover/request",
  "/emailavecsucces/request",
  "/passwordnew/request",
  "/recover/",
  "/dashboard",
  "/dashboard/seethegroup",
  "/dashboard/adminaccount",
  "/dashboard/constatsencours",
  "/dashboard/constatsencour/",
  "/dashboard/bailiffaccount",
  "/dashboard/statistiques/",
  "/dashboard/retrocessionconstats/",
  "/dashboard/secretary",
]

const AppRouter = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { user, checkingSession, loggingIn } = useSelector(
    (state: any) => state.auth
  )

  useEffect(() => {
    const refreshToken = localStorage.getItem("token")
    if (refreshToken) {
      dispatch(setLogging(true))
      authenticationService
        .guardAPI(refreshToken)
        .then((response: any) => response.data)
        .then((response: IUsersResponse) => {
          dispatch(setUser(response))
          dispatch(setLogging(false))
          if (location.pathname === "/login" || location.pathname === "/") {
            history.push(`/dashboard`)
          }
        })
        .catch((error) => {
          localStorage.clear()
          dispatch(setLogging(false))
        })
    } else {
      localStorage.clear()
    }
  }, [])

  // useEffect(() => {
  //   // Check if user has session
  //   dispatch(fetchMe())
  // }, [dispatch])

  // useEffect(() => {
  //   if (checkingSession === false) {
  //     const { pathname } = location
  //     if (user) {
  //       console.log("user obj in:", user)
  //       // If user is logged in and outside dashboard, redirect in
  //       const redirect = loggedOutRoutes.some((route) => pathname.startsWith(route))
  //       // if (redirect && location.pathname !== "/dashboard/constatsencours") history.push("/dashboard/constatsencours");
  //       //  if(user.role_id == 1)
  //       //   history.push("/dashboard/constatsencours")
  //       //   else if(user.role_id == 2)
  //       //   history.push("/dashboard/bailiffaccount")
  //       //   else if(user.role_id == 3)
  //       //   history.push("/dashboard/secretary")
  //     } else {
  //       // If user is logged out and inside dashboard, redirect out
  //       console.log(pathname)
  //       const redirect = loggedOutRoutes.every(
  //         (route) => !pathname.startsWith(route)
  //       )
  //       if (redirect && location.pathname !== "/login") history.push("/login")
  //     }
  //   }
  // }, [location, checkingSession, history])

  if (!!loggingIn) {
    return <Loader />
  }

  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/recover/request" component={RecoverRequest} />
      <Route exact path="/emailavecsucces/request" component={EmailRequest} />
      <Route exact path="/passwordnew/request" component={NewPassword} />
      <Route exact path="/reset-password" component={RecoverToken} />
      <Route exact path="/creermoncomp" component={Creermoncomp} />
      <Route exact path="/forgotpassword" component={Forgotpassword} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/createaccount" component={CreateAccount} />
      <ConfigurePrivateRoute path="/dashboard" component={LandingPage} />
      <Redirect from="*" to="/" />
    </Switch>
  )
}

export default AppRouter
