import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Menu,
  Space,
  Row,
  Col,
  Form,
  Select,
  Upload,
  message,
  Comment,
  Avatar,
  Modal,
  Button,
  Carousel,
  Progress,
  Empty,
  Badge,
} from "antd";
import { AlignType } from 'rc-table/lib/interface'
import {
  VerticalAlignBottomOutlined,
  MailOutlined,
  PlusOutlined,
  CaretRightOutlined,
  RollbackOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import Commonsecretaryheader from "../commonsecretaryheader";
import { reportServices } from "../../../_services/reportServices";
import { facturesServices } from "../../../_services/facturesServices";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { fileTypeReturn } from "../../../utils/functions/fileTypeReturn";
import { formatRecName } from "../../../utils/functions/formatRecName";
import FileUtils from "../../../utils/files";
import {
  addCommentReport,
  getReports,
  resetReportState,
} from "../../../redux/report";
import { getAllUsers } from "../../../redux/user";
import { saveAs } from "file-saver";
import ModalInstructions from "../../../components/ModalInstructions/ModalInstructions";
import ModalDocument from "../../../components/ModalDocument/ModalDocument";
import ExpandedRowConstat from "../../../components/ExpandedRowConstat/ExpandedRowConstat";
import { RoutesDashboard } from "../../../helpers/routes";
import ModalComments, { userRoles } from "../../../components/ModalComments";
import {
  createBillSecretary,
  getAllBillsSecretary,
  removeBillSecretary,
  setPercentageProgress,
} from "../../../redux/billing";
import { commentUtils } from "../../../utils/functions/comments";

const { Option } = Select;

function ReportsRetrocessionsSecretary({ history }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.auth);
  const {
    reports,
    successAddCommentReport,
    errorAddCommentReport,
    successGetAllBillsSecretary,
    errorGetAllBillsSecretary,
    successMarkAsReadCommentReport,
  } = useSelector((state: any) => state.report);
  const {
    progress,
    loadingCreateBillSecretary,
    billsDocumentsSecretary,
    successCreateBillSecretary,
    successRemoveBillSecretary,
    errorRemoveBillSecretary,
  } = useSelector((state: any) => state.billing);
  const { users } = useSelector((state: any) => state.user);
  const [reportsFiltered, setReportsFiltered]: any = useState([]);
  const [
    billDocumentsSecretaryFiltered,
    setBillDocumentsSecretaryFiltered,
  ]: any = useState([]);
  const [filters, setFilters] = useState({
    month: moment().format("MMMM"),
    year: moment().format("YYYY"),
    secretary_id: "",
  });
  const [modalInstructions, setModalInstructions] = useState({
    visible: false,
    data: {},
  });
  const [modalViewDocument, setModalViewDocument] = useState({
    visible: false,
    data: {},
  });
  const [modalComments, setModalComments]: any = useState({
    visible: false,
    data: {},
  });

  useEffect(() => {
    dispatch(resetReportState());
    dispatch(getAllUsers());
    dispatch(
      getReports({
        states: ["TE"],
        secretary_id: user.id,
        without_filter_archived: true,
      })
    );
    dispatch(
      getAllBillsSecretary({
        secretary_id: user.id,
        only_bills_secretary: true,
      })
    );
  }, [
    dispatch,
    successAddCommentReport,
    errorAddCommentReport,
    successGetAllBillsSecretary,
    errorGetAllBillsSecretary,
    successCreateBillSecretary,
    successRemoveBillSecretary,
    errorRemoveBillSecretary,
    successMarkAsReadCommentReport,
  ]);

  useEffect(() => {
    if (reports?.length > 0 || billsDocumentsSecretary?.length > 0) {
      let reportsFiltered = reports.filter((report) => {
        const date = moment(report.createdAt);
        return (
          date.format("MMMM") === filters.month &&
          date.format("YYYY") === filters.year
        );
      });

      if (filters.secretary_id && filters.secretary_id !== "") {
        reportsFiltered = reportsFiltered.filter((report) => {
          return report.secretary_id === filters.secretary_id;
        });
      }

      const secretary = users.find(
        (userUsers: any) => userUsers.id === user.id
      );

      reportsFiltered = reportsFiltered.map((report: any) => {
        return {
          ...report,
          amount_without_taxes_pages:
            Math.round(
              report.count_pages_retrocession -
              report.count_photos_retrocession / 2
            ) * (secretary?.secretary_price_page || 0),
          amount_without_taxes_photos:
            report.count_photos_retrocession *
            (secretary?.secretary_price_photo || 0),
        };
      });

      let billDocumentsSecretaryFiltered = billsDocumentsSecretary.filter(
        (billDocument) => {
          const date = moment(billDocument.createdAt);
          return (
            date.format("MMMM") === filters.month &&
            date.format("YYYY") === filters.year
          );
        }
      );

      billDocumentsSecretaryFiltered = billDocumentsSecretaryFiltered.map(
        (billDocument: any) => {
          const files = billDocument.files;

          return {
            ...billDocument,
            file: files &&
              files.length > 0 && {
              uid: billDocument.id,
              name: files[0].originalname,
              status: "done",
              url: files[0].linkUrl,
            },
          };
        }
      );

      setReportsFiltered(reportsFiltered);
      setBillDocumentsSecretaryFiltered(billDocumentsSecretaryFiltered);
    }
  }, [reports, filters, billsDocumentsSecretary]);

  useEffect(() => {
    if (
      modalComments.visible &&
      reportsFiltered &&
      reportsFiltered.length > 0
    ) {
      const report = reportsFiltered.find(
        (report: any) => report.id === modalComments.data.id
      );
      setModalComments({
        ...modalComments,
        data: report,
      });
    }
  }, [reportsFiltered]);

  const columns: any = [
    {
      title: "Date",
      key: "date",
      align: "left" as AlignType,
      render: (report) => {
        return (
          <span>
            {moment(report.createdAt).format("DD/MM/YYYY")}{" "}
            {moment(report.createdAt).format("HH:mm")}
          </span>
        );
      },
    },
    {
      title: "Huissier",
      key: "name",
      align: "left" as AlignType,
      render: (report) => {
        const user = users?.filter(
          (user: any) => user.id === report.bailiff_id
        )[0];
        return (
          <Space size="middle">
            <a
              style={{ textDecoration: "underline" }}
              onClick={(e) => {
                e.stopPropagation();
                setModalInstructions({
                  data: user,
                  visible: true,
                });
              }}
            >
              {user?.last_name}
            </a>
          </Space>
        );
      },
    },
    {
      title: "Nom du constat",
      key: "name",
      align: "left" as AlignType,
      render: (report) => report.name,
    },
    {
      title: "Éléments déposés",
      key: "deposited_items",
      align: "left" as AlignType,
      width: "5%",
      render: (report) => (
        <div style={{ textAlign: "left" }}>
          <Space
            size="middle"
            onClick={(e) => {
              e.stopPropagation();
              report.deposited_items.forEach((file) => {
                saveAs(file.linkUrl, file.originalname);
              });
            }}
          >
            <a>{report.deposited_items.length || "-"}</a>
            {report.deposited_items.length > 0 && (
              <>
                <div>
                  <a>
                    <VerticalAlignBottomOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                  </a>
                </div>
                <a href={report.deposited_items[0].file_url} download></a>
                <a>
                  {FileUtils.bytesToMb(
                    report.deposited_items.reduce((acc: any, item: any) => {
                      return acc + item.size;
                    }, 0)
                  ) + "Mo"}
                </a>
              </>
            )}
          </Space>
        </div>
      ),
    },
    {
      title: "Date retour",
      key: "date",
      align: "left" as AlignType,
      render: (report) => {
        return (
          <span>
            {moment(report.updatedAt).format("DD/MM/YYYY")}{" "}
            {moment(report.updatedAt).format("HH:mm")}
          </span>
        );
      },
    },
    {
      title: "Nb pages texte",
      key: "count_pages",
      align: "center" as AlignType,
      width: "10%",
      render: (report) => report.count_pages_retrocession,
    },
    {
      title: "Nb photos",
      key: "count_photos",
      align: "center" as AlignType,
      width: "10%",
      render: (report) => report.count_photos_retrocession,
    },
    {
      title: "Montant HT",
      key: "amount",
      align: "center" as AlignType,
      width: "5%",
      render: (report) =>
        (
          report.amount_without_taxes_pages + report.amount_without_taxes_photos
        ).toFixed(2) + "€",
    },
    {
      title: "Commentaire",
      key: "operation",
      align: "center" as AlignType,
      width: "5%",
      render: (report, info, index, showOne = false) => {
        const comments = commentUtils.filterByRole({
          comments: report?.comments,
          role: userRoles.secretary,
          user,
          users,
        });

        const commentsWithoutRead = comments.filter((comment: any) =>
          report.comments_without_read.includes(comment.id)
        );

        return (
          <Space size="small">
            <a
              className={
                commentsWithoutRead?.length > 0 ? "red-dot-container" : ""
              }
              onClick={(e) => {
                e.stopPropagation();
                setModalComments({
                  visible: true,
                  data: report,
                });
              }}
            >
              {commentsWithoutRead?.length > 0 ? (
                <div className="red-dot-alert" />
              ) : null}
              <MailOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
            </a>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="dashboard-right-section creer-constat-wrapper noleftmenu_section retrocessionsendbill_wrapper">
      <ModalInstructions
        visible={modalInstructions.visible}
        onClose={() => setModalInstructions({ visible: false, data: {} })}
        data={modalInstructions.data}
        onOpenFile={(data) =>
          setModalViewDocument({ visible: true, data: { ...data } })
        }
      />
      <ModalDocument
        visible={modalViewDocument.visible}
        data={modalViewDocument.data}
        onClose={() => setModalViewDocument({ visible: false, data: {} })}
      />
      <ModalComments
        visible={modalComments.visible}
        onClose={() => setModalComments({ visible: false, data: {} })}
        onSend={(data) => {
          dispatch(addCommentReport(data));
        }}
        data={modalComments.data}
      />
      <div className="form_outer_wrapper secretarydashboard">
        <Commonsecretaryheader />
        <div className="form_tableouter_wrapper">
          <div
            className="tabletop-filter-section"
            style={{ marginTop: "0.5em" }}
          >
            <Row>
              <Col className="gutter-row" xs={24} md={12} lg={3}>
                <h2>RÉTROCESSION</h2>
                <Row className="gutter-row">
                  <Col className="gutter-row" lg={12}>
                    <Select
                      value={filters.year}
                      onChange={(value) => {
                        setFilters({
                          ...filters,
                          year: value,
                        });
                      }}
                    >
                      {Array.from(Array(moment().year() - 2019).keys()).map(
                        (year) => (
                          <Option value={`${2020 + year}`}>
                            {2020 + year}
                          </Option>
                        )
                      )}
                    </Select>
                  </Col>
                  <Col className="gutter-row" lg={12}>
                    <Select
                      value={filters.month}
                      onChange={(value) => {
                        setFilters({
                          ...filters,
                          month: value,
                        });
                      }}
                    >
                      {moment.months().map((month) => (
                        <Option value={month}>
                          {month.charAt(0).toUpperCase() + month.slice(1)}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col
                className="gutter-row"
                xs={24}
                md={7}
                lg={12}
                style={{ marginTop: "0.5em" }}
              >
                <div className="table-toptable-layout">
                  <div className="left-section">
                    <div className="left-section-top-title">
                      <div className="top-title-left-section">
                        CUMUL PÉRIODE
                      </div>
                      <div className="top-title-right-section">Total</div>
                    </div>
                    <div className="left-section-content">
                      <div className="left-section-content-left">
                        Nombre de constats
                      </div>
                      <div className="left-section-content-right">
                        {reportsFiltered?.length || 0}
                      </div>
                    </div>
                    <div className="left-section-content">
                      <div className="left-section-content-left">
                        Nombre de pages texte
                      </div>
                      <div className="left-section-content-right">
                        {reportsFiltered?.reduce((acc, report) => {
                          return acc + report.count_pages_retrocession;
                        }, 0)}
                      </div>
                    </div>
                    <div className="left-section-content">
                      <div className="left-section-content-left">
                        Nombre de photos
                      </div>
                      <div className="left-section-content-right">
                        {reportsFiltered?.reduce((acc, report) => {
                          return acc + report.count_photos_retrocession;
                        }, 0)}
                      </div>
                    </div>
                  </div>
                  <div className="right-section">
                    <div className="left-section-top-title">
                      <div className="top-title-right-section">Total HT</div>
                    </div>
                    <div className="left-section-content">
                      <div className="left-section-content-total">
                        {reportsFiltered
                          ?.reduce((acc, report) => {
                            return (
                              acc +
                              (report.amount_without_taxes_pages +
                                report.amount_without_taxes_photos)
                            );
                          }, 0)
                          ?.toFixed(2) + " €"}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                className="gutter-row facture-section"
                xs={24}
                md={6}
                lg={9}
                style={{ marginTop: "0.5em" }}
              >
                <div className="factures-wrapper">
                  <Row>
                    <div className="toptitlesection">
                      <div className="toptitleleft">FACTURE</div>
                      <div className="toptitleright">
                        <Link to={RoutesDashboard.billsMonthSecretary}>
                          Mes factures
                        </Link>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    {console.log(
                      "billDocumentsSecretaryFiltered",
                      billDocumentsSecretaryFiltered?.map(
                        (billDocument) => billDocument.file
                      )
                    )}
                    <div style={{ height: "fit-content", marginBottom: "1em" }}>
                      <Upload.Dragger
                        accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                        multiple={false}
                        showUploadList={true}
                        maxCount={1}
                        className={
                          !(billDocumentsSecretaryFiltered?.length > 0)
                            ? ""
                            : "disabled-upload-dragger"
                        }
                        fileList={
                          billDocumentsSecretaryFiltered?.length > 0
                            ? billDocumentsSecretaryFiltered?.map(
                              (billDocument: any) => billDocument.file
                            )
                            : undefined
                        }
                        onRemove={(file) => {
                          dispatch(
                            removeBillSecretary({
                              bill_id: file.uid,
                            })
                          );
                        }}
                        customRequest={FileUtils.generalDummyRequestLocal}
                        onChange={(info: any) => {
                          switch (info.file.status) {
                            case "done":
                              const formData = new FormData();
                              formData.append("secretary_id", user.id);
                              formData.append("bill", info.file.originFileObj);
                              formData.append(
                                "month",
                                moment(filters.month, "MMMM").format("MM")
                              );
                              formData.append("year", filters.year);
                              dispatch(
                                createBillSecretary({
                                  data: formData,
                                  setPercentageProgress,
                                  dispatch,
                                  section: "",
                                  id: "",
                                })
                              );
                              break;
                          }
                        }}
                      >
                        <p className="ant-upload-drag-icon">
                          <PlusOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                        </p>
                        <p className="ant-upload-text">Déposez votre facture</p>
                        <p className="ant-upload-hint">
                          Cliquez ou faites glisser pour télécharger votre pdf
                        </p>
                      </Upload.Dragger>
                      {loadingCreateBillSecretary && (
                        <Progress
                          percent={progress.percentage}
                          status="active"
                        />
                      )}
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
          <Table
            columns={columns}
            className="constatsencours-tables manageiconforalign"
            rowKey={(record) => record.id}
            expandable={{
              expandedRowRender: (record) => (
                <ExpandedRowConstat
                  record={record}
                  enableUploadDeposited={false}
                  enableUploadReturned={false}
                  enableDeleteDeposited={false}
                  enableDeleteReturned={false}
                  showDataRetrocession
                  onOpenFile={(data: any) =>
                    setModalViewDocument({
                      visible: true,
                      data: { ...data },
                    })
                  }
                  onOpenComments={(report_id: any, comments: any) =>
                    setModalComments({
                      visible: true,
                      data: { ...comments },
                    })
                  }
                />
              ),
              rowExpandable: (record: any) => true,
              expandRowByClick: true,
              expandIconColumnIndex: -1,
            }}
            dataSource={reportsFiltered || []}
            pagination={{
              pageSize: 10,
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Aucun constat."
                />
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ReportsRetrocessionsSecretary;
