import {
  ILogin,
  IRegisterAPI,
} from "../interfaces/authenticationInference.interface"
import globals from "../helpers/globals"
const axios = require("axios").default
const URL = globals.domain

const guardAPI = (token: string) =>
  axios
    .create({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .get(`${URL}/api/v1/users/me`)

const loginAPI = (data: ILogin) => {
  return axios
    .create({
      headers: {
        "Content-Type": "application/json",
      },
    })
    .post(`${URL}/api/v1/auth/login`, data)
}

const registerAPI = (data: IRegisterAPI) => {
  return axios
    .create({
      headers: {
        "Content-Type": "application/json",
      },
    })
    .post(`${URL}/api/v1/auth/register`, data)
}

const loginAsUserAPI = (data) => {
  const token = localStorage.getItem("token")
  return axios
    .create({
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .post(`${URL}/api/v1/auth/login-as`, data)
}

const recoverPasswordAPI = (data) => {
  return axios
    .create({
      headers: {
        "Content-Type": "application/json",
      },
    })
    .post(`${URL}/api/v1/auth/forgot-password`, data)
}

export const authenticationService = {
  guardAPI,
  loginAPI,
  registerAPI,
  loginAsUserAPI,
  recoverPasswordAPI
}
