import React, { useState } from "react"
import { Link, Route, Switch, useHistory } from "react-router-dom"
import {
  Layout,
  Table,
  Input,
  Badge,
  Menu,
  Dropdown,
  Space,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Breadcrumb,
  Button,
  Upload,
  message,
} from "antd"
import {
  UserOutlined,
  DownOutlined,
  InboxOutlined,
  CloudUploadOutlined,
  PlusOutlined,
} from "@ant-design/icons"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

import styles from "../styles.module.scss"

import Leftmenu from "../leftmenu"
import Topmenu from "../topmenu"
import FileUtils from "../../../utils/files"

const menu = (
  <Menu>
    <Menu.Item>Huissier</Menu.Item>
    <Menu.Item>Secrétaire</Menu.Item>
    <Menu.Item>Admin</Menu.Item>
  </Menu>
)
const { Dragger } = Upload

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result))
  reader.readAsDataURL(img)
}
const props = {
  name: "file",
  multiple: false,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file
    if (status !== "uploading") {
      console.log(info.file, info.fileList)
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`)
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`)
    }
  },
}

const { Content } = Layout
const { Option } = Select

const handleChange = () => {
  console.log(`selected`)
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!")
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!")
  }
  return isJpgOrPng && isLt2M
}

function CreateMyAccount() {
  const imguploadicon = require("../../../pages/images/uploadimage-icon.svg")
  const [imageUrl, setImage] = useState(
    require("../../../pages/images/uploadimage.svg")
  )
  const uploadButton = (
    <div>
      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )
  const handleChangeImage = (info) => {
    if (info.file.status === "uploading") {
      // this.setState({ loading: true });
      return
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (imageUrl) => {
          setImage(imageUrl)
        }

        // this.setState({
        //   imageUrl,
        //   loading: false,
        // }),
      )
    }
  }
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok")
    }, 0)
  }

  return (
    //   <Table
    //   columns={columns}
    //   expandable={{
    //     expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
    //     rowExpandable: record => record.date !== 'Not Expandable',
    //   }}
    //   dataSource={data}
    //   className="constats-table"
    // />
    <Layout>
      <Row>
        <Topmenu />
        <Col xs={24} md={3} lg={3}>
          <div className="dashboard-left-section">
            <Leftmenu />
          </div>
        </Col>
        <Col xs={24} md={21} lg={21}>
          <div className="dashboard-right-section">
          <Content style={{ marginLeft: "0px" }}>
              <Breadcrumb>
                <Breadcrumb.Item>Comptes</Breadcrumb.Item>
                <Breadcrumb.Item>Voir les comptes</Breadcrumb.Item>
              </Breadcrumb>
            </Content>

            <div className="form_outer_wrapper">
              <h2>
                <UserOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                CRÉER MON COMPTE
              </h2>
              <Form className="create_account secretaryacc_form">
                <Row>
                  <Col xs={24} md={24} lg={12}>
                    {/*<Form.Item>
                     <Select defaultValue="huissier" onChange={handleChange}>
                      <Option value="huissier">Huissier</Option>
                      <Option value="secrétaire">Secrétaire</Option>
                      <Option value="admin">Admin</Option>
                    </Select> 
                  </Form.Item>*/}
                  </Col>
                  <Col xs={24} md={24} lg={4}>
                    <div className="profile_uploadimg_wrapper">
                      <Form.Item>
                        <Upload
                          name="avatar"
                          showUploadList={false}
                          listType="picture-card"
                          className="avatar-uploader"
                          customRequest={FileUtils.generalDummyRequestLocal}
                          onChange={handleChangeImage}
                        >
                          <div className="uploadimg-wrapper">
                            {imageUrl ? (
                              <img
                                src={imageUrl}
                                alt="avatar"
                                style={{ width: "100%" }}
                              />
                            ) : (
                              uploadButton
                            )}
                            <div className="uploadimg-icons">
                              <img src={imguploadicon} alt="avatar-icon" />
                            </div>
                          </div>
                        </Upload>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={24} lg={12}>
                    <Form.Item
                      label="Civilité"
                      name="civility"
                      valuePropName="civility"
                      rules={[
                        { required: true, message: "Veuillez entér Civilité" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={24} lg={12}>
                    <Form.Item
                      label="Nom"
                      name="lastname"
                      valuePropName="lastname"
                      rules={[{ required: true, message: "Veuillez entér Nom" }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={24} lg={12}>
                    <Form.Item
                      label="Prénom"
                      name="firstname"
                      valuePropName="firstname"
                      rules={[{ required: true, message: "Veuillez entér Prénom" }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={24} lg={12}>
                    <Form.Item
                      label="Téléphone"
                      name="phone"
                      valuePropName="phone"
                      rules={[
                        { required: true, message: "Veuillez entér Téléphone" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={24} lg={12}>
                    <Form.Item
                      label="Email"
                      name="email"
                      valuePropName="email"
                      rules={[{ required: true, message: "Veuillez entér Email" }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={24} lg={12}>
                    <Form.Item
                      label="Siret"
                      name="siret"
                      valuePropName="siret"
                      rules={[{ required: true, message: "Veuillez entér Siret" }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={24} lg={12}>
                    <Form.Item label="Adresse postale*">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={24} lg={12}>
                    <Form.Item
                      label="CP/ Ville"
                      name="cpcity"
                      valuePropName="cpcity"
                      rules={[
                        { required: true, message: "Veuillez entér CP/ Ville" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={24} lg={12}>
                    <Form.Item
                      className="passwordbottom"
                      label="Mot de passe"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Entrez votre mot de passe, s'il vous plaît!",
                        },
                      ]}
                    >
                      <Input.Group>
                        <Row gutter={8}>
                          <Col span={12}>
                            {/* <Input.Password  defaultValue=""/> */}
                            <Form.Item
                              name="password"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Entrez votre mot de passe, s'il vous plaît!",
                                },
                              ]}
                              style={{ display: "inline-block" }}
                            >
                              <Input.Password
                                placeholder=""
                                className="form_password"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} md={24} lg={12}>
                    <Form.Item
                      label="Confirmer mot de passe*"
                      name="conpassword"
                      rules={[
                        { required: true, message: "Please Confirmer mot de passe" },
                      ]}
                    >
                      <Input.Group>
                        <Row gutter={8}>
                          <Col span={12}>
                            {/* <Input.Password  defaultValue=""/> */}
                            <Form.Item
                              name="conpassword"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Entrez votre mot de passe, s'il vous plaît!",
                                },
                              ]}
                              style={{ display: "inline-block" }}
                            >
                              <Input.Password
                                placeholder=""
                                className="form_password"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Input.Group>
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} md={24} lg={12} className="register-btn-mask">
                    <Form.Item label="  ">
                      <Button type="primary" className="validate-btn">
                        Valider
                      </Button>
                      <Button className="cancel-btn">Annuler</Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default CreateMyAccount
