import React, { useState, useEffect } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import {
  Layout,
  Table,
  Input,
  Badge,
  Menu,
  Dropdown,
  Space,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Breadcrumb,
  Button,
  Upload,
  message,
  InputNumber,
} from "antd";
import {
  CheckCircleOutlined,
  CloudUploadOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector, useDispatch } from "react-redux";
import { setPhoneLengthValidation } from "../../../redux/auth/actions";

import styles from "../styles.module.scss";
import { BASE_URL } from "../../../helpers/api";
// import Leftmenu from '../leftmenu';
import Topmenu from "../topmenu";
import { filesUploadServices } from "../../../_services/uploadFIles";
import { usersService } from "../../../_services/usersSerives";

import { setUser } from "../../../redux/auth/actions";
import FileUtils from "../../../utils/files";

const validIdentify = RegExp(/^[A-Za-z0-9]{3,}$/);
const validPassword = RegExp(
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
);
const validEmailRegex = RegExp(
  /^(([^<>()\\[\]\\.,;:\s@\\"]+(\.[^<>()\\[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i
);
const validPhone = RegExp(
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
);
const onlyNoAllowed = RegExp(/^[0-9]*$/);

const validZipCode = RegExp(/^\d{5}(?:[-\s]\d{4})?$/);

const menu = (
  <Menu>
    <Menu.Item>Huissier</Menu.Item>
    <Menu.Item>Secrétaire</Menu.Item>
    <Menu.Item>Admin</Menu.Item>
  </Menu>
);
const { Dragger } = Upload;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

//const { Content } = Layout;
const { Header, Footer, Sider, Content } = Layout;
const { Option } = Select;

const handleChange = () => {
  console.log(`selected`);
};

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Vous ne pouvez télécharger que des fichiers JPG / PNG!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("L'image doit être inférieure à 2 Mo!");
  }
  return isJpgOrPng && isLt2M;
}

function CreateaReportBailiff(props) {
  const [fileUploads, setFileUploads]: any = useState({
    selectedFile: null,
    selectedFileList: [],
  });
  const { user } = useSelector((state: any) => state.auth);
  const [bailiffProfileForm, setBailiffProfileForm] = useState({
    civility: user?.civility || "",
    last_name: user?.last_name || "",
    first_name: user?.first_name || "",
    telephone: user?.telephone || "",
    email: user?.email || "",
    studyName: user?.group_name || "",
    studyPhone: user?.group_telephone || "",
    accountingEmail: user?.group_email || "",
    address: user?.address || "",
    userID: user?.identity || "",
    password: user?.password || "",
    zipCode: user?.zip || "0",
    city: user?.city || " ",
    matrices_associated: [],
    errors: {
      civility: "",
      last_name: "",
      first_name: "",
      telephone: "",
      email: "",
      studyName: "",
      studyPhone: "",
      accountingEmail: "",
      address: "",
      userID: "",
      password: "",
      zipCode: "",
      city: "",
    },
  });
  const [showDragger, setShowDragger]: any = useState(false);
  const [matriceChange, setMatriceChange]: any = useState(false);
  useEffect(() => {}, []);

  useEffect(() => {
    let store = fileUploads;
    let temp: any = [];
    //console.log('user: ', user)
    if (user?.matrices_associated) {
      user.matrices_associated.map((d, i) => {
        temp.push({
          uid: i,
          name: d.split("/").splice(-1)[0],
          status: "done",
          url: d,
        });
      });
      store.selectedFileList = temp;
    }
    setFileUploads(store);
    setShowDragger(true);
  }, [user]);

  const onChangeDragger = async (info) => {
    const nextState: any = {};
    switch (info.file.status) {
      case "uploading":
        nextState.selectedFileList = info.fileList;
        break;
      case "done":
        nextState.selectedFile = info.fileList;
        nextState.selectedFileList = info.fileList;
        let data = new FormData();
        data.append("profile", info.file.originFileObj);
        await filesUploadServices
          .uploadFileAPI(data)
          .then((response) => {
            let temp: any = bailiffProfileForm;
            temp.matrices_associated.push(response.data.url);
            setBailiffProfileForm(temp);
            message.success(
              `${info.file.name} fichier téléchargé avec succès.`
            );
          })
          .catch((err) => console.error(err));
        break;

      default:
        // error or removed
        nextState.selectedFile = info.fileList;
        nextState.selectedFileList = info.fileList;
    }
    setFileUploads(nextState);
    setMatriceChange(true);
  };

  const notFilledPasswordField = () => {
    let errors = bailiffProfileForm.errors;
    // if (bailiffProfileForm.civility === "") {
    //   errors.civility = "Champ requis"
    // }
    // if (bailiffProfileForm.last_name === "") {
    //   errors.last_name = "Champ requis"
    // }
    // if (bailiffProfileForm.first_name === "") {
    //   errors.first_name = "Champ requis"
    // }
    // if (bailiffProfileForm.telephone === "") {
    //   errors.telephone = "Champ requis"
    // }
    // if (bailiffProfileForm.email === "") {
    //   errors.email = "Champ requis"
    // }
    // if (bailiffProfileForm.studyName === "") {
    //   errors.studyName = "Champ requis"
    // }
    // if (bailiffProfileForm.studyPhone === "") {
    //   errors.studyPhone = "Champ requis"
    // }
    // if (bailiffProfileForm.accountingEmail === "") {
    //   errors.accountingEmail = "Champ requis"
    // }
    // if (bailiffProfileForm.address === "") {
    //   errors.address = "Champ requis"
    // }
    // if (bailiffProfileForm.userID === "") {
    //   errors.userID = "Champ requis"
    // }
    // if (bailiffProfileForm.password === "") {
    //   errors.password = "Champ requis"
    // }
    // if (bailiffProfileForm.confirmPassword === "") {
    //   errors.confirmPassword = "Champ requis"
    // }
    // if (bailiffProfileForm.zipCode === "") {
    //   errors.zipCode = "Champ requis"
    // }
    // if (bailiffProfileForm.city === "") {
    //   errors.city = "Champ requis"
    // }
    setBailiffProfileForm({ ...bailiffProfileForm, ...{ errors } });
  };

  const validateForm = (formFields) => {
    notFilledPasswordField();
    let valid = true;
    Object.values(formFields.errors).forEach(
      (val: any) => val.length > 0 && (valid = false)
    );
    return valid;
  };

  const _submitHandler = async () => {
    if (validateForm(bailiffProfileForm)) {
      let data: any = {};
      data["role_id"] = user.role_id;
      data["civility"] = bailiffProfileForm.civility;
      data["last_name"] = bailiffProfileForm.last_name;
      data["first_name"] = bailiffProfileForm.first_name;
      data["telephone"] = bailiffProfileForm.telephone;
      // data["email"] = bailiffProfileForm.email
      data["studyName"] = bailiffProfileForm.studyName;
      data["studyPhone"] = bailiffProfileForm.studyPhone;
      data["accountingEmail"] = bailiffProfileForm.accountingEmail;
      data["address"] = bailiffProfileForm.address;
      data["identity"] = bailiffProfileForm.userID;
      data["password"] = bailiffProfileForm.password;
      data["zip"] = bailiffProfileForm.zipCode;
      data["city"] = bailiffProfileForm.city;
      if (bailiffProfileForm.password != "") {
        data["password"] = bailiffProfileForm.password;
      }
      data["profile"] = imageUrl;
      if (matriceChange === true) {
        data["matrices_associated"] = bailiffProfileForm.matrices_associated;
      }
      data["Instructions_to_secretaries"] = null;
      await usersService
        .updateUsersAPI(user.id, data)
        .then((response) => response.data)
        .then((response: any) => {
          dispatch(setUser(response));
          message.success("Compte modifié avec succès.");
          props.history.push("/dashboard");
        })
        .catch((err) => console.error(err));
    }
  };
  const imguploadicon = require("../../../pages/images/uploadimage-icon.svg");
  const [imageUrl, setImage] = useState(
    user.profile || require("../../../pages/images/uploadimage.svg")
  );
  const { isPhoneLength } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  //const [imageUrl, setImage] = useState('https://www.kindpng.com/picc/m/163-1636340_user-avatar-icon-avatar-transparent-user-icon-png.png');
  const uploadButton = (
    <div>
      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  // const _changeHandler = (event, name) => {
  //   setBailiffProfileForm({
  //     ...bailiffProfileForm,
  //     ...{ [name]: event?.target?.value || event },
  //   })
  // }

  const handleChangeImage = async (info) => {
    if (info.file.status === "done") {
      let data = new FormData();
      data.append("profile", info.file.originFileObj);
      await filesUploadServices
        .uploadFileAPI(data)
        .then((response) => {
          setImage(response.data.url);
        })
        .catch((err) => console.error(err));
    }
  };

  const _changeHandler = (event, name) => {
    let errors = bailiffProfileForm.errors;

    const { value } = event.target;
    switch (name) {
      case "last_name":
        errors.last_name =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "first_name":
        errors.first_name =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "telephone":
        errors.telephone = validPhone.test(value)
          ? ""
          : "Numéro de téléphone invalide.";
        break;
      case "password":
        errors.password = validPassword.test(value)
          ? ""
          : "Mauvais mot de passe.";
        break;

      case "first_name":
        errors.first_name =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "accountingEmail":
        errors.accountingEmail = validEmailRegex.test(value)
          ? ""
          : "Doit contenir au moins 3 caractères";
        break;
      case "address":
        errors.address =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "userID":
        errors.userID = validIdentify.test(value)
          ? ""
          : "Doit contenir au moins 3 caractères";
        break;
      case "zipCode":
        errors.zipCode = validZipCode.test(value)
          ? ""
          : "Doit contenir au moins 5 caractères";
        break;
      case "civility":
        errors.civility =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "city":
        errors.city =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "email":
        errors.email = validEmailRegex.test(value)
          ? ""
          : "Identifiant de messagerie non valide.";
        break;
      case "studyName":
        errors.studyName =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;

      case "studyPhone":
        errors.studyPhone = validPhone.test(value)
          ? ""
          : "Numéro de téléphone invalide.";
        break;

      default:
        break;
    }

    setBailiffProfileForm({
      ...bailiffProfileForm,
      ...{ errors, [name]: value },
    });
  };

  const onFinish = async (values) => {
    let data = {
      civility: values.civility,
      first_name: values.firstname,
      last_name: values.lastname,
      telephone: values.telephone,
      address: values.address,
      city: values.city,
      role_id: 2,
      profile:
        "http://localhost:8000/public/images/profile-2020-11-05T20:06:46.916Z-gbepvjettqttzqlz_1593258788.jpeg",
      identity: 787877,
      // rib: null,
      email: values.email,
      password: values.confirm_password,
      zip: 564009,
    };
    const postData = Object.keys(data)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`)
      .join("&");
    // return;
    let showNotification = {};
    let modalCloseStatus = 1;
    const response = await fetch(BASE_URL + "/api/v1/auth/register", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded", // <-- Specifying the Content-Type
      }),
      // headers: generalAuthTokenHeader(),
      body: postData,
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Discharge Planner",
            message: "Mauvaise réponse du serveur",
            type: "danger",
          };
        } else if (response.ok) {
        } else {
          showNotification = {
            title: "Discharge Planner",
            message: "Mauvaise réponse du serveur.",
            type: "danger",
          };
        }
        return response.json();
      })
      .then((data) => {
        if (data.status == "400" && data.message == "error.idexists") {
          // modalCloseStatus = 0;
          // showNotification = {
          //     title: "Discharge Planner",
          //     message: data.title,
          //     type: 'danger'
          // };
        } else if (data.user) {
          // showNotification = {
          //     title: "Success",
          //     message: "Status updated successfully",
          //     type: "success"
          // };
          message.success("Inscrivez-vous avec succès.");
          return true;
        }
      })
      .catch((error) => {
        // showNotification = {
        //     title: 'Discharge Planner',
        //     message: 'Something went wrong.Please try after sometime..',
        //     type: "danger"
        // };
      });
  };
  //  const handleNumberChange = value => {
  // 	const validateStatus = value.length === 10;
  // 	this.setState({
  // 	  validateStatus,
  // 	  value,
  // 	  errorMsg: isValid ? null : 'Invalid Mobile number'
  // 	});
  //   };
  function validateMobileNumber(value) {
    // const{ form } = this.props;
    // let mnumber = form.getFieldValue('phone');
    if (value !== "") {
      console.log("this is a mobil validator", value, value.toString().length);
    }

    if (value.toString().length === 10) {
      dispatch(setPhoneLengthValidation(null));
      console.log("numéro de mobile valide");
    } else {
      console.log("Type de formulaire non valide");
      dispatch(
        setPhoneLengthValidation(
          "Le téléphone doit être composé de 10 chiffres"
        )
      );
    }
  }
  return (
    //   <Table
    //   columns={columns}
    //   expandable={{
    //     expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
    //     rowExpandable: record => record.date !== 'Not Expandable',
    //   }}
    //   dataSource={data}
    //   className="constats-table"
    // />
    <Layout className="creer-constat-wrapper noleftmenu_section">
      <Topmenu />
      <Row>
        <Col xs={24} md={24} lg={24}>
          <div className="dashboard-right-section profile_bailiff_wrapper">
            <div className="form_outer_wrapper">
              <div className="onlyscreen_template_inner">
                <div className="onlyscreen_template_wrapper">
                  <div className="form_outer_onlyscreenwrapper">
                    <div className="form_onlyscreenwrapper">
                      <div className="userprofile-layout-wrapper">
                        <Layout>
                          {/* <div className="topalertonsuccess">
                            <CheckCircleOutlined />
                            Enregistré{" "}
                            <span className="close-alert">
                              <CloseOutlined />
                            </span>
                          </div> */}
                          <Sider>
                            <Menu
                              defaultSelectedKeys={["1"]}
                              defaultOpenKeys={["sub1"]}
                              mode="inline"
                              theme="light"
                            >
                              <Menu.Item>
                                <Link to="/dashboard/">Accueil</Link>
                              </Menu.Item>
                              <Menu.Item key="1">
                                <Link to="/dashboard/bailiffprofile">
                                  Profil
                                </Link>
                              </Menu.Item>
                              <Menu.Item>
                                <Link to="/dashboard/bailiffcalendar">
                                  Calendrier
                                </Link>
                              </Menu.Item>
                            </Menu>
                          </Sider>
                          <Layout>
                            <Header>
                              <h2>MON PROFIL</h2>
                            </Header>
                            <Content>
                              <div className="content-section-wrapper">
                                <div>
                                  <Form className="bailiffprofile-form">
                                    <Row>
                                      <Col xs={24} md={12} lg={12}>
                                        <div className="profile_uploadimg_wrapper profileimgresponsive">
                                          <Form.Item>
                                            <Upload
                                              name="avatar"
                                              showUploadList={false}
                                              listType="picture-card"
                                              className="avatar-uploader"
                                              customRequest={
                                                FileUtils.generalDummyRequestLocal
                                              }
                                              onChange={handleChangeImage}
                                            >
                                              <div className="uploadimg-wrapper">
                                                {imageUrl ? (
                                                  <img
                                                    src={imageUrl}
                                                    alt="avatar"
                                                    style={{ width: "100%" }}
                                                  />
                                                ) : (
                                                  uploadButton
                                                )}
                                                {/* <div className="uploadimg-icons">
                                                  <img src={imguploadicon} alt="avatar-icon" />
                                                </div> */}
                                              </div>
                                            </Upload>
                                            <h2>
                                              Vos identifiants de connexion
                                            </h2>
                                          </Form.Item>
                                        </div>
                                        <Form.Item
                                          label="Civilité"
                                          valuePropName="civility"
                                          validateStatus={
                                            bailiffProfileForm.errors.civility
                                              .length > 0
                                              ? "error"
                                              : "success"
                                          }
                                          help={
                                            bailiffProfileForm.errors.civility
                                          }
                                        >
                                          <Input
                                            placeholder="Civilité"
                                            className="inputfield-middle"
                                            value={bailiffProfileForm.civility}
                                            onChange={(e) =>
                                              _changeHandler(e, "civility")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Nom"
                                          valuePropName="last_name"
                                          validateStatus={
                                            bailiffProfileForm.errors.last_name
                                              .length > 0
                                              ? "error"
                                              : "success"
                                          }
                                          help={
                                            bailiffProfileForm.errors.last_name
                                          }
                                        >
                                          <Input
                                            placeholder="Nom"
                                            className="inputfield-middle"
                                            value={bailiffProfileForm.last_name}
                                            onChange={(e) =>
                                              _changeHandler(e, "last_name")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Prénom"
                                          valuePropName="first_name"
                                          validateStatus={
                                            bailiffProfileForm.errors.first_name
                                              .length > 0
                                              ? "error"
                                              : "success"
                                          }
                                          help={
                                            bailiffProfileForm.errors.first_name
                                          }
                                        >
                                          <Input
                                            placeholder="Prénom"
                                            className="inputfield-middle"
                                            value={
                                              bailiffProfileForm.first_name
                                            }
                                            onChange={(e) =>
                                              _changeHandler(e, "first_name")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Téléphone"
                                          valuePropName="telephone"
                                          validateStatus={
                                            bailiffProfileForm.errors.telephone
                                              .length > 0
                                              ? "error"
                                              : "success"
                                          }
                                          help={
                                            bailiffProfileForm.errors.telephone
                                          }
                                        >
                                          <Input
                                            placeholder="Téléphone"
                                            className="inputfield-middle"
                                            value={bailiffProfileForm.telephone}
                                            onChange={(e) =>
                                              _changeHandler(e, "telephone")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Email"
                                          valuePropName="email"
                                          validateStatus={
                                            bailiffProfileForm.errors.email
                                              .length > 0
                                              ? "error"
                                              : "success"
                                          }
                                          help={bailiffProfileForm.errors.email}
                                        >
                                          <Input
                                            placeholder="Email"
                                            className="inputfield-middle"
                                            value={bailiffProfileForm.email}
                                            disabled={true}
                                            onChange={(e) =>
                                              _changeHandler(e, "email")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Nom de l’étude"
                                          valuePropName="studyName"
                                          validateStatus={
                                            bailiffProfileForm.errors.studyName
                                              .length > 0
                                              ? "error"
                                              : "success"
                                          }
                                          help={
                                            bailiffProfileForm.errors.studyName
                                          }
                                        >
                                          <Input
                                            disabled={true}
                                            placeholder="Nom de l’étude"
                                            className="inputfield-middle"
                                            value={bailiffProfileForm.studyName}
                                            onChange={(e) =>
                                              _changeHandler(e, "studyName")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Téléphone de l’étude"
                                          valuePropName="studyPhone"
                                          validateStatus={
                                            bailiffProfileForm.errors.studyPhone
                                              .length > 0
                                              ? "error"
                                              : "success"
                                          }
                                          help={
                                            bailiffProfileForm.errors.studyPhone
                                          }
                                        >
                                          <Input
                                            disabled={true}
                                            placeholder="Téléphone de l’étude"
                                            className="inputfield-middle"
                                            value={
                                              bailiffProfileForm.studyPhone
                                            }
                                            onChange={(e) =>
                                              _changeHandler(e, "studyPhone")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Email de comptabilité"
                                          valuePropName="accountingEmail"
                                          validateStatus={
                                            bailiffProfileForm.errors
                                              .accountingEmail.length > 0
                                              ? "error"
                                              : "success"
                                          }
                                          help={
                                            bailiffProfileForm.errors
                                              .accountingEmail
                                          }
                                        >
                                          <Input
                                            disabled={true}
                                            placeholder="Email de comptabilité"
                                            className="inputfield-middle"
                                            value={
                                              bailiffProfileForm.accountingEmail
                                            }
                                            onChange={(e) =>
                                              _changeHandler(
                                                e,
                                                "accountingEmail"
                                              )
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Adresse postale"
                                          valuePropName="address"
                                          validateStatus={
                                            bailiffProfileForm.errors.address
                                              .length > 0
                                              ? "error"
                                              : "success"
                                          }
                                          help={
                                            bailiffProfileForm.errors.address
                                          }
                                        >
                                          <Input
                                            placeholder="Adresse postale"
                                            className="inputfield-middle"
                                            value={bailiffProfileForm.address}
                                            onChange={(e) =>
                                              _changeHandler(e, "address")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Matrices associées à l’utilisateur*"
                                          name="body"
                                          valuePropName="data"
                                          className="auto-adjust-text adapt-file-list-bailiff-profile"
                                        >
                                          {showDragger == true && (
                                            <Dragger
                                              className="inputfield-middle"
                                              multiple={false}
                                              fileList={
                                                fileUploads.selectedFileList
                                              }
                                              customRequest={
                                                FileUtils.generalDummyRequestLocal
                                              }
                                              onChange={onChangeDragger}
                                            >
                                              <p className="ant-upload-drag-icon">
                                                <CloudUploadOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                              </p>
                                              <p className="ant-upload-hint">
                                                Clique o faitez glisser pour
                                                télécharger vois fichiers{" "}
                                              </p>
                                            </Dragger>
                                          )}
                                        </Form.Item>
                                        <Form.Item
                                          className="profileimgweb"
                                          style={{
                                            textAlignLast: "center",
                                          }}
                                        >
                                          <Button
                                            type="primary"
                                            className="validate-btn"
                                            onClick={_submitHandler}
                                          >
                                            Valider
                                          </Button>
                                          <Button
                                            className="cancel-btn"
                                            onClick={() =>
                                              props.history.push("/dashboard")
                                            }
                                          >
                                            Annuler
                                          </Button>
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} md={12} lg={12}>
                                        <div className="profile_uploadimg_wrapper profileimgweb">
                                          <Form.Item>
                                            <Upload
                                              name="avatar"
                                              showUploadList={false}
                                              listType="picture-card"
                                              className="avatar-uploader"
                                              customRequest={
                                                FileUtils.generalDummyRequestLocal
                                              }
                                              onChange={handleChangeImage}
                                            >
                                              <div className="uploadimg-wrapper">
                                                {imageUrl ? (
                                                  <img
                                                    src={imageUrl}
                                                    alt="avatar"
                                                    style={{ width: "100%" }}
                                                  />
                                                ) : (
                                                  uploadButton
                                                )}
                                                {/* <div className="uploadimg-icons">
                                                  <img src={imguploadicon} alt="avatar-icon" />
                                                </div> */}
                                              </div>
                                            </Upload>
                                            <h2>
                                              Vos identifiants de connexion
                                            </h2>
                                          </Form.Item>
                                        </div>
                                        <Form.Item
                                          label="Identifiant"
                                          valuePropName="userID"
                                          validateStatus={
                                            bailiffProfileForm.errors.userID
                                              .length > 0
                                              ? "error"
                                              : "success"
                                          }
                                          help={
                                            bailiffProfileForm.errors.userID
                                          }
                                        >
                                          <Input
                                            placeholder="Identifiant"
                                            className="inputfield-middle"
                                            value={bailiffProfileForm.userID}
                                            onChange={(e) =>
                                              _changeHandler(e, "userID")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Mot de passe"
                                          valuePropName="password"
                                          validateStatus={
                                            bailiffProfileForm.errors.password
                                              .length > 0
                                              ? "error"
                                              : "success"
                                          }
                                          help={
                                            bailiffProfileForm.errors.password
                                          }
                                        >
                                          <Input.Password
                                            placeholder="Mot de passe"
                                            className="inputfield-middle"
                                            value={bailiffProfileForm.password}
                                            onChange={(e) =>
                                              _changeHandler(e, "password")
                                            }
                                          />
                                        </Form.Item>
                                        <Col xs={24} md={24} lg={17}>
                                          <div className="bailiffprofile-address">
                                            <Form.Item
                                              label="CP/ Ville*"
                                              name="cpcity"
                                              valuePropName="cpcity"
                                              validateStatus={
                                                bailiffProfileForm.errors
                                                  .zipCode.length > 0 ||
                                                bailiffProfileForm.errors.city
                                                  .length > 0
                                                  ? "error"
                                                  : "success"
                                              }
                                              help={
                                                bailiffProfileForm.errors
                                                  .zipCode ||
                                                bailiffProfileForm.errors.city
                                              }
                                            >
                                              <Input.Group>
                                                <Row gutter={8}>
                                                  <Col span={12}>
                                                    <Input
                                                      value={
                                                        bailiffProfileForm.zipCode
                                                      }
                                                      onChange={(e) =>
                                                        _changeHandler(
                                                          e,
                                                          "zipCode"
                                                        )
                                                      }
                                                    />
                                                  </Col>
                                                  <Col span={12}>
                                                    <Input
                                                      value={
                                                        bailiffProfileForm.city
                                                      }
                                                      onChange={(e) =>
                                                        _changeHandler(
                                                          e,
                                                          "city"
                                                        )
                                                      }
                                                    />
                                                  </Col>
                                                </Row>
                                              </Input.Group>
                                            </Form.Item>
                                            <Form.Item className="profileimgresponsive">
                                              <Button
                                                type="primary"
                                                className="validate-btn"
                                                onClick={_submitHandler}
                                              >
                                                Valider
                                              </Button>
                                              <Button className="cancel-btn">
                                                Annuler
                                              </Button>
                                            </Form.Item>
                                          </div>
                                        </Col>
                                      </Col>
                                    </Row>
                                  </Form>
                                </div>
                              </div>
                            </Content>
                          </Layout>
                        </Layout>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default CreateaReportBailiff;
