import authConstants from "./constants";
import authServices from './services'


const { SET_USER } = authConstants;
export const setUser = (user: any) => ({ type: SET_USER, user });


const { CHECKING_SESSION } = authConstants;
const setCheckingMe = (isChecking: boolean) => ({ type: CHECKING_SESSION, isChecking });

export const fetchMe = () => {
	return async (dispatch: any) => {
		dispatch(setCheckingMe(true));
		try {
			const response = await authServices.me();
			if (response.data.me) {
				dispatch(setUser(response.data.me));
			}
			dispatch(setCheckingMe(false));
		} catch (e) {
			dispatch(setCheckingMe(false));
		}
	}
};


const { LOGGING_IN, SET_LOGIN_ERROR } = authConstants;
export const setLogging = (isLoggingIn: boolean) => ({ type: LOGGING_IN, isLoggingIn });
const setLoggingError = (loginError: string | null) => ({ type: SET_LOGIN_ERROR, loginError });

export const loginUser = (data: { email: string, password: string }, remember: boolean, callback?: any) => {
	return async (dispatch: any) => {
		dispatch(setLogging(true));
		dispatch(setLoggingError(null));
		try {
			const { email, password } = data;
			const response = await authServices.login(email, password, remember);
			const { user } = response.data;
			dispatch(setUser(user))
			if (callback) callback();
			dispatch(setLogging(false));
			
		} catch (err: any) {
			if (
				// Messy
				err.response
				&& err.response.status !== 500
				&& err.response.data
				&& err.response.data.errors.message) {
					dispatch(setLoggingError(err.response.data.errors.message));
			} else {
				dispatch(setLoggingError("Ha ocurrido un error"));
			}
			dispatch(setLogging(false));
		}
	}
};


const { LOGGING_OUT } = authConstants;
const setLoggingOut = (isLoggingOut: boolean) => ({ type: LOGGING_OUT, isLoggingOut });

export const logoutUser = (callback?: any) => {
	return async (dispatch: any) => {
		dispatch(setLoggingOut(true));
		await authServices.logout();
		dispatch(setUser(null))
		if (callback) callback();
		dispatch(setLoggingOut(false));
	}
};


const { PHONE_LENGTH } = authConstants;
// export const setPhoneLengthValidation = (isPhoneLength: boolean) => ({ type: PHONE_LENGTH, isPhoneLength });

export const setPhoneLengthValidation = (isPhoneLength: string | null) => ({ type: PHONE_LENGTH, isPhoneLength });
