import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Layout,
  Input,
  Row,
  Col,
  Form,
  Select,
  Breadcrumb,
  Button,
  Upload,
  message,
  Modal,
} from "antd";
import {
  UserOutlined,
  CloudUploadOutlined,
  PlusOutlined,
  EuroCircleOutlined,
} from "@ant-design/icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector } from "react-redux";
import { usersService } from "../../../_services/usersSerives";
import { ISendUserDataI } from "../../../interfaces/users.interface";
import { filesUploadServices } from "../../../_services/uploadFIles";
import { groupServices } from "../../../_services/groupsServices";
import FileViewer from "react-file-viewer";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import axios from "axios";
import FileUtils from "../../../utils/files";

const validPassword = RegExp(
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
);
const validEmailRegex = RegExp(
  /^(([^<>()\\[\]\\.,;:\s@\\"]+(\.[^<>()\\[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i
);
const validPhone = RegExp(
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
);
const onlyNoAllowed = RegExp(/^[0-9]*$/);

const validZipCode = RegExp(/^\d{5}(?:[-\s]\d{4})?$/);
const validIdentify = RegExp(/^[A-Za-z0-9.]{3,}$/);
const { Dragger } = Upload;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const { Content } = Layout;
const { Option } = Select;

// function beforeUpload(file) {
//   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
//   if (!isJpgOrPng) {
//     message.error("You can only upload JPG/PNG file!")
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2
//   if (!isLt2M) {
//     message.error("Image must smaller than 2MB!")
//   }
//   return isJpgOrPng && isLt2M
// }

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

function BailiffAccount({ bailifForm, isDoneEdit, ...props }) {
  const [fileUploads, setFileUploads]: any = useState({
    selectedFile: null,
    selectedFileList: [],
  });
  const [fileUploads2, setFileUploads2]: any = useState({
    selectedFileList: [],
  });
  const imguploadicon = require("../../../pages/images/uploadimage-icon.svg");
  const [imageUrl, setImage] = useState(
    bailifForm?.profile || require("../../../pages/images/uploadimage.svg")
  );
  const { user } = useSelector((state: any) => state.auth);
  const [allGroups, setAllGroups] = useState([]);
  const [createAccountForm, setCreateAccountForm] = useState({
    type: bailifForm?.role_name || "",
    studyname: "",
    studyphone: "",
    siret: "",
    accountingemail: "",
    address: bailifForm?.address || "",
    pricepage: "",
    zipcode: bailifForm?.zip || null,
    city: bailifForm?.city || "",
    pricephoto: "",
    confirm_password: "",
    description: bailifForm?.description || "",
    username: "",
    typeofaccount: bailifForm?.typeofaccount || "",
    civility: bailifForm?.civility || "Monsieur",
    lastname: bailifForm?.last_name || "",
    firstname: bailifForm?.first_name || "",
    phone: bailifForm?.telephone || "",
    useremail: bailifForm?.email || "",
    userID: bailifForm?.identity || "",
    password: "",
    confirmPassword: "",
    data: bailifForm?.data || "",
    message: bailifForm?.Instructions_to_secretaries || "",
    uploads: bailifForm?.matrices_associated || [],
    linkto: bailifForm?.group_id || "",
    matrices_associated: bailifForm?.matrices_associated || [],
    errors: {
      linkto: "",
      civility: "",
      lastname: "",
      firstname: "",
      phone: "",
      studyname: "",
      studyphone: "",
      siret: "",
      accountingemail: "",
      address: "",
      pricepage: "",
      zipcode: "",
      city: "",
      pricephoto: "",
      useremail: "",
      userID: "",
      password: "",
      confirmPassword: "",
      message: "",
      uploads: "",
    },
  });
  const [fileToView, setFileToView]: any = useState({
    url: "",
    type: "",
    visible: false,
  });
  const [matriceChange, setMatriceChange]: any = useState(false);

  useEffect(() => {
    let store = fileUploads;
    let temp: any = [];
    if (bailifForm?.matrices_associated) {
      bailifForm.matrices_associated.map((d, i) => {
        temp.push({
          uid: i,
          name: d.split("/").splice(-1)[0],
          status: "done",
          url: d,
        });
      });
      store.selectedFileList = temp;
    }
    setFileUploads(store);
  }, [bailifForm]);

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const uploadFileHere = async (file) => {
    let data = new FormData();
    data.append("profile", file);
    return await filesUploadServices
      .uploadFileAPI(data)
      .then((response) => response.data.url)
      .catch((error) => console.log("error: ", error));
  };
  const onChangeDragger = async (info) => {
    const nextState: any = {};
    switch (info.file.status) {
      case "uploading":
        nextState.selectedFileList = info.fileList;
        break;
      case "done":
        nextState.selectedFile = info.fileList;
        nextState.selectedFileList = info.fileList;

        let promise = await Promise.all(
          info.fileList.map(async (file) => {
            let innerPromise = await uploadFileHere(file.originFileObj);
            console.log(innerPromise);
            return innerPromise;
          })
        );
        let temp: any = createAccountForm.uploads;
        let errors = createAccountForm.errors;
        errors.uploads = "";
        message.success(`Fichier téléchargé avec succès.`);

        setCreateAccountForm({
          ...createAccountForm,
          ...{ errors, ["uploads"]: promise },
        });
        break;
      default:
        // error or removed
        nextState.selectedFile = info.fileList;
        nextState.selectedFileList = info.fileList;
    }

    setFileUploads2(nextState);
    let store = fileUploads;
    let temp: any = [];

    nextState.selectedFileList.map((d, i) => {
      let mime_type = d.type || "text/plain";
      var blob = new Blob([d.originFileObj], { type: mime_type });
      let url = window.URL.createObjectURL(blob);
      temp.push({
        uid: i,
        name: d.name,
        status: "done",
        url: url,
      });
    });
    store.selectedFileList = temp;
    setFileUploads(store);
    setMatriceChange(true);
  };

  useEffect(() => {}, [fileUploads]);

  const notFilledPasswordField = () => {
    let errors = createAccountForm.errors;
    if (createAccountForm.linkto === "") {
      errors.linkto = "Champ requis";
    }
    if (createAccountForm.civility === "") {
      errors.civility = "Champ requis";
    }
    if (createAccountForm.lastname === "") {
      errors.lastname = "Champ requis";
    }
    if (createAccountForm.firstname === "") {
      errors.firstname = "Champ requis";
    }
    if (createAccountForm.phone === "") {
      errors.phone = "Champ requis";
    }
    if (createAccountForm.studyname === "") {
      errors.studyname = "Champ requis";
    }
    if (createAccountForm.studyphone === "") {
      errors.studyphone = "Champ requis";
    }
    if (createAccountForm.siret === "") {
      errors.siret = "Champ requis";
    }
    if (createAccountForm.accountingemail === "") {
      errors.accountingemail = "Champ requis";
    }
    if (createAccountForm.address === "") {
      errors.address = "Champ requis";
    }
    if (createAccountForm.pricepage === "") {
      errors.pricepage = "Champ requis";
    }
    if (createAccountForm.zipcode === "") {
      errors.zipcode = "Champ requis";
    }
    if (createAccountForm.city === "") {
      errors.city = "Champ requis";
    }
    if (createAccountForm.pricephoto === "") {
      errors.pricephoto = "Champ requis";
    }
    if (createAccountForm.useremail === "") {
      errors.useremail = "Champ requis";
    }
    if (createAccountForm.userID === "") {
      errors.userID = "Champ requis";
    }
    if (createAccountForm.password === "") {
      errors.password = "Champ requis";
    }
    if (createAccountForm.confirmPassword === "") {
      errors.confirmPassword = "Champ requis";
    }
    // if (createAccountForm.message === "") {
    //   errors.message = "Champ requis"
    // }
    // if (createAccountForm.uploads.length === 0) {
    //   errors.message = "Champ requis"
    // }
    setCreateAccountForm({ ...createAccountForm, ...{ errors } });
  };

  const validateForm = (formFields) => {
    notFilledPasswordField();
    let valid = true;
    Object.values(formFields.errors).forEach(
      (val: any) => val.length > 0 && (valid = false)
    );
    return valid;
  };

  // const _submitHandler = async () => {
  //   if (validateForm(createAccountForm)) {
  //     let data: any = {}
  //     data["role_id"] = user.role_id
  //     data["civility"] = createAccountForm.civility
  //     data["typeofaccount"] = "2"
  //     data["lastname"] = createAccountForm.lastname
  //     data["firstname"] = createAccountForm.firstname
  //     data["phone"] = createAccountForm.phone
  //     data["useremail"] = createAccountForm.useremail
  //     data["userID"] = createAccountForm.userID
  //     data["password"] = createAccountForm.password
  //     data["confirmPassword"] = createAccountForm.confirmPassword
  //     data["data"] = createAccountForm.data
  //     data["message"] = createAccountForm.message
  //     if (createAccountForm.linkto === "") {
  //       data["linkto"] = 0
  //     } else {
  //       data["linkto"] = createAccountForm.linkto
  //     }
  //     if (createAccountForm.password != "") {
  //       data["password"] = createAccountForm.password
  //     }
  //     data["profile"] = imageUrl
  //     data["matrices_associated"] = createAccountForm.matrices_associated
  //     data["Instructions_to_secretaries"] = null
  //     await usersService
  //       .updateUsersAPI(user.id, data)
  //       .then((response) => response.data)
  //       .then((response: any) => {
  //         message.success("Doit contenir au moins 3 caractères")
  //       })
  //       .catch((err) => console.error(err))
  //   }
  // }

  const _changeHandler = (event, name) => {
    let errors = createAccountForm.errors;
    const { value } = event.target;
    switch (name) {
      case "civility":
        errors.civility =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "lastname":
        errors.lastname =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "firstname":
        errors.firstname =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "phone":
        errors.phone = validPhone.test(value)
          ? ""
          : "Numéro de téléphone invalide.";
        break;
      case "studyname":
        errors.studyname =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "studyphone":
        errors.studyphone = validPhone.test(value)
          ? ""
          : "Numéro de téléphone invalide.";
        break;
      case "siret":
        errors.siret =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;

      case "accountingemail":
        errors.accountingemail = validEmailRegex.test(value)
          ? ""
          : "Doit contenir au moins 3 caractères";
        break;
      case "address":
        errors.address =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;

      case "pricepage":
        errors.pricepage = onlyNoAllowed.test(value)
          ? ""
          : "Doit contenir au moins 3 caractères";
        break;
      case "zipcode":
        errors.zipcode = validZipCode.test(value)
          ? ""
          : "Doit contenir au moins 5 caractères";
        break;
      case "city":
        errors.city =
          value.length > 2 ? "" : "Doit contenir au moins 3 caractères";
        break;
      case "pricephoto":
        errors.pricephoto = onlyNoAllowed.test(value)
          ? ""
          : "Doit contenir au moins 3 caractères";
        break;
      case "useremail":
        errors.useremail = validEmailRegex.test(value)
          ? ""
          : "Doit contenir au moins 3 caractères";
        break;
      case "userID":
        errors.userID = validIdentify.test(value)
          ? ""
          : "Doit contenir au moins 3 caractères";
        break;

      case "password":
        errors.password = validPassword.test(value)
          ? ""
          : "Le mot de passe doit comporter des majuscules, des minuscules, des chiffres et un symbole.";
        break;
      case "confirmPassword":
        errors.confirmPassword =
          createAccountForm.password === value ? "" : "Mot de passe incorrect.";
        break;
    }

    setCreateAccountForm({
      ...createAccountForm,
      ...{ errors, [name]: value },
    });
  };
  const changeHandler = (value, name) => {
    setCreateAccountForm({
      ...createAccountForm,
      ...{ [name]: value },
    });
  };

  useEffect(() => {
    _getAllGroups();
  }, []);

  const closeDocumentModal = () => {
    setFileToView({
      url: "",
      type: "",
      view: false,
    });
  };

  useEffect(() => {}, [fileToView]);

  useEffect(() => {
    if (allGroups.length > 0) {
      let temp: any = createAccountForm;
      allGroups.map((group: any) => {
        if (
          group.id == Number(createAccountForm.linkto) ||
          group.id == Number(bailifForm?.group_id)
        ) {
          temp.studyname = group.group_name;
          temp.studyphone = group.group_telephone;
          temp.siret = group.group_street;
          temp.accountingemail = group.group_email;
          temp.address = group.group_address;
          temp.pricepage = group.group_price_page;
          temp.zipcode = group.group_zip;
          temp.city = group.group_city;
          temp.pricephoto = group.group_price_photo;
          setCreateAccountForm({ ...createAccountForm, ...temp });
        }
      });
    }
  }, [allGroups.length, createAccountForm.linkto]);

  const _getGroupByID = async (id) => {
    await groupServices
      .getGroupByID(id)
      .then((response) => response.data)
      .then((response: any) => {})
      .catch((err) => console.error(err));
  };

  const _getAllGroups = async () => {
    await groupServices
      .getAllGroupsAPI()
      .then((response) => response.data)
      .then((response: any) => {
        let temp: any = createAccountForm;
        response.map((group: any) => {
          if (group.id == bailifForm?.group_id) {
            temp.studyname = group.group_name;
            temp.studyphone = group.group_telephone;
            temp.siret = group.group_street;
            temp.accountingemail = group.group_email;
            temp.address = group.group_address;
            temp.pricepage = group.group_price_page;
            temp.zipcode = group.group_zip;
            temp.city = group.group_city;
            temp.pricephoto = group.group_price_photo;
            setCreateAccountForm({ ...createAccountForm, ...temp });
          }
        });

        setAllGroups(response);
      })
      .catch((err) => console.error(err));
  };

  const uploadButton = (
    <div>
      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const [fileName, setFileName] = useState("");
  const handleChangeImage = async (info) => {
    const dataFileName = info.file.name;
    setFileName(dataFileName);
    if (info.file.status === "done") {
      let data = new FormData();
      data.append("profile", info.file.originFileObj);
      await filesUploadServices
        .uploadFileAPI(data)
        .then((response) => {
          setImage(response.data.url);
        })
        .catch((err) => console.error(err));
    }
  };
  let dataImg;
  let dataBailiff = bailifForm?.profile;
  let url = "/static/media/uploadimage.aeda1424.svg";

  if (dataBailiff !== undefined || dataBailiff !== url) {
    dataImg = dataBailiff;
  }
  if (dataBailiff === url) {
    dataImg = fileName;
  } else if (fileName != "") {
    dataImg = fileName;
  }

  const handleChange = (e) => {
    switch (e) {
      case "huissier":
        props.history.push("/dashboard/bailiffaccount");
        break;
      case "admin":
        props.history.push("/dashboard/adminaccount");
        break;
      case "secrétaire":
        props.history.push("/dashboard/secretary");
        break;
    }
  };

  const setErrorGroupRequired = (description: string) => {
    setCreateAccountForm({
      ...createAccountForm,
      errors: {
        ...createAccountForm.errors,
        linkto: description,
      },
    });
  };

  const _submitHandler = async () => {
    let data: any = {};
    data["role_id"] = "2";
    data["civility"] = createAccountForm.civility;
    data["last_name"] = createAccountForm.lastname;
    data["first_name"] = createAccountForm.firstname;
    data["telephone"] = createAccountForm.phone;
    data["street"] = createAccountForm.siret;
    data["address"] = createAccountForm.address;
    data["city"] = createAccountForm.city;

    if (createAccountForm.password != "") {
      data["password"] = createAccountForm.password;
    }
    data["profile"] = imageUrl;
    data["Instructions_to_secretaries"] = createAccountForm.message.replace(
      /(<\s*\/?\s*)(\s*([^>]*)?\s*>)/gi,
      ""
    );
    if (matriceChange === true) {
      data["matrices_associated"] = createAccountForm.uploads.filter(
        (item) => item
      );
    }
    data["identity"] = createAccountForm.userID;
    data["zip"] = createAccountForm.zipcode;
    data["email"] = createAccountForm.useremail;
    data["group_id"] = Number(createAccountForm.linkto);
    // data["message"] = createAccountForm.message
    if (bailifForm) {
      //update
      await usersService
        .updateUsersAPI(bailifForm.id, data)
        .then((response) => response.data)
        .then((response: ISendUserDataI) => {
          message.success("Compte modifié avec succès.");
          isDoneEdit();
        })
        .catch((err) => {
          console.error(err);
          message.error(" Tous les champs obligatoires ne sont pas remplis.");
          if (err?.message === "group_id") {
            setErrorGroupRequired(err?.description);
          }
        });
    } else {
      //new
      data["email"] = createAccountForm.useremail;
      data["created_by"] = user.id;
      data["matrices_associated"] = createAccountForm.uploads;
      await usersService
        .createUsersAPI(data)
        .then((response) => response.data)
        .then((response: ISendUserDataI) => {
          message.success("Compte créé avec succès.");
          props.history.push("/dashboard/seetheaccount");
        })
        .catch((err) => {
          const field = err?.response?.data?.field;
          if (field) {
            if (field == "email") {
              message.error("Cet email existe déjà.");
            } else if (field == "user") {
              message.error("Cet identifiant existe déjà.");
            }
          } else {
            message.error(" Tous les champs obligatoires ne sont pas remplis.");
            if (err?.message === "group_id") {
              setErrorGroupRequired(err?.description);
            }
          }
        });
    }
  };

  // const _changeHandler = (event, name) => {
  //   setCreateAccountForm({
  //     ...createAccountForm,
  //     ...{ [name]: event?.target?.value || event },
  //   })
  // }

  // const descriptionChangeHandler = (data) => {
  //   setCreateAccountForm({
  //     ...createAccountForm,
  //     ...{ ["description"]: data },
  //   })
  // }

  const descriptionChangeHandler = (data) => {
    setCreateAccountForm({
      ...createAccountForm,
      ...{ ["message"]: data },
    });
  };
  const handleChange_civility = (e) => {
    createAccountForm.civility = e;
  };

  return (
    <div className="dashboard-right-section bailiffaccount_wrapper">
      {!bailifForm && (
        <Content style={{ marginLeft: "0px" }}>
          <Breadcrumb>
            <Breadcrumb.Item>Comptes</Breadcrumb.Item>
            <Breadcrumb.Item>Voir les comptes</Breadcrumb.Item>
          </Breadcrumb>
        </Content>
      )}
      <div
        className="form_outer_wrapper"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <h2>
          <span>
            <UserOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </span>
          {bailifForm ? "MODIFICATION" : "CRÉATION"} D’UN COMPTE UTILISATEUR
        </h2>
        <Form className="create_account bailiffaccount_form">
          <Row>
            <Col xs={24} md={24} lg={12} className="only-for-web">
              <Form.Item
                label="Type de compte"
                name="typeofaccount"
                valuePropName="typeofaccount"
                rules={[
                  {
                    required: true,
                    message: "Veuillez sélectionner Type de compte",
                  },
                ]}
              >
                <Select
                  defaultValue="Huissier"
                  className="select-user-type"
                  onChange={handleChange}
                  disabled={bailifForm ? true : false}
                >
                  <Option value="huissier">Huissier</Option>
                  <Option value="admin">Admin</Option>
                  <Option value="secrétaire">Secrétaire</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <div className="profile_uploadimg_wrapper">
                <Form.Item>
                  <Upload
                    name="avatar"
                    showUploadList={false}
                    listType="picture-card"
                    className="avatar-uploader"
                    customRequest={FileUtils.generalDummyRequestLocal}
                    onChange={handleChangeImage}
                  >
                    <div className="uploadimg-wrapper">
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton
                      )}
                      {dataImg ? (
                        <div className="uploadimg-icons"></div>
                      ) : (
                        <div className="uploadimg-icons">
                          <img src={imguploadicon} alt="avatar-icon" />
                        </div>
                      )}
                    </div>
                  </Upload>
                  <Input.Group>
                    <Row>
                      <Col xs={12} md={12} lg={12}>
                        {/* <label className="uploadimg-label">Lier à :</label> */}
                        <Form.Item
                          className="uploadimage-bottom-select"
                          label="Lier à"
                          valuePropName="linkto"
                          validateStatus={
                            createAccountForm.errors.linkto.length > 0
                              ? "error"
                              : "success"
                          }
                          help={createAccountForm.errors.linkto}
                        >
                          <Select
                            className={
                              createAccountForm.linkto != 0 ? "" : "redBorder"
                            }
                            style={{
                              display: "contents !important",
                            }}
                            defaultValue={
                              Number(createAccountForm.linkto) ||
                              "Groupes existants"
                            }
                            onChange={(e) => changeHandler(e, "linkto")}
                            // disabled={bailifForm ? !true : !false}
                          >
                            {allGroups.length > 0 &&
                              allGroups.map((group: any) => (
                                <Option value={group.id}>
                                  {group.group_name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={12} lg={12}>
                        <Form.Item className="groupe-button">
                          <Link to="/dashboard/creerungroupe">
                            <Button className="validate-btn">
                              Créer nouveau groupe
                            </Button>
                          </Link>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Input.Group>
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12} className="only-for-mobile">
              <Form.Item
                label="Type de compte"
                name="typeofaccount"
                valuePropName="typeofaccount"
                rules={[
                  {
                    required: true,
                    message: "Veuillez sélectionner Type de compte",
                  },
                ]}
              >
                <Select
                  defaultValue="huissier"
                  onChange={handleChange}
                  disabled={bailifForm ? true : false}
                >
                  <Option value="huissier">Huissier</Option>
                  <Option value="secrétaire">Secrétaire</Option>
                  <Option value="admin">Admin</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Civilité"
                name="civility"
                valuePropName="civility"
                validateStatus={
                  createAccountForm.errors.civility.length > 0
                    ? "error"
                    : "success"
                }
                help={createAccountForm.errors.civility}
              >
                {/*   <Input
                  placeholder="Civilité"
                  className="inputfield-full"
                  value={createAccountForm.civility}
                  onChange={(e) => _changeHandler(e, "civility")}
              /> */}

                <Select
                  defaultValue={
                    createAccountForm.civility
                      ? `${createAccountForm.civility}`
                      : "Monsieur"
                  }
                  onChange={handleChange_civility}
                  disabled={bailifForm ? true : false}
                >
                  <Option value="Monsieur">Monsieur</Option>
                  <Option value="Madame">Madame</Option>
                  <Option value="Maître">Maître</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Nom"
                valuePropName="lastname"
                validateStatus={
                  createAccountForm.errors.lastname.length > 0
                    ? "error"
                    : "success"
                }
                help={createAccountForm.errors.lastname}
              >
                <Input
                  placeholder="Nom"
                  className="inputfield-full"
                  value={createAccountForm.lastname}
                  onChange={(e) => _changeHandler(e, "lastname")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Prénom"
                valuePropName="firstname"
                validateStatus={
                  createAccountForm.errors.firstname.length > 0
                    ? "error"
                    : "success"
                }
                help={createAccountForm.errors.firstname}
              >
                <Input
                  placeholder="Prénom"
                  className="inputfield-full"
                  value={createAccountForm.firstname}
                  onChange={(e) => _changeHandler(e, "firstname")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Téléphone"
                valuePropName="phone"
                validateStatus={
                  createAccountForm.errors.phone.length > 0
                    ? "error"
                    : "success"
                }
                help={createAccountForm.errors.phone}
              >
                <Input
                  placeholder="Téléphone"
                  className="inputfield-full"
                  value={createAccountForm.phone}
                  onChange={(e) => _changeHandler(e, "phone")}
                />
              </Form.Item>
            </Col>
          </Row>

          {createAccountForm.linkto !== "" && (
            <div className="infoalready-exesting">
              <Row>
                <Col xs={24} md={24} lg={12}>
                  <Form.Item
                    label="Nom de l’etude"
                    name="studyname"
                    valuePropName="studyname"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez entér Nom de l’etude",
                      },
                    ]}
                  >
                    <Input
                      disabled={true}
                      onChange={(e) => _changeHandler(e, "studyname")}
                      value={createAccountForm.studyname}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={12}>
                  <div className="input-currenct">
                    <Form.Item
                      label="Téléphone étude"
                      name="studyphone"
                      valuePropName="studyphone"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez entér Téléphone étude",
                        },
                      ]}
                    >
                      <Input
                        disabled={true}
                        onChange={(e) => _changeHandler(e, "studyphone")}
                        value={createAccountForm.studyphone}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={24} lg={12}>
                  <Form.Item
                    label="Siret"
                    name="siret"
                    valuePropName="siret"
                    rules={[
                      { required: true, message: "Veuillez entér Siret" },
                    ]}
                  >
                    <Input
                      disabled={true}
                      onChange={(e) => _changeHandler(e, "siret")}
                      value={createAccountForm.siret}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={12}>
                  <div className="input-currenct">
                    <Form.Item
                      label="Email comptabilité"
                      name="accountingemail"
                      valuePropName="accountingemail"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez entér Email comptabilité",
                        },
                      ]}
                    >
                      <Input
                        disabled={true}
                        onChange={(e) => _changeHandler(e, "accountingemail")}
                        value={createAccountForm.accountingemail}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={24} lg={12}>
                  <Form.Item
                    label="Adresse postale"
                    name="address"
                    valuePropName="address"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez entér Adresse postale",
                      },
                    ]}
                  >
                    <Input
                      value={createAccountForm.address}
                      disabled={true}
                      onChange={(e) => _changeHandler(e, "address")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={12}>
                  <div className="input-currenct">
                    <Form.Item
                      label="Tarif/page"
                      name="pricepage"
                      valuePropName="pricepage"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez entér Tarif/page",
                        },
                      ]}
                    >
                      <Input
                        disabled={true}
                        onChange={(e) => _changeHandler(e, "pricepage")}
                        value={createAccountForm.pricepage}
                      />
                      €
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={24} lg={12}>
                  <Form.Item
                    label="CP/ Ville"
                    name="cpcity"
                    valuePropName="cpcity"
                    rules={[
                      { required: true, message: "Veuillez entér CP/ Ville" },
                    ]}
                  >
                    <Input.Group>
                      <Row gutter={8}>
                        <Col span={12}>
                          <Input
                            value={createAccountForm.zipcode || ""}
                            disabled={true}
                            onChange={(e) => _changeHandler(e, "zipcode")}
                          />
                        </Col>
                        <Col span={12}>
                          <Input
                            value={createAccountForm.city}
                            disabled={true}
                            onChange={(e) => _changeHandler(e, "city")}
                          />
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col xs={24} md={24} lg={12}>
                  <div className="input-currenct">
                    <Form.Item
                      label="Tarif/photo"
                      name="pricephoto"
                      valuePropName="pricephoto"
                      rules={[
                        {
                          required: true,
                          message: "Veuillez entér Tarif/photo",
                        },
                      ]}
                    >
                      <Input
                        disabled={true}
                        onChange={(e) => _changeHandler(e, "pricephoto")}
                        value={createAccountForm.pricephoto}
                      />
                      €
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>
          )}
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Email utilisateur"
                valuePropName="email"
                validateStatus={
                  createAccountForm.errors.useremail.length > 0
                    ? "error"
                    : "success"
                }
                help={createAccountForm.errors.useremail}
              >
                <Input
                  placeholder="Email utilisateur"
                  className="inputfield-full"
                  value={createAccountForm.useremail}
                  onChange={(e) => _changeHandler(e, "useremail")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Identifiant"
                valuePropName="userID"
                validateStatus={
                  createAccountForm.errors.userID.length > 0
                    ? "error"
                    : "success"
                }
                help={createAccountForm.errors.userID}
              >
                <Input
                  placeholder="Identifiant"
                  className="inputfield-full"
                  value={createAccountForm.userID}
                  onChange={(e) => _changeHandler(e, "userID")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                label="Mot de passe"
                name="password"
                // rules={[{ required: true, message: "Entrez votre mot de passe, s'il vous plaît!" }]}
              >
                <Input.Group>
                  <Row gutter={8}>
                    <Col span={12}>
                      {/* <Input.Password  defaultValue=""/> */}
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Veuillez saisir votre mot de passe!",
                          },
                        ]}
                        style={{ display: "inline-block" }}
                        validateStatus={
                          createAccountForm.errors.password.length > 0
                            ? "error"
                            : "success"
                        }
                        help={createAccountForm.errors.password}
                      >
                        <Input.Password
                          placeholder="Mot de passe"
                          className="form_password inputfield-full"
                          onChange={(e) => _changeHandler(e, "password")}
                          value={createAccountForm.password}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="confirmPassword"
                        rules={[
                          {
                            required: true,
                            message:
                              "Veuillez saisir votre mot de passe de confirmation!",
                          },
                        ]}
                        validateStatus={
                          createAccountForm.errors.confirmPassword.length > 0
                            ? "error"
                            : "success"
                        }
                        help={createAccountForm.errors.confirmPassword}
                        style={{ display: "inline-block" }}
                      >
                        <Input.Password
                          placeholder="Confirmez*"
                          className="confirmPassword-input inputfield-full"
                          onChange={(e) => _changeHandler(e, "confirmPassword")}
                          value={createAccountForm.confirmPassword}
                        />
                      </Form.Item>
                      {/* <Input.Password  defaultValue="" placeholder="Confirmez*"/> */}
                    </Col>
                  </Row>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                className="inputfield-full"
                label="Matrices associées à l’utilisateur"
                valuePropName="uploads"
                validateStatus={
                  createAccountForm.errors.uploads.length > 0
                    ? "error"
                    : "success"
                }
                help={createAccountForm.errors.uploads}
              >
                <Dragger
                  multiple={true}
                  fileList={
                    bailifForm ? undefined : fileUploads2.selectedFileList
                  }
                  customRequest={FileUtils.generalDummyRequestLocal}
                  onChange={onChangeDragger}
                  defaultFileList={createAccountForm.matrices_associated.map(
                    (item: any) => {
                      const uid = Math.random() * 100;
                      const name = item.split("/")?.pop();
                      return {
                        uid: uid,
                        name: name,
                        status: "done",
                        url: item,
                      };
                    }
                  )}
                  onPreview={async (file) => {
                    try {
                      let type = file.name.slice(-3);
                      switch (type) {
                        case "ocx":
                          type = "docx";
                          break;
                        case "peg":
                          type = "jpeg";
                          break;
                      }
                      const response = await axios.get(
                        createAccountForm.uploads.filter((item) =>
                          item.includes(file.name.replace(/\s/g, ""))
                        )[0]
                      );
                      if (response.status === 200) {
                        setFileToView({
                          url: createAccountForm.uploads.filter((item) =>
                            item.includes(file.name.replace(/\s/g, ""))
                          )[0],
                          type,
                          visible: true,
                        });
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  onRemove={(file) => {
                    let index: any = file.uid;
                    let newFiles = fileUploads2.selectedFileList;
                    newFiles.splice(index, 1);
                    setFileUploads({ selectedFileList: newFiles });
                    setFileUploads2({ selectedFileList: newFiles });
                    setMatriceChange(true);
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                  </p>
                  <p className="ant-upload-hint">
                    Cliquez ou faites glisser pour envoyer vos fichiers{" "}
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col xs={24} md={24} lg={12}>
            <div className="ckeditor_label_wrapper">
              <Form.Item
                label="Consignes aux secrétaires"
                valuePropName="message"
                className="inputfield-full"
                validateStatus={
                  createAccountForm.errors.message.length > 0
                    ? "error"
                    : "success"
                }
                help={createAccountForm.errors.message}
              >
                <CKEditor
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    descriptionChangeHandler(data);
                  }}
                  data={createAccountForm.message}
                  config={{
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "link",
                      "bulletedList",
                      "numberedList",
                      "blockQuote",
                    ],
                    heading: {
                      options: [
                        {
                          model: "paragraph",
                          title: "Paragraph",
                          class: "ck-heading_paragraph",
                        },
                        {
                          model: "heading1",
                          view: "h1",
                          title: "Heading 1",
                          class: "ck-heading_heading1",
                        },
                        {
                          model: "heading2",
                          view: "h2",
                          title: "Heading 2",
                          class: "ck-heading_heading2",
                        },
                      ],
                    },
                  }}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row className="create_account">
          <Col xs={24} md={24} lg={12} className="register-btn-mask">
            <Form.Item label="  ">
              <Button
                type="primary"
                className="validate-btn"
                onClick={_submitHandler}
              >
                Valider
              </Button>

              <Link to="/dashboard/seetheaccount">
                <Button className="cancel-btn" onClick={isDoneEdit}>
                  Annuler
                </Button>
              </Link>
            </Form.Item>
          </Col>
        </Row>
      </div>
      <Modal
        centered
        visible={fileToView.visible}
        onCancel={closeDocumentModal}
        footer={null}
        width="80vw"
        bodyStyle={{
          height: "80vh",
        }}
      >
        {fileToView.url != "" && (
          // <FileViewer
          //   filePath={fileToView.url}
          //   fileType={fileToView.type}
          // />
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            style={{ width: "100%", height: "100%" }}
            documents={[
              {
                uri: fileToView.url,
              },
            ]}
            config={{
              header: {
                disableHeader: false,
                disableFileName: true,
                retainURLParams: false,
              },
            }}
          />
        )}
      </Modal>
    </div>
  );
}

export default BailiffAccount;
