import React from "react";
import moment from "moment";
import globals from "../helpers/globals"
moment.locale("fr");

interface TemplateBillingProps {
  data: any,
  footerOnly?: boolean,
  contentOnly?: boolean
}

const TemplateBillingBailiff = ({ data, footerOnly = false, contentOnly = false }: TemplateBillingProps) => {
  const formattedReports = data?.reports?.map((report) => {
    const amountWithoutTaxesPagesWithDiscount =
      report?.amount_without_taxes_pages -
      (report?.amount_without_taxes_pages * report?.discount) / 100;
    const amountWithoutTaxesPagesWithDiscountAndDiscounts =
      amountWithoutTaxesPagesWithDiscount -
      (amountWithoutTaxesPagesWithDiscount * data?.discount) / 100;
    const amountWithoutTaxesPhotosWithDiscount =
      report?.amount_without_taxes_photos -
      (report?.amount_without_taxes_photos * report?.discount) / 100;
    const amountWithoutTaxesPhotosWithDiscountAndDiscounts =
      amountWithoutTaxesPhotosWithDiscount -
      (amountWithoutTaxesPhotosWithDiscount * data?.discount) / 100;
    const amountWithoutTaxesWithDiscount =
      amountWithoutTaxesPagesWithDiscount +
      amountWithoutTaxesPhotosWithDiscount;
    const amountWithoutTaxesWithDiscountAndDiscounts =
      amountWithoutTaxesPagesWithDiscountAndDiscounts +
      amountWithoutTaxesPhotosWithDiscountAndDiscounts;

    return {
      ...report,
      amount_without_taxes_pages_with_discount:
        amountWithoutTaxesPagesWithDiscount,
      amount_without_taxes_pages_with_discount_and_discounts:
        amountWithoutTaxesPagesWithDiscountAndDiscounts,
      amount_without_taxes_photos_with_discount:
        amountWithoutTaxesPhotosWithDiscount,
      amount_without_taxes_photos_with_discount_and_discounts:
        amountWithoutTaxesPhotosWithDiscountAndDiscounts,
      amount_without_taxes_with_discount: amountWithoutTaxesWithDiscount,
      amount_without_taxes_with_discount_and_discounts:
        amountWithoutTaxesWithDiscountAndDiscounts,
    };
  });

  const formattedReportsInGroups = formattedReports.reduce((acc, report) => {
    const key = `${moment(report?.updatedAt).format("MM")}-${moment(
      report?.updatedAt
    ).format("YYYY")}-${report?.bailiff_id}`;

    if (!acc[key]) {
      acc[key] = {
        updatedAt: report?.updatedAt,
        bailiff_full_name: `${report?.bailiff?.first_name} ${report?.bailiff?.last_name}`,
        reports: [],
      };
    }

    acc[key]["reports"].push(report);

    return acc;
  }, {});

  const formattedReportsInGroupsByBailiff = formattedReports.reduce(
    (acc, report) => {
      const key = `${report?.bailiff_id}`;

      if (!acc[key]) {
        acc[key] = {
          bailiff_full_name: `${report?.bailiff?.first_name} ${report?.bailiff?.last_name}`,
          reports: [],
          count_reports: 0,
          count_pages_billing: 0,
          count_photos_billing: 0,
          amount_without_taxes_pages_with_discount: 0,
          amount_without_taxes_photos_with_discount: 0,
          amount_without_taxes_with_discount_and_discounts: 0,
        };
      }

      acc[key]["count_reports"] += 1;
      acc[key]["count_pages_billing"] += report.count_pages_billing;
      acc[key]["count_photos_billing"] += report.count_photos_billing;
      acc[key]["amount_without_taxes_pages_with_discount"] +=
        report.amount_without_taxes_pages_with_discount;
      acc[key]["amount_without_taxes_photos_with_discount"] +=
        report.amount_without_taxes_photos_with_discount;
      acc[key]["amount_without_taxes_with_discount_and_discounts"] +=
        report.amount_without_taxes_with_discount_and_discounts;

      acc[key]["reports"].push(report);

      return acc;
    },
    {}
  );

  const formattedData = {
    ...data,
    reports: formattedReports,
    groups_reports: formattedReportsInGroups,
    groups_reports_by_bailiff: formattedReportsInGroupsByBailiff,
    amount_without_taxes_with_discount: formattedReports.reduce(
      (acc, report) => {
        return (
          acc +
          report.amount_without_taxes_pages_with_discount +
          report.amount_without_taxes_photos_with_discount
        );
      },
      0
    ),
  };

  return (
    <>
      {/* Content */}
      {(!footerOnly || contentOnly) && (
        <div id="content"
          style={{
            fontFamily: "Arial, sans-serif",
            display: "block",
            width: "210mm",
            backgroundColor: "#fff",
          }}
        >
          {/* First page */}
          <div className="page" style={{
            display: "block",
            position: "relative",
            width: "100%",
            backgroundColor: "#fff",
            breakAfter: "page",
          }}>

            {/* Header */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                textAlign: "right",
                alignItems: "center"
              }}
            >
              <img style={{ width: "420px", height: "auto" }} src={globals.domain + "/public/images/logoTemplate.jpg"} alt="CRC Logo" />
              <div>
                <h3 style={{ margin: "0 0 5px 0", fontWeight: "bold" }}>https://crc-constat.fr</h3>
                <h3 style={{ margin: "0 0 5px 0", fontWeight: "bold" }}>laurence.crc@gmail.com</h3>
                <h3 style={{ color: "#357fbb", margin: 0, fontWeight: "bold" }}>Téléphone: 06 95 57 14 34</h3>
              </div>
            </div>

            <hr style={{ margin: "30px 0" }}></hr>

            {/* Client name + address */}
            <div style={{ marginLeft: "55%" }}>
              <h2 style={{ margin: "0 0 5px 0", fontWeight: "bold" }}>{formattedData?.group_name}</h2>
              <h2 style={{ margin: "0 0 5px 0", fontWeight: "bold" }}>{formattedData?.group_address}</h2>
              <h2 style={{ margin: "0 0 5px 0", fontWeight: "bold" }}>{formattedData?.group_zip} {formattedData?.group_city}</h2>
              <h3 style={{ margin: "20px 0 0 0", fontWeight: "normal" }}>Orthevielle, le {moment().format("DD/MM/YYYY")}</h3>
            </div>

            {/* Bill's number */}
            <div style={{ marginBottom: "30px" }}>
              <h2 style={{ margin: "40px 0 30px 0", fontWeight: "bold", textAlign: "center" }}>
                FACTURE N° : {`${formattedData?.billing_number}`}
              </h2>
              <div >
                <h3 style={{ margin: "0 0 5px 0", fontWeight: "normal" }}>
                  Mois de facturation : <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>{moment().format("MMMM")}</span>
                </h3>
                <h3 style={{ margin: "0 0 5px 0", fontWeight: "normal" }}>
                  Année : <span style={{ fontWeight: "bold" }}>{moment().format("YYYY")}</span>
                </h3>
              </div>
            </div>

            {/* Client's bailiffs list */}
            <div>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        borderBottom: "1px solid",
                        marginBottom: "10px",
                        textAlign: "left",
                        padding: "0 5px 5px 5px",
                        width: "35%"
                      }}
                    >Auteurs des constats</th>
                    <th
                      style={{
                        borderBottom: "1px solid",
                        marginBottom: "10px",
                        textAlign: "center",
                        padding: "0 5px 5px 5px",
                        fontWeight: "normal"
                      }}
                    >Nb constats</th>
                    <th
                      style={{
                        borderBottom: "1px solid",
                        marginBottom: "10px",
                        textAlign: "center",
                        padding: "0 5px 5px 5px",
                        fontWeight: "normal"
                      }}
                    >Nb pages texte</th>
                    <th
                      style={{
                        borderBottom: "1px solid",
                        marginBottom: "10px",
                        textAlign: "center",
                        padding: "0 5px 5px 5px",
                      }}
                    >Montant pages H.T</th>
                    <th
                      style={{
                        borderBottom: "1px solid",
                        marginBottom: "10px",
                        textAlign: "center",
                        padding: "0 5px 5px 5px",
                        fontWeight: "normal"
                      }}
                    >Nb photos</th>
                    <th
                      style={{
                        borderBottom: "1px solid",
                        marginBottom: "10px",
                        textAlign: "center",
                        padding: "0 5px 5px 5px",
                      }}
                    >Montant photos H.T</th>
                    <th
                      style={{
                        borderBottom: "1px solid",
                        textAlign: "center",
                        padding: "0 5px 5px 5px",
                        fontWeight: 800,
                        fontSize: "18px",
                        lineHeight: "20px",
                      }}
                    >Total H.T</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(formattedData.groups_reports_by_bailiff).map(
                    (key, index) => {
                      const group = formattedData.groups_reports_by_bailiff[key];

                      return (
                        <tr>
                          <td style={{ textAlign: "left", padding: "5px 5px 0 5px", fontWeight: "bold" }}>
                            {`${group?.bailiff_full_name}`}
                          </td>
                          <td style={{ textAlign: "center", padding: "5px 5px 0 5px", whiteSpace: "nowrap" }}>
                            {group.count_reports}
                          </td>
                          <td style={{ textAlign: "center", padding: "5px 5px 0 5px", whiteSpace: "nowrap" }}>
                            {group.count_pages_billing}
                          </td>
                          <td style={{ textAlign: "center", padding: "5px 5px 0 5px", fontWeight: "bold", whiteSpace: "nowrap" }}>
                            {group.amount_without_taxes_pages_with_discount.toFixed(
                              2
                            )}
                            €
                          </td>
                          <td style={{ textAlign: "center", padding: "5px 5px 0 5px", whiteSpace: "nowrap" }}>
                            {group.count_photos_billing}
                          </td>
                          <td style={{ textAlign: "center", padding: "5px 5px 0 5px", fontWeight: "bold", whiteSpace: "nowrap" }}>
                            {group.amount_without_taxes_photos_with_discount.toFixed(
                              2
                            )}
                            €
                          </td>
                          <td style={{
                            textAlign: "center",
                            padding: "5px 5px 0 5px",
                            fontWeight: 800,
                            fontSize: "17px",
                            lineHeight: "20px",
                            whiteSpace: "nowrap"
                          }}>
                            {(
                              group.amount_without_taxes_pages_with_discount +
                              group.amount_without_taxes_photos_with_discount
                            ).toFixed(2)}
                            €
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>

            {/* Totals resume */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "40px",
              }}
            >
              <div>
                <h2 style={{ fontWeight: "bold" }}>Totaux pour la periode</h2>
                <table style={{ borderCollapse: "collapse" }}>
                  <tr>
                    <td style={{ paddingBottom: "5px", paddingRight: "5px" }}>Nombre de constats :</td>
                    <td style={{ fontWeight: "bold", textAlign: "right" }}>{formattedData?.reports?.length}</td>
                  </tr>
                  <tr>
                    <td style={{ paddingBottom: "5px", paddingRight: "5px" }}>Nombre de pages texte :</td>
                    <td style={{ fontWeight: "bold", textAlign: "right" }}>{formattedData?.total_count_pages}</td>
                  </tr>
                  <tr>
                    <td style={{ paddingBottom: "5px", paddingRight: "5px" }}>Nombre de photos :</td>
                    <td style={{ fontWeight: "bold", textAlign: "right" }}>{formattedData?.total_count_photos}</td>
                  </tr>
                </table>
              </div>
              <table style={{
                textAlign: "right",
                fontWeight: "bold",
                fontSize: "16px",
                lineHeight: "18px",
                borderCollapse: "collapse"
              }}>
                <tr style={{ border: "2px solid" }}>
                  <td style={{ padding: "12px 30px 12px 10px" }}> Total H.T :</td>
                  <td style={{ padding: "0 10px 0 0" }}>{formattedData?.amount_without_taxes_with_discount?.toFixed(2)} €</td>
                </tr>
                <tr style={{ border: "2px solid", borderTop: "none" }}>
                  <td style={{ padding: "12px 30px 12px 10px" }}>Remise {formattedData?.discount}% :</td>
                  <td style={{ padding: "0 10px 0 0" }}>{(
                    formattedData?.discount *
                    (formattedData?.amount_without_taxes_with_discount / 100)
                  )?.toFixed(2)} €</td>
                </tr>
                <tr style={{ border: "2px solid", borderTop: "none" }}>
                  <td style={{ padding: "12px 30px 12px 10px" }}>T.V.A. 20% :</td>
                  <td style={{ padding: "0 10px 0 0" }}>{(
                    (20 * formattedData?.amount_without_taxes_with_discount) /
                    100
                  )?.toFixed(2)} €</td>
                </tr>
                <tr style={{ border: "2px solid", borderTop: "none" }}>
                  <td style={{ padding: "12px 30px 12px 10px" }}>Total T.T.C. :</td>
                  <td style={{ padding: "0 10px 0 0" }}>{(
                    formattedData?.amount_without_taxes_with_discount -
                    formattedData?.discount *
                    (formattedData?.amount_without_taxes_with_discount /
                      100) +
                    (20 * formattedData?.amount_without_taxes_with_discount) /
                    100
                  )?.toFixed(2)} €</td>
                </tr>
              </table>
            </div>

            {/* Legal text */}
            <div
              style={{
                marginTop: "50px",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              <h4 style={{ color: "#857e7e", fontWeight: "bold", margin: "0 0 5px 0" }}>En votre aimable règlement comptant.</h4>
              <h4 style={{ color: "#857e7e", fontWeight: "bold", margin: "0 0 5px 0" }}>En cas de règlement tardif, le taux d'intérêt de retard sera de 1.5% par mois.</h4>
              <h4 style={{ color: "#857e7e", fontWeight: "bold", margin: "0 0 5px 0" }}>TVA acquittée sur les encaissements.</h4>
            </div>
          </div >

          {/* Annexes */}
          {
            Object.keys(formattedData.groups_reports).map((key, index) => {
              const group_reports = formattedData.groups_reports[key];
              const amount_with_discount = group_reports?.reports?.reduce(
                (acc, report) => {
                  return acc + report?.amount_without_taxes_with_discount;
                },
                0
              );

              return (
                <div className="page" style={{
                  display: "block",
                  position: "relative",
                  width: "210mm",
                  backgroundColor: "#fff",
                  breakBefore: "page",
                }}>

                  {/* Header */}
                  <div>
                    <img style={{ width: "210px", height: "auto" }} src={globals.domain + "/public/images/logoTemplate.jpg"} alt="CRC Logo" />
                  </div>

                  {/* Bill's number */}
                  <h2 style={{ margin: "40px 0 30px 0", fontWeight: "bold" }}>ANNEXE Facture N° : {formattedData?.billing_number}</h2>
                  <div style={{ marginLeft: "50%", marginBottom: "40px" }}>
                    <h2 style={{ fontWeight: "bold", margin: "0 0 5px 0" }}>{formattedData.group_name} - {group_reports?.bailiff_full_name}</h2>
                    <h2 style={{ fontWeight: "bold", margin: "0 0 5px 0" }}>{group_reports?.bailiff_full_name}</h2>
                  </div>

                  {/* Bailiff reports */}
                  <table style={{ borderCollapse: "collapse" }}>
                    <thead>
                      <tr style={{ verticalAlign: "top" }}>
                        <th
                          style={{
                            borderBottom: "1px solid",
                            marginBottom: "10px",
                            padding: "0 5px 5px 5px",
                            fontWeight: "normal",
                            textAlign: "left",
                            height: "1px",
                          }}
                        >
                          Date
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid",
                            marginBottom: "10px",
                            width: "19%",
                            padding: "0 5px 5px 5px",
                            textAlign: "left"
                          }}
                        >
                          Nom de constat
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid",
                            marginBottom: "10px",
                            textAlign: "center",
                            padding: "0 5px 5px 5px",
                            fontWeight: "normal"
                          }}
                        >
                          Nb pages Total
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid",
                            marginBottom: "10px",
                            textAlign: "center",
                            padding: "0 5px 5px 5px",
                            fontWeight: "normal"
                          }}
                        >
                          Nb pages texte
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid",
                            marginBottom: "10px",
                            textAlign: "center",
                            padding: "0 5px 5px 5px",
                          }}
                        >
                          Montant pages H.T
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid",
                            marginBottom: "10px",
                            textAlign: "center",
                            padding: "0 5px 5px 5px",
                            fontWeight: "normal"
                          }}
                        >
                          Nb photos
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid",
                            marginBottom: "10px",
                            textAlign: "center",
                            padding: "0 5px 5px 5px",
                          }}
                        >
                          Montant photos H.T
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid",
                            marginBottom: "10px",
                            textAlign: "center",
                            padding: "0 5px 5px 5px",
                          }}
                        >
                          Remises
                        </th>
                        <th
                          style={{
                            borderBottom: "1px solid",
                            textAlign: "center",
                            padding: "0 5px 5px 5px",
                            fontWeight: 800,
                            fontSize: "18px",
                            lineHeight: "20px",

                          }}
                        >
                          Total H.T
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {group_reports?.reports?.map((report) => {
                        return (
                          <tr>
                            <td style={{ padding: "5px 5px 0 5px", whiteSpace: "nowrap" }}>
                              {moment(report.createdAt, "YYYY-MM-DD").format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                            <td style={{ padding: "5px 5px 0 5px", fontWeight: "bold" }}>{report.name}</td>
                            <td style={{ textAlign: "center", padding: "5px 5px 0 5px", whiteSpace: "nowrap" }}>
                              {report.count_pages_billing +
                                report.count_photos_billing}
                            </td>
                            <td style={{ textAlign: "center", padding: "5px 5px 0 5px", whiteSpace: "nowrap" }}>
                              {report.count_pages_billing}
                            </td>
                            <td style={{ textAlign: "center", padding: "5px 5px 0 5px", fontWeight: "bold", whiteSpace: "nowrap" }}>
                              {report?.amount_without_taxes_pages?.toFixed(2)} €
                            </td>
                            <td style={{ textAlign: "center", padding: "5px 5px 0 5px", whiteSpace: "nowrap" }}>
                              {report.count_photos_billing}
                            </td>
                            <td style={{ textAlign: "center", padding: "5px 5px 0 5px", fontWeight: "bold", whiteSpace: "nowrap" }}>
                              {report?.amount_without_taxes_photos?.toFixed(2)} €
                            </td>
                            <td style={{ textAlign: "center", padding: "5px 5px 0 5px", fontWeight: "bold", whiteSpace: "nowrap" }}>
                              {(
                                report?.amount_without_taxes_pages -
                                report?.amount_without_taxes_pages_with_discount +
                                (report?.amount_without_taxes_photos -
                                  report?.amount_without_taxes_photos_with_discount)
                              )?.toFixed(2)}{" "}
                              €
                            </td>
                            <td style={{
                              textAlign: "center",
                              padding: "5px 5px 0 5px",
                              fontWeight: 800,
                              fontSize: "17px",
                              lineHeight: "20px",
                              whiteSpace: "nowrap"
                            }}>{report?.amount_without_taxes_with_discount?.toFixed(
                              2
                            )}{" "} €</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  {/* Totals resume */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "40px",
                    }}
                  >
                    <div>
                      <h2 style={{ fontWeight: "bold" }}>Totaux pour la periode</h2>
                      <table style={{ borderCollapse: "collapse" }}>
                        <tr>
                          <td style={{ paddingBottom: "5px", paddingRight: "10px", textAlign: "right" }}>Mois de facturation :</td>
                          <td style={{ fontWeight: "bold", textAlign: "left", textTransform: "capitalize" }}>{moment(group_reports.updatedAt, "YYYY-MM-DD").format("MMMM")}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingBottom: "5px", paddingRight: "10px", textAlign: "right" }}>Année :</td>
                          <td style={{ fontWeight: "bold", textAlign: "left" }}>{moment(group_reports.updatedAt, "YYYY-MM-DD").format("YYYY")}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingBottom: "5px", paddingRight: "10px", textAlign: "right" }}>Auteur des constats :</td>
                          <td style={{ fontWeight: "bold", textAlign: "left" }}>{group_reports.bailiff_full_name}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingBottom: "5px", paddingRight: "10px", textAlign: "right" }}>Nombre de constats :</td>
                          <td style={{ fontWeight: "bold", textAlign: "left" }}>{group_reports.reports.length}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingBottom: "5px", paddingRight: "10px", textAlign: "right" }}>Tarif page texte :</td>
                          <td style={{ fontWeight: "bold", textAlign: "left" }}>{formattedData.group_price_page.toFixed(2)} €</td>
                        </tr>
                        <tr>
                          <td style={{ paddingBottom: "5px", paddingRight: "10px", textAlign: "right" }}>Tarif par photo :</td>
                          <td style={{ fontWeight: "bold", textAlign: "left" }}>{formattedData.group_price_photo.toFixed(2)} €</td>
                        </tr>
                        <tr>
                          <td style={{ paddingBottom: "5px", paddingRight: "10px", textAlign: "right" }}>Nombre de pages Total :</td>
                          <td style={{ fontWeight: "bold", textAlign: "left" }}>{group_reports.reports.reduce((acc, report) => {
                            return acc + report.count_pages_billing + report.count_photos_billing;
                          }, 0)}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingBottom: "5px", paddingRight: "10px", textAlign: "right" }}>Nombre de pages texte :</td>
                          <td style={{ fontWeight: "bold", textAlign: "left" }}>{group_reports.reports.reduce((acc, report) => {
                            return acc + report.count_pages_billing
                          }, 0)}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingBottom: "5px", paddingRight: "10px", textAlign: "right" }}>Montant des pages texte :</td>
                          <td style={{ fontWeight: "bold", textAlign: "left" }}>{group_reports.reports.reduce((acc, report) => {
                            return acc + report.amount_without_taxes_pages
                          }, 0).toFixed(2)} €</td>
                        </tr>
                        <tr>
                          <td style={{ paddingBottom: "5px", paddingRight: "10px", textAlign: "right" }}>Nombre de photos :</td>
                          <td style={{ fontWeight: "bold", textAlign: "left" }}>{group_reports.reports.reduce((acc, report) => {
                            return acc + report.count_photos_billing
                          }, 0)}</td>
                        </tr>
                        <tr>
                          <td style={{ paddingBottom: "5px", paddingRight: "10px", textAlign: "right" }}>Montant des photos :</td>
                          <td style={{ fontWeight: "bold", textAlign: "left" }}>{group_reports.reports.reduce((acc, report) => {
                            return acc + report.amount_without_taxes_photos
                          }, 0).toFixed(2)} €</td>
                        </tr>
                      </table>
                    </div>
                    <table style={{
                      textAlign: "right",
                      fontWeight: "bold",
                      fontSize: "16px",
                      lineHeight: "18px",
                      height: "1px",
                      borderCollapse: "collapse"
                    }}>
                      <tr style={{ border: "2px solid" }}>
                        <td style={{ padding: "12px 30px 12px 10px" }}> Total H.T :</td>
                        <td style={{ padding: "0 10px 0 0" }}>{amount_with_discount?.toFixed(2)} €</td>
                      </tr>
                      <tr style={{ border: "2px solid", borderTop: "none" }}>
                        <td style={{ padding: "12px 30px 12px 10px" }}>T.V.A. 20% :</td>
                        <td style={{ padding: "0 10px 0 0" }}>{((amount_with_discount * 20) / 100)?.toFixed(2)} €</td>
                      </tr>
                      <tr style={{ border: "2px solid", borderTop: "none" }}>
                        <td style={{ padding: "12px 30px 12px 10px" }}>Total T.T.C. :</td>
                        <td style={{ padding: "0 10px 0 0" }}>{(
                          (amount_with_discount * 20) / 100 +
                          amount_with_discount
                        )?.toFixed(2)} €</td>
                      </tr>
                    </table>
                  </div>
                </div>
              );
            })
          }
        </div>)}

      {/* Footer */}
      {(!contentOnly || footerOnly) && (
        <div id="footer" style={{
          position: "relative",
          top: "5px",
          display: "block",
          width: "100%",
          margin: 0,
          padding: 0,
          fontFamily: "Arial, sans-serif",
          backgroundColor: "#fff",
          textAlign: "center",
        }}>
          <h2 style={{ fontSize: "10px", color: "#357fbb", fontWeight: "bold", margin: "0 0 5px 0" }}>1061, route de Lahourcade - 40300 ORTHEVIELLE</h2>
          <h3 style={{ fontSize: "10px", color: "#857e7e", fontWeight: "bold", margin: "0 0 5px 0" }}>RCS Dax 801 912 452 - Capital 5 000 €</h3>
        </div>)}
    </>
  );
};

interface TemplateBillingProps {
  data: any;
}

const TemplateBillingSecretary = ({ data }: TemplateBillingProps) => {
  const formattedData = {
    ...data,
    billing_number:
      moment().valueOf() +
      "" +
      Math.floor(Math.random() * (9999 - 1000) + 1000),
  };

  return (
    <div
      id="template_billing_secretary"
      style={{
        width: "210mm",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "#fff",
      }}
    >
      <div className="page">
        <div style={{ height: "20vh" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "right",
            }}
          >
            <img style={{ maxWidth: "50%", height: "fit-content", alignSelf: "center" }} src={"/images/logoTemplate.jpg"} alt="CRC Logo" />
            <div>
              <h3 style={{ color: "#40A9FF" }}>https://crc-constat.fr</h3>
              <h3 style={{ color: "#40A9FF" }}>
                laurence.crc@gmail.com
              </h3>
              <h3 style={{ color: "#40A9FF" }}>Téléphone: 06 95 57 14 34</h3>
            </div>
          </div>
          <h2 style={{ textAlign: "left", color: "#40A9FF" }}>
            TÉLÉSECRÉTARIAT EXCLUSIF HUISSIERS DE JUSTICE
          </h2>
        </div>
        <hr></hr>
        <div className="page_0" style={{ minHeight: "100vh" }}>
          <div>
            <h3>{`${formattedData?.secretary?.first_name} ${formattedData?.secretary?.last_name}`}</h3>
            <h3>Cagnes sur mer, le {moment().format("DD/MM/YYYY")}</h3>
          </div>
          <div>
            <h2 style={{ textAlign: "right" }}>
              FACTURE N° - {`${formattedData?.billing_number}`}
            </h2>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "15px",
              }}
            >
              <h4 style={{ textAlign: "center", marginRight: "20px" }}>
                Mois de facturation: {moment().format("MMMM")}
              </h4>
              <h4 style={{ textAlign: "center" }}>
                {" "}
                Année : {moment().format("YYYY")}
              </h4>
            </div>
          </div>

          <div>
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      borderBottom: "2px solid #40A9FF",
                      marginBottom: "10px",
                      textAlign: "center",
                      wordSpacing: "100vw",
                    }}
                  >
                    Nb constats
                  </th>
                  <th
                    style={{
                      borderBottom: "2px solid #40A9FF",
                      marginBottom: "10px",
                      textAlign: "center",
                      wordSpacing: "100vw",
                    }}
                  >
                    Nb pages texte
                  </th>
                  <th
                    style={{
                      borderBottom: "2px solid #40A9FF",
                      marginBottom: "10px",
                      textAlign: "center",
                      wordSpacing: "100vw",
                    }}
                  >
                    Montant pages H.T
                  </th>
                  <th
                    style={{
                      borderBottom: "2px solid #40A9FF",
                      marginBottom: "10px",
                      textAlign: "center",
                      wordSpacing: "100vw",
                    }}
                  >
                    Nb photos
                  </th>
                  <th
                    style={{
                      borderBottom: "2px solid #40A9FF",
                      marginBottom: "10px",
                      textAlign: "center",
                      wordSpacing: "100vw",
                    }}
                  >
                    Montant photos H.T
                  </th>
                  <th
                    style={{
                      borderBottom: "2px solid #40A9FF",
                      marginBottom: "10px",
                      textAlign: "center",
                      wordSpacing: "100vw",
                    }}
                  >
                    Total H.T
                  </th>
                </tr>
              </thead>
              <tbody>
                {formattedData?.reports?.map((report) => {
                  return (
                    <tr>
                      <td style={{ textAlign: "center" }}>{report.name}</td>
                      <td style={{ textAlign: "center" }}>
                        {report.count_pages_billing}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {report.amount_without_taxes_pages.toFixed(2)}€
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {report.count_photos_billing}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {report.amount_without_taxes_photos.toFixed(2)}€
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {(
                          report.amount_without_taxes_pages +
                          report.amount_without_taxes_photos
                        ).toFixed(2)}
                        €
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "40px",
            }}
          >
            <div>
              <h3>Totaux pour la periode</h3>
              <h4>Nombre de constats: {formattedData?.reports?.length}</h4>
              <h4>Nombre de pages: {formattedData?.total_count_pages}</h4>
              <h4>Nombre de photos: {formattedData?.total_count_photos}</h4>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h3 style={{ color: "#40A9FF", marginRight: "20px" }}>
                  Total H.T.{" "}
                </h3>
                <h3 style={{ color: "#40A9FF" }}>
                  {formattedData?.amount_without_taxes?.toFixed(2)}€
                </h3>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h3 style={{ color: "#40A9FF", marginRight: "20px" }}>
                  T.V.A 20%{"  "}
                </h3>
                <h3 style={{ color: "#40A9FF" }}>
                  {((20 * formattedData?.amount_without_taxes) / 100)?.toFixed(
                    2
                  )}
                  €{" "}
                </h3>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h3 style={{ color: "#40A9FF", marginRight: "20px" }}>
                  Total T.T.C. {"  "}
                </h3>
                <h3 style={{ color: "#40A9FF" }}>
                  {(
                    formattedData?.amount_without_taxes +
                    (20 * formattedData?.amount_without_taxes) / 100
                  )?.toFixed(2)}
                  €
                </h3>
              </div>
            </div>
          </div>
          <div
            style={{
              textAlign: "center",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            <h6 style={{ textAlign: "center", color: "#979797" }}>
              En votre aimable règlement comptant.
            </h6>
            <h6 style={{ textAlign: "center", color: "#979797" }}>
              En cas de règlement tardif, le taux d'intérêt de retard sera de
              1.5% par mois.
            </h6>
            <h6 style={{ textAlign: "center", color: "#979797" }}>
              TVA acquittée sur les encaissements.
            </h6>
            <br></br>
            <br></br>
            <br></br>
            <h3 style={{ textAlign: "center", color: "#40A9FF" }}>
              1061, route de Lahourcade - 40300 ORTHEVIELLE
            </h3>
            <h6 style={{ textAlign: "center", color: "#938B8B" }}>
              RCS Dax 801 912 452 - Capital 5 000 €
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export {
  TemplateBillingBailiff,
  TemplateBillingSecretary,
};
