import React, { useState } from "react"
import { Link, Route, Switch, useHistory } from "react-router-dom"
import {
  Layout,
  Table,
  Input,
  Badge,
  Menu,
  Dropdown,
  Space,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Breadcrumb,
  Button,
  Upload,
  message,
  InputNumber,
} from "antd"
import {
  CheckCircleOutlined,
  CloudUploadOutlined,
  CloseOutlined,
} from "@ant-design/icons"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { useSelector, useDispatch } from "react-redux"
import { setPhoneLengthValidation } from "../../../redux/auth/actions"

import styles from "../styles.module.scss"
import { BASE_URL } from "../../../helpers/api"
// import Leftmenu from '../leftmenu';
import Topmenu from "../topmenu"
import { filesUploadServices } from "../../../_services/uploadFIles"
import { usersService } from "../../../_services/usersSerives"
import { ISendUserDataI } from "../../../interfaces/users.interface"
import FileUtils from "../../../utils/files"

const { Dragger } = Upload

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result))
  reader.readAsDataURL(img)
}
const props = {
  name: "file",
  multiple: false,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file
    if (status !== "uploading") {
      console.log(info.file, info.fileList)
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`)
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`)
    }
  },
}

//const { Content } = Layout;
const { Header, Footer, Sider, Content } = Layout
const { Option } = Select

const handleChange = () => {
  console.log(`selected`)
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!")
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!")
  }
  return isJpgOrPng && isLt2M
}

function CreateaReportBailiff() {
  const { user } = useSelector((state: any) => state.auth)

  const [adminForm, setAdminForm] = useState({
    civility: user?.civility || "",
    last_name: user?.last_name || "",
    first_name: user?.first_name || "",
    phone: user?.telephone || "",
    email: user?.email || "",
    password: user?.password || "",
    confirmPassword: user?.civility || "",
    userID: user?.identity || "",
  })

  const imguploadicon = require("../../../pages/images/uploadimage-icon.svg")
  const [imageUrl, setImage] = useState(
    user.profile || require("../../../pages/images/uploadimage.svg")
  )
  const dispatch = useDispatch()
  //const [imageUrl, setImage] = useState('https://www.kindpng.com/picc/m/163-1636340_user-avatar-icon-avatar-transparent-user-icon-png.png');
  const uploadButton = (
    <div>
      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const handleChangeImage = async (info) => {
    if (info.file.status === "done") {
      let data = new FormData()
      data.append("profile", info.file.originFileObj)
      await filesUploadServices
        .uploadFileAPI(data)
        .then((response) => {
          setImage(response.data.url)
        })
        .catch((err) => console.error(err))
    }
  }

  const onFinish = async (values) => {
    let data = {
      civility: values.civility,
      first_name: values.firstname,
      last_name: values.lastname,
      telephone: values.telephone,
      address: values.address,
      city: values.city,
      role_id: 2,
      profile:
        "http://localhost:8000/public/images/profile-2020-11-05T20:06:46.916Z-gbepvjettqttzqlz_1593258788.jpeg",
      identity: 787877,
      // rib: null,
      email: values.email,
      password: values.confirm_password,
      zip: 564009,
    }
    const postData = Object.keys(data)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`)
      .join("&")
    // return;
    let showNotification = {}
    let modalCloseStatus = 1
    const response = await fetch(BASE_URL + "/api/v1/auth/register", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded", // <-- Specifying the Content-Type
      }),
      // headers: generalAuthTokenHeader(),
      body: postData,
    })
      .then((response) => {
        if (response.status === 400) {
          showNotification = {
            title: "Discharge Planner",
            message: "Bad response from server",
            type: "danger",
          }
        } else if (response.ok) {
        } else {
          showNotification = {
            title: "Discharge Planner",
            message: "Bad response from server.",
            type: "danger",
          }
        }
        return response.json()
      })
      .then((data) => {
        if (data.status == "400" && data.message == "error.idexists") {
          // modalCloseStatus = 0;
          // showNotification = {
          //     title: "Discharge Planner",
          //     message: data.title,
          //     type: 'danger'
          // };
        } else if (data.user) {
          // showNotification = {
          //     title: "Success",
          //     message: "Status updated successfully",
          //     type: "success"
          // };
          message.success("Inscrivez-vous avec succès.")
          return true
        }
      })
      .catch((error) => {
        // showNotification = {
        //     title: 'Discharge Planner',
        //     message: 'Something went wrong.Please try after sometime..',
        //     type: "danger"
        // };
      })
  }
  //  const handleNumberChange = value => {
  // 	const validateStatus = value.length === 10;
  // 	this.setState({
  // 	  validateStatus,
  // 	  value,
  // 	  errorMsg: isValid ? null : 'Invalid Mobile number'
  // 	});
  //   };
  function validateMobileNumber(value) {
    // const{ form } = this.props;
    // let mnumber = form.getFieldValue('phone');
    if (value !== "") {
      console.log("this is a mobil validator", value, value.toString().length)
    }

    if (value.toString().length === 10) {
      dispatch(setPhoneLengthValidation(null))
      console.log("valid mobile nuumber")
    } else {
      console.log("invalid mobile nuumber")
      dispatch(
        setPhoneLengthValidation("Le téléphone doit être composé de 10 chiffres")
      )
    }
  }

  const _changeHandler = (event, name) => {
    setAdminForm({
      ...adminForm,
      ...{ [name]: event.target.value },
    })
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok")
    }, 0)
  }

  const _submitHandler = async () => {
    let data: any = {}
    data["role_id"] = "1"
    data["civility"] = adminForm.civility
    data["last_name"] = adminForm.last_name
    data["first_name"] = adminForm.first_name
    data["telephone"] = adminForm.phone
    if (adminForm.password !== "") {
      data["password"] = adminForm.password
    }
    data["profile"] = imageUrl
    await usersService
      .updateUsersAPI(user?.id, data)
      .then((response) => response.data)
      .then((response: ISendUserDataI) => {
        message.success("Compte modifié avec succès.")
      })
      .catch((err) => console.error(err))
  }

  return (
    <Layout className="creer-constat-wrapper noleftmenu_section profile_section">
      <Row>
        <Col xs={24} md={24} lg={24}>
          <div className="dashboard-right-section profile_bailiff_wrapper">
            <div className="form_outer_wrapper">
              <div className="onlyscreen_template_inner">
                <div className="onlyscreen_template_wrapper">
                  <div className="form_outer_onlyscreenwrapper">
                    <div className="form_onlyscreenwrapper">
                      <div className="userprofile-layout-wrapper">
                        <Layout>
                          <Layout>
                            <Content>
                              <div className="content-section-wrapper">
                                <div>
                                  <Form className="adminprofile-form">
                                    <h2>MON PROFIL</h2>
                                    <Row>
                                      <div className="profile-mobile">
                                        <Form.Item>
                                            <Upload
                                              name="avatar"
                                              showUploadList={false}
                                              listType="picture-card"
                                              className="avatar-uploader"
                                              customRequest={FileUtils.generalDummyRequestLocal}
                                              onChange={handleChangeImage}
                                            >
                                              <div className="uploadimg-wrapper">
                                                {imageUrl ? (
                                                  <img
                                                    src={imageUrl}
                                                    alt="avatar"
                                                    style={{ width: "100%" }}
                                                  />
                                                ) : (
                                                  uploadButton
                                                )}
                                                {/* <div className="uploadimg-icons">
                                                  <img src={imguploadicon} alt="avatar-icon" />
                                                </div> */}
                                              </div>
                                            </Upload>
                                          </Form.Item>
                                      </div>
                                      <Col xs={24} md={12} lg={12}>
                                        <Form.Item
                                          label="Civilité"
                                          valuePropName="civility"
                                          rules={[
                                            {
                                              required: true,
                                              message: "Please enter the Civilité",
                                            },
                                          ]}
                                        >
                                          <Input
                                            placeholder="Civilité"
                                            value={adminForm.civility || ""}
                                            onChange={(e) =>
                                              _changeHandler(e, "civility")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Nom"
                                          valuePropName="last_name"
                                        >
                                          <Input
                                            placeholder="Nom"
                                            value={adminForm.last_name || ""}
                                            onChange={(e) =>
                                              _changeHandler(e, "last_name")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Prénom"
                                          valuePropName="first_name"
                                        >
                                          <Input
                                            placeholder="Prénom"
                                            value={adminForm.first_name || ""}
                                            onChange={(e) =>
                                              _changeHandler(e, "first_name")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Téléphone"
                                          valuePropName="phone"
                                        >
                                          <Input
                                            placeholder="Téléphone"
                                            value={adminForm.phone || ""}
                                            onChange={(e) =>
                                              _changeHandler(e, "phone")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Email"
                                          valuePropName="email"
                                        >
                                          <Input
                                            placeholder="Email"
                                            value={adminForm.email || ""}
                                            onChange={(e) =>
                                              _changeHandler(e, "email")
                                            }
                                            disabled
                                          />
                                        </Form.Item>
                                        <h3>Vos identifiants de connexion</h3>

                                        <Form.Item
                                          label="Identifiant"
                                          valuePropName="userID"
                                        >
                                          <Input
                                            placeholder="Identifiant"
                                            value={adminForm.userID || ""}
                                            onChange={(e) =>
                                              _changeHandler(e, "userID")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Mot de passe"
                                          valuePropName="password"
                                        >
                                          <Input.Password
                                            placeholder="Mot de passe"
                                            value={adminForm.password || ""}
                                            onChange={(e) =>
                                              _changeHandler(e, "password")
                                            }
                                          />
                                        </Form.Item>
                                        <Form.Item style={{ textAlignLast: "center" }}>
                                          <Button
                                            type="primary"
                                            className="validate-btn"
                                            onClick={_submitHandler}
                                          >
                                            Valider
                                          </Button>
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} md={12} lg={12}>
                                        <div className="profile_uploadimg_wrapper profile-website">
                                          <Form.Item>
                                            <Upload
                                              name="avatar"
                                              showUploadList={false}
                                              listType="picture-card"
                                              className="avatar-uploader"
                                              customRequest={FileUtils.generalDummyRequestLocal}
                                              onChange={handleChangeImage}
                                            >
                                              <div className="uploadimg-wrapper">
                                                {imageUrl ? (
                                                  <img
                                                    src={imageUrl}
                                                    alt="avatar"
                                                    style={{ width: "100%" }}
                                                  />
                                                ) : (
                                                  uploadButton
                                                )}
                                                {/* <div className="uploadimg-icons">
                                                  <img src={imguploadicon} alt="avatar-icon" />
                                                </div> */}
                                              </div>
                                            </Upload>
                                          </Form.Item>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Form>
                                </div>
                              </div>
                            </Content>
                          </Layout>
                        </Layout>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default CreateaReportBailiff
