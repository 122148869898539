import React, { useState, useEffect, useReducer } from "react";
import {
  Avatar,
  Button,
  Col,
  Comment,
  Form,
  Input,
  Modal,
  Row,
  Select,
} from "antd";
import { RollbackOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { commentUtils } from "../../utils/functions/comments";
import { markAsReadCommentReport } from "../../redux/report";
const { Option } = Select;

export const userRoles = {
  all: 0,
  admin: 1,
  bailiff: 2,
  secretary: 3,
};

interface ModalCommentsConstatProps {
  visible: boolean;
  onClose: () => void;
  data: any;
  onSend: (data) => void;
}

const ModalComments = ({
  visible,
  onClose,
  data,
  onSend,
}: ModalCommentsConstatProps) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { user } = useSelector((state: any) => state.auth);
  const { users } = useSelector((state: any) => state.user);
  const { successMarkAsReadCommentReport } = useSelector(
    (state: any) => state.report
  );
  const [comments, setComments] = useState(data.comments);
  const [filters, setFilters] = useState({
    user_type: userRoles.all,
    maxSize: 3,
  });

  useEffect(() => {
    let commentsFiltered = data?.comments ? [...data?.comments] : [];

    commentsFiltered = commentUtils.filterByRole({
      comments: commentsFiltered,
      role: filters.user_type,
      user,
      users,
    });

    commentsFiltered = commentsFiltered?.sort((a, b) => {
      return moment(a.created_at).diff(moment(b.created_at)) > 0 ? -1 : 1;
    });

    setComments(commentsFiltered);
  }, [dispatch, data, filters]);

  useEffect(() => {
    if (visible) {
      const commetsWithoutRead: any = [];

      for (const comment of comments) {
        if (data.comments_without_read.includes(comment.id)) {
          commetsWithoutRead.push(comment.id);
        }
      }

      if (commetsWithoutRead.length > 0) {
        dispatch(
          markAsReadCommentReport({
            report_id: data.id,
            comments_ids: commetsWithoutRead,
          })
        );
      }
    }
  }, [comments]);

  return (
    <Modal
      className="retrocessionadminreports-modal"
      centered
      footer={null}
      visible={visible}
      destroyOnClose={true}
      onCancel={() => {
        setFilters({ ...filters });
        onClose();
      }}
    >
      <Form form={form}>
        <Row>
          <Col span={18}>
            <h2 className="modal_title">Commentaires</h2>
          </Col>
          <Col span={6}>
            {user?.roleId == userRoles.admin && (
              <Select
                defaultValue={userRoles.all}
                className="select-user-type"
                onChange={(value) => {
                  setFilters({
                    ...filters,
                    user_type: value,
                  });
                }}
              >
                <Option value={userRoles.all}>Tous</Option>
                <Option value={userRoles.bailiff}>Huissier</Option>
                <Option value={userRoles.secretary}>Secrétaire</Option>
              </Select>
            )}
          </Col>
        </Row>
        <div className="comment_wrapper">
          {comments
            ?.slice(0, comments.length > 3 ? filters.maxSize : comments.length)
            ?.map((comment: any, index) => {
              const user = users.find((user) => user.id == comment.id);

              return (
                <Comment
                  key={index}
                  author={
                    <Row>
                      <Col span={17} className="comment-left-wrapper">
                        <Avatar
                          src={user.profile}
                          alt="Han Solo"
                          className="comment_avatar"
                        />
                        <a
                          className="comment_avt_name"
                          onClick={(e) => e.preventDefault}
                        >
                          {`${user.first_name} ${user.last_name}`}
                        </a>
                      </Col>
                      <Col span={7} className="comment-right-wrapper">
                        <span className="comment_avt_date">
                          {moment(comment.created_at).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </span>
                      </Col>
                    </Row>
                  }
                  content={<p>{comment.comment}</p>}
                ></Comment>
              );
            })}
        </div>
        <div>
          {(
            user?.roleId == userRoles.admin
              ? filters.user_type != userRoles.all
              : true
          ) ? (
            <>
              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  padding: "0px 0px 0px 2em",
                }}
              >
                <Form.Item
                  name="comment"
                  rules={[
                    {
                      required: true,
                      message: "Veuillez saisir un commentaire",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Input.TextArea placeholder="Commentaire" />
                </Form.Item>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Form.Item>
                  <Button
                    style={{ marginTop: "10px", color: "#40A9FF" }}
                    onClick={() => {
                      form.validateFields().then((values) => {
                        form.resetFields();
                        onSend({
                          report_id: data.id,
                          id: user.id,
                          comment: values.comment,
                          ...(userRoles.admin == user.roleId && {
                            receiver_id:
                              filters.user_type == userRoles.bailiff
                                ? data?.bailiff_id
                                : data?.secretary_id,
                          }),
                        });
                        setFilters({ ...filters });
                      });
                    }}
                  >
                    Envoyer
                  </Button>
                </Form.Item>
              </div>
            </>
          ) : (
            <div style={{ marginBottom: "20px" }}></div>
          )}
        </div>
        {comments &&
          comments.length > 3 &&
          filters.maxSize < comments.length && (
            <div
              style={{
                marginLeft: "15px",
                borderTop: "1px solid #E9E9E9",
                marginTop: "10px",
              }}
            >
              <a
                style={{ textDecoration: "underline", color: "grey" }}
                onClick={(e) =>
                  setFilters({ ...filters, maxSize: filters.maxSize + 3 })
                }
              >
                Voir les anciens messages
              </a>
            </div>
          )}
      </Form>
    </Modal>
  );
};

export default ModalComments;
