import React, { useState } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import {
  Layout,
  Table,
  Input,
  Badge,
  Menu,
  Dropdown,
  Space,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Breadcrumb,
  Button,
  Upload,
  message,
  InputNumber,
} from "antd";
import {
  CheckCircleOutlined,
  CloudUploadOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useSelector, useDispatch } from "react-redux";
import { setPhoneLengthValidation } from "../../../redux/auth/actions";

import styles from "../styles.module.scss";
import { BASE_URL } from "../../../helpers/api";
// import Leftmenu from '../leftmenu';
//import Topmenu from "../topmenu";
import { filesUploadServices } from "../../../_services/uploadFIles";
import { usersService } from "../../../_services/usersSerives";

const { Dragger } = Upload

//const { Content } = Layout;
const { Header, Footer, Sider, Content } = Layout;
const { Option } = Select;


function EmailTemplate() {
  const { user } = useSelector((state: any) => state.auth);
  return (
    //   <Table
    //   columns={columns}
    //   expandable={{
    //     expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
    //     rowExpandable: record => record.date !== 'Not Expandable',
    //   }}
    //   dataSource={data}
    //   className="constats-table"
    // />
    <Layout className="creer-constat-wrapper noleftmenu_section">
     <Row>
        <Col xs={24} md={24} lg={24}>
          <div className="emailtemplate_wrapper">
            <div className="emailtemplate_mask">
            <div className="emailtemplate_inner">
              <div className="headertemplate">
                <div className="headertemplate_left">
                  <img src={"/images/template-left.png"} alt="top-left" className={styles.responsive_img} />
                </div>
                <div className="logocenter">
                  <img src={"/images/template-logo.png"} alt="logo" className={styles.responsive_img} />
                </div>
                <div className="headertemplate_right">
                  <img src={"/images/template-right.png"} alt="top-right" className={styles.responsive_img} />
                </div>
              </div>
              <div className="emailtemplatecontent">
                <h2>Nouveau Constat</h2>
              </div>
              <div className="footertemplate">
                <div className="footertemplate_left">
                  <img src={"/images/footer-logo.png"} alt="footer-left" className={styles.responsive_img} />
                </div>
                <div className="logocenter">
                 <div className="footerrow-one">&nbsp;</div>
                 <div className="footerrow-two">
                  <img src={"/images/gloabtemplate.png"} alt="web" className={styles.responsive_img} />
                  <span>www.crc.com</span>
                 </div>
                 <div className="footerrow-three">
                  <img src={"/images/calltemplate.png"} alt="call" className={styles.responsive_img} />
                  <span>www.crc.com</span>
                 </div>
                </div>
                <div className="footertemplate_right">
                  <img src={"/images/template-right.png"} alt="footer-right" className={styles.responsive_img} />
                </div>
              </div>
            </div>
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default EmailTemplate;
