import React, { useEffect, useState } from "react";
import { Row, Col, Select } from "antd";
import { usersService } from "../../_services/usersSerives";
import { useSelector } from "react-redux";
const { Option } = Select;

const YEARS = ["2020", "2021", "2022", "2023"];
const MONTH = [
  { value: "01", label: "Janvier" },
  { value: "02", label: "Février" },
  { value: "03", label: "Mars" },
  { value: "04", label: "Avril" },
  { value: "05", label: "Mai" },
  { value: "06", label: "Juin" },
  { value: "07", label: "Juillet" },
  { value: "08", label: "Août" },
  { value: "09", label: "Septembre" },
  { value: "10", label: "Octobre" },
  { value: "11", label: "Novembre" },
  { value: "12", label: "Décembre" },
];

const AdvanceFilterOptions = ({ handleChangeAdvance, isNew = false }) => {
  const [bailiffUsers, setBailiffUsers] = useState([]);
  const [secretaries, setSecretaries] = useState([]);
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    _getUsersList();
    _getSecretaryList();
  }, []);

  const _getSecretaryList = async () => {
    let params = {};
    params["role_name"] = "Secretary";
    await usersService
      .getAllUsersAPI(params)
      .then((response) => response.data)
      .then((response) => {
        setSecretaries(response);
      })
      .catch((err) => console.error(err));
  };

  const _getUsersList = async () => {
    let params = {};
    params["role_name"] = "Bailiff";
    await usersService
      .getAllUsersAPI(params)
      .then((response) => response.data)
      .then((response) => {
        setBailiffUsers(response);
      })

      .catch((err) => console.error(err));
  };
  return (
    <div className="tableselect_toprow">
      <Row gutter={[3, 24]}>
        <Col id="col1" className="gutter-row" xs={12} md={3} lg={3}>
          &nbsp;
        </Col>
        <Col id="col2" className="gutter-row" xs={12} md={3} lg={3}>
          &nbsp;
        </Col>
        <Col id="col3" className="gutter-row" xs={12} md={3} lg={3}>
          &nbsp;
        </Col>
        {user.role_id == "1" && (
          <Col className="gutter-row" xs={12} md={3} lg={3}>
            <Select
              defaultValue="Nom huissier"
              className="table-rows-select"
              onChange={(value) =>
                isNew
                  ? handleChangeAdvance({ baliff_id: value })
                  : handleChangeAdvance(value, "baliff_id")
              }
            >
              {bailiffUsers &&
                bailiffUsers.map((bailiff, key) => (
                  <Option value={bailiff.id} key={key + "fd"}>
                    {bailiff.first_name + " " + bailiff.last_name}
                  </Option>
                ))}
              <Option value="">Tous</Option>
            </Select>
          </Col>
        )}
        {user.role_id == "1" && (
          <Col className="gutter-row" xs={12} md={3} lg={3}>
            <Select
              defaultValue="Nom Secrétaire"
              className="table-rows-select"
              onChange={(value) =>
                isNew
                  ? handleChangeAdvance({ secretary_id: value })
                  : handleChangeAdvance(value, "secretary_id")
              }
            >
              {secretaries &&
                secretaries.map((bailiff, key) => (
                  <Option value={bailiff.id} key={key + "fd"}>
                    {bailiff.first_name + " " + bailiff.last_name}
                  </Option>
                ))}
              <Option value="">Tous</Option>
            </Select>
          </Col>
        )}
        <Col className="gutter-row" xs={12} md={3} lg={3}>
          <Select
            defaultValue="Année"
            className="table-rows-select"
            onChange={(value) =>
              isNew
                ? handleChangeAdvance({ year: value })
                : handleChangeAdvance(value, "created_at")
            }
          >
            {YEARS &&
              YEARS.map((year, key) => (
                <Option value={year} key={key + "year"}>
                  {year}
                </Option>
              ))}
            <Option value="">Tous</Option>
          </Select>
        </Col>
        <Col className="gutter-row" xs={12} md={3} lg={3}>
          <Select
            defaultValue="Périodé"
            className="table-rows-select"
            onChange={(value) =>
              isNew
                ? handleChangeAdvance({ month: value })
                : handleChangeAdvance(value, "month")
            }
          >
            {MONTH &&
              MONTH.map((month, key) => (
                <Option value={month.value} key={key + "momht"}>
                  {month.label}
                </Option>
              ))}
            <Option value="">Tous</Option>
          </Select>
        </Col>
        {user.role_id == "1" && (
          <Col className="gutter-row" xs={12} md={3} lg={3}>
            <Select
              defaultValue="Statut"
              className="table-rows-select"
              onChange={(value) =>
                isNew
                  ? handleChangeAdvance({ status: value })
                  : handleChangeAdvance(value, "status")
              }
            >
              {user.role_id == "1" && <Option value="NA">NA</Option>}
              <Option value="EC">EC</Option>
              {user.role_id == "1" && <Option value="RS">RS</Option>}
              <Option value="TE">TE</Option>
              <Option value="AR">AR</Option>
              <Option value="">Tous</Option>
            </Select>
          </Col>
        )}
        {user.role_id == "2" && (
          <Col className="gutter-row" xs={12} md={3} lg={3}>
            <Select
              defaultValue="Statut"
              className="table-rows-select"
              onChange={(value) =>
                isNew
                  ? handleChangeAdvance({ status: value })
                  : handleChangeAdvance(value, "status")
              }
            >
              <Option value="EC">EC</Option>
              <Option value="TE">TE</Option>
              <Option value="AR">AR</Option>
              <Option value="">Tous</Option>
            </Select>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default AdvanceFilterOptions;
