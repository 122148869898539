import React, { useState, useEffect } from "react"
import { Layout, Table, Input, Space, Row, Col, Select, Modal } from "antd"
import {
  SearchOutlined,
  FlagOutlined,
  EyeOutlined,
  FlagFilled,
} from "@ant-design/icons"
import moment from "moment"
import Commonsecretaryheader from "../commonsecretaryheader"
import { newsServices } from "../../../_services/newsServices"

const { Content } = Layout
const { Option } = Select

function ViewnewSecretarie() {
  const [paginateNo, setPaginateNo] = useState(10)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [allNews, setAllNews] = useState([])
  const [modalData, setModalData] = useState({
    description: "",
    title: "",
    date: "",
    important: "",
  })
  useEffect(() => {
    _getAllNews()
  }, [])

  const keywordHander = async (event) => {
    await _getAllNews({ keyword: event.target.value })
  }

  const _getAllNews = async (params = {}) => {
    await newsServices
      .getAllNewsAPI()
      .then((response) => response.data)
      .then((response) => {
        let temp: any = []
        response.map((news) => {
          temp.push({
            important: news.important,
            date: moment(news.created_at).format("DD/MM/YYYY HH:mm"),
            actualite: news.title.replace(/&lt;/g, "<").replace(/&gt;/g, ">"),
            view: news,
          })
        })
        setAllNews(temp)
      })
      .catch((err) => console.error(err))
  }

  const showModal = (news) => {
    let temp = {
      description: news.description.replace(/&lt;/g, "<").replace(/&gt;/g, ">"),
      title: news.title,
      date: moment(news.createdAt).format("DD/MM/YYYY HH:mm"),
      important: news.important,
    }
    setModalData(temp)
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const columns = [
    {
      title: "",
      dataIndex: "important",
      render: (important) => (
        <Space size="middle">
          {important ? (
            <FlagFilled className="modalflaticon" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          ) : (
            <FlagOutlined className="modalflaticon-empty" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          )}
        </Space>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Actualité",
      dataIndex: "actualite",
      render: (desctiption) => (
        <Space size="middle">
          <div
            dangerouslySetInnerHTML={{
              __html: desctiption.replace(/&lt;/g, "<").replace(/&gt;/g, ">"),
            }}
          />
        </Space>
      ),
    },
    {
      title: "Voir",
      dataIndex: "view",
      render: (news) => (
        <Space size="middle">
          <EyeOutlined onClick={() => showModal(news)} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
        </Space>
      ),
    },
  ]

  const data = [] as any
  for (let i = 0; i < 9; ++i) {
    data.push({
      key: i,
      date: "10-10-2017  19:23",
      actualite: "Actualisation des graphiques de présentation po...",
    })
  }
  return (
    <div className="dashboard-right-section creer-constat-wrapper noleftmenu_section">
      <div className="form_outer_wrapper secretarydashboard secretarydashboard-reaponsive">
        <Commonsecretaryheader />
        <h2>VOIR LES ACTUALITÉS</h2>
        <div className="form_tableouter_wrapper">
          <Row style={{ marginBottom: "20px" }}>
            <Col className="gutter-row" xs={8} md={10} lg={20}>
              <Select
                defaultValue="10"
                className="table-rows-select"
                onChange={(value: any) => setPaginateNo(Number(value))}
              >
                <Option value="10">10</Option>
                <Option value="20">20</Option>
                <Option value="30">30</Option>
                <Option value="40">40</Option>
                <Option value="50">50</Option>
                <Option value="60">60</Option>
              </Select>
            </Col>
            <Col className="gutter-row" xs={16} md={7} lg={4}>
              <Input
                size="large"
                className="table-search-input"
                placeholder="Rechercher..."
                prefix={<SearchOutlined className="searchiconalign" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
              />
            </Col>
          </Row>

          <Table
            columns={columns}
            dataSource={allNews || []}
            size="middle"
            className="viewnews-table"
            pagination={{ pageSize: paginateNo }}
          />
          <Modal
            footer={null}
            className="viewsecretarie-modal"
            centered
            visible={isModalVisible}
            onCancel={handleCancel}
          >
            <div className="modal-container">
              {modalData.important ? (
                <FlagFilled className="modalflaticon" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              ) : (
                <FlagOutlined className="modalflaticon-empty" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
              )}
              <h2>{modalData.title}</h2>
              <div className="viewsecretarie-date">{modalData.date}</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: modalData.description
                    .replace(/&lt;/g, "<")
                    .replace(/&gt;/g, ">"),
                }}
              ></div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  )
}
export default ViewnewSecretarie
