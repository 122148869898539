export const progressEventPerformance = (
  progressEvent,
  dispatch,
  eventRedux,
  section,
  id
) => {
  const { loaded, total } = progressEvent;
  const percentageProgress = Math.floor((loaded * 100) / total);
  dispatch(
    eventRedux({ id: id, section: section, percentage: percentageProgress })
  );
};
