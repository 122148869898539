import React, { useState, useEffect } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import {
  Layout,
  Table,
  Input,
  Badge,
  Menu,
  Dropdown,
  Space,
  Row,
  Col,
  Checkbox,
  Form,
  Select,
  Breadcrumb,
  Button,
  Upload,
  message,
  Modal,
  Empty,
  notification,
} from "antd";
import {
  EditOutlined,
  SearchOutlined,
  FlagOutlined,
  EyeOutlined,
  FlagFilled,
} from "@ant-design/icons";
import moment from "moment";
import { notificationServices } from "../../../_services/notificationServices";
import { useSelector, useDispatch } from "react-redux";
//import ReactHtmlParser from "react-html-parser"

const { Content } = Layout;
const { Option } = Select;

function ViewNotifications(props) {
  const [paginateNo, setPaginateNo] = useState(10);
  const [allNotifications, setAllNotifications] = useState([]);
  const { user } = useSelector((state: any) => state.auth);

  useEffect(() => {
    _getAllNotification();
  }, []);

  const _getAllNotification = async () => {
    let notifications: any = [];
    await notificationServices
      .getAllNotificationAPI({ sendTo: user.id })
      .then((response) => response.data)
      .then((response) => {
        response.map((e) => {
          let notification = {
            date: moment(e.created_at).format("DD/MM/YYYY HH:mm"),
            body: e.body,
          };
          notifications.push({
            ...notification,
            body: notification.body
              .replace(/&lt;/g, "<")
              .replace(/&gt;/g, ">")
              .replace(/<br>/g, "")
              .replace(/&nbsp;/g, ""),
          });
        });
        setAllNotifications(notifications);
      })
      .catch((err) => console.error(err));
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      width: "10%",
    },
    {
      title: "Notifications",
      dataIndex: "body",
      width: "90%",
      render: (desctiption) => {
        //remove all br tags, remove all spaces and line breaks, and replace all html tags with spaces and replace all lines with white space
        let description = desctiption
          .replace(/<[^>]*>/g, " ")
          .replace(/[\r\n]+/g, " ");

        //remove spaces > 1
        description = description.replace(/\s\s+/g, " ");

        return (
          <Space size="middle" className="clickable">
            <div
              dangerouslySetInnerHTML={{
                //limit to 100 characters
                __html:
                  description.length > 100
                    ? description.substring(0, 100) + "..."
                    : description,
              }}
            />
          </Space>
        );
      },
    },
  ];

  const ExpandedRowRender = (record) => {
    return (
      <div className="expanded-row">
        <div className="expanded-row-content">
          <div className="expanded-row-content-title">
            <h3>Contenu de la notification</h3>
          </div>
          <div className="expanded-row-content-body">
            <div dangerouslySetInnerHTML={{ __html: record.body }} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="dashboard-right-section viewnews_wrapper">
      <Content style={{ marginLeft: "0px" }}>
        <Breadcrumb>
          <Breadcrumb.Item>Actualités</Breadcrumb.Item>
          <Breadcrumb.Item>Tous les notifications</Breadcrumb.Item>
        </Breadcrumb>
      </Content>
      <div className="form_viewnews_wrapper">
        <h2>TOUTES LES NOTIFICATIONS</h2>
        <div className="tabletop-filter-section">
          <Row>
            <Col className="gutter-row" xs={8} md={10} lg={8}>
              <Select
                defaultValue="10"
                className="table-rows-select"
                onChange={(value: any) => setPaginateNo(Number(value))}
              >
                <Option value="10">10</Option>
                <Option value="20">20</Option>
                <Option value="30">30</Option>
                <Option value="40">40</Option>
                <Option value="50">50</Option>
                <Option value="60">60</Option>
              </Select>
            </Col>
          </Row>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={allNotifications || []}
        size="middle"
        className="viewnews-table"
        rowKey={(index) => index}
        expandable={{
          expandedRowRender: (record) => ExpandedRowRender(record),
          rowExpandable: (record: any) => true,
          expandRowByClick: true,
          expandIconColumnIndex: -1,
        }}
        pagination={{ pageSize: paginateNo }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Aucune notification associée"
            />
          ),
        }}
      />
    </div>
  );
}
export default ViewNotifications;
