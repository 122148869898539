import api from "../../configuration/api";

const statisticsService = {
  getStatistics: async (params) =>
    api.get("statistics/get", {
      params: params,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }),
};

export default statisticsService;
